import "./applicantNotes.scss";
import React, { useEffect, useRef, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { message } from "antd";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ClipLoader from "react-spinners/ClipLoader";
import { somethingWentWrong } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const ApplicantNotes = ({ applicant }) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowCreate, setAllowCreate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const newNote = useRef();
  const newEditedNote = useRef();
  const { t } = useTranslation();
  const translationComponent = "applicantNotes";

  useEffect(() => {
    const getNotes = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`notes/candidate/${applicant?._id}`);
        if (data.status === "success") setNotes(data.notes);
        else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
      setLoading(false);
    };
    getNotes();
  }, [applicant, refresh]);

  const handleDelete = async (id) => {
    if (window.confirm(t(`${translationComponent}.confirmDeleteCallLog`))) {
      try {
        const { data } = await axios.delete(`notes/${id}`);
        if (data.status === "success") {
          message.success(data.message);
          setSelectedNote({});
          setRefresh(!refresh);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    } else {
      setSelectedNote({});
    }
  };

  const handleEdit = async () => {
    try {
      const { data } = await axios.put(`notes/${selectedNote._id}`, {
        note: newEditedNote.current.value,
      });
      if (data.status === "success") setRefresh(!refresh);
      else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleCreate = async () => {
    if (newNote.current.value.trim() === "")
      return message.info("Note cannot be empty");
    try {
      const { data } = await axios.post(`notes`, {
        applicantId: applicant?._id,
        note: newNote.current.value.trim(),
      });
      if (data.status === "success") {
        setRefresh(!refresh);
        setAllowCreate(false);
      } else if (data.status === "error") {
        message.error(data.message);
      }
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  return (
    <div className="applicantNotesContainer">
      {allowCreate && (
        <div className="applicantNotesAddContainer">
          <div className="applicantNotesAddWrapper">
            <input
              ref={newNote}
              className="applicantNotesAddTextInput"
              type="text"
            />
            <span className="applicantNotesAddBtnsWrapper">
              <button
                className="applicantNotesAddBtn"
                onClick={() => setAllowCreate(false)}
              >
                {t(`${translationComponent}.Cancel`)}
              </button>
              <button
                onClick={() => handleCreate()}
                className="applicantNotesAddBtn"
              >
                {t(`${translationComponent}.Save`)}
              </button>
            </span>
          </div>
        </div>
      )}
      <h2>
        {applicant?.lastName} {applicant?.firstName}'s Notes
      </h2>
      <button
        className="applicantNotesAddNewNoteBtn"
        onClick={() => {
          setAllowEdit(false);
          setSelectedNote({});
          setAllowCreate(true);
        }}
      >
        {t(`${translationComponent}.Add new Note`)}
      </button>

      {loading ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : notes?.length > 0 ? (
        <table className="applicantNotesTable">
          <tr className="applicantNotesTableTr">
            <th>{t(`${translationComponent}.Date`)}</th>
            <th>{t(`${translationComponent}.Note`)}</th>
            <th>{t(`${translationComponent}.Action`)}</th>
          </tr>
          {notes?.map((note) => (
            <tr key={note._id} className="applicantNotesTableTr">
              <td>
                {note.createdAt.slice(0, 10)} at {note.createdAt.slice(11, 16)}
              </td>
              <td>
                {selectedNote === note && allowEdit ? (
                  <input type="text" ref={newEditedNote} />
                ) : (
                  <p>{note.note}</p>
                )}
              </td>
              <td className="applicantNotesTableActionIcons">
                {allowEdit ? (
                  <>
                    <DoneIcon
                      className="applicantNotesTableActionIcon"
                      onClick={() => {
                        handleEdit();
                        setAllowEdit(false);
                        setSelectedNote({});
                      }}
                    />
                    <CloseIcon
                      className="applicantNotesTableActionIcon"
                      onClick={() => {
                        setAllowEdit(false);
                        setSelectedNote({});
                      }}
                    />
                  </>
                ) : (
                  <CreateIcon
                    onClick={() => {
                      setSelectedNote(note);
                      setAllowEdit(true);
                    }}
                    className="applicantNotesTableActionIcon"
                  />
                )}
                <DeleteIcon
                  onClick={() => {
                    handleDelete(note._id);
                  }}
                  className="applicantNotesTableActionIcon"
                />
              </td>
            </tr>
          ))}
        </table>
      ) : (
        <p style={{ margin: "auto" }}>
          {t(`${translationComponent}.noNotesYet`)}
        </p>
      )}
    </div>
  );
};

export default ApplicantNotes;
