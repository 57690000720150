import { message } from "antd";
import "./deletePopup.scss";
import axios from "axios";
import { somethingWentWrong } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const DeletePopup = ({
  element,
  closeDeletePopup,
  user,
  need,
  log,
  userRole,
  refresh,
  applicant,
  setRefresh,
  test,
}) => {
  const { t } = useTranslation();
  const translationComponent = "deletePopup";

  const handleDeleteUser = async () => {
    try {
      const { data } = await axios.delete(`users/${user?._id}`, {
        data: { userRole },
      });
      if (data.status === "success") {
        message.success(data.message);
        closeDeletePopup();
        setRefresh(!refresh);
      } else if (data.status === "error") message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleDeleteNeed = async () => {
    try {
      const { data } = await axios.delete(`hiringNeeds/${need._id}`);
      if (data.status === "success") {
        message.success(data.message);
        setTimeout(() => {
          closeDeletePopup();
          setRefresh(!refresh);
        }, 1000);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleDeleteApplicant = async () => {
    const { data } = await axios.delete(`applications/${applicant._id}`, {
      data: { role: userRole },
    });
    if (data.status === "success") {
      message.success(data.message);
      setTimeout(() => {
        closeDeletePopup();
        window.location.replace("/hiring");
      }, 500);
    } else if (data.status === "notFound") message.error(data.message);
    try {
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleDeleteLog = async () => {
    try {
      const { data } = await axios.delete(`logs/${log._id}`);
      if (data.status === "success") {
        message.success(data.message);
        closeDeletePopup();
        setRefresh(!refresh);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleDeleteApplicantLog = async () => {
    try {
      const { data } = await axios.delete(`logs/${log._id}`);
      if (data.status === "success") {
        message.success(data.message);
        closeDeletePopup();
        setRefresh(!refresh);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleDeleteTest = async () => {
    try {
      const { data } = await axios.delete(`candidatesTests/${test._id}`);
      if (data.status === "success") {
        message.success(data.message);
        closeDeletePopup();
        setRefresh(!refresh);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleDeleteStudentTest = async () => {
    try {
      const { data } = await axios.delete(`studentsTests/${test._id}`);
      if (data.status === "success") {
        message.success(data.message);
        closeDeletePopup();
        setRefresh(!refresh);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  return (
    <div className="deletePopupWrapper">
      <p>
        {t(`${translationComponent}.You are about to delete this `)}
        {element}
        {t(`${translationComponent}.actionCantBeUndone`)}
      </p>
      <span className="deletePopupBtns">
        <button
          onClick={() =>
            element === "user"
              ? handleDeleteUser()
              : element === "need"
              ? handleDeleteNeed()
              : element === "applicant"
              ? handleDeleteApplicant()
              : element === "call"
              ? handleDeleteLog()
              : element === "applicantLog"
              ? handleDeleteApplicantLog()
              : element === "test"
              ? handleDeleteTest()
              : element === "studentTest"
              ? handleDeleteStudentTest()
              : message.error(t(`${translationComponent}.pleaseSelectElement`))
          }
        >
          {t(`${translationComponent}.Yes`)}
        </button>
        <button onClick={() => closeDeletePopup()}>
          {t(`${translationComponent}.Cancel`)}
        </button>
      </span>
    </div>
  );
};

export default DeletePopup;
