import { useEffect, useRef, useState } from "react";
import "./candidatesTests.scss";
import axios from "axios";
import { message } from "antd";
import {
  constants,
  gradeLevels,
  subjects,
  testsDifficulty,
} from "../../utils/constants";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeletePopup from "../../components/deletePopup/DeletePopup";
import { fileOnChangeTests } from "../../hooks/fileOnChange";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClipLoader from "react-spinners/ClipLoader";
import { somethingWentWrong } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const CandidatesTests = () => {
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}images/`;
  const [type, setType] = useState("");
  const [selectedTest, setSelectedTest] = useState({});
  const element = "test";
  const [showTest, setShowTest] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const { t } = useTranslation();
  const translationComponent = "candidatesTests";

  useEffect(() => {
    const fetchTests = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`candidatesTests`);
        if (data.status === "success") setTests(data.tests);
      } catch (err) {
        message.error(somethingWentWrong);
      }
      setLoading(false);
    };
    fetchTests();
  }, [refresh]);

  const AddEditPopup = () => {
    const grade = useRef();
    const subject = useRef();
    const difficulty = useRef();
    const createdBy = useRef();
    const approvedBy = useRef();
    const [test, setTest] = useState([]);
    const [testPreview, setTestPreview] = useState("");
    const [answers, setAnswers] = useState([]);
    const [answersPreview, setAnswersPreview] = useState("");

    const handleAddEditTest = async (e) => {
      e.preventDefault();
      let newOrUpdatedTest;
      if (type === "add") {
        newOrUpdatedTest = {
          grade: grade.current.value,
          subject: subject.current.value,
          difficulty: difficulty.current.value,
          createdBy: createdBy.current.value,
          approvedBy: approvedBy.current.value,
        };
      } else if (type === "edit") {
        newOrUpdatedTest = {
          grade: grade.current.value || selectedTest.grade,
          subject: subject.current.value || selectedTest.subject,
          difficulty: difficulty.current.value || selectedTest.difficulty,
          createdBy: createdBy.current.value || selectedTest.createdBy,
          approvedBy: approvedBy.current.value || selectedTest.approvedBy,
        };
      }
      if (test.length > 0) {
        newOrUpdatedTest.test = type === "add" ? [] : selectedTest.test || [];
        const data = new FormData();
        for (let i = 0; i < test.length; i++) {
          const filename =
            Date.now() +
            newOrUpdatedTest.grade +
            newOrUpdatedTest.subject +
            "candidatesTest" +
            test[i].name;
          data.append("name", filename);
          const img = test[i];
          data.append("file", img, filename);
          newOrUpdatedTest.test.push(filename);
        }
        try {
          await axios.post("multiUpload", data);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      if (answers.length > 0) {
        newOrUpdatedTest.answers =
          type === "add" ? [] : selectedTest.answers || [];
        const data = new FormData();
        for (let i = 0; i < answers.length; i++) {
          const filename =
            Date.now() +
            newOrUpdatedTest.grade +
            newOrUpdatedTest.subject +
            "candidatesTestAnswers" +
            answers[i].name;
          data.append("name", filename);
          const img = answers[i];
          data.append("file", img, filename);
          newOrUpdatedTest.answers.push(filename);
        }
        try {
          await axios.post("multiUpload", data);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      try {
        let res;
        if (type === "add") {
          res = await axios.post(`candidatesTests`, newOrUpdatedTest);
          if (res?.data?.status === "success") {
            setRefresh(!refresh);
            message.success(res?.data?.message);
            setOpenAddPopup(false);
          } else
            message.error(
              t(
                `${translationComponent}.somethingWentWrongWithCandidateTestCreation`
              )
            );
        } else if (type === "edit") {
          res = await axios.put(
            `candidatesTests/${selectedTest._id}`,
            newOrUpdatedTest
          );
          if (res?.data?.status === "success") {
            setRefresh(!refresh);
            message.success(res?.data?.message);
            setOpenEditPopup(false);
          } else message.error(res?.data?.message);
        }
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };

    return (
      <div className="candidatesTestsAddEditPopupContainer">
        <div className="candidatesTestsAddEditPopupWrapper">
          <span
            onClick={
              type === "add"
                ? () => setOpenAddPopup(false)
                : () => setOpenEditPopup(false)
            }
            className="candidatesTestsAddEditPopupCloseBtn"
          >
            &times;{" "}
          </span>
          <form
            className="candidatesTestsAddEditPopupForm"
            onSubmit={handleAddEditTest}
          >
            <label>
              <span>
                {t(`${translationComponent}.Grade`)} &nbsp;{" "}
                {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
              </span>
              <select required={type === "add"} ref={grade}>
                <option value="" hidden></option>
                {gradeLevels.map((cycle) => (
                  <optgroup label={t(cycle.translationKey)}>
                    {cycle.grades.map((grade) => (
                      <option value={grade.value}>
                        {t(grade.translationKey)}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </label>
            <label>
              <span>
                {t(`${translationComponent}.Subject`)} &nbsp;{" "}
                {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
              </span>
              <select required={type === "add"} ref={subject}>
                <option value="" hidden></option>
                {subjects.map((subject) => (
                  <option value={subject.value}>
                    {t(subject.translationKey)}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <span>{t(`${translationComponent}.Difficulty`)}</span>
              <select ref={difficulty}>
                <option value="" hidden></option>
                {testsDifficulty.map((difficulty) => (
                  <option value={difficulty.value}>
                    {t(difficulty.translationKey)}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <span>
                {t(`${translationComponent}.Created By `)}&nbsp;
                {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
              </span>
              <input required={type === "add"} ref={createdBy} type="text" />
            </label>
            <label>
              <span>{t(`${translationComponent}.Approved By `)}</span>
              <input ref={approvedBy} type="text" />
            </label>
            <label className="candidatesTestsAddEditPopupTestAnswersLabel">
              <span>
                <span>
                  {t(`${translationComponent}.Test`)} &nbsp;
                  {type === "add" && (
                    <span style={{ color: "#ef711d" }}>*</span>
                  )}
                </span>
                <AttachFileIcon className="candidatesTestsAddEditPopupTestIcon" />
                <input
                  type="file"
                  multiple
                  onChange={(e) =>
                    fileOnChangeTests(
                      e,
                      "test",
                      test,
                      setTest,
                      testPreview,
                      setTestPreview,
                      answers,
                      setAnswers,
                      answersPreview,
                      setAnswersPreview
                    )
                  }
                />
              </span>
              {type === "add"
                ? testPreview && (
                    <span className="newApplicationFormImgPreviewSpan">
                      <img
                        className="candidatesTestsAddEditPopupAnswersPreview"
                        src={testPreview}
                      />
                      {test.length > 1 && (
                        <span className="newApplicationFormImgPreviewLength">
                          {" "}
                          +{test.length - 1}{" "}
                        </span>
                      )}
                    </span>
                  )
                : (testPreview || selectedTest?.test.length > 0) && (
                    <span className="applicantEditPhotoPreview">
                      <img
                        className="candidatesTestsAddEditPopupTestPreview"
                        src={
                          selectedTest?.test.length > 0
                            ? PF + selectedTest?.test[0]
                            : testPreview
                        }
                      />
                      {(selectedTest?.test.length > 1 ||
                        test.length > 1 ||
                        selectedTest?.test.length + test.length > 1) && (
                        <span className="newApplicationFormImgPreviewLength">
                          {" "}
                          +{selectedTest?.test.length + test.length - 1}
                        </span>
                      )}
                    </span>
                  )}
            </label>
            <label className="candidatesTestsAddEditPopupTestAnswersLabel">
              <span>
                {t(`${translationComponent}.Answers / Correction`)}{" "}
                <AttachFileIcon className="candidatesTestsAddEditPopupAnswersIcon" />
                <input
                  type="file"
                  multiple
                  onChange={(e) =>
                    fileOnChangeTests(
                      e,
                      "answers",
                      test,
                      setTest,
                      testPreview,
                      setTestPreview,
                      answers,
                      setAnswers,
                      answersPreview,
                      setAnswersPreview
                    )
                  }
                />
              </span>
              {type === "add"
                ? answersPreview && (
                    <span className="newApplicationFormImgPreviewSpan">
                      <img
                        className="candidatesTestsAddEditPopupAnswersPreview"
                        src={answersPreview}
                      />
                      {answers.length > 1 && (
                        <span className="newApplicationFormImgPreviewLength">
                          {" "}
                          +{answers.length - 1}{" "}
                        </span>
                      )}
                    </span>
                  )
                : (answersPreview || selectedTest?.answers.length > 0) && (
                    <span className="applicantEditPhotoPreview">
                      <img
                        className="candidatesTestsAddEditPopupTestPreview"
                        src={
                          selectedTest?.answers.length > 0
                            ? PF + selectedTest?.answers[0]
                            : answersPreview
                        }
                      />
                      {(selectedTest?.answers.length > 1 ||
                        answers.length > 1 ||
                        selectedTest?.answers.length + answers.length > 1) && (
                        <span className="newApplicationFormImgPreviewLength">
                          {" "}
                          +{selectedTest?.answers.length +
                            answers.length -
                            1}{" "}
                        </span>
                      )}
                    </span>
                  )}
            </label>
            <button
              className="candidatesTestsAddEditPopupSubmitBtn"
              type="submit"
            >
              {t(`${translationComponent}.Submit`)}
            </button>
          </form>
        </div>
      </div>
    );
  };

  const handleDeleteSingleImg = async (idx, img, element) => {
    if (element !== "singleTest" || selectedTest.test.length > 1) {
      if (window.confirm(`Are you sure you want to delete this image? `)) {
        try {
          const { data } = await axios.put(
            `candidatesTests/remove/${selectedTest?._id}/${element}/${idx}/${img}`
          );
          if (data.status === "success") {
            message.success(data.message);
            window.location.reload();
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    } else message.error(t(`${translationComponent}.keepOneImage`));
  };

  if (showTest) {
    return (
      <div className="testContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowTest(false)}
        >
          <ArrowBackIosIcon />
          {t(`${translationComponent}.View all tests`)}
        </span>
        <div className="resumeContainerImages">
          {selectedTest.test.length > 0 &&
            selectedTest?.test.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(index, image, "singleTest");
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Candidate Test" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  if (showAnswers) {
    return (
      <div className="testContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowAnswers(false)}
        >
          <ArrowBackIosIcon />
          {t(`${translationComponent}.View all tests`)}
        </span>
        <div className="resumeContainerImages">
          {selectedTest.answers.length > 0 &&
            selectedTest?.answers.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(index, image, "singleTestAnswers");
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Candidate Test Answers" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <button
        className="candidatesTestsAddBtn"
        onClick={() => {
          setType("add");
          setOpenAddPopup(true);
          setOpenEditPopup(false);
          setOpenDeletePopup(false);
        }}
      >
        {t(`${translationComponent}.Add Test`)}
      </button>
      {loading ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : tests.length ? (
        <table className="candidatesTestsTable">
          <tr className="candidatesTestsTableTr">
            <th>{t(`${translationComponent}.Test Created`)}</th>
            <th>{t(`${translationComponent}.By`)}</th>
            <th>{t(`${translationComponent}.Approved By `)}</th>
            <th>{t(`${translationComponent}.Grade/Level`)}</th>
            <th>{t(`${translationComponent}.Subject`)}</th>
            <th>{t(`${translationComponent}.Difficulty`)}</th>
            <th>{t(`${translationComponent}.Test`)}</th>
            <th>{t(`${translationComponent}.Answers`)}</th>
            <th>{t(`${translationComponent}.Edit/Delete`)}</th>
          </tr>
          {tests?.map((test) => (
            <tr className="candidatesTestsTableTr">
              <td>{test.createdAt?.slice(0, 10)}</td>
              <td>{test.createdBy}</td>
              <td>{test.approvedBy}</td>
              <td>{test.grade}</td>
              <td>{test.subject}</td>
              <td>{test.difficulty}</td>
              <td>
                <VisibilityIcon
                  className="candidatesTestsTableVisibilityIcon"
                  onClick={() => {
                    setSelectedTest(test);
                    setShowTest(true);
                  }}
                />
              </td>
              <td>
                {test.answers.length > 0 && (
                  <VisibilityIcon
                    className="candidatesTestsTableVisibilityIcon"
                    onClick={() => {
                      setSelectedTest(test);
                      setShowAnswers(true);
                    }}
                  />
                )}
              </td>
              <td className="candidatesTestIcons">
                <CreateIcon
                  onClick={() => {
                    setType("edit");
                    setSelectedTest(test);
                    setOpenAddPopup(false);
                    setOpenDeletePopup(false);
                    setOpenEditPopup(true);
                  }}
                  className="candidatesTestIcon"
                />
                <DeleteIcon
                  onClick={() => {
                    setOpenEditPopup(false);
                    setOpenAddPopup(false);
                    setSelectedTest(test);
                    setOpenDeletePopup(true);
                  }}
                  className="candidatesTestIcon"
                />
              </td>
            </tr>
          ))}
          {openEditPopup && <AddEditPopup />}
          {openDeletePopup && (
            <div className="deletePopupContainer">
              <DeletePopup
                element={element}
                closeDeletePopup={() => {
                  setOpenDeletePopup(false);
                }}
                refresh={refresh}
                setRefresh={setRefresh}
                test={selectedTest}
              />
            </div>
          )}
        </table>
      ) : (
        <p style={{ textAlign: "center" }}>
          {t(`${translationComponent}.No Tests Found`)}
        </p>
      )}
      {openAddPopup && <AddEditPopup />}
    </div>
  );
};

export default CandidatesTests;
