import "./footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import QR from "../../images/QR.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const translationComponent = "footer";

  return (
    <div className="footerContainer">
      {/**footerTop is the 6 columns, footerBottom is the copyright text*/}
      <div className="footerTop">
        <span className="footerTop1">
          <p className="footerTopHeader">Hilali Targa International School</p>
          <div className="footerTopHeaderLocationDiv">
            <span className="footerInLine footerTopHeaderLocationText">
              <LocationOnOutlinedIcon className="footerInfoIcon" />
              <p className="footerTopAddress">
                N°1 lotissement Assalam,Av.Palais Badi, Route de Targa,
                Marrakesh 40000
              </p>
            </span>
            <span class="footerInLine footerTopHeaderLocationText">
              <MailOutlineOutlinedIcon className="footerInfoIcon" />
              <p className="footerTopEmail">hilali.targa@gmail.com</p>
            </span>
            <span class="footerInLine footerTopHeaderLocationText">
              <LocalPhoneOutlinedIcon className="footerInfoIcon" />
              <p className="footerTopPhone">+212 524 341 341</p>
            </span>
          </div>
          <iframe
            className="footerMap"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=N%C2%B01%20lotissement%20Assalam,Av.Palais%20Badi,%20Route%20de%20Targa,%20Marrakesh%2040000&t=&z=17&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </span>
        <span className="footerTop2">
          <ul className="footerTopHeader">
            {t(`${translationComponent}.About`)}
            <li>
              <Link className="links" to="/letter">
                {t(
                  `${translationComponent}.Letter from the Founding Directors`
                )}
              </Link>
            </li>
            <li>
              <Link className="links" to="/project">
                {t(`${translationComponent}.Our School Project`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/cycles">
                {t(`${translationComponent}.Our Cycles`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/alumni">
                {t(`${translationComponent}.Alumni`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/traditions">
                {t(`${translationComponent}.Traditions`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/accreditations">
                {t(`${translationComponent}.Accreditations & Certifications`)}
              </Link>
            </li>
          </ul>
        </span>
        <span className="footerTop3">
          <ul className="footerTopHeader">
            {t(`${translationComponent}.Student Life`)}
            <li>
              <Link className="links" to="/clubs">
                {t(`${translationComponent}.Clubs & Organizations`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/webinars">
                {t(`${translationComponent}.Interviews & Webinars`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/radio">
                {t(`${translationComponent}.School Radio`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/publicSpeaking">
                {t(`${translationComponent}.Public Speaking`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/virtualExchange">
                {t(`${translationComponent}.Virtual Exchange`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/tigersNights">
                Tigers Nights
              </Link>
            </li>
            <li>
              <Link className="links" to="/MUN">
                MUN
              </Link>
            </li>
            <li>
              <Link className="links" to="/sports">
                {t(`${translationComponent}.Sports`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/trips">
                {t(`${translationComponent}.School Trips`)}
              </Link>
            </li>
          </ul>
        </span>
        <span className="footerTop4">
          <ul className="footerTopHeader">
            {t(`${translationComponent}.Services`)}
            <li>
              <Link className="links" to="/service/cafeteria">
                {t(`${translationComponent}.Cafeteria`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/service/bus">
                {t(`${translationComponent}.School Bus`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/service/infirmary">
                {t(`${translationComponent}.Infirmary`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/service/tutoring">
                {t(`${translationComponent}.Tutoring`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/service/library">
                {t(`${translationComponent}.Library`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/service/afterSchoolCare">
                {t(`${translationComponent}.After-school Care`)}
              </Link>
            </li>
          </ul>
        </span>
        <span className="footerTop5">
          <ul className="footerTopHeader">
            {t(`${translationComponent}.Resources`)}
            <li>
              <Link className="links" to="/virtualTour">
                {t(`${translationComponent}.Virtual Tour`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/orientation">
                {t(`${translationComponent}.Orientation`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/wallOfFame">
                Wall of Fame
              </Link>
            </li>
            <li>
              <Link className="links" to="/rules">
                {t(`${translationComponent}.School Rules`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/calendar">
                {t(`${translationComponent}.School Calendar`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/suppliesLists">
                {t(`${translationComponent}.School Supplies Lists`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/eLearning">
                {t(`${translationComponent}.e-Learning`)}
              </Link>
            </li>
            <li>
              <Link className="links" to="/scholarships">
                {t(`${translationComponent}.Scholarships`)}
              </Link>
            </li>
          </ul>
        </span>
        <span className="footerTop6">
          <ul>
            <li className="footerTopHeader" style={{ cursor: "pointer" }}>
              <Link className="links" to="/contact">
                {t(`${translationComponent}.Contact Us`)}
              </Link>
            </li>
            <li className="footerTopHeader" style={{ cursor: "pointer" }}>
              <Link className="links" to="/apply">
                {t(`${translationComponent}.Employment`)}
              </Link>
            </li>
          </ul>
          <div className="footerTop4IconsAndQR">
            <span className="footerSocialIcons">
              <a
                className="links"
                href="https://fr-fr.facebook.com/hilali.targa/"
                target="_blank"
              >
                <FacebookIcon className="footerSocialIcon" />
              </a>
              <a
                className="links"
                href="https://www.youtube.com/channel/UCQs4zHrM9OTza-mgX99XO1Q"
                target="_blank"
              >
                <YouTubeIcon className="footerSocialIcon" />
              </a>
              <a
                className="links"
                href="https://www.instagram.com/htis_official"
                target="_blank"
              >
                <InstagramIcon className="footerSocialIcon" />
              </a>
            </span>
            <img className="footerQR" src={QR} alt="HTIS QR Code" />
          </div>
        </span>
      </div>

      <div className="footerBottom">
        Copyright @{new Date().getFullYear()} All Rights Reserved
      </div>
      {/*<span id="developedBy">Developed by Sara Hilali</span>*/}
    </div>
  );
};

export default Footer;
