import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { message } from "antd";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import ApplicantNotes from "../../components/applicantNotes/ApplicantNotes";
import { AuthContext } from "../../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import { somethingWentWrong } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const ShowNotes = ({ setShowNotes, applicant, applicantId }) => {
  const { user } = useContext(AuthContext);
  const [candidate, setCandidate] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const translationComponent = "showNotes";

  useEffect(() => {
    const fetchCandidate = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `applications/${user?.role}/${applicantId}`
        );
        if (data.status === "success") setCandidate(data.application);
        else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
      setLoading(false);
    };

    if (applicantId) fetchCandidate();
  }, [applicant]);

  return (
    <>
      {loading ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : (
        <div className="applicantLogsContainer">
          <span
            onClick={() => setShowNotes(false)}
            style={{ marginBottom: "20px", cursor: "pointer" }}
          >
            <ArrowBackIosIcon />
            {t(`${translationComponent}.Go Back`)}
          </span>
          <ApplicantNotes applicant={applicant ? applicant : candidate} />
        </div>
      )}
    </>
  );
};

export default ShowNotes;
