import "./accreditations.scss";
import DELFDALFLogo from "../../images/DELFDALFLogo.png";
import DELELogo from "../../images/DELELogo.jpeg";
import CambridgeLogo from "../../images/CambridgeLogo.png";
import RedCrossLogo from "../../images/RedCrossLogo.png";

const Accreditations = () => {
  return (
    <div className="accreditationsContainer">
      <img
        className="homeCertificationsLogo"
        src={CambridgeLogo}
        alt="Cambridge Logo"
      />
      <img className="homeCertificationsLogo" src={DELELogo} alt="DELE Logo" />
      <img
        className="homeCertificationsLogo"
        src={DELFDALFLogo}
        alt="DELF DALF Logo"
      />
      <img
        className="homeCertificationsLogo"
        src={RedCrossLogo}
        alt="Red Cross Logo"
      />
    </div>
  );
};

export default Accreditations;
