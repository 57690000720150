import "./webinars.scss";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import { webinars } from "../../utils/texts";

const Webinars = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = webinars[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="webinarsContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
      <span className="radioSocialMediaIcons">
        <a
          className="links radioSocialMediaIcon radioYTIcon"
          href="https://www.youtube.com/watch?v=k7itxm5CdOo&list=PLtR-4Oz-WiEiA2gBCMnYAyQmjV_LsCl6T"
          target="_blank"
        >
          <YouTubeIcon style={{ fontSize: "5vh" }} />
        </a>
      </span>
    </div>
  );
};

export default Webinars;
