import "./clubs.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Clubs = () => {
  const { t } = useTranslation();
  const translationPage = "pages.clubs";

  return (
    <div className="clubsContainer">
      <h1>{t(`${translationPage}.Clubs & Organizations`)}</h1>
      <div className="clubsWrapper">
        <Link className="links" to="/clubs/tigersInteract">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Interact</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersSocial">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Social</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersSports">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Sports</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersReading">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Reading</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersVirtualExchange">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Virtual Exchange</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersMedia">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Media</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersCooking">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Cooking</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersPublicSpeaking">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Public Speaking</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersRadio">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers School Radio</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersChess">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Chess</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
        <Link className="links" to="/clubs/tigersTheater">
          <div className="clubSingle">
            <div className="clubTop">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
            <div className="clubText">Tigers Theater</div>
            <div className="clubBottom">
              <div className="clubRing"></div>
              <div className="clubRing"></div>
              <div className="clubRing"></div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Clubs;
