import { useState, useEffect } from "react";
import "./popup.scss";
import { constants } from "../../utils/constants";

const Popup = ({ state }) => {
  const {
    popupTitle,
    popupImage,
    popupBody,
    popupBtn,
    setPopupOpen,
    popupIsCountdown,
    popupLink,
    isPreview,
  } = state;
  const hostAndBackendPort = constants.hostAndBackendPort;
  const homePopupPF = `${hostAndBackendPort}images/homePopups/`;

  const calculateTimeLeft = () => {
    let today = new Date();
    let difference = +new Date(popupBody) - +today;
    let timeLeft = {};

    let ds = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hrs = Math.floor((difference / (1000 * 60 * 60)) % 24);
    let min = Math.floor((difference / 1000 / 60) % 60);
    let sec = Math.floor((difference / 1000) % 60);

    if (difference > 0) {
      ds === 1 ? (timeLeft.day = ds) : (timeLeft.days = ds);
      hrs === 1 ? (timeLeft.hour = hrs) : (timeLeft.hours = hrs);
      min === 1 ? (timeLeft.minute = min) : (timeLeft.minutes = min);
      sec === 1 ? (timeLeft.second = sec) : (timeLeft.seconds = sec);
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) return;
    timerComponents.push(
      <li className="popupSingleInterval">
        <span>{timeLeft[interval]}</span> {interval}
      </li>
    );
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="popupContainer">
      <div className="popupContent">
        <span className="popupTexts">
          <span className="popupInline">
            <p className="popupHeader">{popupTitle}</p>
            <span onClick={() => setPopupOpen(false)} className="popupCloseBtn">
              &times;{" "}
            </span>
          </span>
          {popupImage && (
            <span className="popupImgWrapper">
              <img
                src={
                  popupImage.startsWith("blob")
                    ? popupImage
                    : homePopupPF + popupImage
                }
                alt="Popup Image"
                className="popupImg"
              />
            </span>
          )}
          {popupIsCountdown ? (
            <div id="popupCountdown">
              {timerComponents.length || (isPreview && popupBody !== "") ? (
                <ul>{timerComponents}</ul>
              ) : isPreview ? (
                <span className="homePopupTimeIsUp"></span>
              ) : (
                <span className="homePopupTimeIsUp">00 : 00 : 00 : 00</span>
              )}
            </div>
          ) : (
            <p className="popupText">{popupBody}</p>
          )}
          {popupBtn !== "" &&
            (popupLink === "" ? (
              <span className="popupActionSpan">{popupBtn}</span>
            ) : (
              <a href={popupLink} target="_blank" className="popupActionBtn">
                {popupBtn}
              </a>
            ))}
        </span>
      </div>
    </div>
  );
};

export default Popup;
