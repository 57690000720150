import Navbar from "./components/navbar/Navbar";
import "../src/style.scss";
import "../src/styleQueries.scss";
import Home from "./pages/home/Home";
import Applicant from "./pages/applicant/Applicant";
import Apply from "./pages/apply/Apply";
import Calendar from "./pages/calendar/Calendar";
import ContactUs from "./pages/contactUs/ContactUs";
import Login from "./pages/login/Login";
import Agenda from "./pages/agenda/Agenda";
import SuppliesLists from "./pages/suppliesLists/SuppliesLists";
import SuppliesList from "./pages/suppliesList/SuppliesList";
import Cycles from "./pages/cycles/Cycles";
import Kindergarten from "./pages/cycle/Kindergarten";
import MiddleSchool from "./pages/cycle/MiddleSchool";
import HighSchool from "./pages/cycle/HighSchool";
import Elementary from "./pages/cycle/Elementary";
import CyclePhotos from "./pages/cycle/CyclePhotos";
import Sports from "./pages/sports/Sports";
import Trips from "./pages/trips/Trips";
import User from "./pages/user/User";
import Users from "./pages/users/Users";
import VirtualTour from "./pages/virtualTour/VirtualTour";
import Letter from "./pages/letter/Letter";
import Project from "./pages/project/Project";
import MUN from "./pages/MUN/MUN";
import VirtualExchange from "./pages/virtualExchange/VirtualExchange";
import Radio from "./pages/radio/Radio";
import Webinars from "./pages/webinars/Webinars";
import Clubs from "./pages/clubs/Clubs";
import Alumni from "./pages/alumni/Alumni";
import Traditions from "./pages/traditions/Traditions";
import Accreditations from "./pages/accreditations/Accreditations";
import Service from "./pages/service/Service";
import Orientation from "./pages/orientation/Orientation";
import WallOfFame from "./pages/wallOfFame/WallOfFame";
import Rules from "./pages/rules/Rules";
import ELearning from "./pages/eLearning/ELearning";
import Services from "./pages/services/Services";
import Scholarships from "./pages/scholarships/Scholarships";
import PublicSpeaking from "./pages/publicSpeaking/PublicSpeaking";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Footer from "./components/footer/Footer";
import SidebarMini from "./components/sidebarMini/SidebarMini";
import { RingLoader } from "react-spinners";
import { useContext } from "react";
import FourOFour from "./pages/404/FourOFour";
import Club from "./pages/club/Club";
import axios from "axios";
import { AuthContext } from "./context/AuthContext";
import ManageHomePopup from "./pages/manageHomePopup/ManageHomePopup";
import { useState, useEffect } from "react";
import Hiring from "./pages/hiring/Hiring";
import StudentTests from "./pages/studentTests/StudentTests";
import TigersNights from "./pages/tigersNights/TigersNights";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

function App() {
  axios.defaults.baseURL = "https://hilalitarga.com/api/";
  const { user } = useContext(AuthContext);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    if (user?.role === "admin") setUserRole("admin");
    else if (user?.role === "hr") setUserRole("hr");
    else if (user?.role === "it") setUserRole("it");
  }, [user]);

  return (
    <Router>
      <Navbar />
      <SidebarMini />
      <Routes fallbackElement={<RingLoader className="ringloader" />}>
        <Route exact path="/hiring" element={user ? <Hiring /> : <Apply />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/letter" element={<Letter />} />
        <Route exact path="/project" element={<Project />} />
        <Route exact path="/publicSpeaking" element={<PublicSpeaking />} />
        <Route exact path="/alumni" element={<Alumni />} />
        <Route exact path="/traditions" element={<Traditions />} />
        <Route exact path="/accreditations" element={<Accreditations />} />
        <Route exact path="/calendar" element={<Calendar />} />
        <Route
          exact
          path="/applications/:id"
          element={user ? <Applicant /> : <Navigate replace to="/login" />}
        />
        <Route exact path="/agenda" element={<Agenda />} />
        <Route
          exact
          path="/apply"
          element={user ? <Navigate replace to="/hiring" /> : <Apply />}
        />
        <Route
          exact
          path="/managePopup"
          element={
            user ? <ManageHomePopup /> : <Navigate replace to="/login" />
          }
        />
        <Route
          exact
          path="/studentTests"
          element={user ? <StudentTests /> : <Navigate replace to="/login" />}
        />
        <Route exact path="/calendar" element={<Calendar />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/service/:service" element={<Service />} />
        <Route exact path="/orientation" element={<Orientation />} />
        <Route exact path="/wallOfFame" element={<WallOfFame />} />
        <Route exact path="/rules" element={<Rules />} />
        <Route exact path="/eLearning" element={<ELearning />} />
        <Route exact path="/scholarships" element={<Scholarships />} />
        <Route exact path="/MUN" element={<MUN />} />
        <Route exact path="/virtualExchange" element={<VirtualExchange />} />
        <Route exact path="/tigersNights" element={<TigersNights />} />
        <Route exact path="/radio" element={<Radio />} />
        <Route exact path="/webinars" element={<Webinars />} />
        <Route exact path="/clubs/:club" element={<Club />} />
        <Route exact path="/clubs" element={<Clubs />} />
        <Route
          exact
          path="/login"
          element={user ? <Navigate replace to="/user" /> : <Login />}
        />
        <Route exact path="/sports" element={<Sports />} />
        <Route exact path="/trips" element={<Trips />} />
        <Route exact path="/kindergarten" element={<Kindergarten />} />
        <Route exact path="/elementary" element={<Elementary />} />
        <Route exact path="/middleSchool" element={<MiddleSchool />} />
        <Route exact path="/highSchool" element={<HighSchool />} />
        <Route exact path="/photos/:cycle" element={<CyclePhotos />} />
        <Route exact path="/cycles" element={<Cycles />} />
        <Route exact path="/suppliesLists" element={<SuppliesLists />} />
        <Route exact path="/suppliesList/:cycle" element={<SuppliesList />} />
        <Route
          exact
          path="/users"
          element={user ? <Users /> : <Navigate replace to="/login" />}
        />
        <Route
          exact
          path="/user"
          element={user ? <User /> : <Navigate replace to="/login" />}
        />
        <Route exact path="/virtualTour" element={<VirtualTour />} />
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<FourOFour />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
