import "./carousel.scss";
import { SliderData } from "../../utils/carouselImages";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
 
const Carousel = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) return null;

  return (
    <section className="carouselContainer">
      <ArrowBackIosNewIcon className="carouselLeftArrow" onClick={prevSlide} />
      <ArrowForwardIosIcon className="carouselRightArrow" onClick={nextSlide} />
      {SliderData.map((slide, index) => {
        return (
          <div
            className={
              index === current ? "carouselSlide active" : "carouselSlide"
            }
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt="HTIS image" className="carouselImg" />
            )}
          </div>
        );
      })}
    </section>
  );
};

export default Carousel;
