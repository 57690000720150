import "./dropDownMenu.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DropDownMenuResponsive = ({ menu, activeInnerPage, activePage }) => {
  const { t } = useTranslation();
  const translationComponent = "dropDownMenu";

  const About = () => {
    return (
      <ul className="responsiveDropDownMenuList">
        <li
          className={`${
            activeInnerPage === "letter"
              ? "responsiveresponsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/letter">
            {t(`${translationComponent}.Letter from the Founding Directors`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "project"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/project">
            {t(`${translationComponent}.Our School Project`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "cycles"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/cycles">
            {t(`${translationComponent}.Our Cycles`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "alumni"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/alumni">
            {t(`${translationComponent}.Alumni`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "traditions"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/traditions">
            {t(`${translationComponent}.Traditions`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "accreditations"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/accreditations">
            {t(`${translationComponent}.Accreditations & Certifications`)}
          </Link>
        </li>
      </ul>
    );
  };

  const StudentLife = () => {
    return (
      <ul className="responsiveDropDownMenuList">
        <li
          className={`${
            activeInnerPage === "clubs"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/clubs">
            {t(`${translationComponent}.Clubs & Organizations`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "webinars"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/webinars">
            {t(`${translationComponent}.Interviews & Webinars`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "radio"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/radio">
            {t(`${translationComponent}.School Radio`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "publicSpeaking"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/publicSpeaking">
            {t(`${translationComponent}.Public Speaking & Speech Contest`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "virtualExchange"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/virtualExchange">
            {t(`${translationComponent}.Virtual Exchange`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "tigersNights"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/tigersNights">
            Tigers Nights
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "MUN"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/MUN">
            MUN
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "sports"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/sports">
            {t(`${translationComponent}.Sports`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "trips"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/trips">
            {t(`${translationComponent}.School Trips`)}
          </Link>
        </li>
      </ul>
    );
  };

  const Services = () => {
    return (
      <ul className="responsiveDropDownMenuList">
        <li
          className={`${
            activeInnerPage === "cafeteria"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/service/cafeteria">
            {t(`${translationComponent}.Cafeteria`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "bus"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/service/bus">
            {t(`${translationComponent}.School Bus`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "infirmary"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/service/infirmary">
            {t(`${translationComponent}.Infirmary`)}
          </Link>
        </li>

        <li
          className={`${
            activeInnerPage === "tutoring"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/service/tutoring">
            {t(`${translationComponent}.Tutoring`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "library"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/service/library">
            {t(`${translationComponent}.Library`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "afterSchoolCare"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/service/afterSchoolCare">
            {t(`${translationComponent}.After-school Care`)}
          </Link>
        </li>
      </ul>
    );
  };

  const Resources = () => {
    return (
      <ul className="responsiveDropDownMenuList">
        <li
          className={`${
            activeInnerPage === "virtualTour"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/virtualTour">
            {t(`${translationComponent}.Virtual Tour`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "orientation"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/orientation">
            {t(`${translationComponent}.Orientation`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "wallOfFame"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/wallOfFame">
            Wall Of Fame
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "rules"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/rules">
            {t(`${translationComponent}.School Rules`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "calendar"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/calendar">
            {t(`${translationComponent}.School Calendar`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "suppliesList"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/suppliesLists">
            {t(`${translationComponent}.Supplies Lists`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "eLearning"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/eLearning">
            {t(`${translationComponent}.e-Learning`)}
          </Link>
        </li>
        <li
          className={`${
            activeInnerPage === "scholarships"
              ? "responsiveNavbarMenuActive"
              : "hover-underline-animation hover-animation-white responsiveDropDownItem"
          }`}
        >
          <Link className="links" to="/scholarships">
            {t(`${translationComponent}.Scholarships`)}
          </Link>
        </li>
      </ul>
    );
  };

  return menu === "About" ? (
    <About />
  ) : menu === "Student Life" ? (
    <StudentLife />
  ) : menu === "Services" ? (
    <Services />
  ) : menu === "Resources" ? (
    <Resources />
  ) : (
    <></>
  );
};

export default DropDownMenuResponsive;
