import "./cycle.scss";
import KindergartenPhoto from "../../images/Kindergarten.JPG";
import SchoolSupplies from "../../images/SchoolSupplies.jpeg";
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useTranslation } from "react-i18next";
import { cyclesTexts } from "../../utils/texts";

const Kindergarten = () => {
  const { t, i18n } = useTranslation();
  const translationPage = "pages.cycle";

  const getTranslatedText = (cycle) => {
    const currentLanguage = i18n.language;
    const text = cyclesTexts[cycle][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="cycleContainer1">
      <Link className="links" to="/cycles">
        <ReplyIcon className="cycleGoBackArrow" />
      </Link>
      <h1>{t(`${translationPage}.Kindergarten`)}</h1>
      <>{getTranslatedText("kindergarten")}</>
      <div className="cycleSpansWrapper">
        <Link className="links" to="/photos/kindergarten">
          <span
            className="cycleSpan"
            style={{ background: `url(${KindergartenPhoto})` }}
          >
            <p className="hover-underline-animation hover-animation-white">
              {t(`${translationPage}.Photo Gallery`)}
            </p>
          </span>
        </Link>
        <Link className="links" to="/suppliesList/kindergarten">
          <span
            className="cycleSpan cycleSpansImgSupplies"
            style={{ background: `url(${SchoolSupplies})` }}
          >
            <p className="hover-underline-animation hover-animation-white">
              {t(`${translationPage}.School Supplies`)}
            </p>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Kindergarten;
