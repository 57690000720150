import { useTranslation } from "react-i18next";
import { traditions } from "../../utils/texts";
import "./traditions.scss";

function Traditions() {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = traditions[element][currentLanguage];
    return <p>{text}</p>;
  };

  return (
    <div className="traditionsContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>
        {getTranslatedText("text1")}
        {getTranslatedText("text2")}
        {getTranslatedText("text3")}
        {getTranslatedText("text4")}
        {getTranslatedText("text5")}
        {getTranslatedText("text6")}
        {/*<Link className="links" to="/tigersNights">
          <span className="traditionsTigersNights">Tigers Nights</span>
        </Link>*/}
        {getTranslatedText("text7")}
        {getTranslatedText("text8")}
        <br />
      </p>
    </div>
  );
}

export default Traditions;
