import "./contactUs.scss";
import { useForm, ValidationError } from "@formspree/react";
import { Link } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const [state, handleSubmit] = useForm("mrgvpdpa");
  const [formattedPhone, setFormattedPhone] = useState("");
  const { t } = useTranslation();
  const translationPage = "pages.contactUs";

  if (state.succeeded) {
    return (
      <div className="contactUsSuccessContainer">
        <p className="contactUsSuccessfulMsgSent">
          {t(`${translationPage}.Message Successfully Sent !`)}
        </p>
        <Link className="links" to="/">
          <span className="contactUsSuccessfulMsgSentBtn">
            {t(`${translationPage}.Home`)}
          </span>
        </Link>
      </div>
    );
  }

  const handlePhoneInput = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormattedPhone(formattedPhone);
  };

  return (
    <div className="contactUsContainer">
      <form id="contactUsForm" onSubmit={handleSubmit}>
        <h1 className="contactUsHeader">
          {t(`${translationPage}.Contact Us`)}
        </h1>
        <div className="contactUsField">
          <input
            onInput={(e) => e.target.setCustomValidity("")}
            type="text"
            name="name"
            placeholder={t(`${translationPage}.Full Name`)}
            id="name"
            required
          />
          <ValidationError
            className="contactUsValidationError"
            prefix="Full Name"
            field="name"
            errors={state.errors}
          />
          <span style={{ color: "#ef711d" }}>&nbsp; *</span>
        </div>
        <div className="contactUsField">
          <input
            onInput={(e) => e.target.setCustomValidity("")}
            onChange={(e) => handlePhoneInput(e)}
            value={formattedPhone}
            name="phone"
            placeholder={t(`${translationPage}.Phone Number`)}
            id="phone"
          />
          <ValidationError
            className="contactUsValidationError"
            prefix="Phone number"
            field="phone"
            errors={state.errors}
          />
        </div>
        <div className="contactUsField">
          <input
            onInput={(e) => e.target.setCustomValidity("")}
            type="text"
            name="email"
            placeholder={t(`${translationPage}.Email`)}
            id="email"
            required
          />
          <ValidationError
            className="contactUsValidationError"
            prefix="Email"
            field="email"
            errors={state.errors}
          />
          <span style={{ color: "#ef711d" }}>&nbsp; *</span>
        </div>
        <div className="contactUsField">
          <input
            onInput={(e) => e.target.setCustomValidity("")}
            type="text"
            name="subject"
            placeholder={t(`${translationPage}.Subject`)}
            id="subject"
          />
          <ValidationError
            className="contactUsValidationError"
            prefix="Subject"
            field="subject"
            errors={state.errors}
          />
        </div>
        <div className="contactUsField">
          <textarea
            onInput={(e) => e.target.setCustomValidity("")}
            placeholder={t(`${translationPage}.Message`)}
            name="message"
            id="message"
            required
          />
          <ValidationError
            className="contactUsValidationError"
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <span style={{ color: "#ef711d" }}>&nbsp; *</span>
        </div>
        <button
          type="submit"
          disabled={state.submitting}
          className="contactUsBtn"
        >
          {t(`${translationPage}.Send`)}
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
