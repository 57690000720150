import "./rules.scss";
import { rules } from "../../utils/texts";
import { useTranslation } from "react-i18next";

const Rules = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = rules[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="rulesContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p className="rulesText">{getTranslatedText("text")}</p>
    </div>
  );
};

export default Rules;
