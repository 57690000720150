import { message } from "antd";

export function fileOnChange(
  e,
  imgType,
  resume,
  setResume,
  resumePreview,
  setResumePreview,
  photo,
  setPhoto,
  photoPreview,
  setPhotoPreview,
  test,
  setTest,
  testPreview,
  setTestPreview
) {
  const images = e.target.files;
  const acceptedTypes = [
    "image/jpg",
    "image/gif",
    "image/jpeg",
    "image/webp",
    "image/png",
  ];

  Array.from(images).forEach((img) => {
    const acceptedType = acceptedTypes.includes(img.type);
    if (!acceptedType) {
      message.error("Photo Type not accepted");
      return;
    }
    const fileLimit = img.size / 1024 / 1024 < 2;
    if (!fileLimit) {
      message.error("Photo size not accepted");
      return;
    }
    if (imgType === "resume") {
      setResume((prevResume) => [...prevResume, img]);
      setResumePreview(resumePreview || URL.createObjectURL(images[0]));
    } else if (imgType === "photo") {
      setPhoto(img);
      setPhotoPreview(URL.createObjectURL(img));
    } else if (imgType === "test") {
      setTest((prevTest) => [...prevTest, img]);
      setTestPreview(testPreview || URL.createObjectURL(images[0]));
    }
  });
  return;
}

export function fileOnChangeUsers(e, setPhoto, setPhotoPreview) {
  const image = e.target.files[0];
  const acceptedType =
    image.type === "image/jpg" ||
    image.type === "image/gif" ||
    image.type === "image/jpeg" ||
    image.type === "image/webp" ||
    image.type === "image/png";
  if (!acceptedType) {
    message.error("Photo Type not accepted");
    return;
  }
  const fileLimit = image.size / 1024 / 1024 < 2;
  if (!fileLimit) {
    message.error("Photo size not accepted");
    return;
  }
  setPhoto(image);
  setPhotoPreview(URL.createObjectURL(image));
  return;
}

export function fileOnChangeCalendarEvent(
  e,
  type,
  photo,
  setPhoto,
  photoPreview,
  setPhotoPreview,
  photoSize,
  setPhotoSize,
) {
  const images = e.target.files;
  /* TODO: leaving this message to you Sara.
  *
  * When you program, you need to keep in mind the concept
  * of a "single source of truth". Whenever there's some "entity" in your app
  * that gets used in a bunch of places, you generally want to make sure that
  * there's only ONE datastructure representing this entity.
  *
  * The main benefit is that you won't need to make many changes when trying
  * to alter the behavior of that entity
  *
  * Here, acceptedTypes is redefined in a bunch of areas of the code even
  * though it represents the same entity (a list of image types you accept
  * when creating events).
  *
  * As a best practice, I'd get in the habit of creating "constants" files
  * in which you store those unique datastructures. That way, you can pull
  * that datastructure from any file while still having the benefit of making
  * a single change if you want to modify the behavior of your app.
  *
  * As a bonus, I recommend looking into data driven programming.
  * It's basically the art of keeping the functional logic of your app still
  * but relying instead of datastructures to customize the behavior of your app.
  * (as opposed to imperative programming in which you tell exactly to your program
  * what to do).
  * That'll make you a powerhouse.
  */
  const acceptedTypes = [
    "image/jpg",
    "image/gif",
    "image/jpeg",
    "image/webp",
    "image/png",
  ];
  Array.from(images).forEach((img) => {
    const acceptedType = acceptedTypes.includes(img.type);
    if (!acceptedType) {
      message.error("Photo Type not accepted");
      return;
    }
    const fileLimit = img.size / 1024 / 1024 < 2;
    setPhotoSize(img.size / 1024 / 1024);
    if (!fileLimit) {
      message.error("Photo size not accepted");
      return;
    }
    if (type === "add") {
      setPhoto((prevPhoto) => [...prevPhoto, img]);
      setPhotoPreview(photoPreview || URL.createObjectURL(images[0]));
    } else if (type === "edit") {
      setPhoto((prevPhoto) => [...prevPhoto, img]);
      setPhotoPreview(photoPreview || URL.createObjectURL(images[0]));
    }
  });
  return;
}

export function fileOnChangeTests(
  e,
  imgType,
  test,
  setTest,
  testPreview,
  setTestPreview,
  answers,
  setAnswers,
  answersPreview,
  setAnswersPreview
) {
  const images = e.target.files;
  const acceptedTypes = [
    "image/jpg",
    "image/gif",
    "image/jpeg",
    "image/webp",
    "image/png",
  ];
  Array.from(images).forEach((img) => {
    const acceptedType = acceptedTypes.includes(img.type);
    if (!acceptedType) {
      message.error("Photo Type not accepted");
      return;
    }
    const fileLimit = img.size / 1024 / 1024 < 2;
    if (!fileLimit) {
      message.error("Photo size not accepted");
      return;
    }
    if (imgType === "test") {
      setTest((prevTest) => [...prevTest, img]);
      setTestPreview(testPreview || URL.createObjectURL(images[0]));
    } else if (imgType === "answers") {
      setAnswers((prevTest) => [...prevTest, img]);
      setAnswersPreview(answersPreview || URL.createObjectURL(images[0]));
    }
  });
  return;
}

export function fileOnChangeStudentTests(
  e,
  imgType,
  test,
  setTest,
  testPreview,
  setTestPreview,
  answers,
  setAnswers,
  answersPreview,
  setAnswersPreview,
  specialEdTest,
  setSpecialEdTest,
  specialEdTestPreview,
  setSpecialEdTestPreview,
  specialEdAnswers,
  setSpecialEdAnswers,
  specialEdAnswersPreview,
  setSpecialEdAnswersPreview
) {
  const images = e.target.files;
  const acceptedTypes = [
    "image/jpg",
    "image/gif",
    "image/jpeg",
    "image/webp",
    "image/png",
  ];
  Array.from(images).forEach((img) => {
    const acceptedType = acceptedTypes.includes(img.type);
    if (!acceptedType) {
      message.error("Photo Type not accepted");
      return;
    }
    const fileLimit = img.size / 1024 / 1024 < 2;
    if (!fileLimit) {
      message.error("Photo size not accepted");
      return;
    }

    switch (imgType) {
      case "test":
        setTest((prevTest) => [...prevTest, img]);
        setTestPreview(testPreview || URL.createObjectURL(images[0]));
        break;
      case "answers":
        setAnswers((prevAnswers) => [...prevAnswers, img]);
        setAnswersPreview(answersPreview || URL.createObjectURL(images[0]));
        break;
      case "specialEdTest":
        setSpecialEdTest((prevSpecialEdTest) => [...prevSpecialEdTest, img]);
        setSpecialEdTestPreview(
          specialEdTestPreview || URL.createObjectURL(images[0])
        );
        break;
      case "specialEdAnswers":
        setSpecialEdAnswers((prevSpecialEdAnswers) => [
          ...prevSpecialEdAnswers,
          img,
        ]);
        setSpecialEdAnswersPreview(
          specialEdAnswersPreview || URL.createObjectURL(images[0])
        );
        break;
      default:
        return;
    }
  });
}
