import "./agenda.scss";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const Agenda = () => {
  const upcomingEvents = useFetch(`calendarEvents/upcoming`);
  const pastEvents = useFetch(`calendarEvents/past`);
  const upcomingLoading = upcomingEvents?.loading;
  const pastLoading = pastEvents?.loading;
  const { t } = useTranslation();
  const translationPage = "pages.agenda";

  return (
    <div className="agendaContainer">
      <h1>{t(`${translationPage}.Calendar Events`)}</h1>
      {upcomingLoading || pastLoading ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : (
        <div className="agendaWrapper">
          <h3>{t(`${translationPage}.Upcoming`)}</h3>
          {upcomingEvents?.data?.events?.length > 0 ? (
            <ul>
              {upcomingEvents?.data?.events?.map((event) => (
                <li className="agendaEvent">
                  <CalendarMonthIcon className="agendaEventIcon" />
                  <p>{event.title}</p> : &nbsp;{" "}
                  {moment(event.start).utc().format("LLL")}
                </li>
              ))}
            </ul>
          ) : (
            <p>{t(`${translationPage}.There are no upcoming events`)}</p>
          )}
          <h3>{t(`${translationPage}.Past`)}</h3>
          {pastEvents?.data?.events?.length > 0 ? (
            <ul>
              {pastEvents?.data?.events?.map((event) => (
                <li className="agendaEvent">
                  <CalendarMonthIcon className="agendaEventIconPast" />
                  <p>{event.title}</p>: &nbsp;{" "}
                  {moment(event.start).utc().format("LLL")}
                </li>
              ))}{" "}
            </ul>
          ) : (
            <p>{t(`${translationPage}.There are no past events`)}</p>
          )}
          <Link className="links" to="/calendar">
            <button className="agendaBtn">
              {t(`${translationPage}.View All Events`)}
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Agenda;
