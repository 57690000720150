import Carousel1 from "../images/Carousel1.jpg";
import DualDiploma from "../images/dualDiploma.jpg";
import DualDiploma2 from "../images/dualDiploma2.jpg";
import DualDiploma3 from "../images/dualDiploma3.jpg";
import Carousel2 from "../images/Carousel2.jpg";
import Library from "../images/Library.JPG";
import Carousel4 from "../images/Carousel4.jpg";
import Carousel5 from "../images/Carousel5.jpg";

export const SliderData = [
  {
    image: DualDiploma3,
  },
  {
    image: Carousel2,
  },
  {
    image: Library,
  },
  {
    image: Carousel4,
  },
  {
    image: Carousel5,
  },
];
