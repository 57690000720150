import "./navbar.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavbarLinks = ({ classname }) => {
  const { t } = useTranslation();
  const translationComponent = "navbar";

  return (
    <ul className={classname}>
      <li>
        <a
          className="links"
          href="https://www.koolskools.com/unconnected/school_client_inscription/gAAAAABj5Ux6o2m6855CBseXwLjxOT9UVWv-Z2QeyXq2qas_54MujAdd0WE4SFkkEQx8C8kWxASFLmVwM5IHGtaTB-Jow29BQw=="
          target="_blank"
        >
          Kool Skools
        </a>
      </li>
      <li>
        <Link className="links" to="/calendar">
          {t(`${translationComponent}.School Calendar`)}
        </Link>
      </li>
      <li>
        <Link className="links" to="/suppliesLists">
          {t(`${translationComponent}.Supplies Lists`)}
        </Link>
      </li>
      <li>
        <Link className="links" to="/virtualTour">
          {t(`${translationComponent}.Virtual Tour`)}
        </Link>
      </li>
      <li>
        <Link className="links" to="/apply">
          {t(`${translationComponent}.Employment`)}
        </Link>
      </li>
      <li>
        <Link className="links" to="/contact">
          {t(`${translationComponent}.Contact Us`)}
        </Link>
      </li>
      <li>
        <Link className="links" to="/login">
          {t(`${translationComponent}.Admin Login`)}
        </Link>
      </li>
    </ul>
  );
};

export default NavbarLinks;
