import { useState } from "react";
import "./editPopup.scss";
import { message } from "antd";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { roles, constants, somethingWentWrong } from "../../utils/constants";
import { fileOnChangeUsers } from "../../hooks/fileOnChange";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";

const defaultUserData = {
  username: "",
  password: "",
  role: "",
};

const EditPopup = ({ element, closeEditPopup, user, userRole }) => {
  const [userData, setUserData] = useState(defaultUserData);
  const handleUserData = (newValue, fieldName) => {
    userData[fieldName] = newValue;
    setUserData(userData);
  };
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}calendarEvents/images/`;
  const [photo, setPhoto] = useState("");
  const [photoPreview, setPhotoPreview] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const translationComponent = "editPopup";

  const handleEdit = async (e) => {
    e.preventDefault();
    const { username, password, role } = userData;
    const updatedUser = {
      username: username.trim() !== "" ? username.toLowerCase() : user.username,
    };
    if (password !== "") updatedUser.password = password;
    if (role !== "" && role !== user.role)
      updatedUser.role = role.toLowerCase();
    updatedUser.userRole = userRole;
    if (photo) {
      const data = new FormData();
      const img = photo;
      const filename = updatedUser.username + "photo" + img.name;
      data.append("name", filename);
      data.append("file", img, filename);
      updatedUser.photo = filename;
      try {
        await axios.post("upload", data);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    }
    try {
      const { data } = await axios.put(`users/${user._id}`, updatedUser);
      if (data.status === "success") {
        message.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (data.status === "userExists") message.info(data.message);
      else if (data.status === "error") message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleResetField = async (element) => {
    if (window.confirm(t(`${translationComponent}.confirmDelete `)) + element) {
      try {
        const { data } = await axios.put(
          `users/remove/${user?._id}/${element}`
        );
        if (data.status === "success") {
          message.success(data.message);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    }
  };

  return (
    <div className="editPopupWrapper">
      <p>
        {t(`${translationComponent}.You are about to edit the `)}
        {element} {user?.username}
      </p>
      <form className="editPopupForm">
        <span className="editPopupFormInline">
          <label className="editPopupFormImgLabel">
            <input
              onChange={(e) => fileOnChangeUsers(e, setPhoto, setPhotoPreview)}
              type="file"
            />
            <span>
              {t(`${translationComponent}.Photo`)}
              <AttachFileIcon style={{ margin: "auto 5px" }} />
            </span>
            {(photoPreview || user?.photo) && (
              <img
                className="editPopupFormImg"
                src={photoPreview || PF + user?.photo}
              />
            )}
          </label>
          {user?.photo && (
            <ClearIcon
              onClick={() => handleResetField("photo")}
              className="clearIconDeleteField"
            />
          )}
        </span>
        <span className="editPopupFormSpan">
          <label>{t(`${translationComponent}.username`)}</label>
          <input
            className="usersEditPopupInput"
            onChange={(e) => handleUserData(e.target.value, "username")}
            type="text"
            placeholder={user.username}
          />
        </span>
        <span className="editPopupFormSpan">
          <span className="editPopupFormPasswordWrapper">
            <label>{t(`${translationComponent}.password`)}</label>
            {showPassword ? (
              <VisibilityOffIcon
                className="editPopupFormPasswordShowHideBtn"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <RemoveRedEyeIcon
                onClick={() => setShowPassword(true)}
                className="editPopupFormPasswordShowHideBtn"
              />
            )}
          </span>
          <input
            className="usersEditPopupInput"
            onChange={(e) => handleUserData(e.target.value, "password")}
            type={showPassword ? "text" : "password"}
            placeholder={user.password}
          />
        </span>
        <span className="editPopupFormSpan">
          <label>{t(`${translationComponent}.role`)}</label>
          <select
            className="usersEditPopupSelect"
            onChange={(e) => handleUserData(e.target.value, "role")}
          >
            <option value={user?.role} selected hidden>
              {user?.role}
            </option>
            {roles.map((role) => (
              <option value={role.value}>{t(role.translationKey)}</option>
            ))}
          </select>
        </span>
      </form>
      <span className="editPopupBtns">
        <button onClick={handleEdit}>
          {t(`${translationComponent}.Save`)}
        </button>
        <button onClick={() => closeEditPopup()}>
          {t(`${translationComponent}.Cancel`)}
        </button>
      </span>
    </div>
  );
};

export default EditPopup;
