import "./mun.scss";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { MUNIDA } from "../../utils/texts";
import { useTranslation } from "react-i18next";

const MUN = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = MUNIDA[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="MUNContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
      <span className="MUNSocialMediaIcons">
        <a
          className="links MUNSocialMediaIcon MUNYTIcon"
          href="https://www.youtube.com/watch?v=yW5f0hL2eEI&list=PLtR-4Oz-WiEgY55TY3fwnSVDsEUIFnF_b"
          target="_blank"
        >
          <YouTubeIcon style={{ fontSize: "5vh" }} />
        </a>
      </span>
    </div>
  );
};

export default MUN;
