import { useRef } from "react";
import axios from "axios";
import { message } from "antd";
import useFetch from "../../hooks/useFetch";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import {
  meetingTypes,
  meetingOutcomes,
  somethingWentWrong,
} from "../../utils/constants";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const AddEditLog = ({
  log,
  type,
  setOpenAddPopup,
  setOpenEditPopup,
  setLogs,
  element,
  refresh,
  setRefresh,
  applicant,
  note, 
  setNotes,
}) => {
  const { user } = useContext(AuthContext);
  const date = useRef();
  const caller = useRef();
  const reason = useRef();
  const answered = useRef();
  const applicantId = useRef();
  const meetingTime = useRef();
  const meetingType = useRef();
  const outcome = useRef();
  const notes = useRef();
  const applicants = useFetch(`applications/sortedByLName/${user?.role}`).data
    .applications;
  const { t } = useTranslation();
  const translationComponent = "addEditLog";

  const handleAdd = async (e) => {
    e.preventDefault();
    const call = {
      //date: date.current.value,
      date: new Date().toISOString().slice(0, 10),
      caller: caller.current.value,
      reason: reason.current.value,
      answered: answered.current.value,
      applicantId: applicantId.current?.value || applicant?._id,
      meetingTime: meetingTime.current.value,
      meetingType: meetingType.current.value,
      outcome: outcome.current.value,
      notes: notes.current.value,
    };
    if (call.meetingTime && !call.meetingType)
      message.error(t(`${translationComponent}.pickMeetingType`));
    if (call.meetingType && !call.meetingTime)
      message.error(t(`${translationComponent}.pickMeetingTime`));
    if (call.meetingTime) {
      const meetingDate = new Date(call.meetingTime);
      if (meetingDate < new Date()) {
        const confirmed = window.confirm(
          t(`${translationComponent}.settingMeetingPastDate`)
        );
        if (!confirmed) return;
      }
    }
    try {
      const { data } = await axios.post(`logs`, call);
      if (data.status === "success") {
        message.success(data.message);
        setRefresh(!refresh);
        setTimeout(() => {
          setLogs((logs) => [...logs, data.savedLog]);
          setOpenAddPopup(false);
        }, 1000);
      } else {
        message.error(data.message);
      }
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    //const newDate = new Date().toISOString().slice(0,10);
    const call = {
      //date: date.current.value || newDate || log.date,
      date: new Date().toISOString().slice(0, 10),
      caller: caller.current.value || log.caller,
      reason: reason.current.value || log.reason,
      answered: answered.current.value || log.answered,
      applicantId: log.applicantId,
      meetingTime: meetingTime.current.value || log.meetingTime,
      meetingType: meetingType.current.value || log.meetingType,
      outcome: outcome.current.value || log.outcome,
      notes: notes.current.value,
    };
    if (call.meetingTime && !call.meetingType)
      message.error(t(`${translationComponent}.pickMeetingType`));
    if (call.meetingTime && !call.meetingType)
      message.error(t(`${translationComponent}.pickMeetingime`));
    if (meetingTime.current.value) {
      const meetingDate = new Date(meetingTime.current.value);
      if (meetingDate < new Date()) {
        const confirmed = window.confirm(
          t(`${translationComponent}.settingMeetingPastDate`)
        );
        if (!confirmed) return;
      }
    }
    try {
      const { data } = await axios.put(`logs/${log._id}`, call);
      if (data.status === "success") {
        setRefresh(!refresh);
        message.success(data.message);
        setTimeout(() => {
          setOpenEditPopup(false);
        }, 1000);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const ApplicantSingle = ({ id }) => {
    const { data } = useFetch(`applications/call/${user?.role}/${id}`);
    const applicant = data?.application;

    return (
      <>
        {applicant?.lastName} {applicant?.firstName}
      </>
    );
  };

  const handleResetField = async (element) => {
    if (
      window.confirm(t(`${translationComponent}.confirmDelete`)`${element} ?`)
    ) {
      try {
        const { data } = await axios.put(`logs/remove/${log._id}/${element}`);
        if (data.status === "success") {
          message.success(data.message);
          await setOpenEditPopup(false);
          await setRefresh(!refresh);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    }
  };

  return (
    <div className="callsAddPopupContainer">
      <div className="callsAddPopupWrapper">
        <span
          onClick={() => {
            type === "add" ? setOpenAddPopup(false) : setOpenEditPopup(false);
          }}
          className="callsAddPopupCloseBtn"
        >
          &times;{" "}
        </span>
        <form
          className="callsAddPopupForm"
          onSubmit={type === "add" ? handleAdd : handleEdit}
        >
          <label>
            <span>
              {t(`${translationComponent}.Candidate`)}&nbsp;
              {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
            </span>
            {element === "" ? (
              type === "add" ? (
                <select required ref={applicantId}>
                  <option value="" selected hidden></option>
                  {applicants?.map((candidate) => (
                    <option value={candidate._id}>
                      {candidate.lastName} {candidate.firstName}
                    </option>
                  ))}
                </select>
              ) : (
                <select disabled>
                  <option value="">
                    <ApplicantSingle id={log?.applicantId} />
                  </option>
                </select>
              )
            ) : (
              <select disabled>
                <option selected>
                  {applicant?.lastName} {applicant?.firstName}
                </option>
              </select>
            )}
          </label>
          <label>
            {t(`${translationComponent}.Caller`)}
            <select name="caller" ref={caller}>
              <option value="" selected hidden>
                {type === "add" ? "" : log?.caller}
              </option>
              <option value="school">
                {t(`${translationComponent}.School`)}
              </option>
              <option value="applicant">
                {t(`${translationComponent}.Candidate`)}
              </option>
            </select>
          </label>
          <span className="callsAddPopupFormLabelInline">
            <label>
              {t(`${translationComponent}.Reason`)}
              <input
                ref={reason}
                type="text"
                placeholder={type === "add" || !log?.reason ? "" : log?.reason}
              />
            </label>
            {type === "edit" && log?.reason && (
              <ClearIcon
                onClick={() => handleResetField("reason")}
                className="clearIconDeleteField"
              />
            )}
          </span>
          <label>
            <span>
              {t(`${translationComponent}.Answered`)} &nbsp;
              {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
            </span>
            <select required={type === "add"} name="answered" ref={answered}>
              <option value="" select hidden>
                {type === "add"
                  ? ""
                  : log.answered
                  ? t(`${translationComponent}.Yes`)
                  : t(`${translationComponent}.No`)}
              </option>
              <option value="true">{t(`${translationComponent}.Yes`)}</option>
              <option value="false">{t(`${translationComponent}.No`)}</option>
            </select>
          </label>
          <span className="callsAddPopupFormLabelInline">
            <label>
              {t(`${translationComponent}.meetingTimeIfAny`)}
              {type === "add" ? (
                <input ref={meetingTime} type="datetime-local" />
              ) : (
                <input
                  ref={meetingTime}
                  type="text"
                  onFocus={(e) => (e.target.type = "datetime-local")}
                  onBlur={(e) => (e.target.type = "text")}
                  placeholder={
                    type === "edit" && log?.meetingTime
                      ? log.meetingTime?.slice(0, 10) +
                          " à " +
                          log.meetingTime?.slice(11, 13) +
                          ":" +
                          log.meetingTime?.slice(14, 16) || "N/A"
                      : ""
                  }
                />
              )}
            </label>
            {type === "edit" && log?.meetingTime && (
              <ClearIcon
                onClick={() => handleResetField("meetingTime")}
                className="clearIconDeleteField"
              />
            )}
          </span>
          <label>
            {t(`${translationComponent}.meetingTypeIfAny`)}
            <select ref={meetingType}>
              <option value="" selected hidden>
                {type === "add" ? "" : log?.meetingType}
              </option>
              {meetingTypes.map((type) => (
                <option value={type.value}>{t(type.translationKey)}</option>
              ))}
            </select>
          </label>
          <span className="callsAddPopupFormLabelInline">
            <label>
              {t(`${translationComponent}.Outcome`)}
              <select ref={outcome}>
                <option value="" selected hidden>
                  {type === "add" ? "" : log?.outcome}
                </option>
                {meetingOutcomes.map((outcome) => (
                  <option value={outcome.value}>
                    {t(outcome.translationKey)}
                  </option>
                ))}
              </select>
            </label>
            {type === "edit" && log?.outcome && (
              <ClearIcon
                onClick={() => handleResetField("outcome")}
                className="clearIconDeleteField"
              />
            )}
          </span>
          <label>
            {t(`${translationComponent}.Notes`)}
            <input ref={notes} type="text" />
          </label>
          <button className="callsAddPopupBtn" type="submit">
            {t(`${translationComponent}.Submit`)}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEditLog;
