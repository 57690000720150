import "./orientation.scss";
import { orientation } from "../../utils/texts";
import { useTranslation } from "react-i18next";

const Orientation = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = orientation[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="orientationContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
    </div>
  );
};

export default Orientation;
