import "./cycles.scss";
import Kindergarten from "../../images/Kindergarten.JPG";
import Elementary from "../../images/Elementary.JPG";
import MiddleSchool from "../../images/MiddleSchool.jpg";
import High from "../../images/HighSchool.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cycles = () => {
  const { t } = useTranslation();
  const translationPage = "pages.cycles";

  return (
    <div className="cycleContainer">
      <div className="cycleWrapper">
        <Link className="links" to="/kindergarten">
          <span
            className="cycleLevel"
            style={{
              background: `url(${Kindergarten})`,
            }}
          >
            <p className="cyclesText hover-underline-animation hover-animation-orange">
              {t(`${translationPage}.PRESCHOOL`)}
            </p>
          </span>
        </Link>
        <Link className="links" to="/elementary">
          <span
            className="cycleLevel"
            style={{
              background: `url(${Elementary})`,
            }}
          >
            <p className="cyclesText hover-underline-animation hover-animation-grape">
              {t(`${translationPage}.ELEMENTARY`)}
            </p>
          </span>
        </Link>
        <Link className="links" to="/middleSchool">
          <span
            className="cycleLevel"
            style={{
              background: `url(${MiddleSchool})`,
            }}
          >
            <p className="cyclesText hover-underline-animation hover-animation-green">
              {t(`${translationPage}.MIDDLE`)}
            </p>
          </span>
        </Link>
        <Link className="links" to="/highSchool">
          <span
            className="cycleLevel"
            style={{
              background: `url(${High})`,
            }}
          >
            <p className="cyclesText hover-underline-animation hover-animation-gray">
              {t(`${translationPage}.HIGH`)}
            </p>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Cycles;
