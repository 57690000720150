import "./sports.scss";
import { useTranslation } from "react-i18next";
import { sports } from "../../utils/texts";

const Sports = () => {
  const { i18n } = useTranslation();
  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = sports[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="sportsContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
    </div>
  );
};

export default Sports;
