import "./studentTests.scss";
import AccessDenied from "../../components/accessDenied/AccessDenied";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import React, { useContext, useEffect, useState, useRef } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { message } from "antd";
import {
  constants,
  subjects,
  gradeLevels,
  studentTestsPeriods,
  somethingWentWrong,
} from "../../utils/constants";
import axios from "axios";
import DeletePopup from "../../components/deletePopup/DeletePopup";
import { fileOnChangeStudentTests } from "../../hooks/fileOnChange";
import ClearIcon from "@mui/icons-material/Clear";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const StudentTests = () => {
  const { user } = useContext(AuthContext);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}images/`;
  const [type, setType] = useState("");
  const [selectedTest, setSelectedTest] = useState({});
  const element = "studentTest";
  const [showTest, setShowTest] = useState(false);
  const [showTestAnswers, setShowTestAnswers] = useState(false);
  const [showSpecialTest, setShowSpecialTest] = useState(false);
  const [showSpecialTestAnswers, setShowSpecialTestAnswers] = useState(false);
  const { t } = useTranslation();
  const translationPage = "pages.studentTests";

  useEffect(() => {
    const fetchTests = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`studentsTests`);
        if (data.status === "success") setTests(data.tests);
        else {
          setLoading(false);
          message.error(t(`${translationPage}.somethingWentWrongFetching`));
        }
      } catch (err) {
        message.error(somethingWentWrong);
      }
      setLoading(false);
    };
    fetchTests();
  }, [refresh]);

  const AddEditPopup = () => {
    const grade = useRef();
    const subject = useRef();
    const period = useRef();
    const [test, setTest] = useState([]);
    const [testPreview, setTestPreview] = useState("");
    const [answers, setAnswers] = useState([]);
    const [answersPreview, setAnswersPreview] = useState("");
    const [specialEdTest, setSpecialEdTest] = useState([]);
    const [specialEdTestPreview, setSpecialEdTestPreview] = useState("");
    const [specialEdAnswers, setSpecialEdAnswers] = useState([]);
    const [specialEdAnswersPreview, setSpecialEdAnswersPreview] = useState("");

    const handleAddEditTest = async (e) => {
      e.preventDefault();
      let newOrUpdatedTest;
      if (type === "add") {
        newOrUpdatedTest = {
          grade: grade.current.value,
          subject: subject.current.value,
          period: period.current.value,
        };
      } else if (type === "edit") {
        newOrUpdatedTest = {
          grade: grade.current.value || selectedTest.grade,
          subject: subject.current.value || selectedTest.subject,
          period: period.current.value || selectedTest.period,
        };
      }
      if (test.length > 0) {
        newOrUpdatedTest.test = type === "add" ? [] : selectedTest.test || [];
        const data = new FormData();
        for (let i = 0; i < test.length; i++) {
          const filename =
            Date.now() +
            newOrUpdatedTest.grade +
            newOrUpdatedTest.subject +
            "studentTest" +
            test[i].name;
          data.append("name", filename);
          const img = test[i];
          data.append("file", img, filename);
          newOrUpdatedTest.test.push(filename);
        }
        try {
          await axios.post("multiUpload", data);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      if (answers.length > 0) {
        newOrUpdatedTest.answers =
          type === "add" ? [] : selectedTest.answers || [];
        const data = new FormData();
        for (let i = 0; i < answers.length; i++) {
          const filename =
            Date.now() +
            newOrUpdatedTest.grade +
            newOrUpdatedTest.subject +
            "studentTestAnswers" +
            answers[i].name;
          data.append("name", filename);
          const img = answers[i];
          data.append("file", img, filename);
          newOrUpdatedTest.answers.push(filename);
        }
        try {
          await axios.post("multiUpload", data);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      if (specialEdTest.length > 0) {
        newOrUpdatedTest.specialEdTest =
          type === "add" ? [] : selectedTest.specialEdTest || [];
        const data = new FormData();
        for (let i = 0; i < specialEdTest.length; i++) {
          const filename =
            Date.now() +
            newOrUpdatedTest.grade +
            newOrUpdatedTest.subject +
            "studentSpecialEdTest" +
            specialEdTest[i].name;
          data.append("name", filename);
          const img = specialEdTest[i];
          data.append("file", img, filename);
          newOrUpdatedTest.specialEdTest.push(filename);
        }
        try {
          await axios.post("multiUpload", data);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      if (specialEdAnswers.length > 0) {
        newOrUpdatedTest.specialEdAnswers =
          type === "add" ? [] : selectedTest.specialEdAnswers || [];
        const data = new FormData();
        for (let i = 0; i < specialEdAnswers.length; i++) {
          const filename =
            Date.now() +
            newOrUpdatedTest.grade +
            newOrUpdatedTest.subject +
            "studentSpecialEdTestAnswers" +
            specialEdAnswers[i].name;
          data.append("name", filename);
          const img = specialEdAnswers[i];
          data.append("file", img, filename);
          newOrUpdatedTest.specialEdAnswers.push(filename);
        }
        try {
          await axios.post("multiUpload", data);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      try {
        let res;
        if (type === "add") {
          res = await axios.post(`studentsTests`, newOrUpdatedTest);
          if (res?.data?.status === "success") {
            setRefresh(!refresh);
            message.success(res?.data?.message);
            setOpenAddPopup(false);
          } else
            message.error(t(`${translationPage}.somethingWentWrongCreating`));
        } else if (type === "edit") {
          res = await axios.put(
            `studentsTests/${selectedTest._id}`,
            newOrUpdatedTest
          );
          if (res?.data?.status === "success") {
            message.success(res?.data?.message);
            await setRefresh(!refresh);
            await setOpenEditPopup(false);
          } else message.error(res?.data?.message);
        }
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };

    const handleResetField = async (element) => {
      if (
        window.confirm(
          t(`${translationPage}.confirmDelete1`) +
            element +
            t(`${translationPage}.confirmDelete2`)
        )
      ) {
        try {
          const { data } = await axios.put(
            `studentsTests/remove/${selectedTest?._id}/${element}/0/0`
          );
          if (data.status === "success") {
            message.success(data.message);
            setRefresh(!refresh);
            await setOpenEditPopup(false);
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    };

    return (
      <div className="studentsTestsAddEditPopupContainer">
        <div className="studentsTestsAddEditPopupWrapper">
          <span
            onClick={
              type === "add"
                ? () => setOpenAddPopup(false)
                : () => setOpenEditPopup(false)
            }
            className="studentsTestsAddEditPopupCloseBtn"
          >
            &times;{" "}
          </span>
          <form
            className="studentsTestsAddEditPopupForm"
            onSubmit={handleAddEditTest}
          >
            <label>
              <span>
                {t(`${translationPage}.Grade  `)}
                {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
              </span>
              <select required={type === "add"} ref={grade}>
                <option value="" hidden>
                  {type === "edit" && selectedTest.grade}
                </option>
                {gradeLevels.map((cycle) => (
                  <optgroup label={t(cycle.translationKey)}>
                    {cycle.grades.map((grade) => (
                      <option value={grade.value}>
                        {t(grade.translationKey)}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </label>
            <label>
              <span>
                {t(`${translationPage}.Subject  `)}
                {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
              </span>
              <select required={type === "add"} ref={subject}>
                <option value="" hidden>
                  {type === "edit" && selectedTest.subject}
                </option>
                {subjects.map((subject) => (
                  <option value={subject.value}>
                    {t(subject.translationKey)}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <span>
                {t(`${translationPage}.Period / Type  `)}
                {type === "add" && <span style={{ color: "#ef711d" }}>*</span>}
              </span>
              <select required={type === "add"} ref={period}>
                <option value="" hidden>
                  {" "}
                  {type === "edit" && selectedTest.period}
                </option>
                {studentTestsPeriods.map((period) => (
                  <option value={period.value}>
                    {t(period.translationKey)}
                  </option>
                ))}
              </select>
            </label>
            <label className="studentsTestsAddEditPopupTestAnswersLabel">
              <span>
                <span>
                  {t(`${translationPage}.Test  `)}
                  {type === "add" && (
                    <span style={{ color: "#ef711d" }}>*</span>
                  )}
                </span>
                <AttachFileIcon className="studentsTestsAddEditPopupTestIcon" />
                <input
                  type="file"
                  onChange={(e) =>
                    fileOnChangeStudentTests(
                      e,
                      "test",
                      test,
                      setTest,
                      testPreview,
                      setTestPreview,
                      answers,
                      setAnswers,
                      answersPreview,
                      setAnswersPreview,
                      specialEdTest,
                      setSpecialEdTest,
                      specialEdTestPreview,
                      setSpecialEdTestPreview,
                      specialEdAnswers,
                      setSpecialEdAnswers,
                      specialEdAnswersPreview,
                      setSpecialEdAnswersPreview
                    )
                  }
                />
              </span>
              {type === "add"
                ? testPreview && (
                    <span className="newApplicationFormImgPreviewSpan">
                      <img
                        className="studentsTestsAddEditPopupTestPreview"
                        src={testPreview}
                      />
                      {test.length > 1 && (
                        <span className="newApplicationFormImgPreviewLength">
                          {" "}
                          +{test.length - 1}{" "}
                        </span>
                      )}
                    </span>
                  )
                : (testPreview || selectedTest.test.length > 0) && (
                    <span className="applicantEditPhotoPreview">
                      <img
                        className="studentsTestsAddEditPopupTestPreview"
                        src={
                          selectedTest?.test.length > 0
                            ? PF + selectedTest.test[0]
                            : testPreview
                        }
                      />
                      {(selectedTest?.test.length > 1 ||
                        test.length > 1 ||
                        selectedTest?.test.length + test.length > 1) && (
                        <span className="newApplicationFormImgPreviewLength">
                          {" "}
                          +{selectedTest?.test.length + test.length - 1}
                        </span>
                      )}
                    </span>
                  )}
            </label>
            <span className="studentsTestsAddEditPopupTestAnswersLabelInline">
              <label className="studentsTestsAddEditPopupTestAnswersLabel">
                <span>
                  <span>{t(`${translationPage}.Test Answers  `)}</span>
                  <AttachFileIcon className="studentsTestsAddEditPopupTestIcon" />
                  <input
                    type="file"
                    onChange={(e) =>
                      fileOnChangeStudentTests(
                        e,
                        "answers",
                        test,
                        setTest,
                        testPreview,
                        setTestPreview,
                        answers,
                        setAnswers,
                        answersPreview,
                        setAnswersPreview,
                        specialEdTest,
                        setSpecialEdTest,
                        specialEdTestPreview,
                        setSpecialEdTestPreview,
                        specialEdAnswers,
                        setSpecialEdAnswers,
                        specialEdAnswersPreview,
                        setSpecialEdAnswersPreview
                      )
                    }
                  />
                </span>
                {type === "add"
                  ? answersPreview && (
                      <span className="newApplicationFormImgPreviewSpan">
                        <img
                          className="studentsTestsAddEditPopupTestPreview"
                          src={answersPreview}
                        />
                        {answers.length > 1 && (
                          <span className="newApplicationFormImgPreviewLength">
                            {" "}
                            +{answers.length - 1}{" "}
                          </span>
                        )}
                      </span>
                    )
                  : (answersPreview || selectedTest.answers.length > 0) && (
                      <span className="applicantEditPhotoPreview">
                        <img
                          className="studentsTestsAddEditPopupTestPreview"
                          src={
                            selectedTest.answers.length > 0
                              ? PF + selectedTest.answers[0]
                              : answersPreview
                          }
                        />
                        {(selectedTest?.answers.length > 1 ||
                          answers.length > 1 ||
                          selectedTest?.answers.length + answers.length >
                            1) && (
                          <span className="newApplicationFormImgPreviewLength">
                            {" "}
                            +{selectedTest?.answers.length + answers.length - 1}
                          </span>
                        )}
                      </span>
                    )}
              </label>
              {type === "edit" && selectedTest.answers.length > 0 && (
                <ClearIcon
                  onClick={() => handleResetField("allAnswers")}
                  className="clearIconDeleteField"
                />
              )}
            </span>
            <span className="studentsTestsAddEditPopupTestAnswersLabelInline">
              <label className="studentsTestsAddEditPopupTestAnswersLabel">
                <span>
                  <span>{t(`${translationPage}.Special Ed Test  `)}</span>
                  <AttachFileIcon className="studentsTestsAddEditPopupTestIcon" />
                  <input
                    type="file"
                    onChange={(e) =>
                      fileOnChangeStudentTests(
                        e,
                        "specialEdTest",
                        test,
                        setTest,
                        testPreview,
                        setTestPreview,
                        answers,
                        setAnswers,
                        answersPreview,
                        setAnswersPreview,
                        specialEdTest,
                        setSpecialEdTest,
                        specialEdTestPreview,
                        setSpecialEdTestPreview,
                        specialEdAnswers,
                        setSpecialEdAnswers,
                        specialEdAnswersPreview,
                        setSpecialEdAnswersPreview
                      )
                    }
                  />
                </span>
                {type === "add"
                  ? specialEdTestPreview && (
                      <span className="newApplicationFormImgPreviewSpan">
                        <img
                          className="studentsTestsAddEditPopupTestPreview"
                          src={specialEdTestPreview}
                        />
                        {specialEdTest.length > 1 && (
                          <span className="newApplicationFormImgPreviewLength">
                            {" "}
                            +{specialEdTest.length - 1}{" "}
                          </span>
                        )}
                      </span>
                    )
                  : (specialEdTestPreview ||
                      selectedTest.specialEdTest.length > 0) && (
                      <span className="applicantEditPhotoPreview">
                        <img
                          className="studentsTestsAddEditPopupTestPreview"
                          src={
                            selectedTest.specialEdTest.length > 0
                              ? PF + selectedTest.specialEdTest[0]
                              : specialEdTestPreview
                          }
                        />
                        {(selectedTest?.specialEdTest.length > 1 ||
                          specialEdTest.length > 1 ||
                          selectedTest?.specialEdTest.length +
                            specialEdTest.length >
                            1) && (
                          <span className="newApplicationFormImgPreviewLength">
                            {" "}
                            +
                            {selectedTest?.specialEdTest.length +
                              specialEdTest.length -
                              1}{" "}
                          </span>
                        )}
                      </span>
                    )}
              </label>
              {type === "edit" && selectedTest.specialEdTest.length > 0 && (
                <ClearIcon
                  onClick={() => handleResetField("allSpecialEdTest")}
                  className="clearIconDeleteField"
                />
              )}
            </span>
            <span className="studentsTestsAddEditPopupTestAnswersLabelInline">
              <label className="studentsTestsAddEditPopupTestAnswersLabel">
                <span>
                  <span>
                    {t(`${translationPage}.Special Ed Test Answers  `)}
                  </span>
                  <AttachFileIcon className="studentsTestsAddEditPopupTestIcon" />
                  <input
                    type="file"
                    onChange={(e) =>
                      fileOnChangeStudentTests(
                        e,
                        "specialEdAnswers",
                        test,
                        setTest,
                        testPreview,
                        setTestPreview,
                        answers,
                        setAnswers,
                        answersPreview,
                        setAnswersPreview,
                        specialEdTest,
                        setSpecialEdTest,
                        specialEdTestPreview,
                        setSpecialEdTestPreview,
                        specialEdAnswers,
                        setSpecialEdAnswers,
                        specialEdAnswersPreview,
                        setSpecialEdAnswersPreview
                      )
                    }
                  />
                </span>
                {type === "add"
                  ? specialEdAnswersPreview && (
                      <span className="newApplicationFormImgPreviewSpan">
                        <img
                          className="studentsTestsAddEditPopupTestPreview"
                          src={specialEdAnswersPreview}
                        />
                        {specialEdAnswers.length > 1 && (
                          <span className="newApplicationFormImgPreviewLength">
                            {" "}
                            +{specialEdAnswers.length - 1}{" "}
                          </span>
                        )}
                      </span>
                    )
                  : (specialEdAnswersPreview ||
                      selectedTest.specialEdAnswers.length > 0) && (
                      <span className="applicantEditPhotoPreview">
                        <img
                          className="studentsTestsAddEditPopupTestPreview"
                          src={
                            selectedTest.specialEdAnswers.length > 0
                              ? PF + selectedTest.specialEdAnswers[0]
                              : specialEdAnswersPreview
                          }
                        />
                        {(selectedTest?.specialEdAnswers.length > 1 ||
                          specialEdAnswers.length > 1 ||
                          selectedTest?.specialEdAnswers.length +
                            specialEdAnswers.length >
                            1) && (
                          <span className="newApplicationFormImgPreviewLength">
                            {" "}
                            +
                            {selectedTest?.specialEdAnswers.length +
                              specialEdAnswers.length -
                              1}
                          </span>
                        )}
                      </span>
                    )}
              </label>
              {type === "edit" && selectedTest.specialEdAnswers.length > 0 && (
                <ClearIcon
                  onClick={() => handleResetField("allSpecialEdAnswers")}
                  className="clearIconDeleteField"
                />
              )}
            </span>
            <button
              className="studentsTestsAddEditPopupSubmitBtn"
              type="submit"
            >
              {t(`${translationPage}.Submit`)}
            </button>
          </form>
        </div>
      </div>
    );
  };

  const handleDeleteSingleImg = async (idx, img, element) => {
    if (element !== "singleTest" || selectedTest.test.length > 1) {
      if (window.confirm(t(`${translationPage}.confirmDeleteImg`))) {
        try {
          const { data } = await axios.put(
            `studentsTests/remove/${selectedTest?._id}/${element}/${idx}/${img}`
          );
          if (data.status === "success") {
            message.success(data.message);
            window.location.reload();
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    } else message.error(t(`${translationPage}.keep1Img`));
  };

  if (showTest) {
    return (
      <div className="testContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowTest(false)}
        >
          <ArrowBackIosIcon />
          {t(`${translationPage}.View all tests`)}
        </span>
        <div className="resumeContainerImages">
          {selectedTest.test.length > 0 &&
            selectedTest?.test.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(index, image, "singleTest");
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Students Test" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  if (showTestAnswers) {
    return (
      <div className="testContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowTestAnswers(false)}
        >
          <ArrowBackIosIcon />
          {t(`${translationPage}.View all tests`)}
        </span>
        <div className="resumeContainerImages">
          {selectedTest.answers.length > 0 &&
            selectedTest?.answers.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(index, image, "singleAnswers");
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Students Test Answers" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  if (showSpecialTest) {
    return (
      <div className="testContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowSpecialTest(false)}
        >
          <ArrowBackIosIcon />
          {t(`${translationPage}.View all tests`)}
        </span>
        <div className="resumeContainerImages">
          {selectedTest.specialEdTest.length > 0 &&
            selectedTest?.specialEdTest.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(index, image, "singleSpecialEdTest");
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Students Special Ed Test" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  if (showSpecialTestAnswers) {
    return (
      <div className="testContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowSpecialTestAnswers(false)}
        >
          <ArrowBackIosIcon />
          {t(`${translationPage}.View all tests`)}
        </span>
        <div className="resumeContainerImages">
          {selectedTest.specialEdAnswers.length > 0 &&
            selectedTest?.specialEdAnswers.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(
                      index,
                      image,
                      "singleSpecialEdAnswers"
                    );
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Students Special Ed Test Answers" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className="studentTestsContainer">
      <Link className="links goBackSpanBtnDark" to="/user">
        <ArrowBackIosIcon />
        {t(`${translationPage}.Go Back`)}
      </Link>
      {user?.role === "admin" || user?.role === "academics" ? (
        <div className="studentTestsWrapper">
          <button
            className="studentTestsBtn"
            onClick={() => {
              setType("add");
              setOpenAddPopup(true);
              setOpenEditPopup(false);
              setOpenDeletePopup(false);
            }}
          >
            {t(`${translationPage}.Add Test`)}
          </button>
          {loading ? (
            <span className="agendaLoaderSpan">
              <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
            </span>
          ) : tests?.length ? (
            <table className="studentTestsTable">
              <tr className="studentTestsTableTr">
                <th>{t(`${translationPage}.Test Created`)}</th>
                <th>{t(`${translationPage}.Grade/Level`)}</th>
                <th>{t(`${translationPage}.Subject`)}</th>
                <th>{t(`${translationPage}.Period / Type`)}</th>
                <th>{t(`${translationPage}.Test & Answers`)}</th>
                <th>{t(`${translationPage}.Special Ed Test & Answers`)}</th>
                <th>{t(`${translationPage}.Edit/Delete`)}</th>
              </tr>
              {tests?.map((test) => (
                <tr className="studentTestsTableTr">
                  <td>{test.createdAt?.slice(0, 10)}</td>
                  <td>{test.grade}</td>
                  <td>{test.subject}</td>
                  <td>{test.period}</td>
                  <td className="studentTestsTableActionIcons">
                    {test?.test.length > 0 && (
                      <VisibilityIcon
                        className="studentTestsTableVisibilityIcon"
                        onClick={() => {
                          setSelectedTest(test);
                          setShowTest(true);
                        }}
                      />
                    )}
                    {test?.answers.length > 0 && (
                      <VisibilityIcon
                        className="studentTestsTableVisibilityIcon"
                        onClick={() => {
                          setSelectedTest(test);
                          setShowTestAnswers(true);
                        }}
                      />
                    )}
                  </td>
                  <td className="studentTestsTableActionIcons">
                    {test?.specialEdTest.length > 0 && (
                      <VisibilityIcon
                        className="studentTestsTableVisibilityIcon"
                        onClick={() => {
                          setSelectedTest(test);
                          setShowSpecialTest(true);
                        }}
                      />
                    )}
                    {test?.specialEdAnswers.length > 0 && (
                      <VisibilityIcon
                        className="studentTestsTableVisibilityIcon"
                        onClick={() => {
                          setSelectedTest(test);
                          setShowSpecialTestAnswers(true);
                        }}
                      />
                    )}
                  </td>
                  <td className="studentTestsTableActionIcons">
                    <CreateIcon
                      className="studentTestsTableActionIcon"
                      onClick={() => {
                        setType("edit");
                        setSelectedTest(test);
                        setOpenAddPopup(false);
                        setOpenDeletePopup(false);
                        setOpenEditPopup(true);
                      }}
                    />
                    <DeleteIcon
                      className="studentTestsTableActionIcon"
                      onClick={() => {
                        setOpenEditPopup(false);
                        setOpenAddPopup(false);
                        setSelectedTest(test);
                        setOpenDeletePopup(true);
                      }}
                    />
                  </td>
                </tr>
              ))}
              {openEditPopup && <AddEditPopup />}
              {openDeletePopup && (
                <div className="deletePopupContainer">
                  <DeletePopup
                    element={element}
                    closeDeletePopup={() => {
                      setOpenDeletePopup(false);
                    }}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    test={selectedTest}
                  />
                </div>
              )}
            </table>
          ) : (
            <p
              style={{
                textAlign: "center",
              }}
            >
              {t(`${translationPage}.No Tests Found`)}
            </p>
          )}
          {openAddPopup && <AddEditPopup />}
        </div>
      ) : (
        <AccessDenied fontColor="white" />
      )}
    </div>
  );
};

export default StudentTests;
