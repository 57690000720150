import "./eLearning.scss";
import { useState } from "react";
import StarfallLogo from "../../images/eLearning/Starfall logo.png";
import PBSKidsLogo from "../../images/eLearning/PBS Kids logo.svg";
import ABCmouseLogo from "../../images/eLearning/ABCmouse logo.png";
import KhanAcademyKidsLogo from "../../images/eLearning/KhanAcademyKids logo.png";
import BrainPopLogo from "../../images/eLearning/BrainPop logo.png";
import NatGeoKidsLogo from "../../images/eLearning/NatGeoKids logo.png";
import QuizletLogo from "../../images/eLearning/Quizlet logo.png";
import CK12Logo from "../../images/eLearning/CK12 logo.png";
import ReadWorksLogo from "../../images/eLearning/Readworks logo.png";
import CourseraLogo from "../../images/eLearning/Coursera logo.png";
import KhanAcademyLogo from "../../images/eLearning/Khanacademy logo.png";
import MITOpenCourseWareLogo from "../../images/eLearning/MITOCW logo.png";
import CodeCademyLogo from "../../images/eLearning/Codecademy logo.webp";
import UdemyLogo from "../../images/eLearning/Udemy logo.png";
import { useTranslation } from "react-i18next";
import { eLearningTexts } from "../../utils/texts";

const ELearning = () => {
  const [selectedCycle, setSelectedCycle] = useState("all");
  const handleCycleChange = (event) => {
    setSelectedCycle(event.target.value);
  };
  const { t, i18n } = useTranslation();
  const translationPage = "pages.eLearning";

  const getTranslatedText = (resource) => {
    const currentLanguage = i18n.language;
    const text = eLearningTexts[resource][currentLanguage];
    return <p>{text}</p>;
  };

  const resources = [
    {
      title: "Starfall",
      link: "https://www.starfall.com",
      desc: getTranslatedText("Starfall"),
      img: StarfallLogo,
      cycle: ["kindergarten"],
    },
    {
      title: "ABCmouse",
      link: "https://www.abcmouse.com",
      desc: getTranslatedText("ABCmouse"),
      img: ABCmouseLogo,
      cycle: ["kindergarten"],
    },
    {
      title: "PBS Kids",
      link: "https://www.pbskids.org",
      desc: getTranslatedText("PBSKids"),
      img: PBSKidsLogo,
      cycle: ["kindergarten"],
    },
    {
      title: "Khan Academy Kids",
      link: "https://www.khanacademykids.org",
      desc: getTranslatedText("KhanAcademyKids"),
      img: KhanAcademyKidsLogo,
      cycle: ["elementary"],
    },
    {
      title: "BrainPOP",
      link: "https://www.brainpop.com",
      desc: getTranslatedText("BrainPOP"),
      img: BrainPopLogo,
      cycle: ["elementary"],
    },
    {
      title: "Nat Geo Kids",
      link: "https://www.kids.nationalgeographic.com",
      desc: getTranslatedText("NatGeoKids"),
      img: NatGeoKidsLogo,
      cycle: ["elementary"],
    },
    {
      title: "Quizlet",
      link: "https://www.quizlet.com",
      desc: getTranslatedText("Quizlet"),
      img: QuizletLogo,
      cycle: ["middle-school"],
    },
    {
      title: "CK-12",
      link: "https://www.ck12.org",
      desc: getTranslatedText("CK12"),
      img: CK12Logo,
      cycle: ["middle-school"],
    },
    {
      title: "ReadWorks",
      link: "https://www.readworks.org",
      desc: getTranslatedText("ReadWorks"),
      img: ReadWorksLogo,
      cycle: ["middle-school"],
    },
    {
      title: "Coursera",
      link: "https://www.coursera.org",
      desc: getTranslatedText("Coursera"),
      img: CourseraLogo,
      cycle: ["high-school"],
    },
    {
      title: "Khan Academy",
      link: "https://www.khanacademy.org",
      desc: getTranslatedText("KhanAcademy"),
      img: KhanAcademyLogo,
      cycle: ["high-school"],
    },
    {
      title: "MIT OpenCourseWare",
      link: "https://www.ocw.mit.edu",
      desc: getTranslatedText("MITOpenCourseWare"),
      img: MITOpenCourseWareLogo,
      cycle: ["high-school"],
    },
    {
      title: "Codecademy",
      link: "https://www.codecademy.com",
      desc: getTranslatedText("Codecademy"),
      img: CodeCademyLogo,
      cycle: ["kindergarten", "elementary", "middle-school", "high-school"],
    },
    {
      title: "Udemy",
      link: "https://www.udemy.com/",
      desc: getTranslatedText("Udemy"),
      img: UdemyLogo,
      cycle: ["kindergarten", "elementary", "middle-school", "high-school"],
    },
  ];

  const filteredResources = resources.filter(
    (resource) =>
      selectedCycle === "all" || resource.cycle.includes(selectedCycle)
  );

  return (
    <div className="eLearningContainer">
      <div className="eLearningWrapper">
        <h1>{t(`${translationPage}.Online Learning Resources`)}</h1>
        <p>{t(`${translationPage}.exploreText`)}</p>
        <div className="eLearningFilter">
          <label>{t(`${translationPage}.Filter By Cyle:`)}</label>
          <select value={selectedCycle} onChange={handleCycleChange}>
            <option value="all">{t(`${translationPage}.All`)}</option>
            <option value="kindergarten">
              {t(`${translationPage}.Kindergarten`)}
            </option>
            <option value="elementary">
              {t(`${translationPage}.Elementary`)}
            </option>
            <option value="middle-school">
              {t(`${translationPage}.Middle School`)}
            </option>
            <option value="high-school">
              {t(`${translationPage}.High School`)}
            </option>
          </select>
        </div>
        <div className="eLearningResources">
          {filteredResources.map((resource) => (
            <a className="links" href={resource.link} target="_blank">
              <div className="eLearningResource">
                <img src={resource.img} alt="" />
                <p>{resource.title}</p>
                <p className="eLearningResourceDesc">{resource.desc}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ELearning;
