import React from "react";
import "./404.scss";
import OOPS from "../../images/OOPS.jpeg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FourOFour = () => {
  const { t } = useTranslation();
  const translationPage = "pages.404";

  return (
    <div className="FourOFourContainer">
      <img src={OOPS} alt="OOPS" />
      <h1>{t(`${translationPage}.404 - OOPS!`)}</h1>
      <p>{t(`${translationPage}.It looks like this page doesn't exist.`)}</p>

      <Link className="links" to="/">
        {" "}
        <span className="FourOFourBtn">{t(`${translationPage}.Home`)}</span>
      </Link>
    </div>
  );
};

export default FourOFour;
