export function formatPhoneNumber(value, prevValue) {
  if (!value) return value;
  const currValue = value.replace(/[^\d]/g, "");
  const currValLen = currValue.length;

  if (!prevValue || value.length > prevValue.length) {
    if (currValLen < 3) return currValue;

    if (currValLen < 5)
      return `${currValue.slice(0, 2)}-${currValue.slice(2, 4)}`;

    if (currValLen < 7)
      return `${currValue.slice(0, 2)}-${currValue.slice(
        2,
        4
      )}-${currValue.slice(4, 6)}`;

    return `${currValue.slice(0, 2)}-${currValue.slice(2, 4)}-${currValue.slice(
      4,
      6
    )}-${currValue.slice(6, 8)}-${currValue.slice(8, 10)}`;
  }
}
