import React, { useContext, useState } from "react";

const PopupContext = React.createContext();

const PopupProvider = ({ children }) => {
  const [popupOpen, setPopupOpen] = useState(true);
  const [popupActive, setPopupActive] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupBody, setPopupBody] = useState("");
  const [popupImage, setPopupImage] = useState("");
  const [popupBtn, setPopupBtn] = useState("");
  const [popupIsCountdown, setPopupIsCountdown] = useState(false);
  const [popupLink, setPopupLink] = useState("");

  return (
    <PopupContext.Provider
      value={{
        popupOpen,
        setPopupOpen,
        popupActive,
        setPopupActive,
        popupTitle,
        setPopupTitle,
        popupBody,
        popupImage,
        setPopupImage,
        setPopupBody,
        popupBtn,
        setPopupBtn,
        popupIsCountdown,
        setPopupIsCountdown,
        popupLink,
        setPopupLink,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export const PopupState = () => {
  return useContext(PopupContext);
};

export default PopupProvider;
