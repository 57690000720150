import { message } from "antd";
import axios from "axios";
import { somethingWentWrong } from "./utils/constants";

export const loginCall = async (userCredentials, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  try {
    const { data } = await axios.post(`auth/login`, userCredentials);
    if (data.status === "success") {
      message.success(data.message);
      dispatch({ type: "LOGIN_SUCCESS", payload: data.user });
    } else if (data.status === "invalidCred") {
      message.error(data.message);
    }
  } catch (err) {
    message.error(somethingWentWrong);
    dispatch({ type: "LOGIN_FAILURE", payload: err });
  }
};

export const logoutCall = async (dispatch) => {
  dispatch({ type: "LOGOUT" });
  try {
    dispatch({ type: "LOGOUT" });
  } catch (err) {
    message.error(somethingWentWrong);
  }
};
