import { useState } from "react";
import "./tigersNights.scss";
import ReplyIcon from "@mui/icons-material/Reply";
import ProgressiveImage from "react-progressive-graceful-image";
import Loading from "../../images/Loading.gif";
import TigersNightsPhoto from "../../images/tigersNights/Photobooth.JPG";
import Games from "../../images/tigersNights/Games.JPG";
import Party from "../../images/tigersNights/Party.JPG";
import Party1 from "../../images/tigersNights/Party1.JPG";
import Party2 from "../../images/tigersNights/Party2.JPG";
import Party3 from "../../images/tigersNights/Party3.JPG";
import Party4 from "../../images/tigersNights/Party4.JPG";
import Party5 from "../../images/tigersNights/Party5.JPG";
import Photobooth from "../../images/tigersNights/Photobooth.JPG";
import Photobooth1 from "../../images/tigersNights/Photobooth1.JPG";
import Photobooth2 from "../../images/tigersNights/Photobooth2.JPG";
import { useTranslation } from "react-i18next";
import { tigersNights } from "../../utils/texts";

const TigersNights = () => {
  const [showPhotos, setShowPhotos] = useState(false);
  const [slideIndex, setSlideIndex] = useState(1);
  const photos = [
    { src: TigersNightsPhoto, index: 1 },
    { src: Games, index: 2 },
    { src: Party, index: 3 },
    { src: Party1, index: 4 },
    { src: Party2, index: 5 },
    { src: Party3, index: 6 },
    { src: Party4, index: 7 },
    { src: Party5, index: 8 },
    { src: Photobooth, index: 9 },
    { src: Photobooth1, index: 10 },
    { src: Photobooth2, index: 11 },
  ];
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = tigersNights[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  const openModal = () => {
    document.getElementById("tigersNightsPhotosModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("tigersNightsPhotosModal").style.display = "none";
  };

  const plusSlides = (n) => {
    const newIndex = slideIndex + n;
    if (newIndex > 4) {
      setSlideIndex(1);
    } else if (newIndex < 1) {
      setSlideIndex(4);
    } else {
      setSlideIndex(newIndex);
    }
    showSlides(newIndex);
  };

  const currentSlide = (n) => {
    showSlides(n);
  };

  function showSlides(n) {
    const slides = document.getElementsByClassName(
      "tigersNightsPhotosModalSlides"
    );
    const dots = document.getElementsByClassName("tigersNightsPhotosDemo2");
    const captionText = document.getElementById("tigersNightsPhotosCaption");
    let i;
    let index = n;
    if (n > slides.length) {
      index = 1;
    } else if (n < 1) {
      index = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[index - 1].style.display = "block";
    dots[index - 1].className += " active";
    captionText.innerHTML = dots[index - 1].alt;
    setSlideIndex(index);
  }

  if (showPhotos) {
    return (
      <div className="tigersNightsWrapper">
        <ReplyIcon
          onClick={() => setShowPhotos(false)}
          className="tigersNightsGoBackArrow"
          style={{ marginLeft: "30px", marginTop: "30px" }}
        />
        <div>
          <div
            className="tigersNightsPhotosRow"
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                photos.length / 3
              )}, 400px)`,
            }}
          >
            {photos.map((photo) => (
              <div className="tigersNightsPhotosColumn">
                <ProgressiveImage src={photo.src} placeholder={Loading}>
                  {(src, loading) => (
                    <img
                      className={
                        loading ? "tigersNightsImageGalleryLoading" : ""
                      }
                      src={src}
                      onClick={() => {
                        openModal();
                        currentSlide(photo.index);
                      }}
                    />
                  )}
                </ProgressiveImage>
              </div>
            ))}
          </div>
          <div id="tigersNightsPhotosModal" className="tigersNightsPhotosModal">
            <span className="tigersNightsPhotosCloseBtn" onClick={closeModal}>
              &times;
            </span>
            <div className="tigersNightsPhotosModalContent">
              {photos.map((photo) => (
                <div className="tigersNightsPhotosModalSlides">
                  <div className="tigersNightsPhotosNumberTxt">
                    {photo.index} / {photos.length}
                  </div>
                  <img src={photo.src} className="tigersNightsPhotosDemo2" />
                </div>
              ))}
            </div>

            <a
              className="tigersNightsPhotosPrevBtn"
              onClick={() => plusSlides(-1)}
            >
              &#10094;
            </a>
            <a
              className="tigersNightsPhotosNextBtn"
              onClick={() => plusSlides(1)}
            >
              &#10095;
            </a>

            <div className="tigersNightsPhotosCaptioncontainer">
              <p id="tigersNightsPhotosCaption"></p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="tigersNightsContainer">
      <h1>Tigers Nights</h1>
      <p>{getTranslatedText("text")}</p>
      <span
        onClick={() => {
          setShowPhotos(true);
        }}
        className="tigersNightsPhotoSpan"
        style={{ background: `url(${TigersNightsPhoto})` }}
      >
        <p className="hover-underline-animation hover-animation-white">
          {getTranslatedText("photoGallery")}
        </p>
      </span>
    </div>
  );
};

export default TigersNights;
