import "./dropDownMenu.scss";
import Directors from "../../images/Directors.JPG";
import Studentslife from "../../images/StudentsLife.JPG";
import ResourcesImg from "../../images/Bees.JPG";
import Cafeteria from "../../images/Cafeteria.JPG";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DropDownMenu = ({ menu, activeInnerPage, activePage }) => {
  const { t } = useTranslation();
  const translationComponent = "dropDownMenu";

  const About = () => {
    return (
      <div className="dropDownMenuContainer">
        <img className="dropDownMenuImg" src={Directors} alt="dropDownImg" />
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "letter"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/letter">
              {t(`${translationComponent}.Letter from the Founding Directors`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "project"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/project">
              {t(`${translationComponent}.Our School Project`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "cycles"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/cycles">
              {t(`${translationComponent}.Our Cycles`)}
            </Link>
          </li>
        </ul>
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "alumni"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/alumni">
              {t(`${translationComponent}.Alumni`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "traditions"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/traditions">
              {t(`${translationComponent}.Traditions`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "accreditations"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/accreditations">
              {t(`${translationComponent}.Accreditations & Certifications`)}
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  const StudentLife = () => {
    return (
      <div className="dropDownMenuContainer">
        <img className="dropDownMenuImg" src={Studentslife} alt="dropDownImg" />
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "clubs"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/clubs">
              {t(`${translationComponent}.Clubs & Organizations`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "webinars"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/webinars">
              {t(`${translationComponent}.Interviews & Webinars`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "radio"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/radio">
              {t(`${translationComponent}.School Radio`)}
            </Link>
          </li>
        </ul>
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "publicSpeaking"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/publicSpeaking">
              {t(`${translationComponent}.Public Speaking & Speech Contest`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "virtualExchange"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/virtualExchange">
              {t(`${translationComponent}.Virtual Exchange`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "tigersNights"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/tigersNights">
              Tigers Nights
            </Link>
          </li>
        </ul>
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "MUN"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/MUN">
              MUN
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "sports"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/sports">
              {t(`${translationComponent}.Sports`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "trips"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/trips">
              {t(`${translationComponent}.School Trips`)}
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  const Services = () => {
    return (
      <div className="dropDownMenuContainer">
        <img className="dropDownMenuImg" src={Cafeteria} alt="dropDownImg" />
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "cafeteria"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/service/cafeteria">
              {t(`${translationComponent}.Cafeteria`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "bus"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/service/bus">
              {t(`${translationComponent}.School Bus`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "infirmary"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/service/infirmary">
              {t(`${translationComponent}.Infirmary`)}
            </Link>
          </li>
        </ul>
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "tutoring"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/service/tutoring">
              {t(`${translationComponent}.Tutoring`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "library"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/service/library">
              {t(`${translationComponent}.Library`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "afterSchoolCare"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/service/afterSchoolCare">
              {t(`${translationComponent}.After-school Care`)}
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  const Resources = () => {
    return (
      <div className="dropDownMenuContainer">
        <img className="dropDownMenuImg" src={ResourcesImg} alt="dropDownImg" />
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "virtualTour"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/virtualTour">
              {t(`${translationComponent}.Virtual Tour`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "orientation"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/orientation">
              {t(`${translationComponent}.Orientation`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "wallOfFame"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/wallOfFame">
              Wall Of Fame
            </Link>
          </li>
        </ul>
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "rules"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/rules">
              {t(`${translationComponent}.School Rules`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "calendar"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/calendar">
              {t(`${translationComponent}.School Calendar`)}
            </Link>
          </li>
        </ul>
        <ul className="dropDownMenuList">
          <li
            className={`${
              activeInnerPage === "suppliesList"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/suppliesLists">
              {t(`${translationComponent}.Supplies Lists`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "eLearning"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/eLearning">
              {t(`${translationComponent}.e-Learning`)}
            </Link>
          </li>
          <li
            className={`${
              activeInnerPage === "scholarships"
                ? "navbarMenuActive"
                : "hover-underline-animation hover-animation-white dropDownItem"
            }`}
          >
            <Link className="links" to="/scholarships">
              {t(`${translationComponent}.Scholarships`)}
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  return menu == "About" ? (
    <About />
  ) : menu == "Student Life" ? (
    <StudentLife />
  ) : menu == "Services" ? (
    <Services />
  ) : menu == "Resources" ? (
    <Resources />
  ) : (
    <></>
  );
};

export default DropDownMenu;
