import "./trips.scss";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { trips } from "../../utils/texts";
import { useTranslation } from "react-i18next";

const Trips = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = trips[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="tripsContainer">
      <h1>{getTranslatedText("title")}</h1>
      {getTranslatedText("text")}
      <span className="tripsSocialMediaIcons">
        <a
          className="links tripsSocialMediaIcon tripsYTIcon"
          href="https://www.youtube.com/watch?v=ZjIkN25lbR4&list=PLtR-4Oz-WiEhFf6msNOeezrlGWOs1_mPe"
          target="_blank"
        >
          <YouTubeIcon style={{ fontSize: "5vh" }} />
        </a>
      </span>
    </div>
  );
};

export default Trips;
