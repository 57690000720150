import { useLocation, Link } from "react-router-dom";
import "./service.scss";
import SchoolBusImage from "../../images/SchoolBus.JPG";
import LibraryPhoto from "../../images/Library.JPG";
import Cafeteria from "../../images/Cafeteria.JPG";
import Tutoring from "../../images/Tutoring.JPG";
import AfterschoolCare from "../../images/AfterschoolCare.JPG";
import Infirmary from "../../images/Infirmary.JPG";
import { useTranslation } from "react-i18next";
import { serviceToTranslate } from "../../utils/texts";

const Service = () => {
  const service = useLocation().pathname.split("/")[2];
  //bus, cafeteria, infirmary, tutoring, afterSchoolCare, library
  const { t, i18n } = useTranslation();
  const translationPage = "pages.service";

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = serviceToTranslate[element][currentLanguage];
    return <p>{text}</p>;
  };

  return (
    <div className="serviceContainer">
      {service === "bus" ? (
        <>
          <div
            className="serviceTop"
            style={{
              background: `linear-gradient(to top, #4a154db3, #4a154db3), url(${SchoolBusImage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <h1 className="serviceTopHeader">
              {t(`${translationPage}.School Bus`)}
            </h1>
          </div>
          <div className="serviceText">{getTranslatedText("bus")}</div>
        </>
      ) : service === "cafeteria" ? (
        <>
          <div
            className="serviceTop"
            style={{
              background: `linear-gradient(to top, #4a154db3, #4a154db3), url(${Cafeteria})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <h1 className="serviceTopHeader">
              {t(`${translationPage}.Cafeteria`)}
            </h1>
          </div>
          <div className="serviceText">{getTranslatedText("cafeteria")}</div>
        </>
      ) : service === "infirmary" ? (
        <>
          <div
            className="serviceTop"
            style={{
              background: `linear-gradient(to top, #4a154db3, #4a154db3), url(${Infirmary})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <h1 className="serviceTopHeader">
              {t(`${translationPage}.Infirmary`)}
            </h1>
          </div>
          <div className="serviceText">{getTranslatedText("infirmary")}</div>
        </>
      ) : service === "tutoring" ? (
        <>
          <div
            className="serviceTop"
            style={{
              background: `linear-gradient(to top, #4a154db3, #4a154db3), url(${Tutoring})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <h1 className="serviceTopHeader">
              {t(`${translationPage}.Tutoring`)}
            </h1>
          </div>
          <div className="serviceText">{getTranslatedText("tutoring")}</div>
        </>
      ) : service === "library" ? (
        <>
          <div
            className="serviceTop"
            style={{
              background: `linear-gradient(to top, #4a154db3, #4a154db3), url(${LibraryPhoto})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <h1 className="serviceTopHeader">
              {t(`${translationPage}.Library`)}
            </h1>
          </div>
          <div className="serviceText">{getTranslatedText("library")}</div>
        </>
      ) : service === "afterSchoolCare" ? (
        <>
          <div
            className="serviceTop"
            style={{
              background: `linear-gradient(to top, #4a154db3, #4a154db3), url(${AfterschoolCare})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <h1 className="serviceTopHeader">
              {t(`${translationPage}.After-school Care`)}
            </h1>
          </div>
          <div className="serviceText">{getTranslatedText("ASC")}</div>
        </>
      ) : (
        <div className="serviceRedirectContainer">
          <p>{t(`${translationPage}.Please select a service`)}</p>
          <div>
            <Link className="links" to="/service/bus">
              <span className="serviceRedirectBtn">
                {t(`${translationPage}.School Bus`)}
              </span>
            </Link>
            <Link className="links" to="/service/cafeteria">
              <span className="serviceRedirectBtn">
                {t(`${translationPage}.Cafeteria`)}
              </span>
            </Link>
            <Link className="links" to="/service/infirmary">
              <span className="serviceRedirectBtn">
                {t(`${translationPage}.Infirmary`)}
              </span>
            </Link>
            <Link className="links" to="/service/tutoring">
              <span className="serviceRedirectBtn">
                {t(`${translationPage}.Tutoring`)}
              </span>
            </Link>
            <Link className="links" to="/service/library">
              <span className="serviceRedirectBtn">
                {t(`${translationPage}.Library`)}
              </span>
            </Link>
            <Link className="links" to="/service/afterSchoolCare">
              <span className="serviceRedirectBtn">
                {t(`${translationPage}.After-school Care`)}
              </span>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Service;
