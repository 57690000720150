import "./applications.scss";
import { useEffect, useState } from "react";
import Rating from "../rating/Rating";
import {
  applicationStatus,
  cycles,
  hiringTypes,
  positions,
  ratings,
  constants,
  somethingWentWrong,
} from "../../utils/constants";
import moment from "moment";
import { message } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ApplicantPhoto from "../../images/Applicant.jpeg";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const defaultFilterData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  rating: "",
  type: "",
  outcome: "",
};

const Applications = () => {
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [applications, setApplications] = useState([]);
  const [loadingApplications, setLoadingApplications] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState("");
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}images/`;
  const [cycleList, setCycleList] = useState([]);
  const [cycle, setCycle] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [position, setPosition] = useState([]);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();
  const translationComponent = "applications";

  useEffect(() => {
    fetchData();
  }, [filterData, reload]);

  const fetchApplications = async () => {
    const { firstName, lastName, email, phone, rating, type, outcome } =
      filterData; //what about cycles and position/field?
    setLoadingApplications(true);
    try {
      const { data } = await axios.get(
        `applications/find?firstName=${firstName}&lastName=${lastName}&email=${email}&phone=${phone}&rating=${rating}&type=${type}&position=${JSON.stringify(
          position
        )}&cycle=${JSON.stringify(cycle)}&status=${outcome}`
      );
      if (data.status === "success") {
        setLoadingApplications(false);
        return data.applications;
      } else {
        setLoadingApplications(false);
        return [];
      }
    } catch (err) {
      message.error(somethingWentWrong);
    }
    setLoadingApplications(false);
    return;
  };

  const fetchData = async () => {
    const apps = await fetchApplications();
    setApplications(apps);
  };

  const handleFilterData = (newValue, fieldName) => {
    const newFilterData = { ...filterData };
    newFilterData[fieldName] = newValue;
    setFilterData(newFilterData);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleReset = async () => {
    setPositionList([]);
    setPosition([]);
    setCycleList([]);
    setCycle([]);
    setFilterData(defaultFilterData);
    setFormattedPhone("");
    await fetchData();
  };

  const handlePhoneInput = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormattedPhone(formattedPhone);
  };

  const handleMarkAsUnseen = async (id) => {
    try {
      await axios.put(`applications/unsee/${id}`);
      setReload(!reload);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  return (
    <div className="applicationsContainer">
      <div className="applicationsFilterWrapper">
        <p>{t(`${translationComponent}.Filter Applications`)}</p>
        <form className="applicationsFilterForm" action="" method="POST">
          <div className="applicationsFilterFormWrapper">
            <input
              className="applicationsFilterFormCol1"
              type="text"
              name="fName"
              placeholder={t(`${translationComponent}.First Name`)}
              id="applicationsFilterFormFName"
              onChange={(e) => handleFilterData(e.target.value, "firstName")}
            />
            <input
              className="applicationsFilterFormCol2"
              type="text"
              name="lName"
              placeholder={t(`${translationComponent}.Last Name`)}
              id="applicationsFilterFormLName"
              onChange={(e) => handleFilterData(e.target.value, "lastName")}
            />

            <select
              name="rating"
              className="applicationsFilterFormCol3"
              onChange={(e) => handleFilterData(e.target.value, "rating")}
            >
              <option className="applicationsFilterDefaultOption" value="">
                {t(`${translationComponent}.Rating`)}
              </option>
              {ratings.map((rating) => (
                <option value={rating.value}>
                  {rating.name} {rating.value === 1 ? "star" : "stars"}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => handleFilterData(e.target.value, "outcome")}
              className="applicationsFilterFormCol1"
            >
              <option className="applicationsFilterDefaultOption" value="">
                {t(`${translationComponent}.Outcome`)}
              </option>
              {applicationStatus.map((option) => (
                <option value={option.value}>{t(option.translationKey)}</option>
              ))}
            </select>
            <select
              name="type"
              className="applicationsFilterFormCol2"
              onChange={(e) => handleFilterData(e.target.value, "type")}
            >
              <option className="applicationsFilterDefaultOption" value="">
                {t(`${translationComponent}.Type`)}
              </option>
              {hiringTypes.map((type) => (
                <option value={type.value}>{t(type.translationKey)}</option>
              ))}
            </select>
            <input
              className="applicationsFilterFormCol3"
              value={formattedPhone}
              onChange={(e) => {
                handlePhoneInput(e);
                handleFilterData(e.target.value, "phone");
              }}
              name="phone"
              placeholder={t(`${translationComponent}.Phone Number`)}
              id="applicationsFilterFormPhone"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="applicationsFilterFormCol1">
              <Select
                className="applicationsFilterFormMultiSelectCol"
                options={cycles.map((cycle) => ({
                  value: cycle.value,
                  label: t(cycle.translationKey),
                }))}
                isMulti
                value={cycleList}
                onChange={(selected, triggeredAction) => {
                  if (triggeredAction.action === "clear") {
                    setCycleList([]);
                    setCycle([]);
                  } else if (triggeredAction.action === "remove-value") {
                    const removedValue = triggeredAction.removedValue.value;
                    setCycleList(
                      cycleList.filter(
                        (option) => option.value !== removedValue
                      )
                    );
                    setCycle(cycle.filter((val) => val !== removedValue));
                  } else if (triggeredAction.action === "select-option") {
                    setCycleList(selected);
                    const values = selected?.map((option) => option?.value);
                    setCycle(values);
                  }
                  setReload(!reload);
                }}
                closeMenuOnSelect={false}
                placeholder={t(`${translationComponent}.Cycle(s)`)}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    outline: state.isFocused ? "none" : "none",
                    border: state.isFocused ? "none" : "none",
                    boxShadow: state.isFocused ? "none" : "none",
                    height: state.menuIsOpen ? "100%" : "50px",
                    overflow: state.isFocused ? "visible" : "hidden",
                    overflow: state.menuIsOpen ? "visible" : "hidden",
                    width: "100%",
                    marginTop: state.isFocused ? "none" : "-5px",
                  }),
                  indicatorsContainer: (base, state) => ({
                    ...base,
                    position: state.isMulti ? "absolute" : "inherit",
                    top: state.isMulti ? "0" : "inherit",
                    right: state.isMulti ? "0" : "inherit",
                    zIndex: "999",
                    backgroundColor: "white",
                    display: "flex",
                  }),
                }}
              />
            </span>
            <input
              className="applicationsFilterFormCol2"
              type="text"
              name="email"
              placeholder={t(`${translationComponent}.Email`)}
              id="applicationsFilterFormEmail"
              onChange={(e) => handleFilterData(e.target.value, "email")}
            />
            <span className="applicationsFilterFormCol3">
              <Select
                className="applicationsFilterFormMultiSelectCol"
                options={positions.map((position) => ({
                  value: position.value,
                  label: t(position.translationKey),
                }))}
                isMulti
                value={positionList}
                onChange={(selected, triggeredAction) => {
                  if (triggeredAction.action === "clear") {
                    setPositionList([]);
                    setPosition([]);
                  } else if (triggeredAction.action === "remove-value") {
                    const removedValue = triggeredAction.removedValue.value;
                    setPositionList(
                      cycleList.filter(
                        (option) => option.value !== removedValue
                      )
                    );
                    setPosition(cycle.filter((val) => val !== removedValue));
                  } else if (triggeredAction.action === "select-option") {
                    setPositionList(selected);
                    const values = selected?.map((option) => option?.value);
                    setPosition(values);
                  }
                  setReload(!reload);
                }}
                closeMenuOnSelect={false}
                placeholder={t(`${translationComponent}.Position / Field`)}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    outline: state.isFocused ? "none" : "none",
                    border: state.isFocused ? "none" : "none",
                    boxShadow: state.isFocused ? "none" : "none",
                    height: state.menuIsOpen ? "100%" : "50px",
                    overflow: state.isFocused ? "visible" : "hidden",
                    overflow: state.menuIsOpen ? "visible" : "hidden",
                    width: "100%",
                    marginTop: state.isFocused ? "none" : "-5px",
                  }),
                  indicatorsContainer: (base, state) => ({
                    ...base,
                    position: state.isMulti ? "absolute" : "inherit",
                    top: state.isMulti ? "0" : "inherit",
                    right: state.isMulti ? "0" : "inherit",
                    zIndex: "9",
                    backgroundColor: "white",
                    display: "flex",
                  }),
                }}
              />
            </span>
          </div>
          <input
            className="applicationsFilterFormReset"
            type="reset"
            name="reset"
            placeholder={t(`${translationComponent}.Reset`)}
            id="applicationsFilterFormReset"
            onClick={() => handleReset()}
          />
          <button onClick={handleFilter}>
            {t(`${translationComponent}.GO`)}
          </button>
        </form>
      </div>

      <span
        style={{
          marginTop: "15px",
          fontFamily: "Poppins, sans-serif",
          fontSize: "25px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Total:{" "}
        <p style={{ fontWeight: "bold" }}>&nbsp; {applications?.length}</p>
      </span>
      {loadingApplications ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : (
        <div className="applicationsListWrapper">
          {applications?.length > 0 ? (
            <table className="applicationsList">
              <tr>
                <th>&nbsp;</th>
                <th> {t(`${translationComponent}.Applied`)}</th>
                <th> {t(`${translationComponent}.Photo`)}</th>
                <th> {t(`${translationComponent}.First Name`)}</th>
                <th> {t(`${translationComponent}.Last Name`)}</th>
                <th> {t(`${translationComponent}.Position`)}</th>
                <th> {t(`${translationComponent}.Cycle`)}</th>
                <th> {t(`${translationComponent}.Phone`)}</th>
                <th> {t(`${translationComponent}.Rating`)}</th>
                <th> {t(`${translationComponent}.Status`)}</th>
              </tr>
              {applications?.map((application) => (
                <tr
                  className={`applicationsListSingle ${
                    application?.seen ? "" : "unseenApplication"
                  }`}
                >
                  <div
                    style={{
                      width: "110%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      className="links"
                      to={`/applications/${application?._id}`}
                    >
                      <RemoveRedEyeIcon />
                    </Link>
                    <VisibilityOffIcon
                      onClick={() => handleMarkAsUnseen(application?._id)}
                      className="applicantIcon"
                    />
                  </div>
                  <td>
                    {moment(application?.createdAt)
                      .utc()
                      .format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td className="applicationsListPhotoTd">
                    <img
                      className={
                        application.photo
                          ? "applicationsListPhoto"
                          : "applicationsListNoPhoto"
                      }
                      src={
                        application?.photo === ""
                          ? ApplicantPhoto
                          : PF + application.photo
                      }
                      alt="Applicant photo"
                    />
                  </td>
                  <td>{application?.firstName}</td>
                  <td>{application?.lastName}</td>
                  <td>{application?.position.join(", ")}</td>
                  <td>{application?.cycle.join(", ")}</td>
                  <td>{application?.phone}</td>
                  <td className="applicationsListStars">
                    <Rating applicant={application} />
                  </td>
                  <td>{application?.status}</td>
                </tr>
              ))}
            </table>
          ) : (
            <p> {t(`${translationComponent}.noAppsFound`)}</p>
          )}
        </div>
      )}
      <div className="applicationsStatusInfo">
        <span className="applicationsStatusColorCode">
          <span></span>
          <p> {t(`${translationComponent}.notYetSeen`)}</p>
        </span>
        <p>
          <b> {t(`${translationComponent}.Statuses:`)}</b>
          {applicationStatus.map((status) => (
            <>{t(status.translationKey)} | </>
          ))}
        </p>
      </div>
    </div>
  );
};

export default Applications;
