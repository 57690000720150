import React, { useEffect, useState } from "react";
import { message } from "antd";


function FacebookLoginButton() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    function checkLoginStatus() {
      window.FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    }

    function statusChangeCallback(response) {
      if (response.status === "connected") {
        message.success("FB: Logged in and authenticated");
        setIsLoggedIn(true);
      } else {
        message.error("FB: Not Authenticated");
        setIsLoggedIn(false);
      }
    }

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1327152284513971",
        cookie: true,
        xfbml: true,
        version: "v16.0",
      });
      checkLoginStatus();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.checkLoginState = function () {
      checkLoginStatus();
    };

    window.FB.XFBML.parse();
  }, []);

  function handleLogout() {
    window.FB.logout(function (response) {
      setIsLoggedIn(false);
      message.success("FB: Logged out");
    });
  }

  function handleLogin() {
    window.FB.login(function (response) {
      if (response.authResponse) {
        setIsLoggedIn(true);
        message.success("FB: Logged in and authenticated");
      } else {
        setIsLoggedIn(false);
        message.error("FB: Not authenticated");
      }
    });
  }

  return (
    <div>
      {isLoggedIn ? (
        <div>
          <button className="fbLogoutBtn" onClick={handleLogout}>
            FB Logout
          </button>
        </div>
      ) : (
        <div>
          {" "}
          <button className="fbLoginBtn" onClick={handleLogin}>
            FB Login
          </button>
        </div>
      )}
    </div>
  );
}

export default FacebookLoginButton;
