import "./project.scss";
import { useTranslation } from "react-i18next";
import { project } from "../../utils/texts";

const Project = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = project[element][currentLanguage];
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
  };

  return (
    <div className="projectContainer">
      <h1>{getTranslatedText("title")}</h1>
      <h2>
        {" "}
        {getTranslatedText("slogan1")}
        <span className="projectHighlight">
          {getTranslatedText("slogan2")}
        </span>{" "}
        {getTranslatedText("slogan3")}{" "}
        <span className="projectHighlight">{getTranslatedText("slogan4")}</span>
      </h2>
      <span>{getTranslatedText("text")}</span>
    </div>
  );
};

export default Project;
