import "./radio.scss";
import LanguageIcon from "@mui/icons-material/Language";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import { radio } from "../../utils/texts";

const Radio = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = radio[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="radioContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
      <span className="radioSocialMediaIcons">
        <a
          className="links radioSocialMediaIcon"
          href="https://www.spreaker.com/show/radio-scaolaire-hilali-targas-show"
          target="_blank"
        >
          <LanguageIcon style={{ fontSize: "5vh" }} />
        </a>
        <a
          className="links radioSocialMediaIcon radioYTIcon"
          href="https://www.youtube.com/watch?v=9GjgCtmm2L4&list=PLtR-4Oz-WiEhogzpivAxnsJmXKwlb7MNM"
          target="_blank"
        >
          <YouTubeIcon style={{ fontSize: "5vh" }} />
        </a>
      </span>
    </div>
  );
};

export default Radio;
