import "./virtualExchange.scss";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import { virtualExchange } from "../../utils/texts";

const VirtualExchange = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = virtualExchange[element][currentLanguage];
    return <p>{text}</p>;
  };

  return (
    <div className="virtualExchangeContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
      <span className="virtualExchangeSocialMediaIcons">
        <a
          className="links virtualExchangeSocialMediaIcon"
          href="https://virtualexchange.ma/hilali-targa-international-high-school/"
          target="_blank"
        >
          <LanguageIcon style={{ fontSize: "5vh" }} />
        </a>
        <a
          className="links virtualExchangeSocialMediaIcon virtualExchangeYTIcon"
          href="https://www.youtube.com/watch?v=KHe-fJ4e68g&list=PLtR-4Oz-WiEiWf74CRsWu1IhUvJ01fZyk"
          target="_blank"
        >
          <YouTubeIcon style={{ fontSize: "5vh" }} />
        </a>
      </span>
    </div>
  );
};

export default VirtualExchange;
