import "./newApplication.scss";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useRef, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { Link } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import {
  applicationStatus,
  cycles,
  positions,
  ratings,
  hiringTypes,
  applicationsStreams,
} from "../../utils/constants";
import { fileOnChange } from "../../hooks/fileOnChange";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const NewApplication = () => {
  const [submitted, setSubmitted] = useState(false);
  const firstName = useRef();
  const lastName = useRef();
  const email = useRef();
  const type = useRef();
  const [formattedPhone, setFormattedPhone] = useState("");
  const [position, setPosition] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [cycleList, setCycleList] = useState([]);
  const [cycle, setCycle] = useState([]);
  const [resume, setResume] = useState([]);
  const [photo, setPhoto] = useState("");
  const [test, setTest] = useState([]);
  const [resumePreview, setResumePreview] = useState();
  const [photoPreview, setPhotoPreview] = useState();
  const [testPreview, setTestPreview] = useState();
  const rating = useRef();
  const appStream = useRef();
  const status = useRef();
  const notes = useRef();
  const lastContact = useRef();
  const [application, setApplication] = useState();
  const { t } = useTranslation();
  const translationComponent = "newApplication";

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newApplication = {
      firstName:
        firstName.current.value.charAt(0).toUpperCase() +
        firstName.current.value.slice(1).toLowerCase(),
      lastName:
        lastName.current.value.charAt(0).toUpperCase() +
        lastName.current.value.slice(1).toLowerCase(),
      email: email.current.value,
      type: type.current.value,
      phone: formattedPhone,
      position: position,
      cycle,
      rating: rating.current.value,
      appStream: appStream.current.value,
      status: status.current.value || "Applied / Not seen",
      notes: notes.current.value,
      lastContact: lastContact.current.value,
      resume: [],
    };
    if (resume.length > 0) {
      const data = new FormData();
      for (let i = 0; i < resume.length; i++) {
        const filename =
          Date.now() +
          newApplication.lastName +
          newApplication.firstName +
          "resume" +
          resume[i].name;
        data.append("name", filename);
        const img = resume[i];
        data.append("file", img, filename);
        newApplication.resume.push(filename);
      }
      try {
        await axios.post("multiUpload", data);
      } catch (err) {}
    }
    if (photo) {
      const data = new FormData();
      const img = photo;
      const filename =
        newApplication.lastName + newApplication.firstName + "photo" + img.name;
      data.append("name", filename);
      data.append("file", img, filename);
      newApplication.photo = filename;
      try {
        await axios.post("upload", data);
      } catch (err) {}
    }
    if (test.length > 0) {
      newApplication.test = [];
      const data = new FormData();
      for (let i = 0; i < test.length; i++) {
        const filename =
          Date.now() +
          newApplication.lastName +
          newApplication.firstName +
          "test" +
          test[i].name;
        data.append("name", filename);
        const img = test[i];
        data.append("file", img, filename);
        newApplication.test.push(filename);
      }
      try {
        await axios.post("multiUpload", data);
      } catch (err) {}
    }
    try {
      const { data } = await axios.post("applications", newApplication);
      if (data.status === "applicationExists") message.info(data.message);
      else if (data.status === "success") {
        setApplication(data.savedApplication);
        message.success(data.message);
        setSubmitted(true);
      }
    } catch (err) {}
  };

  if (submitted) {
    return (
      <div className="newApplicationSubmitted">
        <p>{t(`${translationComponent}.Successfully added !`)}</p>
        <span>
          <Link className="links" to={`/applications/${application?._id}`}>
            <button className="newApplicationSubmittedBtn">
              {t(`${translationComponent}.View Application`)}
            </button>
          </Link>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="newApplicationSubmittedBtn"
          >
            {t(`${translationComponent}.Add new one`)}
          </button>
          <button
            onClick={() => window.location.reload()}
            className="newApplicationSubmittedBtn"
          >
            {t(`${translationComponent}.View All`)}
          </button>
        </span>
      </div>
    );
  }

  const handlePhoneInput = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormattedPhone(formattedPhone);
  };

  return (
    <div className="newApplicationContainer">
      <form className="newApplicationForm" onSubmit={handleSubmit}>
        <div className="newApplicationFormWrapper">
          <span className="newApplicationCol1 newApplicationRequiredCol">
            <input
              ref={firstName}
              type="text"
              placeholder={t(`${translationComponent}.First Name`)}
              required
            />
            <p>*</p>
          </span>
          <span className="newApplicationCol2 newApplicationRequiredCol">
            <input
              ref={lastName}
              type="text"
              placeholder={t(`${translationComponent}.Last Name`)}
              required
            />
            <p>*</p>
          </span>
          <span className="newApplicationCol3">
            <input
              ref={email}
              type="email"
              placeholder={t(`${translationComponent}.Email`)}
              required={false} //used to be true but some don't have an email
            />
          </span>
          <span className="newApplicationCol1 newApplicationRequiredCol">
            <input
              value={formattedPhone}
              onChange={(e) => handlePhoneInput(e)}
              placeholder={t(`${translationComponent}.Phone Number`)}
              required
            />
            <p>*</p>
          </span>
          <select ref={rating} className="newApplicationCol2">
            <option selected value="">
              {t(`${translationComponent}.Rating`)}
            </option>
            {ratings.map((rating) => (
              <option value={rating.value}>
                {rating.name} {rating.value === 1 ? "star" : "stars"}
              </option>
            ))}
          </select>
          <span className="newApplicationCol3" style={{ position: "relative" }}>
            <Select
              options={cycles.map((cycle) => ({
                value: cycle.value,
                label: t(cycle.translationKey),
              }))}
              isMulti
              value={cycleList}
              onChange={(selected, triggeredAction) => {
                if (triggeredAction.action === "clear") {
                  setCycleList([]);
                  setCycle([]);
                } else if (triggeredAction.action === "remove-value") {
                  const removedValue = triggeredAction.removedValue.value;
                  setCycleList(
                    cycleList.filter((option) => option.value !== removedValue)
                  );
                  setCycle(cycle.filter((val) => val !== removedValue));
                } else if (triggeredAction.action === "select-option") {
                  setCycleList(selected);
                  const values = selected?.map((option) => option?.value);
                  setCycle(values);
                }
              }}
              closeMenuOnSelect={false}
              placeholder={t(`${translationComponent}.Cycles`)}
              styles={{
                control: (base, state) => ({
                  ...base,
                  outline: state.isFocused ? "none" : "none",
                  border: state.isFocused ? "none" : "none",
                  boxShadow: state.isFocused ? "none" : "none",
                  height: state.menuIsOpen ? "100%" : "50px",
                  overflow: state.isFocused ? "visible" : "hidden",
                  overflow: state.menuIsOpen ? "visible" : "hidden",
                }),
                indicatorsContainer: (base, state) => ({
                  ...base,
                  position: state.isMulti ? "absolute" : "inherit",
                  top: state.isMulti ? "0" : "inherit",
                  right: state.isMulti ? "0" : "inherit",
                  zIndex: "999",
                  backgroundColor: "white",
                  display: "flex",
                }),
              }}
            />
          </span>
          <label id="newApplicationFormPhoto">
            <input
              onChange={(e) =>
                fileOnChange(
                  e,
                  "photo",
                  resume,
                  setResume,
                  resumePreview,
                  setResumePreview,
                  photo,
                  setPhoto,
                  photoPreview,
                  setPhotoPreview,
                  "",
                  ""
                )
              }
              className="newApplicationCol1"
              type="file"
            />
            {t(`${translationComponent}.Photo`)}{" "}
            <AttachFileIcon className="newApplicationFormAttachIcon" />
            {photo && (
              <img
                className="newApplicationFormImgPreview"
                src={photoPreview}
              />
            )}
          </label>
          <input
            ref={notes}
            className="newApplicationCol2"
            type="text"
            placeholder={t(`${translationComponent}.Notes`)}
          />
          <label
            id="newApplicationFormResume"
            className="newApplicationRequiredCol"
          >
            <input
              onChange={(e) =>
                fileOnChange(
                  e,
                  "resume",
                  resume,
                  setResume,
                  resumePreview,
                  setResumePreview,
                  photo,
                  setPhoto,
                  photoPreview,
                  setPhotoPreview,
                  test,
                  setTest,
                  testPreview,
                  setTestPreview
                )
              }
              className="newApplicationCol3"
              type="file"
              required
              multiple
              name="resume"
            />
            {t(`${translationComponent}.Resume / CV`)}{" "}
            <AttachFileIcon className="newApplicationFormAttachIcon" />
            {resumePreview && (
              <span className="newApplicationFormImgPreviewSpan">
                <img
                  className="newApplicationFormImgPreview"
                  src={resumePreview}
                />
                {resume.length > 1 && (
                  <span className="newApplicationFormImgPreviewLength">
                    {" "}
                    +{resume.length - 1}{" "}
                  </span>
                )}
              </span>
            )}
            <p>*</p>
          </label>

          <select ref={status} className="newApplicationCol1">
            <option selected value="">
              {t(`${translationComponent}.Status`)}
            </option>
            {applicationStatus.map((status) => (
              <option value={status.value}>{t(status.translationKey)}</option>
            ))}
          </select>

          <span className="newApplicationCol2 newApplicationSelectRequired">
            <Select
              required
              options={positions.map((position) => ({
                value: position.value,
                label: t(position.translationKey),
              }))}
              isMulti
              value={positionList}
              onChange={(selected, triggeredAction) => {
                if (triggeredAction.action === "clear") {
                  setPositionList([]);
                  setPosition([]);
                } else if (triggeredAction.action === "remove-value") {
                  const removedValue = triggeredAction.removedValue.value;
                  setPositionList(
                    positionList.filter(
                      (option) => option.value !== removedValue
                    )
                  );
                  setPosition(position.filter((val) => val !== removedValue));
                } else if (triggeredAction.action === "select-option") {
                  setPositionList(selected);
                  const values = selected?.map((option) => option?.value);
                  setPosition(values);
                }
              }}
              closeMenuOnSelect={false}
              placeholder={t(`${translationComponent}.Position / Field`)}
              styles={{
                control: (base, state) => ({
                  ...base,
                  outline: state.isFocused ? "none" : "none",
                  border: state.isFocused ? "none" : "none",
                  boxShadow: state.isFocused ? "none" : "none",
                  height: state.menuIsOpen ? "100%" : "50px",
                  overflow: state.isFocused ? "visible" : "hidden",
                  overflow: state.menuIsOpen ? "visible" : "hidden",
                  //border: "1px solid red",
                  width: "300px",
                }),
                indicatorsContainer: (base, state) => ({
                  ...base,
                  position: state.isMulti ? "absolute" : "inherit",
                  top: state.isMulti ? "0" : "inherit",
                  right: state.isMulti ? "0" : "inherit",
                  zIndex: "999",
                  backgroundColor: "white",
                  display: "flex",
                }),
              }}
            />
            <p>*</p>
          </span>
          <span className="newApplicationCol3 newApplicationFormlastContacted">
            <label>
              {t(`${translationComponent}.Last Contact`)}
              <input ref={lastContact} type="date" />
            </label>
          </span>
          <label id="newApplicationFormTest">
            <input
              onChange={(e) =>
                fileOnChange(
                  e,
                  "test",
                  resume,
                  setResume,
                  resumePreview,
                  setResumePreview,
                  photo,
                  setPhoto,
                  photoPreview,
                  setPhotoPreview,
                  test,
                  setTest,
                  testPreview,
                  setTestPreview
                )
              }
              type="file"
              multiple
            />
            {t(`${translationComponent}.Test`)}
            <AttachFileIcon className="newApplicationFormAttachIcon" />
            {testPreview && (
              <span className="newApplicationFormImgPreviewSpan">
                <img
                  className="newApplicationFormImgPreview"
                  src={testPreview}
                />
                {test.length > 1 && (
                  <span className="newApplicationFormImgPreviewLength">
                    {" "}
                    +{test.length - 1}{" "}
                  </span>
                )}
              </span>
            )}
          </label>
          <select name="type" className="newApplicationCol2" ref={type}>
            <option value="">{t(`${translationComponent}.Type`)}</option>
            {hiringTypes.map((type) => (
              <option value={type.value}>{t(type.translationKey)}</option>
            ))}
          </select>
          <select
            name="appStream"
            className="newApplicationCol3"
            ref={appStream}
          >
            <option value="">
              {t(`${translationComponent}.How Did They Hear About Us?`)}
            </option>
            {applicationsStreams.map((stream) => (
              <option value={stream.value}>{t(stream.translationKey)}</option>
            ))}
          </select>
        </div>
        {/*<div style={{ display: "flex", justifyContent: "space-between" }}></div>*/}
        <p className="newApplicationRequiredNote">
          *<span> {t(`${translationComponent}.required fields`)}</span>
        </p>

        <button type="submit">{t(`${translationComponent}.Submit`)}</button>
      </form>
    </div>
  );
};

export default NewApplication;
