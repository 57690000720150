import "./services.scss";
import LibraryPhoto from "../../images/Library.JPG";
import Cafeteria from "../../images/Cafeteria.JPG";
import SchoolBus from "../../images/SchoolBus.JPG";
import Tutoring from "../../images/Tutoring.JPG";
import AfterschoolCare from "../../images/AfterschoolCare.JPG";
import Infirmary from "../../images/Infirmary.JPG";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  const translationPage = "pages.service";

  return (
    <div className="servicesContainer">
      <div>
        <Link className="links" to="/service/cafeteria">
          <span
            className="servicesSpan"
            style={{ background: `url(${Cafeteria})` }}
          >
            <p className="servicesText">{t(`${translationPage}.Cafeteria`)}</p>
          </span>
        </Link>
        <Link className="links" to="/service/infirmary">
          <span
            className="servicesSpan"
            style={{ background: `url(${Infirmary})` }}
          >
            <p className="servicesText">{t(`${translationPage}.Infirmary`)}</p>
          </span>
        </Link>

        <Link className="links" to="/service/library">
          <span
            className="servicesSpan"
            style={{ background: `url(${LibraryPhoto})` }}
          >
            <p className="servicesText">{t(`${translationPage}.Library`)}</p>
          </span>
        </Link>
      </div>
      <div>
        <Link className="links" to="/service/bus">
          <span
            className="servicesSpan"
            style={{ background: `url(${SchoolBus})` }}
          >
            <p className="servicesText">{t(`${translationPage}.School Bus`)}</p>
          </span>
        </Link>
        <Link className="links" to="/service/tutoring">
          <span
            className="servicesSpan"
            style={{ background: `url(${Tutoring})` }}
          >
            <p className="servicesText">{t(`${translationPage}.Tutoring`)}</p>
          </span>
        </Link>
        <Link className="links" to="/service/afterSchoolCare">
          <span
            className="servicesSpan"
            style={{ background: `url(${AfterschoolCare})` }}
          >
            <p className="servicesText">
              {t(`${translationPage}.After-school Care`)}
            </p>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Services;
