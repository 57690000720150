import "./publicSpeaking.scss";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import { publicSpeaking } from "../../utils/texts";

const PublicSpeaking = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = publicSpeaking[element][currentLanguage];
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
  };

  return (
    <div className="publicSpeakingContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
      <span className="publicSpeakingSocialMediaIcons">
        <a
          className="links publicSpeakingSocialMediaIcon publicSpeakingYTIcon"
          href="https://www.youtube.com/watch?v=4vR2e819FX0&list=PLtR-4Oz-WiEiFtQZs9DYVpT77PsGrqWhg"
          target="_blank"
        >
          <YouTubeIcon style={{ fontSize: "5vh" }} />
        </a>
      </span>
    </div>
  );
};

export default PublicSpeaking;
