import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../images/Logo.png";
import DropDownMenu from "../dropDownMenu/DropDownMenu";
import { AuthContext } from "../../context/AuthContext";
import { logoutCall } from "../../apiCalls";
import { PopupState } from "../../context/Popup";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import LinkIcon from "@mui/icons-material/Link";
import * as utils from "../../utils/constants";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import NavbarLinks from "./NavbarLinks";
import DropDownMenuResponsive from "../dropDownMenu/DropDownMenuResponsive";

const Navbar = () => {
  const { user, dispatch } = useContext(AuthContext);
  const {
    setPopupActive,
    setPopupTitle,
    setPopupBody,
    setPopupImage,
    setPopupBtn,
    setPopupIsCountdown,
    setPopupLink,
  } = PopupState();
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [activeInnerPage, setActiveInnerPage] = useState("");
  const path = location.pathname.split("/")[1];
  const { t, i18n } = useTranslation();
  const translationComponent = "navbar";
  const languages = utils.languages;

  useEffect(() => {
    if (path === "/" || path === "") {
      setActivePage("home");
      setActiveInnerPage("");
    } else if (
      path === "letter" ||
      path === "project" ||
      path === "cycles" ||
      path === "alumni" ||
      path === "traditions" ||
      path === "accreditations"
    ) {
      setActivePage("about");
      setActiveInnerPage(path);
    } else if (
      path === "clubs" ||
      path === "webinars" ||
      path === "radio" ||
      path === "publicSpeaking" ||
      path === "virtualExchange" ||
      path === "tigersNights" ||
      path === "MUN" ||
      path === "sports" ||
      path === "trips"
    ) {
      setActivePage("studentLife");
      setActiveInnerPage(path);
    } else if (path === "services") {
      setActivePage(path);
      setActiveInnerPage("");
    } else if (path === "service") {
      setActivePage("services");
      setActiveInnerPage(location.pathname.split("/")[2]);
    } else if (
      path === "suppliesList" ||
      path === "virtualTour" ||
      path === "orientation" ||
      path === "wallOfFame" ||
      path === "rules" ||
      path === "calendar" ||
      path === "eLearning" ||
      path === "scholarships"
    ) {
      setActivePage("resources");
      setActiveInnerPage(path);
    } else if (path === "services") {
      setActivePage(path);
      if (location.pathname.split("/")[2])
        setActiveInnerPage(location.pathname.split("/")[2]);
      else setActiveInnerPage("");
    } else if (path === "contact") {
      setActivePage("contact");
      setActiveInnerPage("");
    } else {
      setActivePage("");
      setActiveInnerPage("");
    }
  }, [location.pathname]);

  //Fetch popup state and contents
  useEffect(() => {
    const fetchPopupState = async () => {
      try {
        const { data } = await axios.get("homePopup");
        if (data.status === "success") {
          setPopupTitle(data.popup.title);
          setPopupBody(data.popup.body);
          setPopupImage(data.popup.image);
          setPopupBtn(data.popup.btn);
          setPopupActive(data.popup.active);
          if (data.popup.countdown) setPopupIsCountdown(true);
          setPopupLink(data.popup.link);
        }
      } catch (err) {
        message.error(utils.somethingWentWrong(t));
      }
    };
    fetchPopupState();
  }, []);

  const handleLogout = () => {
    try {
      logoutCall(dispatch);
    } catch (err) {
      message.error(utils.somethingWentWrong(t));
    }
  };

  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <>
      <div className="navbarContainer">
        {/**/}
        <div className="navbarResponsiveContainer">
          <div className="navbarResponsiveWrapper">
            <span className="navbarSandwishMenuIconSpan">
              <MenuIcon className="navbarSandwishMenuIcon" />
            </span>
            <ul className="navbarResponsiveDropdown">
              <li className="navbarResponsiveDropdownItem">
                <select
                  className="narvbarResponsiveLinksTranslateSpan"
                  id="navbarLanguageSelect"
                  onChange={(e) => handleChangeLanguage(e)}
                >
                  {Object.keys(languages).map((language) => (
                    <option type="submit" key={language} value={language}>
                      <span>
                        {languages[language].flag}
                        {languages[language].nativeName
                          .slice(0, 2)
                          .toUpperCase()}{" "}
                      </span>
                    </option>
                  ))}
                </select>
              </li>
              <li className="navbarResponsiveDropdownItem">
                <span className="navbarResponsiveDropdownBtn navbarResponsiveDropdownQuicklinks">
                  <LinkIcon style={{ marginRight: "5px" }} />
                  {t(`${translationComponent}.Quicklinks`)}
                </span>
                <NavbarLinks classname="navbarResponsiveSubListDropdown" />
              </li>
              <li className="navbarResponsiveDropdownItem">
                <span className="navbarResponsiveDropdownBtn">
                  <Link className="links" to="/">
                    {t(`${translationComponent}.HOME`)}
                  </Link>
                </span>
              </li>
              <li className="navbarResponsiveDropdownItem">
                <span className="navbarResponsiveDropdownBtn">
                  {t(`${translationComponent}.ABOUT`)}
                </span>
                <span className="navbarResponsiveSubListDropdown">
                  <DropDownMenuResponsive
                    menu="About"
                    activeInnerPage={activeInnerPage}
                    activePage={activePage}
                  />
                </span>
              </li>
              <li className="navbarResponsiveDropdownItem">
                <span className="navbarResponsiveDropdownBtn">
                  {t(`${translationComponent}.STUDENT LIFE`)}
                </span>
                <span className="navbarResponsiveSubListDropdown">
                  <DropDownMenuResponsive
                    menu="Student Life"
                    activeInnerPage={activeInnerPage}
                    activePage={activePage}
                  />
                </span>
              </li>
              <li className="navbarResponsiveDropdownItem">
                <span className="navbarResponsiveDropdownBtn">
                  {t(`${translationComponent}.SERVICES`)}
                </span>
                <span className="navbarResponsiveSubListDropdown">
                  <DropDownMenuResponsive
                    menu="Services"
                    activeInnerPage={activeInnerPage}
                    activePage={activePage}
                  />
                </span>
              </li>
              <li className="navbarResponsiveDropdownItem">
                <span className="navbarResponsiveDropdownBtn">
                  {t(`${translationComponent}.RESOURCES`)}
                </span>
                <span className="navbarResponsiveSubListDropdown">
                  <DropDownMenuResponsive
                    menu="Resources"
                    activeInnerPage={activeInnerPage}
                    activePage={activePage}
                  />
                </span>
              </li>
              <li className="navbarResponsiveDropdownItem">
                <span className="navbarResponsiveDropdownBtn">
                  {t(`${translationComponent}.CONTACT US`)}
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/**/}

        <div className="navbarLinks">
          {user ? (
            <ul className="navbarLinksUser">
              <li>
                {t(`${translationComponent}.Hi`)}
                {user?.username.charAt(0).toUpperCase() +
                  user?.username.slice(1)}
                !
              </li>
              <li>
                <Link className="links" to="user">
                  {t(`${translationComponent}.Account`)}
                </Link>
              </li>
              <li onClick={handleLogout}>
                {t(`${translationComponent}.Logout`)}
              </li>
            </ul>
          ) : (
            <div className="navbarQuickLinks">
              <span className="navbarQuickLinksDropdownBtn">
                <LinkIcon style={{ marginRight: "5px" }} />
                {t(`${translationComponent}.Quicklinks`)}
              </span>
              <NavbarLinks classname={"navbarQuickLinksDropdown"} />
            </div>
          )}
          <span>
            <select
              className="narvbarLinksTranslateSpan"
              id="navbarLanguageSelect"
              onChange={(e) => handleChangeLanguage(e)}
            >
              {Object.keys(languages).map((language) => (
                <option type="submit" key={language} value={language}>
                  <span>
                    {languages[language].flag}
                    {languages[language].nativeName
                      .slice(0, 2)
                      .toUpperCase()}{" "}
                  </span>
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="navbarMiddle">
          <Link className="links navbarLogoLink" to="/">
            <img className="navbarLogo" src={Logo} alt="HTIS Logo" />
          </Link>
          <span className="navbarHeader">
            <p className="navbarP1">
              &nbsp;<span className="navbarHTISInitial">H</span>ILALI{" "}
              <span className="navbarHTISInitial">T</span>ARGA
            </p>
            <p className="navbarP2">
              &nbsp;<span className="navbarHTISInitial">I</span>NTERNATIONAL{" "}
              <span className="navbarHTISInitial">S</span>CHOOL
            </p>
            <p className="navbarP3">&nbsp;KNOWLEDGE IS POWER</p>
            <p className="navbarP4">Since 1996</p>
          </span>
          <span className="navbarEmptySpan"></span>
        </div>
        <ul className="navbarMenu">
          <Link className="links" to={`/`}>
            <li className="narbarLink">
              <span>{t(`${translationComponent}.HOME`)}</span>
            </li>
          </Link>
          <li className="navbarMenuItem">
            <span
              className={`${activePage === "about" ? "navbarMenuActive" : ""}`}
            >
              {t(`${translationComponent}.ABOUT`)}
            </span>
            <DropDownMenu
              menu="About"
              activeInnerPage={activeInnerPage}
              activePage={activePage}
            />
          </li>
          <li className="navbarMenuItem">
            <span
              className={`${
                activePage === "studentLife" ? "navbarMenuActive" : ""
              }`}
            >
              {t(`${translationComponent}.STUDENT LIFE`)}
            </span>
            <DropDownMenu
              menu="Student Life"
              activeInnerPage={activeInnerPage}
              activePage={activePage}
            />
          </li>
          <li className="navbarMenuItem">
            <a className="navbarMenuItemName">
              <Link className="links" to="/services">
                <span
                  className={`${
                    activePage === "services" ? "navbarMenuActive" : ""
                  }`}
                >
                  {t(`${translationComponent}.SERVICES`)}
                </span>
              </Link>
            </a>
            <DropDownMenu
              menu="Services"
              activeInnerPage={activeInnerPage}
              activePage={activePage}
            />
          </li>
          <li className="navbarMenuItem">
            <a className="navbarMenuItemName">
              <span
                className={`${
                  activePage === "resources" ? "navbarMenuActive" : ""
                }`}
              >
                {t(`${translationComponent}.RESOURCES`)}
              </span>
            </a>
            <DropDownMenu
              menu="Resources"
              activeInnerPage={activeInnerPage}
              activePage={activePage}
            />
          </li>
          <Link className="links" to={`/contact`}>
            <li className="narbarLink">
              <span>{t(`${translationComponent}.CONTACT US`)}</span>
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
