import "./applicant.scss";
import ApplicantPhoto from "../../images/Applicant.jpeg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import useFetch from "../../hooks/useFetch";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { message } from "antd";
import { AuthContext } from "../../context/AuthContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Rating from "../../components/rating/Rating";
import {
  positions,
  applicationsStreams,
  constants,
  ratings,
  applicationStatus,
  cycles,
  somethingWentWrong,
  hiringTypes,
} from "../../utils/constants";
import { Link } from "react-router-dom";
import ApplicantLogs from "../../components/applicantLogs/ApplicantLogs";
import DeletePopup from "../../components/deletePopup/DeletePopup";
import { fileOnChange } from "../../hooks/fileOnChange";
import AccessDenied from "../../components/accessDenied/AccessDenied";
import Select from "react-select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowNotes from "../../components/showNotes/ShowNotes";
import { useTranslation } from "react-i18next";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const defaultApplicant = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  photo: "",
  rating: "",
  status: "",
  lastContact: "",
  appStream: "",
  type: "",
  cycle: [],
  position: [],
};

const Applicant = () => {
  const userId = useLocation().pathname.split("/")[2];
  const { user } = useContext(AuthContext);
  const { data } = useFetch(`applications/${user?.role}/${userId}`);
  const applicant = data.application;
  const createdDate = moment(applicant?.createdAt)
    .utc()
    .format("DD/MM/YYYY HH:mm");
  const lastContact =
    applicant?.lastContact !== null
      ? moment(applicant?.lastContact).utc().format("DD/MM/YYYY")
      : "";
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}images/`;
  const [showResume, setShowResume] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [showCVFlash, setShowCVFlash] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [applicantData, setApplicantData] = useState(defaultApplicant);
  const [photo, setPhoto] = useState("");
  const [resume, setResume] = useState([]);
  const [test, setTest] = useState([]);
  const [resumePreview, setResumePreview] = useState();
  const [testPreview, setTestPreview] = useState();
  const [photoPreview, setPhotoPreview] = useState();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const element = "applicant";
  const [cycleList, setCycleList] = useState([]);
  const [cycle, setCycle] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [position, setPosition] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const { t } = useTranslation();
  const translationPage = "pages.applicant";

  //Mark application as seen once we view it
  useEffect(() => {
    const changeVisibility = async () => {
      try {
        await axios.put(`applications/${userId}`, { seen: true });
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };
    changeVisibility();
  }, userId);

  const handleDeleteSingleImg = async (idx, img, element) => {
    if (element !== "singleResume" || applicant.resume.length > 1) {
      if (
        window.confirm(
          t(`${translationPage}.Are you sure you want to delete this image?`)
        )
      ) {
        try {
          const { data } = await axios.put(
            `applications/remove/${applicant?._id}/${element}/${idx}/${img}`
          );
          if (data.status === "success") {
            message.success(data.message);
            window.location.reload();
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    } else message.error(t(`${translationPage}.mustKeep1Image`));
  };

  if (showResume) {
    return (
      <div className="resumeContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowResume(false)}
        >
          <ArrowBackIosIcon />
          {t(`${translationPage}.Go Back`)}
        </span>
        <div className="resumeContainerImages">
          {applicant.resume.length > 0 &&
            applicant?.resume.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(index, image, "singleResume");
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Applicant Resume" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  if (showTest) {
    return (
      <div className="testContainer">
        <span
          className="resumeContainerGoBackBtn"
          onClick={() => setShowResume(false)}
        >
          <span onClick={() => setShowTest(false)}>
            <ArrowBackIosIcon />
            {t(`${translationPage}.Go Back`)}
          </span>
        </span>
        <div className="resumeContainerImages">
          {applicant.test.length > 0 &&
            applicant?.test.map((image, index) => (
              <span
                className="applicantContainerSpanShowPhotos"
                style={{ marginTop: "5px" }}
              >
                <span
                  onClick={() => {
                    handleDeleteSingleImg(index, image, "singleTest");
                  }}
                >
                  {" "}
                  &times;
                </span>
                <img src={PF + image} alt="Applicant Test" />
              </span>
            ))}
        </div>
      </div>
    );
  }

  if (showLogs) {
    return (
      <div className="applicantLogsContainer">
        <span
          onClick={() => setShowLogs(false)}
          style={{ marginBottom: "20px", cursor: "pointer" }}
        >
          <ArrowBackIosIcon />
          {t(`${translationPage}.Go Back`)}
        </span>
        <ApplicantLogs />
      </div>
    );
  }

  if (showCVFlash) {
    return (
      <div className="applicantLogsContainer">
        <span
          onClick={() => setShowLogs(false)}
          style={{ marginBottom: "20px", cursor: "pointer" }}
        >
          <ArrowBackIosIcon />
          {t(`${translationPage}.Go Back`)}
        </span>
        <p className="applicationCVFlashTitle">CV FLASH</p>
        <div className="applicationCVFlashWrapper">
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Civility</p>
            <p className="applicationCVFlashValue">{applicant.civility}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Full Name</p>
            <p className="applicationCVFlashValue">
              {applicant.firstName} {applicant.lastName}
            </p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Date of birth</p>
            <p className="applicationCVFlashValue">
              {applicant.dob.slice(0, 10)}
            </p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Place of birth</p>
            <p className="applicationCVFlashValue">{applicant.birthPlace}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Nationality</p>
            <p className="applicationCVFlashValue">{applicant.nationality}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Marital Status</p>
            <p className="applicationCVFlashValue">{applicant.maritalStatus}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Number of children</p>
            <p className="applicationCVFlashValue">{applicant.noOfChildren}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Pregnant</p>
            <p className="applicationCVFlashValue">{applicant.pregnant}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Address</p>
            <p className="applicationCVFlashValue">{applicant.address}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Transportation Means</p>
            <p className="applicationCVFlashValue">
              {applicant.transportationMeans}
            </p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Diplomas</p>
            <p className="applicationCVFlashValue">{applicant.diplomas}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Professional Experience</p>
            <p className="applicationCVFlashValue">
              {applicant.professionalExperience}
            </p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Current Status</p>
            <p className="applicationCVFlashValue">{applicant.currentStatus}</p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Current Situation</p>
            <p className="applicationCVFlashValue">
              {applicant.currentSituation}
            </p>
          </span>
          <span className="applicationCVFlashInline">
            <p className="applicationCVFlashKey">Languages</p>
            <p className="applicationCVFlashValue">{applicant.languages}</p>
          </span>
          <span>
            <p className="applicationCVFlashKey">Application Letter</p>
            <p className="applicationCVFlashValue">
              {applicant.applicationLetter}
            </p>
          </span>
        </div>
      </div>
    );
  }

  if (showNotes) {
    return <ShowNotes setShowNotes={setShowNotes} applicant={applicant} />;
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phone,
      rating,
      status,
      lastContact,
      appStream,
      type,
    } = applicantData;
    applicantData.cycle = cycle;
    applicantData.position = position;
    const updatedApplicant = {
      firstName: firstName || applicant.firstName,
      lastName: lastName || applicant.lastName,
      email: email || applicant.email,
      phone: phone || applicant.phone,
      position: position || applicant.position,
      cycle: cycle || applicant.cycle,
      rating: rating || applicant.rating,
      status: status === "null" ? "" : status || applicant.status,
      lastContact: lastContact || applicant.lastContact,
      appStream: appStream || applicant.appStream,
      resume: applicant.resume || [],
      test: applicant.test || [],
      type: type || applicant.type,
    };
    updatedApplicant.role = user?.role;
    if (photo) {
      const data = new FormData();
      const img = photo;
      const filename =
        updatedApplicant.lastName +
        updatedApplicant.firstName +
        "photo" +
        img.name;
      data.append("name", filename);
      data.append("file", img, filename);
      updatedApplicant.photo = filename;
      try {
        await axios.post("upload", data);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    }
    if (resume.length > 0) {
      const data = new FormData();
      for (let i = 0; i < resume.length; i++) {
        const filename =
          Date.now() +
          updatedApplicant.lastName +
          updatedApplicant.firstName +
          "resume" +
          resume[i].name;
        data.append("name", filename);
        const img = resume[i];
        data.append("file", img, filename);
        updatedApplicant.resume.push(filename);
      }
      try {
        await axios.post("multiUpload", data);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    }
    if (test.length > 0) {
      const data = new FormData();
      for (let i = 0; i < test.length; i++) {
        const filename =
          Date.now() +
          updatedApplicant.lastName +
          updatedApplicant.firstName +
          "test" +
          test[i].name;
        data.append("name", filename);
        const img = test[i];
        data.append("file", img, filename);
        updatedApplicant.test.push(filename);
      }
      try {
        await axios.post("multiUpload", data);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    }
    try {
      const { data } = await axios.put(
        `applications/${userId}`,
        updatedApplicant
      );
      if (data.status === "success") {
        message.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (data.status === "notFound") message.error(data.message);
      else if (data.status === "error") message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleChangeInput = (newValue, fieldName) => {
    applicantData[fieldName] = newValue;
    setApplicantData(applicantData);
  };

  const ShowUpdate = () => {
    const handleResetField = async (element) => {
      if (
        window.confirm(
          t(`${translationPage}.Are you sure you want to delete the `) + element
        )
      ) {
        try {
          const { data } = await axios.put(
            `applications/remove/${applicant?._id}/${element}`
          );
          if (data.status === "success") {
            message.success(data.message);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    };

    return (
      <div className="applicantEditContainer">
        <span onClick={() => setShowUpdate(false)}>
          <ArrowBackIosIcon />
          {t(`${translationPage}.Cancel / Go Back`)}
        </span>
        <form>
          <div className="applicantEditContainerLabelInline">
            <label className="applicantEditContainerLabel">
              <input
                onChange={(e) =>
                  fileOnChange(
                    e,
                    "photo",
                    resume,
                    setResume,
                    resumePreview,
                    setResumePreview,
                    photo,
                    setPhoto,
                    photoPreview,
                    setPhotoPreview,
                    test,
                    setTest,
                    testPreview,
                    setTestPreview
                  )
                }
                type="file"
              />
              <span>
                {t(`${translationPage}.Photo`)}
                <AttachFileIcon className="applicantEditAttachIcon" />
              </span>
              {photoPreview && <img src={photoPreview} alt="Photo Preview" />}
              {applicant?.photo && !photoPreview && (
                <img src={PF + applicant?.photo} alt="Applicant Photo" />
              )}
            </label>
            {applicant?.photo && (
              <ClearIcon
                onClick={() => handleResetField("photo")}
                className="clearIconDeleteField"
              />
            )}
          </div>
          <label className="applicantEditContainerLabel">
            <input
              onChange={(e) =>
                fileOnChange(
                  e,
                  "resume",
                  resume,
                  setResume,
                  resumePreview,
                  setResumePreview,
                  photo,
                  setPhoto,
                  photoPreview,
                  setPhotoPreview,
                  test,
                  setTest,
                  testPreview,
                  setTestPreview
                )
              }
              type="file"
            />
            <span>
              {t(`${translationPage}.Resume / CV`)}
              <AttachFileIcon className="applicantEditAttachIcon" />
            </span>
            {(resumePreview || applicant?.resume.length > 0) && (
              <span className="applicantEditPhotoPreview">
                <img
                  className="applicantAddEditPopupTestPreview"
                  src={
                    applicant?.resume.length > 0
                      ? PF + applicant?.resume[0]
                      : resumePreview
                  }
                />
                {(applicant?.resume.length > 1 ||
                  resume.length > 1 ||
                  applicant?.resume.length + resume.length > 1) && (
                  <span className="newApplicationFormImgPreviewLength">
                    {" "}
                    +{applicant?.resume.length + resume.length - 1}
                  </span>
                )}
              </span>
            )}
          </label>
          <div className="applicantEditContainerLabelInline">
            <label className="applicantEditContainerLabel">
              <input
                onChange={(e) =>
                  fileOnChange(
                    e,
                    "test",
                    resume,
                    setResume,
                    resumePreview,
                    setResumePreview,
                    photo,
                    setPhoto,
                    photoPreview,
                    setPhotoPreview,
                    test,
                    setTest,
                    testPreview,
                    setTestPreview
                  )
                }
                type="file"
              />
              <span>
                {t(`${translationPage}.Test`)}
                <AttachFileIcon className="applicantEditAttachIcon" />
              </span>
              {(testPreview || applicant?.test.length > 0) && (
                <span className="applicantEditPhotoPreview">
                  <img
                    className="applicantAddEditPopupTestPreview"
                    src={
                      applicant?.test.length > 0
                        ? PF + applicant?.test[0]
                        : testPreview
                    }
                  />
                  {(applicant?.test.length > 1 ||
                    test.length > 1 ||
                    applicant?.test.length + test.length > 1) && (
                    <span className="newApplicationFormImgPreviewLength">
                      {" "}
                      +{applicant?.test.length + test.length - 1}{" "}
                    </span>
                  )}
                </span>
              )}
            </label>
            {applicant?.test.length > 0 && (
              <ClearIcon
                onClick={() => handleResetField("test")}
                className="clearIconDeleteField"
              />
            )}
          </div>
          <span>
            <p>{t(`${translationPage}.Application Date`)}</p>
            <span>{createdDate}</span>
          </span>
          <span>
            <p>{t(`${translationPage}.First Name`)}</p>
            <input
              onChange={(e) => handleChangeInput(e.target.value, "firstName")}
              placeholder={applicant?.firstName}
              type="text"
            />
          </span>
          <span>
            <p>{t(`${translationPage}.Last Name`)}</p>
            <input
              onChange={(e) => handleChangeInput(e.target.value, "lastName")}
              placeholder={applicant?.lastName}
              type="text"
            />
          </span>
          <span>
            <p>{t(`${translationPage}.Email`)}</p>
            <input
              onChange={(e) => handleChangeInput(e.target.value, "email")}
              placeholder={applicant?.email}
              type="text"
            />
          </span>
          <span>
            <p>{t(`${translationPage}.Phone`)}</p>
            <input
              onChange={(e) => handleChangeInput(e.target.value, "phone")}
              placeholder={applicant?.phone}
              type="text"
            />
          </span>
          <span>
            <p>{t(`${translationPage}.Position`)}</p>
            <Select
              className="applicantMultiSelect"
              required
              options={positions.map((position) => ({
                value: position.value,
                label: t(position.translationKey),
              }))}
              isMulti
              value={positionList}
              onChange={(selected, triggeredAction) => {
                if (triggeredAction.action === "clear") {
                  setPositionList([]);
                  setPosition([]);
                } else if (triggeredAction.action === "remove-value") {
                  const removedValue = triggeredAction.removedValue.value;
                  setPositionList(
                    positionList.filter(
                      (option) => option.value !== removedValue
                    )
                  );
                  setPosition(position.filter((val) => val !== removedValue));
                } else if (triggeredAction.action === "select-option") {
                  setPositionList(selected);
                  const values = selected?.map((option) => option?.value);
                  setPosition(values);
                }
              }}
              closeMenuOnSelect={false}
              placeholder={applicant?.position.join(", ")}
              styles={{
                control: (base, state) => ({
                  ...base,
                  outline: state.isFocused ? "none" : "none",
                  border: state.isFocused ? "none" : "none",
                  boxShadow: state.isFocused ? "none" : "none",
                  height: state.menuIsOpen ? "100%" : "40px",
                  overflow: state.isFocused ? "visible" : "hidden",
                  overflow: state.menuIsOpen ? "visible" : "hidden",
                }),
                indicatorsContainer: (base, state) => ({
                  ...base,
                  position: state.isMulti ? "absolute" : "inherit",
                  top: state.isMulti ? "0" : "inherit",
                  right: state.isMulti ? "0" : "inherit",
                  zIndex: "999",
                  backgroundColor: "white",
                  display: "flex",
                }),
              }}
            />
          </span>
          <span>
            <p>{t(`${translationPage}.Type`)}</p>
            <select onChange={(e) => handleChangeInput(e.target.value, "type")}>
              <option defaultValue hidden value={applicant?.type}>
                {applicant?.type}
              </option>
              {hiringTypes.map((type) => (
                <option value={type.value}>{t(type.translationKey)}</option>
              ))}
            </select>
          </span>

          <span>
            <p>{t(`${translationPage}.Cycle`)}</p>
            <Select
              className="applicantMultiSelect"
              required
              options={cycles.map((cycle) => ({
                value: cycle.value,
                label: t(cycle.translationKey),
              }))}
              isMulti
              value={cycleList}
              onChange={(selected, triggeredAction) => {
                if (triggeredAction.action === "clear") {
                  setCycleList([]);
                  setCycle([]);
                } else if (triggeredAction.action === "remove-value") {
                  const removedValue = triggeredAction.removedValue.value;
                  setCycleList(
                    cycleList.filter((option) => option.value !== removedValue)
                  );
                  setCycle(cycle.filter((val) => val !== removedValue));
                } else if (triggeredAction.action === "select-option") {
                  setCycleList(selected);
                  const values = selected?.map((option) => option?.value);
                  setCycle(values);
                }
              }}
              closeMenuOnSelect={false}
              placeholder={t(`${translationPage}.Cycle(s)`)}
              styles={{
                control: (base, state) => ({
                  ...base,
                  outline: state.isFocused ? "none" : "none",
                  border: state.isFocused ? "none" : "none",
                  boxShadow: state.isFocused ? "none" : "none",
                  height: state.menuIsOpen ? "100%" : "40px",
                  overflow: state.isFocused ? "visible" : "hidden",
                  overflow: state.menuIsOpen ? "visible" : "hidden",
                }),
                indicatorsContainer: (base, state) => ({
                  ...base,
                  position: state.isMulti ? "absolute" : "inherit",
                  top: state.isMulti ? "0" : "inherit",
                  right: state.isMulti ? "0" : "inherit",
                  zIndex: "999",
                  backgroundColor: "white",
                  display: "flex",
                }),
              }}
            />
          </span>
          <span>
            <p>{t(`${translationPage}.Rating`)}</p>
            <select
              onChange={(e) => handleChangeInput(e.target.value, "rating")}
            >
              <option value={applicant?.rating} defaultValue hidden>
                {applicant?.rating}
              </option>
              {applicant?.rating && <option value="0">Reset</option>}
              {ratings.map((rating) => (
                <option value={rating.value}>
                  {rating.name} {rating.value === 1 ? "star" : "stars"}
                </option>
              ))}
            </select>
          </span>
          <span>
            <p>{t(`${translationPage}.Status`)}</p>
            <select
              onChange={(e) => handleChangeInput(e.target.value, "status")}
            >
              <option defaultValue hidden value={applicant?.status}>
                {applicant?.status}
              </option>
              {applicant?.status && <option value="null">Reset</option>}
              {applicationStatus.map((status) => (
                <option value={status.value}>{t(status.translationKey)}</option>
              ))}
            </select>
          </span>
          <span>
            <p>{t(`${translationPage}.Last Contacted`)}</p>
            <input
              onChange={(e) => handleChangeInput(e.target.value, "lastContact")}
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              placeholder={lastContact || "N/A"}
            />
          </span>
          <span>
            <p>{t(`${translationPage}.How They Heard About Us`)}</p>
            <select
              onChange={(e) => handleChangeInput(e.target.value, "appStream")}
            >
              <option value={applicant?.appStream} defaultValue hidden>
                {applicant?.appStream}
              </option>
              {applicationsStreams.map((stream) => (
                <option value={stream.value}>{t(stream.translationKey)}</option>
              ))}
            </select>
          </span>
        </form>
        <span className="applicantEditIcons">
          <CheckIcon onClick={handleUpdate} className="applicantEditIcon" />
          <ClearIcon
            onClick={() => setShowUpdate(false)}
            className="applicantEditIcon"
          />
        </span>
      </div>
    );
  };

  if (showUpdate) {
    return <ShowUpdate />;
  }

  const displayDeletePopup = () => {
    return (
      <div className="deletePopupContainer">
        <DeletePopup
          element={element}
          closeDeletePopup={() => {
            setOpenDeletePopup(false);
          }}
          applicant={applicant}
          userRole={user?.role}
        />
      </div>
    );
  };

  const handleMarkAsUnseen = async () => {
    try {
      await axios.put(`applications/unsee/${userId}`);
      window.location.replace("/hiring");
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  return (
    <>
      <Link className="links" to="/hiring">
        <span style={{ margin: "20px 10px", cursor: "pointer" }}>
          <ArrowBackIosIcon />
          {t(`${translationPage}.Go Back`)}
        </span>
      </Link>
      {user?.role === "hr" || user?.role === "admin" ? (
        <>
          <div className="applicantContainer">
            <img
              className="applicantPhoto"
              src={
                applicant?.photo === "" || applicant?.photo === null
                  ? ApplicantPhoto
                  : PF + applicant?.photo
              }
              alt="Applicant Photo"
            />
            <table>
              <tr>
                <th
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "20px",
                  }}
                >
                  <span>{t(`${translationPage}.Application Date`)}</span>
                  <span style={{ fontSize: "15px" }}>(DD/MM/YYYY)</span>
                </th>
                <td>{createdDate}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Full Name`)}</th>
                <td>{`${applicant?.firstName} ${applicant?.lastName}`}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Position`)}</th>
                <td>{applicant?.position.join(", ")}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Type`)}</th>
                <td>{applicant?.type}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Cycle`)}</th>
                <td>{applicant?.cycle.join(", ")}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Email`)}</th>
                <td>{applicant?.email}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Phone`)}</th>
                <td>{applicant?.phone}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Status`)}</th>
                <td>{applicant?.status}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Rating`)}</th>
                <td>
                  <Rating applicant={applicant} />
                </td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Notes`)}</th>
                <td>
                  {applicant?.notes?.length > 0 ? (
                    <VisibilityIcon
                      className="applicantNotesVisibilityIcon"
                      onClick={() => {
                        setShowNotes(true);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.Last Contacted`)}</th>
                <td>{lastContact}</td>
              </tr>
              <tr>
                <th>{t(`${translationPage}.How They Heard About Us`)}</th>
                <td>{applicant?.appStream}</td>
              </tr>
            </table>
            <span
              onClick={() => setShowResume(true)}
              className="applicantResumeSpan"
            >
              <AttachFileIcon className="applicantAttachIcon" />
              <p>{t(`${translationPage}.Resume / CV`)}</p>
            </span>
            {applicant?.test.length > 0 && (
              <span
                onClick={() => setShowTest(true)}
                className="applicantTestSpan"
              >
                <AttachFileIcon className="applicantAttachIcon" />
                <p>{t(`${translationPage}.Test`)}</p>
              </span>
            )}
            {applicant?.manualCVFlash && (
              <span
                onClick={() => setShowCVFlash(true)}
                className="applicantTestSpan"
              >
                <p>CV Flash</p>
              </span>
            )}
            <span
              onClick={() => setShowLogs(true)}
              className="applicantLogsSpan"
            >
              {t(`${translationPage}.Events Log / Historique`)}
            </span>
            <span className="applicantIcons">
              <CreateIcon
                onClick={() => setShowUpdate(true)}
                className="applicantIcon"
              />
              <DeleteIcon
                onClick={() => setOpenDeletePopup(true)}
                className="applicantIcon"
              />
              <VisibilityOffIcon
                onClick={() => handleMarkAsUnseen()}
                className="applicantIcon"
              />
            </span>
          </div>
          {openDeletePopup && displayDeletePopup()}
        </>
      ) : (
        <AccessDenied fontColor="black" />
      )}
    </>
  );
};

export default Applicant;
