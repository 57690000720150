import "./manageHomePopup.scss";
import { message } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { PopupState } from "../../context/Popup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useFetch from "../../hooks/useFetch";
import AccessDenied from "../../components/accessDenied/AccessDenied";
import { AuthContext } from "../../context/AuthContext";
import Popup from "../../components/popup/Popup";
import { somethingWentWrong } from "../../utils/constants";
import { constants } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const ManageHomePopup = () => {
  const {
    popupActive,
    popupTitle,
    setPopupTitle,
    popupBody,
    setPopupBody,
    popupImage,
    setPopupImage,
    popupBtn,
    setPopupBtn,
    popupIsCountdown,
    popupLink,
    setPopupLink,
  } = PopupState();
  const { user } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const body = useRef();
  const btn = useRef();
  const link = useRef();
  const [popupState, setPopupState] = useState(false);
  const upcomingEvents = useFetch(`calendarEvents/upcoming`).data.events;
  const [useCountdown, setUseCountdown] = useState(popupIsCountdown);
  const [event, setEvent] = useState({});
  const [preview, setPreview] = useState(false);
  const { t } = useTranslation();
  const translationPage = "pages.manageHomePopup";
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}images/homePopups/`;

  useEffect(() => {
    setPopupState(popupActive);
  }, [popupActive]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (imageFile) {
      const imageFormData = new FormData();
      imageFormData.append("file", imageFile);
      try {
        const { data: imageData } = await axios.post(
          "homePopups/upload",
          imageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageData.status !== "201") {
          message.error("Image upload failed");
          return;
        }
      } catch (err) {
        message.error("Image upload failed");
        return;
      }
    }
    const popup = {
      active: popupState || popupActive,
      title: title || popupTitle,
      btn: btn.current.value || popupBtn,
      link: link.current.value || popupLink,
      image: imageName || popupImage,
    };
    if (useCountdown) {
      popup.body = event;
      popup.countdown = true;
    } else {
      popup.body = body.current.value;
      popup.countdown = false;
    }
    try {
      //if you only want to change the state of the popup
      const bodyIsEmpty = useCountdown
        ? Object.keys(popup.body).length === 0
        : popup.body.trim().length === 0;
      const onlyChangeState =
        popupState !== popupActive &&
        !popup.btn.trim().length &&
        !popup.link.trim().length &&
        !popup.title.trim().length &&
        bodyIsEmpty;
      const { data } = onlyChangeState
        ? await axios.post("homePopup", { active: popupState })
        : await axios.post("homePopup", popup);
      if (data.status === "success") {
        if (onlyChangeState) {
          setPopupState(popupState);
        } else {
          setPopupTitle(popup.title);
          setPopupBody(popup.body);
          setPopupImage(popup.image);
          setPopupBtn(popup.btn);
          setPopupLink(popup.link);
        }
        message.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const handleImageDelete = async () => {
    setImage(null);
    setImageFile(null);
    setImageName("");
  };

  const handlePopupImageDelete = async () => {
    try {
      const { data } = await axios.delete("homePopups/delete");
      if (data.status === "200") {
        setImage(null);
        setImageFile(null);
        setImageName("");
        setPopupImage(null);
        message.success(data.message);
      } else {
        message.error(somethingWentWrong);
      }
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  const resetValues = () => {
    setTitle("");
    setImage(null);
    setImageFile(null);
    setImageName("");
    body?.current && (body.current.value = "");
    btn?.current && (btn.current.value = "");
    link?.current && (link.current.value = "");
  };

  return (
    <div className="manageHomePopupContainer">
      <Link className="links goBackSpanBtn" to="/user">
        <ArrowBackIosIcon />
        {t(`${translationPage}.Go Back`)}
      </Link>
      {user?.role === "it" || user?.role === "admin" ? (
        <>
          {preview && (
            <Popup
              state={{
                popupTitle: title || popupTitle,
                popupBody: useCountdown
                  ? event
                  : body.current?.value || popupBody,
                popupBtn: btn.current?.value || popupBtn,
                popupLink: link.current?.value || popupLink,
                setPopupOpen: setPreview,
                popupIsCountdown: useCountdown || popupIsCountdown,
                isPreview: true,
                popupImage: image || popupImage,
              }}
            />
          )}
          <h1>
            {t(`${translationPage}.The popup is currently : `)}
            {popupActive
              ? t(`${translationPage}.ACTIVE`)
              : t(`${translationPage}.INACTIVE`)}
          </h1>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color={useCountdown ? "secondary" : "warning"}
                  defaultChecked={popupIsCountdown}
                  onChange={() => {
                    resetValues();
                    setUseCountdown(!useCountdown);
                  }}
                />
              }
              className="manageHomePopupSwitch"
              label={
                useCountdown
                  ? t(`${translationPage}.Use Regular Popup`)
                  : t(`${translationPage}.Use Event Countdown`)
              }
            />
          </FormGroup>
          <form>
            <span>
              {useCountdown ? (
                <>
                  {upcomingEvents?.length ? (
                    <div className="manageHomePopupWrapper">
                      <span className="manageHomePopupWrapperSpan">
                        <label>
                          {t(`${translationPage}.Event for Countdown:`)}
                        </label>
                        <select
                          onChange={(e) => {
                            setEvent(JSON.parse(e.target.value).start);
                            setTitle(JSON.parse(e.target.value).title);
                          }}
                        >
                          <option value="" selected hidden>
                            {popupIsCountdown && popupTitle}
                          </option>
                          {upcomingEvents?.map((event, index) => (
                            <option value={JSON.stringify(event)}>
                              {event.title}
                            </option>
                          ))}
                        </select>
                      </span>
                      <span className="manageHomePopupWrapperSpan">
                        <label>{t(`${translationPage}.Button Text`)}</label>
                        <input
                          maxLength={20}
                          placeholder={popupIsCountdown ? popupBtn : ""}
                          type="text"
                          ref={btn}
                        />
                      </span>
                      <span className="manageHomePopupWrapperSpan">
                        <label>{t(`${translationPage}.Button Link`)}</label>
                        <input
                          placeholder={popupIsCountdown ? popupLink : ""}
                          type="text"
                          ref={link}
                        />
                      </span>
                      <div className="manageHomePopupImageSection">
                        <div className="manageHomePopupImageWrapper">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              setImageFile(e.target.files[0]);
                            }}
                          />
                          <label className="uploadImageLabel">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  setImageFile(file);
                                  setImage(URL.createObjectURL(file));
                                  setImageName(file?.name);
                                }
                              }}
                            />
                            {t(`${translationPage}.Upload Image`)}
                            <AttachFileIcon className="uploadImageIcon" />
                          </label>
                          {image ? (
                            <div
                              className="deleteImageInput"
                              onClick={() => handleImageDelete()}
                            >
                              {t(`${translationPage}.Delete Image`)}
                            </div>
                          ) : popupImage ? (
                            <div
                              className="deleteImageInput"
                              onClick={() => handlePopupImageDelete()}
                            >
                              {t(`${translationPage}.Delete Image`)}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {image ? (
                          <div className="popupImagePreviewDiv">
                            <img
                              className="popupImagePreview"
                              src={image}
                              alt="Popup preview"
                            />
                          </div>
                        ) : popupImage ? (
                          <div className="popupImagePreviewDiv">
                            <img
                              className="popupImagePreview"
                              src={PF + popupImage}
                              alt="Popup Img"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <span className="manageHomePopupWrapperSpan">
                        <label>
                          {t(`${translationPage}.Change Popup State`)}
                        </label>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                color={popupState ? "secondary" : "warning"}
                                defaultChecked={popupState}
                                onChange={() => {
                                  setPopupState(!popupState);
                                }}
                              />
                            }
                            className="manageHomePopupSwitch"
                            label={
                              popupState
                                ? t(`${translationPage}.Deactivate`)
                                : t(`${translationPage}.Activate`)
                            }
                          />
                        </FormGroup>
                      </span>
                      <div className="manageHomePopupBtns">
                        <span
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <button
                            className="manageHomePopupPreviewBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              setPreview(true);
                            }}
                          >
                            {t(`${translationPage}.Preview`)}
                          </button>
                        </span>
                        <span
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <button
                            className="manageHomePopupSubmitBtn"
                            onClick={handleSubmit}
                          >
                            {t(`${translationPage}.Submit`)}
                          </button>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="manageHomePopupNoUpcomingEvents">
                      <p>
                        {t(`${translationPage}.There are no upcoming events`)}
                      </p>
                      <p>
                        {t(`${translationPage}.Make sure to add them in the`)}{" "}
                        <span
                          className="manageHomePopupNoUpcomingEventsLink"
                          onClick={() => window.location.replace("/calendar")}
                        >
                          {t(`${translationPage}.calendar`)}
                        </span>
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <div className="manageHomePopupWrapper">
                  <span className="manageHomePopupWrapperSpan">
                    <label>{t(`${translationPage}.Title`)}</label>
                    <input
                      maxLength={30}
                      placeholder={popupIsCountdown ? "" : popupTitle}
                      type="text"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </span>
                  <span className="manageHomePopupWrapperSpan">
                    <label>{t(`${translationPage}.Body`)}</label>
                    <input
                      maxLength={200}
                      placeholder={popupIsCountdown ? "" : popupBody}
                      type="text"
                      ref={body}
                    />
                  </span>
                  <span className="manageHomePopupWrapperSpan">
                    <label>{t(`${translationPage}.Button Text`)}</label>
                    <input
                      maxLength={20}
                      placeholder={popupIsCountdown ? "" : popupBtn}
                      type="text"
                      ref={btn}
                    />
                  </span>
                  <span className="manageHomePopupWrapperSpan">
                    <label>{t(`${translationPage}.Button Link`)}</label>
                    <input
                      placeholder={popupIsCountdown ? "" : popupLink}
                      type="text"
                      ref={link}
                    />
                  </span>
                  <div className="manageHomePopupImageSection">
                    <div className="manageHomePopupImageWrapper">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          setImageFile(e.target.files[0]);
                        }}
                      />
                      <label className="uploadImageLabel">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setImageFile(file);
                              setImage(URL.createObjectURL(file));
                              setImageName(file?.name);
                            }
                          }}
                        />
                        {t(`${translationPage}.Upload Image`)}
                        <AttachFileIcon className="uploadImageIcon" />
                      </label>
                      {image ? (
                        <div
                          className="deleteImageInput"
                          onClick={() => handleImageDelete()}
                        >
                          {t(`${translationPage}.Delete Image`)}
                        </div>
                      ) : popupImage ? (
                        <div
                          className="deleteImageInput"
                          onClick={() => handlePopupImageDelete()}
                        >
                          {t(`${translationPage}.Delete Image`)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {image ? (
                      <div className="popupImagePreviewDiv">
                        <img
                          className="popupImagePreview"
                          src={image}
                          alt="Popup preview"
                        />
                      </div>
                    ) : popupImage ? (
                      <div className="popupImagePreviewDiv">
                        <img
                          className="popupImagePreview"
                          src={PF + popupImage}
                          alt="Popup Img"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <span className="manageHomePopupWrapperSpan">
                    <label>{t(`${translationPage}.Change Popup State`)}</label>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            color={popupState ? "secondary" : "warning"}
                            defaultChecked={popupState}
                            onChange={() => {
                              setPopupState(!popupState);
                            }}
                          />
                        }
                        className="manageHomePopupSwitch"
                        label={
                          popupState
                            ? t(`${translationPage}.Deactivate`)
                            : t(`${translationPage}.Activate`)
                        }
                      />
                    </FormGroup>
                  </span>
                  <div className="manageHomePopupBtns">
                    <span
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <button
                        className="manageHomePopupPreviewBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          setPreview(true);
                        }}
                      >
                        {t(`${translationPage}.Preview`)}
                      </button>
                    </span>
                    <span
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <button
                        className="manageHomePopupSubmitBtn"
                        onClick={handleSubmit}
                      >
                        {t(`${translationPage}.Submit`)}
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </span>
          </form>
        </>
      ) : (
        <AccessDenied fontColor="white" />
      )}
    </div>
  );
};

export default ManageHomePopup;
