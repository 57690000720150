import "./users.scss";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePopup from "../../components/deletePopup/DeletePopup";
import EditPopup from "../../components/editPopup/EditPopup";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { constants, roles, somethingWentWrong } from "../../utils/constants";
import ApplicantPhoto from "../../images/Applicant.jpeg";
import { useRef, useEffect, useState, useContext } from "react";
import { message } from "antd";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import { fileOnChangeUsers } from "../../hooks/fileOnChange";
import AccessDenied from "../../components/accessDenied/AccessDenied";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const element = "user";
  const { user } = useContext(AuthContext);
  const userRole = user?.role;
  const [selectedUser, setSelectedUser] = useState();
  const currentUser = useContext(AuthContext).user;
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}images/`;
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const translationPage = "pages.users";

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingUsers(true);
      try {
        const { data } = await axios.get(`users`);
        if (data.status === "success") setUsers(data.users);
      } catch (err) {
        message.error(somethingWentWrong);
      }
      setLoadingUsers(false);
    };
    fetchUsers();
  }, [user?.role, refresh]);

  const displayDeletePopup = () => {
    return (
      <div className="deletePopupContainer">
        <DeletePopup
          element={element}
          closeDeletePopup={() => {
            setOpenDeletePopup(false);
          }}
          user={selectedUser}
          userRole={userRole}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    );
  };

  const displayEditPopup = () => {
    return (
      <div className="editPopupContainer">
        <EditPopup
          element={element}
          closeEditPopup={() => {
            setOpenEditPopup(false);
          }}
          user={selectedUser}
          userRole={userRole}
        />
      </div>
    );
  };

  const AddPopup = () => {
    const username = useRef();
    const password = useRef();
    const role = useRef();
    const [photo, setPhoto] = useState("");
    const [photoPreview, setPhotoPreview] = useState("");

    const handleAddUser = async (e) => {
      e.preventDefault();
      const newUser = {
        username: username.current.value.toLowerCase(),
        password: password.current.value,
        role: role.current.value.toLowerCase(),
      };
      newUser.userRole = userRole;
      if (photo) {
        const data = new FormData();
        const img = photo;
        const filename = newUser.username + "photo" + img.name;
        data.append("name", filename);
        data.append("file", img, filename);
        newUser.photo = filename;
        try {
          await axios.post("upload", data);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      try {
        const { data } = await axios.post(`auth/register`, newUser);
        if (data.status === "success") {
          setRefresh(!refresh);
          message.success(data.message);
          setShowAddPopup(false);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };

    return (
      <div className="usersAddPopupContainer">
        <div className="usersAddPopupWrapper">
          <span
            onClick={() => setShowAddPopup(false)}
            className="usersAddPopupCloseBtn"
          >
            &times;{" "}
          </span>
          <form className="usersAddPopupForm" onSubmit={handleAddUser}>
            <label id="usersAddPopupPhoto">
              <span>
                {t(`${translationPage}.Photo`)}{" "}
                <AttachFileIcon className="usersAddPopupPhotoAttachIcon" />
                <input
                  type="file"
                  onChange={(e) =>
                    fileOnChangeUsers(e, setPhoto, setPhotoPreview)
                  }
                />
              </span>
              {photo && (
                <img className="usersAddPopupPhotoPreview" src={photoPreview} />
              )}
            </label>

            <label>
              <span>
                {t(`${translationPage}.Username`)} &nbsp;{" "}
                <span style={{ color: "#ef711d" }}>*</span>
              </span>
              <input required ref={username} type="text" />
            </label>
            <label>
              <span>
                {t(`${translationPage}.Password`)} &nbsp;{" "}
                <span style={{ color: "#ef711d" }}>*</span>
              </span>
              <input required ref={password} type="text" />
            </label>
            <label>
              <span>
                {t(`${translationPage}.Role`)} &nbsp;{" "}
                <span style={{ color: "#ef711d" }}>*</span>
              </span>
              <select required ref={role}>
                <option value=""></option>
                {roles.map((role) => (
                  <option value={role.value}>{t(role.translationKey)}</option>
                ))}
              </select>
            </label>
            <button className="usersAddPopupBtn" type="submit">
              {t(`${translationPage}.Submit`)}
            </button>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="usersContainer">
      <Link className="links" to="/user">
        <ArrowBackIosIcon />
        {t(`${translationPage}.Go Back`)}
      </Link>
      {user?.role === "admin" ? (
        <>
          {loadingUsers ? (
            <span className="agendaLoaderSpan">
              <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
            </span>
          ) : (
            <table className="usersTable">
              <tr>
                <th>{t(`${translationPage}.Photo`)}</th>
                <th>{t(`${translationPage}.Username`)}</th>
                <th>{t(`${translationPage}.Role`)}</th>
                <th>{t(`${translationPage}.Action`)}</th>
              </tr>
              <tr className="usersTableAdmin">
                <td>
                  <img
                    className="usersPhoto"
                    src={user?.photo ? PF + user.photo : ApplicantPhoto}
                    alt="User Photo"
                  />
                </td>
                <td>{user.username}</td>
                <td className="userSingleRole">{user.role}</td>
                <td className="usersIcons">
                  <CreateIcon
                    onClick={() => {
                      setSelectedUser(user);
                      setOpenEditPopup(true);
                    }}
                    className="usersIcon"
                  />
                </td>
              </tr>
              {users?.length > 1 &&
                users
                  ?.filter((user) => user._id !== currentUser._id)
                  .map((user) => (
                    <tr key={user?._id}>
                      <td>
                        <img
                          className="usersPhoto"
                          src={user?.photo ? PF + user.photo : ApplicantPhoto}
                          alt="User Photo"
                        />
                      </td>
                      <td>{user.username}</td>
                      <td className="userSingleRole">{user.role}</td>
                      <td className="usersIcons">
                        <CreateIcon
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenEditPopup(true);
                          }}
                          className="usersIcon"
                        />
                        <DeleteIcon
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenDeletePopup(true);
                          }}
                          className="usersIcon"
                        />
                      </td>
                    </tr>
                  ))}
            </table>
          )}
          {users?.length <= 1 && (
            <p className="usersNoOtherUsersText">
              {t(`${translationPage}.noOtherUsers`)}
            </p>
          )}
          <button onClick={() => setShowAddPopup(true)}>
            {t(`${translationPage}.Add`)}
          </button>
          {openDeletePopup && displayDeletePopup()}
          {openEditPopup && displayEditPopup()}
          {showAddPopup && <AddPopup />}
        </>
      ) : (
        <AccessDenied fontColor="black" />
      )}
    </div>
  );
};

export default Users;
