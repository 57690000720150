import { useLocation } from "react-router-dom";
import "./applicantLogs.scss";
import { AuthContext } from "../../context/AuthContext";
import { useEffect, useState, useRef, useContext } from "react";
import { message } from "antd";
import axios from "axios";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditLog from "../addEditLog/AddEditLog";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import {
  meetingTimes,
  meetingTypes,
  meetingOutcomes,
} from "../../utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowNotes from "../../components/showNotes/ShowNotes";
import ClipLoader from "react-spinners/ClipLoader";
import { somethingWentWrong } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const ApplicantLogs = () => {
  const { user } = useContext(AuthContext);
  const userId = useLocation().pathname.split("/")[2];
  const [logs, setLogs] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [applicant, setApplicant] = useState("");
  const [value, setValue] = useState("1");
  const element = "applicantLog";
  const today = new Date();
  const [showNotes, setShowNotes] = useState(false);
  const { t } = useTranslation();
  const translationComponent = "applicantLogs";

  useEffect(() => {
    const fetchApplicant = async () => {
      try {
        const { data } = await axios.get(
          `applications/${user?.role}/${userId}`
        );
        if (data.status === "success") {
          setApplicant(data.application);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };
    fetchApplicant();
  }, [userId]);

  const getLogs = async () => {
    setLoadingLogs(true);
    try {
      const { data } = await axios.get(`logs/applicant/${userId}`);
      if (data.status === "success") setLogs(data.applicantLogs);
    } catch (err) {
      message.error(somethingWentWrong);
    }
    setLoadingLogs(false);
  };

  const getMeetings = async () => {
    setLoadingMeetings(true);
    try {
      const { data } = await axios.get(`meetings/user/${userId}`);
      if (data.status === "success") setMeetings(data.meetings);
      else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
    setLoadingMeetings(false);
  };

  useEffect(() => {
    getLogs();
    getMeetings();
  }, [userId, refresh]);

  const CallLogs = () => {
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [selectedLog, setSelectedLog] = useState({});
    const [openAddPopup, setOpenAddPopup] = useState(false);

    const handleDeleteLog = async (id) => {
      if (window.confirm(t(`${translationComponent}.confirmDeleteCallLog`))) {
        try {
          const { data } = await axios.delete(`logs/${id}`);
          if (data.status === "success") {
            message.success(data.message);
            getLogs();
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    };

    return (
      <>
        <button
          className="applicantLogsButton"
          onClick={() => {
            setOpenAddPopup(true);
          }}
        >
          {t(`${translationComponent}.Add Log`)}
        </button>
        {loadingLogs ? (
          <span className="agendaLoaderSpan">
            <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
          </span>
        ) : logs.length > 0 ? (
          <table className="applicantLogsTable">
            <tr className="applicantLogsTableTr">
              <th>{t(`${translationComponent}.Date of Call`)}</th>
              <th>{t(`${translationComponent}.Caller`)}</th>
              <th>{t(`${translationComponent}.Reason`)}</th>
              <th>{t(`${translationComponent}.Answered`)}</th>
              <th>{t(`${translationComponent}.meetingTypeIfAny`)}</th>
              <th>{t(`${translationComponent}.meetingTimeIfAny`)}</th>
              <th>{t(`${translationComponent}.Outcome`)}</th>
              <th>{t(`${translationComponent}.Notes`)}</th>
              <th>{t(`${translationComponent}.Edit / Delete`)}</th>
            </tr>
            {logs?.map((log) => (
              <>
                <tr className="applicantLogsTableTr">
                  <td>{log.date?.slice(0, 10)}</td>
                  <td>{log.caller}</td>
                  <td>{log.reason}</td>
                  <td>{log.answered ? "Yes" : "No"}</td>
                  <td>{log.meetingType}</td>
                  <td>
                    {log.meetingTime && (
                      <>
                        {log.meetingTime?.slice(0, 10) +
                          " à " +
                          log.meetingTime?.slice(11, 13) +
                          ":" +
                          log.meetingTime?.slice(14, 16)}
                      </>
                    )}
                  </td>
                  <td>{log.outcome}</td>
                  <td>
                    <VisibilityIcon
                      className="applicantNotesVisibilityIcon"
                      onClick={() => {
                        setShowNotes(true);
                      }}
                    />
                  </td>
                  <td className="applicantLogsIcons">
                    <CreateIcon
                      onClick={() => {
                        setOpenEditPopup(true);
                        setSelectedLog(log);
                      }}
                      className="applicantLogsIcon"
                    />
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteLog(log._id);
                      }}
                      className="applicantLogsIcon"
                    />
                  </td>
                </tr>
              </>
            ))}
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>
            {t(`${translationComponent}.No Calls Found`)}
          </p>
        )}
        {openEditPopup && (
          <AddEditLog
            log={selectedLog}
            type="edit"
            setOpenEditPopup={setOpenEditPopup}
            setLogs={setLogs}
            element={element}
            refresh
            setRefresh={setRefresh}
            applicant={applicant}
          />
        )}
        {openAddPopup && (
          <AddEditLog
            type="add"
            setOpenAddPopup={setOpenAddPopup}
            setLogs={setLogs}
            element={element}
            refresh
            setRefresh={setRefresh}
            applicant={applicant}
          />
        )}
      </>
    );
  };

  const MeetingLogs = () => {
    const [selectedMeeting, setSelectedMeeting] = useState({});
    const [openAddMeetingPopup, setOpenAddMeetingPopup] = useState(false);
    const [openEditMeetingPopup, setOpenEditMeetingPopup] = useState(false);

    const handleDeleteMeeting = async (id) => {
      if (window.confirm(t(`${translationComponent}.confirmDeleteMeeting`))) {
        try {
          const { data } = await axios.delete(`meetings/${id}`);
          if (data.status === "success") {
            message.success(data.message);
            getMeetings();
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    };

    const AddEditMeeting = ({ action }) => {
      const date = useRef();
      const time = useRef();
      const type = useRef();
      const came = useRef();
      const outcome = useRef();
      const notes = useRef();

      const handleEditMeeting = async () => {
        let d;
        if (date.current.value) {
          d = new Date(date.current.value);
          if (!time.current.value) {
            d.setHours(selectedMeeting.start.slice(11, 13));
            d.setMinutes(selectedMeeting.start.slice(14, 16));
          } else {
            d.setHours(time.current.value.slice(0, 2));
            d.setMinutes(time.current.value.slice(3));
          }
        } else {
          if (time.current.value) {
            d = new Date(selectedMeeting.start);
            d.setHours(time.current.value.slice(0, 2));
            d.setMinutes(time.current.value.slice(3));
          } else {
            d = selectedMeeting.start;
          }
        }
        if (d < new Date()) {
          const confirmed = window.confirm(
            t(`${translationComponent}.settingMeetingPastDate`)
          );
          if (!confirmed) {
            setOpenEditMeetingPopup(false);
            return;
          }
        }
        try {
          const updatedMeeting = {
            start: d,
            type: type.current.value || selectedMeeting.type,
            came: came.current.value || selectedMeeting.came || null,
            outcome: outcome.current.value || selectedMeeting.outcome || null,
            notes: notes.current.value || null,
          };
          const { data } = await axios.put(
            `meetings/${selectedMeeting._id}`,
            updatedMeeting
          );
          if (data.status === "success") {
            if (notes.current.value) await axios.put(`notes/`);
            message.success(data.message);
            setOpenEditMeetingPopup(false);
            getMeetings();
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      };

      const handleAddMeeting = async () => {
        if (!date.current.value)
          message.error(t(`${translationComponent}.selectDate`));
        if (!time.current.value)
          message.error(t(`${translationComponent}.selectTimeSlot`));
        if (!type.current.value)
          message.error(t(`${translationComponent}.selectMeetingType`));
        const d = new Date(date.current.value);
        d.setHours(time.current.value?.slice(0, 2));
        d.setMinutes(time.current.value?.slice(3));
        if (d < new Date()) {
          const confirmed = window.confirm(
            t(`${translationComponent}.settingMeetingPastDate`)
          );
          if (!confirmed) {
            setOpenAddMeetingPopup(false);
            return;
          }
        }
        try {
          const newMeeting = {
            candidateFName: applicant?.firstName,
            candidateLName: applicant?.lastName,
            applicantId: applicant?._id,
            start: d,
            type: type.current.value,
            came: came.current.value,
            outcome: outcome.current.value,
            notes: notes.current.value,
          };
          const { data } = await axios.post(`meetings`, newMeeting);
          if (data.status === "success") {
            message.success(data.message);
            setTimeout(() => {
              setOpenAddMeetingPopup(false);
            }, 1000);
            getMeetings(); //why is this not working
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      };

      return (
        <div className="applicantLogsEditMeetingPopupContainer">
          <div className="applicantLogsEditMeetingPopupWrapper">
            <form className="applicantLogsEditMeetingPopupForm">
              <label>
                <span>
                  <p>{t(`${translationComponent}.Meeting Date`)}</p>
                  {action === "add" && (
                    <span style={{ color: "#ef711d" }}>&nbsp; *</span>
                  )}
                </span>
                {action === "edit" ? (
                  <input
                    ref={date}
                    type="text"
                    placeholder={selectedMeeting.start?.slice(0, 10)}
                    onFocus={(e) => (e.target.type = "date")}
                  />
                ) : (
                  <input required ref={date} type="date" />
                )}
              </label>
              <label>
                <span>
                  <p>{t(`${translationComponent}.Meeting Time`)}</p>
                  {action === "add" && (
                    <span style={{ color: "#ef711d" }}>&nbsp; *</span>
                  )}
                </span>
                <select required={action === "add"} ref={time}>
                  <option value="" selected hidden>
                    {action === "edit" && selectedMeeting.start?.slice(11, 16)}
                  </option>
                  {meetingTimes.map((time) => (
                    <option value={time.value}>{time.value}</option>
                  ))}
                </select>
              </label>
              <label>
                <span>
                  <p>{t(`${translationComponent}.Meeting Type`)}</p>
                  {action === "add" && (
                    <span style={{ color: "#ef711d" }}>&nbsp; *</span>
                  )}
                </span>
                <select required={action === "add"} ref={type}>
                  <option value="" selected hidden>
                    {action === "edit" ? selectedMeeting?.type : ""}
                  </option>
                  {meetingTypes?.map((type) => (
                    <option value={type.value}>{t(type.translationKey)}</option>
                  ))}
                </select>
              </label>
              <label>
                <span>
                  <p>{t(`${translationComponent}.Came ?`)}</p>
                </span>
                <select ref={came}>
                  <option value="" selected hidden>
                    {action === "edit"
                      ? selectedMeeting?.came === true
                        ? "Yes"
                        : selectedMeeting?.came === false
                        ? "No"
                        : ""
                      : ""}
                  </option>
                  <option value={true}>
                    {t(`${translationComponent}.Yes`)}
                  </option>
                  <option value={false}>
                    {t(`${translationComponent}.No`)}
                  </option>
                </select>
              </label>
              <label>
                <span>
                  <p>{t(`${translationComponent}.Outcome`)}</p>
                </span>
                <select ref={outcome}>
                  <option value="" selected hidden></option>
                  {meetingOutcomes.map((outcome) => (
                    <option value={outcome.value}>
                      {t(outcome.translationKey)}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                <span>
                  <p>{t(`${translationComponent}.Notes`)}</p>
                </span>
                <input ref={notes} type="text" />
              </label>
            </form>
            <span className="applicantLogsEditMeetingPopBtns">
              <button
                onClick={() =>
                  action === "edit" ? handleEditMeeting() : handleAddMeeting()
                }
              >
                {action === "edit" ? "Update" : "Add"}
              </button>
              <button
                onClick={() =>
                  action === "edit"
                    ? setOpenEditMeetingPopup(false)
                    : setOpenAddMeetingPopup(false)
                }
              >
                {t(`${translationComponent}.Cancel`)}
              </button>
            </span>
          </div>
        </div>
      );
    };

    return (
      <>
        <button
          className="applicantLogsButton"
          onClick={() => {
            setSelectedMeeting({});
            setOpenAddMeetingPopup(true);
          }}
        >
          {t(`${translationComponent}.Add Meeting`)}
        </button>
        {loadingMeetings ? (
          <span className="agendaLoaderSpan">
            <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
          </span>
        ) : meetings.length > 0 ? (
          <table className="applicantLogsTable">
            <tr className="applicantLogsTableTr">
              <th>{t(`${translationComponent}.Meeting Date`)}</th>
              <th>{t(`${translationComponent}.Meeting Time`)}</th>
              <th>{t(`${translationComponent}.Meeting Type`)}</th>
              <th>{t(`${translationComponent}.Past ?`)}</th>
              <th>{t(`${translationComponent}.Came ?`)}</th>
              <th>{t(`${translationComponent}.Outcome`)}</th>
              <th>{t(`${translationComponent}.Notes`)}</th>
              <th>{t(`${translationComponent}.Edit / Delete`)}</th>
            </tr>
            {meetings?.map((meeting) => (
              <>
                <tr
                  className={
                    today > new Date(meeting?.start)
                      ? "applicantLogsTableTr applicantLogsTableTrPassedDate"
                      : "applicantLogsTableTr"
                  }
                >
                  <td>{meeting.start?.slice(0, 10)}</td>
                  <td>{meeting.start?.slice(11, 16)}</td>
                  <td>{meeting.type}</td>
                  <td>
                    {today > new Date(meeting?.start)
                      ? t(`${translationComponent}.Yes`)
                      : t(`${translationComponent}.No`)}
                  </td>
                  <td>
                    {meeting.came === true
                      ? t(`${translationComponent}.Yes`)
                      : meeting.came === false
                      ? t(`${translationComponent}.No`)
                      : ""}
                  </td>
                  <td>{meeting.outcome}</td>
                  <td>
                    <VisibilityIcon
                      className="applicantNotesVisibilityIcon"
                      onClick={() => {
                        setShowNotes(true);
                      }}
                    />{" "}
                  </td>
                  <td className="applicantLogsIcons">
                    <CreateIcon
                      onClick={() => {
                        setOpenEditMeetingPopup(true);
                        setSelectedMeeting(meeting);
                      }}
                      className="applicantLogsIcon"
                    />
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteMeeting(meeting._id);
                      }}
                      className="applicantLogsIcon"
                    />
                  </td>
                </tr>
              </>
            ))}
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>
            {t(`${translationComponent}.No Meetings Found`)}
          </p>
        )}

        {openEditMeetingPopup && <AddEditMeeting action="edit" />}
        {openAddMeetingPopup && <AddEditMeeting action="add" />}
      </>
    );
  };

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };

  if (showNotes) {
    return <ShowNotes setShowNotes={setShowNotes} applicant={applicant} />;
  }

  return (
    <>
      <h2 style={{ margin: "auto" }}>
        {applicant?.lastName} {applicant?.firstName}
      </h2>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext className="applicantLogsTabContext" value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "fit-content",
              margin: "auto",
            }}
          >
            <TabList
              className="applicantLogsTabList"
              onChange={handleTabChange}
            >
              <Tab className="applicantLogsTab" label="Calls" value="1" />
              <Tab className="applicantLogsTab" label="Meetings" value="2" />
            </TabList>
          </Box>
          <TabPanel className="applicantLogscallsWrapper" value="1">
            <CallLogs />
          </TabPanel>
          <TabPanel className="applicantLogsMeetingsWrapper" value="2">
            <MeetingLogs />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default ApplicantLogs;
