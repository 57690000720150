import "./wallOfFame.scss";
import Trophy from "../../images/Trophy.png";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useTranslation } from "react-i18next";

const WallOfFame = () => {
  const { t } = useTranslation();
  const translationPage = "pages.wallOfFame";

  return (
    <div className="wallOfFameContainer">
      <span className="wallOfFameTextWrapper">
        <p>WALL</p>
        <span>
          <span className="wallOfFameGoldenStar">
            <StarRateIcon className="wallOfFameStarIcon">OF</StarRateIcon>
            <p>OF</p>
          </span>
        </span>
        <p>FAME</p>
      </span>

      <div className="wallOfFameWrapper">
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Reader of the month`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Leader of the month`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Public Speaker Award`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Best Speller`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Chess Master`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Diplomat of the year`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Math genius`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Ping pong champ`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.IT expert`)}</span>
          </div>
        </a>
        <a /*href="https://google.com" target="_blank"*/>
          <div className="wallOfFameTrophyWrapper">
            <img src={Trophy} alt="Trophy Icon" />
            <span>{t(`${translationPage}.Citizen award`)}</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default WallOfFame;
