import "./user.scss";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowNotes from "../../components/showNotes/ShowNotes";
import ClipLoader from "react-spinners/ClipLoader";
import FacebookLoginButton from "../../facebook-login";
import { somethingWentWrong } from "../../utils/constants";
import { message } from "antd";
import { useTranslation } from "react-i18next";

const User = () => {
  const { user } = useContext(AuthContext);
  const [userRole, setUserRole] = useState("");
  const [value, setValue] = useState("1");
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const [applicantId, setApplicantId] = useState();
  const timeOptions = { hour: "numeric", minute: "numeric", hour12: false };
  const [showTabPanel, setShowTabPanel] = useState(false);
  const [thisWeeksInterviews, setThisWeeksInterviews] = useState([]);
  const [loadingThisWeeksInterviews, setLoadingThisWeeksInterviews] =
    useState(false);
  const now = new Date();
  const startOfWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - now.getDay() + 1
  );
  const endOfWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    startOfWeek.getDate() + 6,
    23,
    59,
    59,
    999
  );
  const week = [startOfWeek, endOfWeek];
  const { t } = useTranslation();
  const translationPage = "pages.user";

  useEffect(() => {
    if (user?.role === "admin") setUserRole("admin");
    else if (user?.role === "hr") setUserRole("hr");
    else if (user?.role === "it") setUserRole("it");
  }, [user]);

  useEffect(() => {
    const fetchInterviews = async () => {
      setLoadingThisWeeksInterviews(true);
      try {
        const { data } = await axios.get(
          `/meetings?start[gte]=${week[0].toISOString()}&start[lt]=${week[1].toISOString()}`
        );
        if (data.status === "success") {
          setLoadingThisWeeksInterviews(false);
          return setThisWeeksInterviews(data.meetings);
        }
      } catch (err) {
        message.error(somethingWentWrong);
      }
      setLoadingThisWeeksInterviews(false);
    };
    fetchInterviews();
  }, []);

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };

  const TodaysInterviews = () => {
    const [todaysInterviews, setTodaysInterviews] = useState([]);
    const [loadingTodaysInterviews, setLoadingTodaysInterviews] =
      useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const now = new Date();
    const endOfYesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    );
    const startOfTomorrow = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );

    const startOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const endOfToday = new Date(startOfTomorrow - 1);

    const today = [startOfToday, endOfToday];

    useEffect(() => {
      const fetchInterviews = async () => {
        setLoadingTodaysInterviews(true);
        try {
          const { data } = await axios.get(
            `/meetings?start[gte]=${today[0].toISOString()}&start[lt]=${today[1].toISOString()}`
          );
          if (data.status === "success") {
            setLoadingTodaysInterviews(false);
            return setTodaysInterviews(data.meetings);
          }
        } catch (err) {
          message.error(somethingWentWrong);
        }
        setLoadingTodaysInterviews(false);
      };
      fetchInterviews();
    }, []);

    if (showNotes) {
      return (
        <ShowNotes setShowNotes={setShowNotes} applicantId={applicantId} />
      );
    }

    return (
      <div>
        {loadingTodaysInterviews ? (
          <span className="agendaLoaderSpan">
            <ClipLoader className="agendaLoader" color="white" size={100} />
          </span>
        ) : (
          <>
            <p>
              You have {todaysInterviews?.length} interview
              {todaysInterviews?.length !== 1 && "s"} today
            </p>
            {todaysInterviews?.length > 0 && (
              <>
                <table className="userUpcomingInterviewsTable">
                  <tr className="userUpcomingInterviewsTableHeaders">
                    <th>Candidate</th>
                    <th>Type</th>
                    <th>Time</th>
                    <th>Notes</th>
                  </tr>
                  {todaysInterviews?.map((interview) => (
                    <tr className="userUpcomingInterviewsTableTDs">
                      <td>{`${interview.candidateLName} ${interview.candidateFName}`}</td>{" "}
                      <td>{interview.type}</td>
                      <td>
                        {new Date(interview.start).toLocaleTimeString(
                          "en-US",
                          timeOptions
                        )}
                      </td>
                      <td>
                        <VisibilityIcon
                          className="applicantNotesVisibilityIcon"
                          onClick={() => {
                            setShowNotes(true);
                            setApplicantId(interview.applicantId);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
                <Link className="links" to="/hiring">
                  <button className="userUpcomingInterviewsTableBtn">
                    View
                  </button>
                </Link>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const ThisWeeksInterviews = () => {
    const [showNotes, setShowNotes] = useState(false);

    if (showNotes) {
      return (
        <ShowNotes setShowNotes={setShowNotes} applicantId={applicantId} />
      );
    }

    return (
      <div>
        {loadingThisWeeksInterviews ? (
          <span className="agendaLoaderSpan">
            <ClipLoader className="agendaLoader" color="white" size={100} />
          </span>
        ) : (
          <>
            <p>
              You have {thisWeeksInterviews?.length} interview
              {thisWeeksInterviews?.length !== 1 && "s"} this week
            </p>
            {thisWeeksInterviews?.length > 0 && (
              <>
                <table className="userUpcomingInterviewsTable">
                  <tr className="userUpcomingInterviewsTableHeaders">
                    <th>Candidate</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Notes</th>
                  </tr>
                  {thisWeeksInterviews?.map((interview) => (
                    <tr className="userUpcomingInterviewsTableTDs">
                      <td>{`${interview.candidateLName} ${interview.candidateFName}`}</td>
                      <td>{interview.type}</td>
                      <td>
                        {new Date(interview.start).toLocaleDateString(
                          "en-US",
                          dateOptions
                        )}
                      </td>
                      <td>
                        {new Date(interview.start).toLocaleTimeString(
                          "en-US",
                          timeOptions
                        )}
                      </td>
                      <td>
                        <VisibilityIcon
                          className="applicantNotesVisibilityIcon"
                          onClick={() => {
                            setShowNotes(true);
                            setApplicantId(interview.applicantId);
                          }}
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </table>
                <Link className="links" to="/hiring">
                  <button className="userUpcomingInterviewsTableBtn">
                    View
                  </button>
                </Link>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="userContainer">
      <h3 style={{ color: "white" }}>{"Your Role: " + user?.role}</h3>
      {/*<FacebookLoginButton fb={window.FB} />*/}
      {(userRole === "admin" || userRole === "hr") &&
        (thisWeeksInterviews?.length > 0 ? (
          <div className="userUpcomingInterviewsWrapper">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext
                className="userUpcomingInterviewsTabContext"
                value={value}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  <TabList onChange={handleTabChange}>
                    <Tab
                      sx={{ fontSize: "20px" }}
                      className="userUpcomingInterviewsTabList"
                      label={t(`${translationPage}.Today's interviews`)}
                      value="1"
                    />
                    <Tab
                      sx={{ fontSize: "20px" }}
                      className="userUpcomingInterviewsTabList"
                      label={t(`${translationPage}.This Week's interviews`)}
                      value="2"
                    />
                  </TabList>
                </Box>
                {showTabPanel === false ? (
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", fontSize: "30px" }}
                    onClick={() => setShowTabPanel(true)}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    style={{ cursor: "pointer", fontSize: "30px" }}
                    onClick={() => setShowTabPanel(false)}
                  />
                )}
                {showTabPanel && (
                  <>
                    <TabPanel value="1">
                      <TodaysInterviews />
                    </TabPanel>
                    <TabPanel value="2">
                      <ThisWeeksInterviews />
                    </TabPanel>
                  </>
                )}
              </TabContext>
            </Box>
          </div>
        ) : (
          <p className="userZeroUpcomingInterviewsText">
            {t(`${translationPage}.You have 0 interviews this week`)}
          </p>
        ))}
      {userRole === "admin" && (
        <Link className="links" to="/users">
          <button className="userViewUsersBtn">
            {t(`${translationPage}.View Users`)}
          </button>
        </Link>
      )}
      {(userRole === "admin" || userRole === "hr") && (
        <>
          <Link className="links" to="/hiring">
            <button className="userHRBtn">
              {t(`${translationPage}.Human Resources`)}
            </button>
          </Link>
        </>
      )}
      {(userRole === "admin" || userRole === "it") && (
        <>
          <Link className="links" to="/calendar">
            <button className="userManageCalendarBtn">
              {t(`${translationPage}.Manage Calendar`)}
            </button>
          </Link>
          <Link className="links" to="/managePopup">
            <button className="userManagePopupBtn">
              {t(`${translationPage}.Manage Home Popup`)}
            </button>
          </Link>
        </>
      )}
      {(userRole === "admin" || userRole === "academics") && (
        <>
          <Link className="links" to="/studentTests">
            <button className="userManageStudentTestsBtn">
              {t(`${translationPage}.Manage Student Tests`)}
            </button>
          </Link>
        </>
      )}
    </div>
  );
};

export default User;
