import "./login.scss";
import { useContext, useRef, useState } from "react";
import { loginCall } from "../../apiCalls";
import { AuthContext } from "../../context/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";

const Login = () => {
  const username = useRef();
  const password = useRef();
  const { user, isFetching, error, dispatch } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const translationPage = "pages.login";

  const handleLogin = (e) => {
    e.preventDefault();
    loginCall(
      {
        username: username.current.value,
        password: password.current.value,
      },
      dispatch
    );
  };

  return (
    <div className="loginContainer">
      <form id="loginForm" onSubmit={handleLogin}>
        <h1 className="loginHeader">{t(`${translationPage}.Admin Login`)}</h1>
        <div className="loginField">
          <input
            className="loginFormUsername"
            type="text"
            name="username"
            placeholder={t(`${translationPage}.Username`)}
            id="username"
            required
            minLength="3"
            maxLength="20"
            ref={username}
          />
        </div>
        <div className="loginField">
          <label className="loginFormPassword">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder={t(`${translationPage}.Password`)}
              id="password"
              required
              minLength="6"
              ref={password}
            />
            {showPassword ? (
              <VisibilityOffIcon
                className="loginPwdIcon"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <VisibilityIcon
                className="loginPwdIcon"
                onClick={() => setShowPassword(true)}
              />
            )}
          </label>
        </div>
        <button type="submit" className="loginBtn">
          {isFetching
            ? /*<CircularProgress color="white" size="15px"/>*/ "Submitting"
            : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default Login;
