import "./rating.scss";
import StarIcon from "@mui/icons-material/Star";

const Rating = ({ applicant }) => {
  switch (applicant?.rating) {
    case 1:
      return <StarIcon className="applicantStarIcon" />;
    case 2:
      return (
        <>
          <StarIcon className="applicantStarIcon" />
          <StarIcon className="applicantStarIcon" />
        </>
      );
    case 3:
      return (
        <>
          <StarIcon className="applicantStarIcon" />
          <StarIcon className="applicantStarIcon" />
          <StarIcon className="applicantStarIcon" />
        </>
      );
    default:
      return <></>;
  }
};

export default Rating;
