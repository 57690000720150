import "./sidebarMini.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { message } from "antd";
import { somethingWentWrong } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const SidebarMini = () => {
  const [events, setEvents] = useState([]);
  const LIMIT = 5;
  const [showSideBarMenu, setShowSideBarMenu] = useState(false);
  const [showHideIcon, setShowHideIcon] = useState(false);
  const [showSideBar, setShowSidebar] = useState(true);
  const { t } = useTranslation();
  const translationComponent = "sidebarMini";

  useEffect(() => {
    const fetchLatestEvents = async () => {
      try {
        const { data } = await axios.get(
          `calendarEvents/upcoming?limit=${LIMIT}`
        );
        if (data.status === "success") setEvents(data.events);
        else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };
    fetchLatestEvents();
  }, []);

  return (
    <>
      {showSideBar ? (
        <div
          id="sidebarMiniContainer"
          className={
            showSideBarMenu && events?.length > 0
              ? "sidebarMiniContainer"
              : "sidebarMiniContainerCollapsed"
          }
          onMouseLeave={() => {
            setShowSideBarMenu(false);
            setShowHideIcon(false);
          }}
          onMouseEnter={() => setShowHideIcon(true)}
        >
          {showSideBar &&
            (showSideBarMenu && events?.length > 0 ? (
              <div className="sidebarMiniIconShown">
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <CalendarMonthIcon
                    className="sidebarMiniContainerIcon"
                    style={{
                      fontSize: "4.5vh",
                    }}
                  />
                  <h3>{t(`${translationComponent}.Upcoming Events`)}</h3>
                </span>
                <Link className="links" to="/agenda">
                  <div className="sidebarMiniEventsWrapper">
                    {events?.map((event) => (
                      <span className="sidebarMiniEvent">
                        <p className="sidebarMiniEventTitle">{event.title}</p>
                        <p>{moment(event.start).utc().format("LLL")}</p>
                      </span>
                    ))}
                  </div>
                </Link>
                <span className="sidebarDivider"></span>
                <div className="sidebarMiniSocialIconsWrapper">
                  <a
                    href="https://fr-fr.facebook.com/hilali.targa/"
                    target="_blank"
                  >
                    <FacebookIcon className="sidebarMiniIcon sidebarMiniFbIcon" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCQs4zHrM9OTza-mgX99XO1Q"
                    target="_blank"
                  >
                    <YouTubeIcon className="sidebarMiniIcon sidebarMiniYtIcon" />
                  </a>
                  <a
                    href="https://www.instagram.com/htis_official/"
                    target="_blank"
                  >
                    <InstagramIcon className="sidebarMiniIcon sidebarMiniIgIcon" />
                  </a>
                </div>
                {showHideIcon && (
                  <KeyboardArrowLeftIcon
                    className="sideBarShowHideIcon"
                    onClick={() => setShowSidebar(false)}
                  />
                )}
              </div>
            ) : (
              <div className="sidebarMiniIconsCollapsed">
                <Link
                    onMouseOver={() => setShowSideBarMenu(true)}
                    className="links"
                    to="/calendar"
                >
                  <CalendarMonthIcon className="sidebarMiniCalendarIcon" />
                </Link>
                <span className="sidebarDivider"></span>
                <a
                  href="https://fr-fr.facebook.com/hilali.targa/"
                  target="_blank"
                >
                  <FacebookIcon className="sidebarMiniIcon sidebarMiniFbIcon" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCQs4zHrM9OTza-mgX99XO1Q"
                  target="_blank"
                >
                  <YouTubeIcon className="sidebarMiniIcon sidebarMiniYtIcon" />
                </a>
                <a
                  href="https://www.instagram.com/htis_official/"
                  target="_blank"
                >
                  <InstagramIcon className="sidebarMiniIcon sidebarMiniIgIcon" />
                </a>
                {showHideIcon && (
                  <KeyboardArrowLeftIcon
                    className="sideBarShowHideIcon"
                    onClick={() => setShowSidebar(false)}
                  />
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="sidebarShowIcon">
          <KeyboardArrowRightIcon
            onClick={() => setShowSidebar(true)}
            className="sideBarShowHideIcon"
          />
        </div>
      )}
    </>
  );
};

export default SidebarMini;
