import "./virtualTour.scss";
import { useTranslation } from "react-i18next";

const VirtualTour = () => {
  const { t } = useTranslation();
  const translationPage = "pages.virtualTour";

  return (
    <div className="virtualTourContainer">
      <h1>{t(`${translationPage}.HTIS Virtual Tour`)}</h1>
      <iframe
        width="853"
        height="480"
        src="https://www.youtube.com/embed/YR8GuEjdSNo"
        frameborder="0"
        allow="autoplay; encrypted-media"
        //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="HTIS Video"
      />
    </div>
  );
};

export default VirtualTour;
