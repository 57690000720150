import "./alumni.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { useState } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import GavelIcon from "@mui/icons-material/Gavel";
import WorkIcon from "@mui/icons-material/Work";
import ComputerIcon from "@mui/icons-material/Computer";
import BadgeIcon from "@mui/icons-material/Badge";
import MedicationIcon from "@mui/icons-material/Medication";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import SchoolIcon from "@mui/icons-material/School";
import CalculateIcon from "@mui/icons-material/Calculate";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ScienceIcon from "@mui/icons-material/Science";
import ProgressiveImage from "react-progressive-graceful-image";
import Loading from "../../images/Loading.gif";
import AlAkhawayn from "../../images/universities/Al_Akhawayn.png";
import UEMF from "../../images/universities/UEMF.png";
import FMM from "../../images/universities/FMM.png";
import Dauphine from "../../images/universities/Dauphine.png";
import IHEPS from "../../images/universities/IHEPS.png";
import UM6 from "../../images/universities/UM6.png";
import ISSS from "../../images/universities/ISSS.png";
import EDC_PARIS from "../../images/universities/EDC_PARIS.png";
import EMSI from "../../images/universities/EMSI.png";
import ESITH from "../../images/universities/ESITH.png";
import FGSES from "../../images/universities/FGSES.png";
import IBNGHAZI from "../../images/universities/IBNGHAZI.png";
import UOTTAWA from "../../images/universities/UOTTAWA.png";
import CPGE from "../../images/universities/CPGE.png";
import HASSAN2 from "../../images/universities/HASSAN2.png";
import HEM from "../../images/universities/HEM.jpeg";
import ISCAE from "../../images/universities/ISCAE.png";
import ENSG from "../../images/universities/ENSG.png";
import ULG from "../../images/universities/ULG.png";
import UPM from "../../images/universities/UPM.jpeg";
import ULB from "../../images/universities/ULB.png";
import FST from "../../images/universities/FST.png";
import ENSA from "../../images/universities/ENSA.png";
import MKECHPREPAS from "../../images/universities/MKECHPREPAS.png";
import SUPDECO from "../../images/universities/SUPDECO.png";
import ENA from "../../images/universities/ENA.png";
import INPT from "../../images/universities/INPT.png";
import UIR from "../../images/universities/UIR.png";
import ISITT from "../../images/universities/ISITT.png";
import ENSEM from "../../images/universities/ENSEM.png";
import UF from "../../images/universities/UF.png";
import FIU from "../../images/universities/FIU.png";
import MCGILL from "../../images/universities/MCGILL.png";
import UESTC from "../../images/universities/UESTC.png";
import POLYMONTREAL from "../../images/universities/POLYMONTREAL.png";
import LASALLE from "../../images/universities/LASALLE.png";
import IAVH from "../../images/universities/IAVH.png";
import NANCHANG from "../../images/universities/NANCHANG.png";
import ENCG from "../../images/universities/ENCG.png";
import SORBONNE from "../../images/universities/SORBONNE.png";
import PENN from "../../images/universities/PENN.png";
import EHL from "../../images/universities/EHL.png";
import HEC from "../../images/universities/HEC.png";
import { useTranslation } from "react-i18next";

const Alumni = () => {
  const { t } = useTranslation();
  const translationPage = "pages.alumni";
  const [value, setValue] = useState("1");
  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };
  const universitiesPhotos = [
    { src: PENN },
    { src: UF },
    { src: SORBONNE },
    { src: MCGILL },
    { src: UM6 },
    { src: FIU },
    { src: HEC },
    { src: AlAkhawayn },
    { src: FMM },
    { src: MKECHPREPAS },
    { src: UESTC },
    { src: UEMF },
    { src: ISCAE },
    { src: HASSAN2 },
    { src: ENCG },
    { src: ENA },
    { src: IBNGHAZI },
    { src: UOTTAWA },
    { src: Dauphine },
    { src: IHEPS },
    { src: ISSS },
    { src: EDC_PARIS },
    { src: EMSI },
    { src: EHL },

    { src: UPM },
    { src: ESITH },
    { src: ULB },
    { src: FGSES },
    { src: CPGE },
    { src: HEM },
    { src: ENSG },
    { src: ULG },
    { src: FST },
    { src: SUPDECO },
    { src: INPT },
    { src: UIR },
    { src: ISITT },
    { src: ENSEM },
    { src: POLYMONTREAL },
    { src: ENSA },
    { src: LASALLE },
    { src: IAVH },
    { src: NANCHANG },
  ];

  return (
    <div className="alumniContainer">
      <h1>{t(`${translationPage}.Our Alumni`)}</h1>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "fit-content",
              margin: "auto",
            }}
          >
            <TabList className="alumniTabList" onChange={handleTabChange}>
              <Tab
                className="alumniTab"
                label="Colleges & Universities"
                value="1"
              />
              <Tab className="alumniTab" label="Where Are They Now" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" className="alumniCollegesWrapper">
            {universitiesPhotos.map((photo) => (
              <ProgressiveImage src={photo.src} placeholder={Loading}>
                {(src, loading) => (
                  <img
                    className={
                      loading
                        ? "alumniUniversitiesLoading"
                        : "alumniCollegeLogo"
                    }
                    src={src}
                    alt="College/Uni Logo"
                  />
                )}
              </ProgressiveImage>
            ))}
          </TabPanel>
          <TabPanel
            className="alumniStatsWrapper alumniCollegesWrapper"
            value="2"
          >
            <span>
              <AirplanemodeActiveIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.Pilots`)}</p>
            </span>
            <span>
              <AirplanemodeActiveIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.Doctors & Nurses`)}
              </p>
            </span>
            <span>
              <EngineeringIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.Engineers`)}</p>
            </span>
            <span>
              <ScienceIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.Scientists`)}</p>
            </span>
            <span>
              <RecordVoiceOverIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.Public Speakers`)}
              </p>
            </span>
            <span>
              <WorkIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.Managers`)}</p>
            </span>
            <span>
              <BadgeIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.Entrepreneurs / Self-employed`)}
              </p>
            </span>
            <span>
              <ComputerIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.in IT`)}</p>
            </span>
            <span>
              <ColorLensIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.in the Arts`)}
              </p>
            </span>
            <span>
              <GavelIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.Judges / Prosecutors / Lawyers`)}
              </p>
            </span>
            <span>
              <AssuredWorkloadIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.Diplomats / Work for the government`)}
              </p>
            </span>
            <span>
              <MilitaryTechIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.Military Officers`)}
              </p>
            </span>
            <span>
              <ArchitectureIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.Architects`)}</p>
            </span>
            <span>
              <DesignServicesIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.Designers`)}</p>
            </span>
            <span>
              <SchoolIcon className="alumniStatsIcon" />
              <p className="alumniStat">
                {t(`${translationPage}.in Education`)}
              </p>
            </span>
            <span>
              <CalculateIcon className="alumniStatsIcon" />
              <p className="alumniStat">{t(`${translationPage}.in Finance`)}</p>
            </span>
            {/*
            <span>
              <EngineeringIcon className="alumniStatsIcon" />
              <p className="alumniStat"> 20%</p>
              <p>are engineers</p>
            </span>
            <span>
              <AirplanemodeActiveIcon className="alumniStatsIcon" />
              <p className="alumniStat">50%</p>
              <p>have studied/lived overseas</p>
            </span>
            <span>
              <SchoolIcon className="alumniStatsIcon" />
              <p className="alumniStat">60%</p>
              <p>are currently attending university</p>
            </span>
            <span>
              <WorkspacePremiumIcon className="alumniStatsIcon" />
              <p className="alumniStat">30%</p>
              <p>Have obtained a Master's or PhD</p>
            </span>
            <span>
              <ScienceIcon className="alumniStatsIcon" />
              <p className="alumniStat">60%</p>
              <p>have majored in science-related fields</p>
            </span>
            <span>
              <SportsMartialArtsIcon className="alumniStatsIcon" />
              <p className="alumniStat">10%</p>
              <p>practice martial arts</p>
            </span>
            <span>
              <TranslateIcon className="alumniStatsIcon" />
              <p className="alumniStat">20%</p>
              <p>speak 4+ languages</p>
            </span>
            <span>
              <RecordVoiceOverIcon className="alumniStatsIcon" />
              <p className="alumniStat">10%</p>
              <p>are public speakers/entrepreneurs</p>
            </span>*/}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Alumni;
