import useFetch from "../../hooks/useFetch";
import "./calls.scss";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import axios from "axios";
import { message } from "antd";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
import AddEditLog from "../addEditLog/AddEditLog";
import DeletePopup from "../../components/deletePopup/DeletePopup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowNotes from "../../components/showNotes/ShowNotes";
import ClipLoader from "react-spinners/ClipLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { somethingWentWrong } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const Calls = () => {
  const { user } = useContext(AuthContext);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedCall, setSelectedCall] = useState({});
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const element = "call";
  const [showUnansweredCalls, setShowUnansweredCalls] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState();
  const { t } = useTranslation();
  const translationComponent = "calls";

  const fetchLogs = async () => {
    setLoading(true);
    try {
      let res;
      if (showUnansweredCalls) {
        res = await axios.get(`logs/unanswered`);
        if (res?.data?.status === "success") setLogs(res?.data?.logs);
        else message.error(res?.data?.message);
      } else {
        res = await axios.get(`logs`);
        if (res?.data?.status === "success") setLogs(res?.data?.logs);
        else message.error(res?.data?.message);
      }
    } catch (err) {
      message.error(somethingWentWrong);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLogs();
  }, [showUnansweredCalls]);

  const displayDeletePopup = () => {
    return (
      <div className="deletePopupContainer">
        <DeletePopup
          element={element}
          closeDeletePopup={() => {
            setOpenDeletePopup(false);
          }}
          log={selectedCall}
          userRole={user?.role}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    );
  };

  const ApplicantSingle = ({ id }) => {
    const { data, loading } = useFetch(`applications/call/${user?.role}/${id}`);
    const applicant = data?.application;

    return (
      <>
        {loading ? (
          <span className="agendaLoaderSpan">
            <BeatLoader color="#4a154d" size={10} />
          </span>
        ) : (
          applicant?.lastName + " " + applicant?.firstName
        )}
      </>
    );
  };

  if (showNotes) {
    return (
      <ShowNotes
        setShowNotes={setShowNotes}
        applicantId={selectedApplicantId}
      />
    );
  }

  return (
    <div className="callsContainer">
      <button className="callsButton" onClick={() => setOpenAddPopup(true)}>
        {t(`${translationComponent}.Add Log`)}
      </button>
      {loading ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : logs.length ? (
        <>
          <span className="callsUnansweredCheckbox">
            <input
              onClick={() => setShowUnansweredCalls(!showUnansweredCalls)}
              type="checkbox"
              id="unansweredCalls"
              name="unansweredCalls"
            />
            <label for="vehicle1">
              {t(`${translationComponent}.onlyShowUnanswered`)}
            </label>
          </span>
          <table className="callsTable">
            <tr className="callsTableTr">
              <th>{t(`${translationComponent}.Date of Call`)}</th>
              <th>{t(`${translationComponent}.Candidate Name`)}</th>
              <th>{t(`${translationComponent}.Caller`)}</th>
              <th>{t(`${translationComponent}.Reason for calling`)}</th>
              <th>{t(`${translationComponent}.Answered`)}</th>
              <th>{t(`${translationComponent}.Meeting Type (if any)`)}</th>
              <th>{t(`${translationComponent}.Meeting Time (if any)`)}</th>
              <th>{t(`${translationComponent}.Outcome`)}</th>
              <th>{t(`${translationComponent}.Notes`)}</th>
              <th>{t(`${translationComponent}.Edit / Delete`)}</th>
            </tr>
            {logs?.map((log) => (
              <tr className="callsTableTr">
                <td>{log.date?.slice(0, 10)}</td>
                <td>
                  <ApplicantSingle id={log.applicantId} />
                </td>
                <td>{log.caller}</td>
                <td>{log.reason}</td>
                <td>
                  {log.answered
                    ? t(`${translationComponent}.Yes`)
                    : t(`${translationComponent}.No`)}
                </td>
                <td>{log.meetingType}</td>
                <td>
                  {log.meetingTime
                    ? log.meetingTime?.slice(0, 10) +
                      " à " +
                      log.meetingTime.slice(11, 13) +
                      ":" +
                      log.meetingTime.slice(14, 16)
                    : ""}
                </td>
                <td>{log.outcome}</td>
                <td>
                  <VisibilityIcon
                    className="applicantNotesVisibilityIcon"
                    onClick={() => {
                      setSelectedCall(log);
                      setSelectedApplicantId(log.applicantId);
                      setShowNotes(true);
                    }}
                  />{" "}
                </td>
                <td className="callsIcons">
                  <CreateIcon
                    onClick={() => {
                      setSelectedCall(log);
                      setOpenEditPopup(true);
                    }}
                    className="callsIcon"
                  />
                  <DeleteIcon
                    onClick={() => {
                      setSelectedCall(log);
                      setOpenDeletePopup(true);
                    }}
                    className="callsIcon"
                  />
                </td>
              </tr>
            ))}
          </table>
        </>
      ) : (
        <p style={{ margin: "auto" }}>
          {t(`${translationComponent}.No Calls Found`)}
        </p>
      )}
      {openAddPopup && (
        <AddEditLog
          type="add"
          setOpenAddPopup={setOpenAddPopup}
          setLogs={setLogs}
          element=""
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {openEditPopup && (
        <AddEditLog
          log={selectedCall}
          type="edit"
          setOpenEditPopup={setOpenEditPopup}
          setLogs={setLogs}
          element=""
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {openDeletePopup && displayDeletePopup()}
    </div>
  );
};

export default Calls;
