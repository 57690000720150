import "./scholarships.scss";
import { useTranslation } from "react-i18next";
import { scholarships } from "../../utils/texts";

const Scholarships = () => {
  const { i18n } = useTranslation();
  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = scholarships[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="scholarshipsContainer">
      <h1>{getTranslatedText("title")}</h1>
      <p>{getTranslatedText("text")}</p>
      <br />
    </div>
  );
};

export default Scholarships;
