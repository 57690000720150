import "./meetings.scss";
import { useContext, useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Box } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import {
  meetingOutcomes,
  meetingTimes,
  meetingTypes,
  somethingWentWrong,
} from "../../utils/constants";
import axios from "axios";
import { message } from "antd";
import { AuthContext } from "../../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const Meetings = () => {
  const { user } = useContext(AuthContext);
  const [displayPrompt, setDisplayPrompt] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [calendarApi, setCalendarApi] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [initialEvents, setInitialEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const translationComponent = "meetings";

  const fetchMeetings = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`meetings`);
      setInitialEvents(data?.meetings);
    } catch (err) {
      message.error(somethingWentWrong);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchMeetings();
  }, []);

  const Prompt = () => {
    const [candidateFName, setCandidateFName] = useState("");
    const [candidateLName, setCandidateLName] = useState("");
    const [applicantId, setApplicantId] = useState("");
    const type = useRef();
    const date = selectedDate;
    const time = useRef();
    const notes = useRef();
    const applicants = useFetch(`applications/sortedByLName/${user?.role}`).data
      .applications;

    const handleDateClick = async (selected) => {
      if (!applicantId)
        message.error(t(`${translationComponent}.selectCandidate`));
      if (!type.current.value)
        message.error(t(`${translationComponent}.selectMeetingType`));
      if (!time.current.value)
        message.error(t(`${translationComponent}.selectTimeSlot`));
      const hours = time.current.value.slice(0, 2);
      const minutes = time.current.value.slice(3);
      const d = new Date(date);
      d.setHours(hours);
      d.setMinutes(minutes);
      const newMeeting = {
        applicantId: applicantId,
        candidateFName: candidateFName,
        candidateLName: candidateLName,
        type: type.current.value,
        start: d,
        notes: notes.current.value,
      };

      if (newMeeting.start) {
        const meetingDate = new Date(newMeeting.start);
        if (meetingDate < new Date()) {
          const confirmed = window.confirm(
            t(`${translationComponent}.settingMeetingPastDate`)
          );
          if (!confirmed) {
            calendarApi.unselect();
            setTimeout(() => {
              setDisplayPrompt(false);
            }, 1000);
            return;
          }
        }
      }
      try {
        const { data } = await axios.post(`meetings`, newMeeting);
        if (data.status === "success") {
          calendarApi.unselect();
          calendarApi.addEvent(data.savedMeeting);
          setTimeout(() => {
            setDisplayPrompt(false);
          }, 1000);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };

    return (
      <div className="calendarPromptContainer">
        <div className="calendarPromptWrapper">
          <p>
            {t(`${translationComponent}.Add New Event.`)}
            <br /> {t(`${translationComponent}.enterCandidateMeetingInfo`)}
          </p>
          <form className="calendarPromptForm">
            <span>
              <select
                onChange={(e) => {
                  setApplicantId(JSON.parse(e.target.value)._id);
                  setCandidateFName(JSON.parse(e.target.value).firstName);
                  setCandidateLName(JSON.parse(e.target.value).lastName);
                }}
                required
              >
                <option value="" selected hidden>
                  {" "}
                  {t(`${translationComponent}.Candidate`)}
                </option>
                {applicants?.map((candidate) => (
                  <option value={JSON.stringify(candidate)}>
                    {candidate.lastName} {candidate.firstName}
                  </option>
                ))}
              </select>
              <span>*</span>
            </span>
            <span>
              <select required ref={type}>
                <option value="" selected hidden>
                  {t(`${translationComponent}.Meeting Type`)}
                </option>
                {meetingTypes?.map((type) => (
                  <option value={type.value}>{t(type.translationKey)}</option>
                ))}
              </select>
              <span>*</span>
            </span>
            <span>
              <select required ref={time}>
                <option value="" selected hidden>
                  {t(`${translationComponent}.Time Slot`)}
                </option>
                {meetingTimes.map((time) => (
                  <option value={time.value}>{time.value}</option>
                ))}
              </select>
              <span>*</span>
            </span>
            <span>
              <input type="text" placeholder="Notes" ref={notes} />
              <span style={{ color: "transparent" }}>*</span>
            </span>
          </form>
          <span className="calendarPromptBtns">
            <button onClick={handleDateClick}>
              {t(`${translationComponent}.Save`)}
            </button>
            <button onClick={() => setDisplayPrompt(false)}>
              {t(`${translationComponent}.Cancel`)}
            </button>
          </span>
        </div>
      </div>
    );
  };

  const eventTimeFormat = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const EditDeleteEvent = () => {
    const [handleShowEdit, setHandleShowEdit] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const type = useRef();
    const day = useRef();
    const time = useRef();
    const came = useRef();
    const outcome = useRef();
    const notes = useRef();

    const handleEventClick = async (selected) => {
      if (window.confirm(t(`${translationComponent}.confirmDeleteEvent`))) {
        try {
          const { data } = await axios.delete(
            `meetings/${selectedEvent._def.extendedProps._id}`
          );
          if (data.status === "success") {
            message.success(data.message);
            selectedEvent.remove();
            setTimeout(() => {
              setShowEditPopup(false);
              fetchMeetings();
            }, 500);
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    };

    const handleUpdate = async () => {
      let start = "";
      if (day.current.value) {
        start = new Date(day.current.value);
        if (time.current.value) {
          start.setHours(time.current.value.slice(0, 2));
          start.setMinutes(time.current.value.slice(3));
        } else {
          start.setHours(selectedEvent.startStr.slice(11, 13));
          start.setMinutes(selectedEvent.startStr.slice(14, 16));
        }
      } else {
        start = new Date(selectedEvent.startStr);
        if (time.current.value) {
          start.setHours(time.current.value.slice(0, 2));
          start.setMinutes(time.current.value.slice(3));
        }
      }
      const updatedMeeting = {
        type: type.current.value || selectedEvent._def.extendedProps.type,
        start,
        came:
          came.current.value || selectedEvent._def.extendedProps.came || null,
        notes: notes.current.value || null,
      };
      if (
        (outcome.current.value && outcome.current.value !== "null") ||
        (!outcome.current.value && selectedEvent._def.extendedProps.outcome)
      ) {
        updatedMeeting.outcome =
          outcome.current.value || selectedEvent._def.extendedProps.outcome;
      } else if (outcome.current.value === "null") {
        updatedMeeting.outcome = null;
      }
      if (updatedMeeting.start < new Date()) {
        const confirmed = window.confirm(
          t(`${translationComponent}.settingMeetingPastDate`)
        );
        if (!confirmed) return;
      }
      try {
        const { data } = await axios.put(
          `meetings/${selectedEvent?._def.extendedProps._id}`,
          updatedMeeting
        );
        if (data.status === "success") {
          message.success(data.message);
          setTimeout(() => {
            setShowEditPopup(false);
            setHandleShowEdit(false);
            fetchMeetings();
          }, 1000);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };

    if (showEvent) {
      return (
        <div className="meetingsViewPopupContainer">
          <div className="meetingsViewPopupWrapper">
            <span className="meetingsViewPopupSpanWrapper">
              <span
                className="meetingsViewPopupCloseBtn"
                onClick={() => setShowEvent(false)}
              >
                &times;
              </span>
              <p>{t(`${translationComponent}.Event`)}</p>
            </span>
            <table>
              <tr>
                <th>{t(`${translationComponent}.Candidate`)} : </th>
                <td>
                  {selectedEvent._def.extendedProps.candidateLName}{" "}
                  {selectedEvent._def.extendedProps.candidateFName}
                </td>
              </tr>
              <tr>
                <th>{t(`${translationComponent}.Meeting Type`)} : </th>
                <td>{selectedEvent._def.extendedProps.type}</td>
              </tr>
              <tr>
                <th>{t(`${translationComponent}.Meeting Time`)} : </th>
                <td>{selectedEvent?.start.toString().slice(0, 21)}</td>
              </tr>
              <tr>
                <th>{t(`${translationComponent}.Candidate Came?`)}</th>
                <td>
                  {selectedEvent._def.extendedProps.came
                    ? t(`${translationComponent}.Yes`)
                    : t(`${translationComponent}.No`)}
                </td>
              </tr>
              <tr>
                <th>{t(`${translationComponent}.Outcome`)} : </th>
                <td>{selectedEvent._def.extendedProps.outcome}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    }

    return (
      <>
        {handleShowEdit ? (
          <div className="meetingsEditPopupContainer">
            <div className="meetingsEditPopupWrapper">
              <p>{t(`${translationComponent}.Edit Event`)}</p>
              <form>
                <span>
                  <label style={{ cursor: "no-drop" }}>
                    {t(`${translationComponent}.Candidate`)}:{" "}
                    {selectedEvent?._def.extendedProps.candidateLName}{" "}
                    {selectedEvent?._def.extendedProps.candidateFName}
                  </label>
                  <select ref={type}>
                    <option
                      value={selectedEvent?._def.extendedProps.type}
                      selected
                      hidden
                    >
                      {t(`${translationComponent}.Meeting Type`)}:{" "}
                      {selectedEvent?._def.extendedProps.type}
                    </option>
                    {meetingTypes?.map((type) => (
                      <option value={type.value}>
                        {t(type.translationKey)}
                      </option>
                    ))}{" "}
                  </select>
                  <input
                    ref={day}
                    type="text"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    placeholder={`Date: ${selectedEvent?.startStr?.slice(
                      0,
                      10
                    )}`}
                  />
                  <select ref={time}>
                    <option value="" selected hidden>
                      {t(`${translationComponent}.Meeting Time`)}{" "}
                      {selectedEvent.start?.toString().slice(16, 21) || ""}
                    </option>
                    {meetingTimes.map((time) => (
                      <option value={time.value}>{time.value}</option>
                    ))}
                  </select>
                  <select ref={came}>
                    <option
                      value={selectedEvent._def.extendedProps.came || ""}
                      selected
                      hidden
                    >
                      {t(`${translationComponent}.Candidate Came ?`)}
                      {selectedEvent._def.extendedProps.came}
                    </option>
                    <option value="true">
                      {t(`${translationComponent}.Yes`)}
                    </option>
                    <option value="false">
                      {t(`${translationComponent}.No`)}
                    </option>
                  </select>
                  <select ref={outcome}>
                    <option
                      value={selectedEvent._def.extendedProps.outcome || ""}
                      selected
                      hidden
                    >
                      {t(`${translationComponent}.Outcome`)}:{" "}
                      {selectedEvent._def.extendedProps.outcome}
                    </option>
                    {meetingOutcomes.map((outcome) => (
                      <option value={outcome.value}>
                        {t(outcome.translationKey)}
                      </option>
                    ))}
                  </select>
                  <input ref={notes} placeholder="Add Notes"></input>
                </span>
              </form>
              <span className="meetingsPromptBtns">
                <button onClick={handleUpdate}>
                  {t(`${translationComponent}.Update`)}
                </button>
                <button
                  onClick={() => {
                    setHandleShowEdit(false);
                  }}
                >
                  {t(`${translationComponent}.Cancel`)}
                </button>
              </span>
            </div>
          </div>
        ) : (
          <div className="meetingsPromptPopupContainer">
            <div className="meetingsPromptPopupWrapper">
              <button onClick={() => setShowEvent(true)}>
                {t(`${translationComponent}.View Event`)}
              </button>
              <button onClick={() => setHandleShowEdit(true)}>
                {t(`${translationComponent}.Edit Event`)}
              </button>
              <button onClick={() => handleEventClick()}>
                {t(`${translationComponent}.Delete Event`)}
              </button>
              <button onClick={() => setShowEditPopup(false)}>
                {t(`${translationComponent}.Cancel`)}
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {showEditPopup && <EditDeleteEvent />}
      {displayPrompt && <Prompt />}
      {loading ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : (
        <>
          <div className="calendarContainer">
            <Box m="20px">
              <Box display="flex" justifyContent="space-between">
                {/* CALENDAR */}
                <Box flex="1 1 100%" ml="15px">
                  <FullCalendar
                    height="75vh"
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      listPlugin,
                    ]}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                    }}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    select={(e) => {
                      setSelectedDate(e["startStr"]);
                      setDisplayPrompt(true);
                      setCalendarApi(e.view.calendar);
                    }}
                    eventTimeFormat={eventTimeFormat}
                    eventClick={(e) => {
                      setShowEditPopup(true);
                      setSelectedEvent(e.event);
                    }}
                    weekends={true}
                    events={initialEvents}
                    eventContent={(eventInfo) => {
                      return (
                        <div
                          className={`schoolCalendarEventTitle ${
                            !eventInfo?.event._def.extendedProps.outcome &&
                            "meetingUnclassified"
                          }`}
                        >
                          {eventInfo.event._def.extendedProps.candidateLName}{" "}
                          &nbsp;
                          {eventInfo.event._def.extendedProps.candidateFName}:
                          &nbsp; {eventInfo.event._def.extendedProps.type} à
                          &nbsp; {eventInfo.timeText}
                        </div>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </div>
          <span
            className="meetingUnclassified"
            style={{
              display: "flex",
              fontStyle: "italic",
            }}
          >
            <p style={{ margin: "auto" }}>
              {t(`${translationComponent}.Unclassified Meetings`)}
            </p>
          </span>
        </>
      )}
    </>
  );
};

export default Meetings;
