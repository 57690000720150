import "./accessDenied.scss";
import { useTranslation } from "react-i18next";

const AccessDenied = ({ fontColor }) => {
  const { t } = useTranslation();
  const translationComponent = "accessDenied";

  return (
    <div style={{ color: fontColor }} className="accessDeniedContainer">
      <h1>{t(`${translationComponent}.Access Denied`)}</h1>
      <p>{t(`${translationComponent}.noPermissionsTxt`)}</p>
      <p>{t(`${translationComponent}.noPermissionsTxt2`)}</p>
    </div>
  );
};

export default AccessDenied;
