import "./apply.scss";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LightModeIcon from "@mui/icons-material/LightMode";
import GppGoodIcon from "@mui/icons-material/GppGood";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { message } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import {
  hiringTypes,
  applicationsStreams,
  cycles,
  positions,
  somethingWentWrong,
} from "../../utils/constants";
import { fileOnChange } from "../../hooks/fileOnChange";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import ReplyIcon from "@mui/icons-material/Reply";

const defaultApplication = {
  firstName: "",
  lastName: "",
  email: "",
  type: "",
  appStream: "",
  dob: "",
  birthPlace: "",
  nationality: "",
  maritalStatus: "",
  noOfChildren: "",
  pregnant: "",
  transportationMeans: "",
  diplomas: "",
  professionalExperience: "",
  currentSituation: "",
  languages: "",
  applicationLetter: "",
  civility: "",
  address: "",
};

const Apply = () => {
  const [application, setApplication] = useState(defaultApplication);
  const [formStep, setFormStep] = useState(1);
  const handleOnChange = (newValue, fieldName) => {
    application[fieldName] = newValue;
    setApplication(application);
  };
  const [dateinputIsFocused, setDateinputIsFocused] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [position, setPosition] = useState([]);
  const [cycleList, setCycleList] = useState([]);
  const [cycle, setCycle] = useState([]);
  const [resume, setResume] = useState([]);
  const [photo, setPhoto] = useState("");
  const [resumePreview, setResumePreview] = useState();
  const [photoPreview, setPhotoPreview] = useState();
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const translationPage = "pages.apply";

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      type,
      appStream,
      dob,
      birthPlace,
      nationality,
      maritalStatus,
      noOfChildren,
      pregnant,
      transportationMeans,
      diplomas,
      professionalExperience,
      currentSituation,
      languages,
      applicationLetter,
      civility,
    } = application;
    const newApplication = {
      firstName:
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase(),
      lastName:
        lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase(),
      email,
      type,
      appStream,
      phone: formattedPhone,
      position,
      cycle,
      resume: [],
      civility,
      dob: dob,
      birthPlace,
      nationality,
      maritalStatus,
      pregnant,
      noOfChildren,
      transportationMeans,
      diplomas,
      professionalExperience,
      currentSituation,
      languages,
      applicationLetter,
      manualCVFlash: true,
    };
    if (resume.length > 0) {
      const data = new FormData();
      for (let i = 0; i < resume.length; i++) {
        const filename =
          Date.now() +
          newApplication.lastName +
          newApplication.firstName +
          "resume" +
          resume[i].name;
        data.append("name", filename);
        const img = resume[i];
        data.append("file", img, filename);
        newApplication.resume.push(filename);
      }
      try {
        await axios.post("multiUpload", data);
      } catch (err) {}
    }
    if (photo) {
      const data = new FormData();
      const img = photo;
      const filename =
        newApplication.lastName + newApplication.firstName + "photo" + img.name;
      data.append("name", filename);
      data.append("file", img, filename);
      newApplication.photo = filename;
      try {
        await axios.post("upload", data);
      } catch (err) {}
    }
    try {
      const { data } = await axios.post("applications", newApplication);
      if (data.status === "applicationExists") message.info(data.message);
      else if (data.status === "success") {
        message.success(data.message);
        setSubmitted(true);
      } else message.error(data.message);
    } catch (err) {
      message.error(somethingWentWrong);
    }
  };

  if (submitted) {
    return (
      <div className="applySubmitted">
        <p>{t(`${translationPage}.Thank you for applying !`)}</p>
        <Link className="links" to="/">
          <span className="applySubmittedBtn">
            {t(`${translationPage}.Home`)}
          </span>
        </Link>
      </div>
    );
  }

  const handlePhoneInput = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormattedPhone(formattedPhone);
  };

  const handleAdvance = () => {
    let allFieldsFilled = true;

    if (formStep === 1) {
      if (
        !application.firstName ||
        !application.lastName ||
        !formattedPhone ||
        !position ||
        !resume
      )
        allFieldsFilled = false;

      if (!allFieldsFilled) {
        message.error(
          t(`${translationPage}.Please fill in all the required fields.`)
        );
        return;
      }
    } else if (formStep === 2) {
      if (
        !application.dob ||
        !application.birthPlace ||
        !application.maritalStatus ||
        !application.pregnant ||
        !application.noOfChildren ||
        !application.transportationMeans ||
        !application.diplomas ||
        !application.professionalExperience ||
        !application.currentSituation
      )
        allFieldsFilled = false;
      if (!allFieldsFilled) {
        message.error(
          t(`${translationPage}.Please fill in all the required fields.`)
        );
        return;
      }
    }
    setFormStep(formStep + 1);
  };

  return (
    <div className="applyContainer">
      <div className="applyTop">
        <h1 className="applyHeader">
          {/*{t(`${translationPage}.Why Work With Us`)}*/}
          Rejoignez une équipe qui gagne !
        </h1>
        <div className="applyIconsSpansWrapper">
          <span>
            <AddHomeWorkIcon
              style={{ fontSize: "10vh" }}
              className="applyIcon"
            />
            <p>{t(`${translationPage}.Great Benefits`)}</p>
          </span>
          <span>
            <LocalAtmIcon style={{ fontSize: "10vh" }} className="applyIcon" />
            <p>{t(`${translationPage}.Competitive Salary`)}</p>
          </span>
          <span>
            <LightModeIcon style={{ fontSize: "10vh" }} className="applyIcon" />
            <p>{t(`${translationPage}.2+ Months Paid Vacation`)}</p>
          </span>
          <span>
            <GppGoodIcon style={{ fontSize: "10vh" }} className="applyIcon" />
            <p>{t(`${translationPage}.High-quality Training`)}</p>
          </span>
        </div>
      </div>
      <div className="applyForm">
        {formStep !== 1 && (
          <ReplyIcon
            className="applyGoBackArrow"
            onClick={() => setFormStep(formStep - 1)}
          />
        )}
        <form id="applyForm">
          {formStep === 1 ? (
            <div className="applyFormInnerdiv">
              <div className="applyRadioBtns">
                <span>
                  <input
                    type="radio"
                    name="salutation"
                    value="Mr"
                    onChange={(e) => handleOnChange("Mr", "civility")}
                  />
                  <label htmlFor="salutationMr">
                    {t(`${translationPage}.Mr`)}
                  </label>
                </span>
                <span>
                  <input
                    type="radio"
                    name="salutation"
                    value="Mrs"
                    onChange={(e) => handleOnChange("Mrs", "civility")}
                  />
                  <label htmlFor="salutationMrs">
                    {t(`${translationPage}.Mrs`)}
                  </label>
                </span>
                <span>
                  <input
                    type="radio"
                    name="salutation"
                    value="Ms"
                    onChange={(e) => handleOnChange("Ms", "civility")}
                  />
                  <label htmlFor="salutationMrs">
                    {t(`${translationPage}.Ms`)}
                  </label>
                </span>
              </div>
              <span className="applyCol1 applyColRequired">
                <input
                  type="text"
                  name="fName"
                  placeholder={t(`${translationPage}.First Name`)}
                  id="fName"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "firstName");
                  }}
                />
                <p>*</p>
              </span>
              <span className="applyCol2 applyColRequired">
                <input
                  type="text"
                  name="lName"
                  placeholder={t(`${translationPage}.Last Name`)}
                  id="lName"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "lastName");
                  }}
                />
                <p>*</p>
              </span>
              <input
                className="applyCol1"
                type="text"
                name="email"
                placeholder={t(`${translationPage}.Email`)}
                id="email"
                required={false}
                onChange={(e) => {
                  handleOnChange(e.target.value, "email");
                }}
              />
              <span className="applyCol2 applyColRequired">
                <input
                  value={formattedPhone}
                  onChange={(e) => handlePhoneInput(e)}
                  name="phone"
                  placeholder={t(`${translationPage}.Phone Number`)}
                  id="phone"
                  required
                />
                <p>*</p>
              </span>
              <span
                className="applyCol1 applySelectRequired"
                style={{ position: "relative" }}
              >
                <Select
                  className="applyMultiSelect"
                  required
                  id="position"
                  options={positions.map((position) => ({
                    value: position.value,
                    label: t(position.translationKey),
                  }))}
                  isMulti
                  value={positionList}
                  onChange={(selected, triggeredAction) => {
                    if (triggeredAction.action === "clear") {
                      setPositionList([]);
                      setPosition([]);
                    } else if (triggeredAction.action === "remove-value") {
                      const removedValue = triggeredAction.removedValue.value;
                      setPositionList(
                        cycleList.filter(
                          (option) => option.value !== removedValue
                        )
                      );
                      setPosition(cycle.filter((val) => val !== removedValue));
                    } else if (triggeredAction.action === "select-option") {
                      setPositionList(selected);
                      const values = selected?.map((option) => option?.value);
                      setPosition(values);
                    }
                  }}
                  closeMenuOnSelect={false}
                  placeholder={t(`${translationPage}.Position / Field`)}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      outline: state.isFocused ? "none" : "none",
                      border: state.isFocused ? "none" : "none",
                      boxShadow: state.isFocused ? "none" : "none",
                      height: state.menuIsOpen ? "100%" : "50px",
                      overflow: state.isFocused ? "visible" : "hidden",
                      overflow: state.menuIsOpen ? "visible" : "hidden",
                      //width: "350px",
                    }),
                    indicatorsContainer: (base, state) => ({
                      ...base,
                      position: state.isMulti ? "absolute" : "inherit",
                      top: state.isMulti ? "0" : "inherit",
                      right: state.isMulti ? "0" : "inherit",
                      zIndex: "9",
                      backgroundColor: "white",
                      display: "flex",
                    }),
                  }}
                />
                <p>*</p>
              </span>
              <span
                className="applyCol2 applySelectRequired"
                style={{ position: "relative" }}
              >
                <Select
                  className="applyMultiSelect"
                  options={cycles.map((cycle) => ({
                    value: cycle.value,
                    label: t(cycle.translationKey),
                  }))}
                  isMulti
                  value={cycleList}
                  onChange={(selected, triggeredAction) => {
                    if (triggeredAction.action === "clear") {
                      setCycleList([]);
                      setCycle([]);
                    } else if (triggeredAction.action === "remove-value") {
                      const removedValue = triggeredAction.removedValue.value;
                      setCycleList(
                        cycleList.filter(
                          (option) => option.value !== removedValue
                        )
                      );
                      setCycle(cycle.filter((val) => val !== removedValue));
                    } else if (triggeredAction.action === "select-option") {
                      setCycleList(selected);
                      const values = selected?.map((option) => option?.value);
                      setCycle(values);
                    }
                  }}
                  closeMenuOnSelect={false}
                  placeholder={t(`${translationPage}.Cycle(s)`)}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      outline: state.isFocused ? "none" : "none",
                      border: state.isFocused ? "none" : "none",
                      boxShadow: state.isFocused ? "none" : "none",
                      height: state.menuIsOpen ? "100%" : "50px",
                      overflow: state.isFocused ? "visible" : "hidden",
                      overflow: state.menuIsOpen ? "visible" : "hidden",
                      // width: "350px",
                    }),
                    indicatorsContainer: (base, state) => ({
                      ...base,
                      position: state.isMulti ? "absolute" : "inherit",
                      top: state.isMulti ? "0" : "inherit",
                      right: state.isMulti ? "0" : "inherit",
                      zIndex: "9",
                      backgroundColor: "white",
                      display: "flex",
                    }),
                  }}
                />
              </span>
              <span className="applyCol1 applyColRequired applyColAttachment">
                <label>
                  <input
                    required
                    onChange={(e) =>
                      fileOnChange(
                        e,
                        "resume",
                        resume,
                        setResume,
                        resumePreview,
                        setResumePreview,
                        photo,
                        setPhoto,
                        photoPreview,
                        setPhotoPreview,
                        "",
                        "",
                        "",
                        ""
                      )
                    }
                    type="file"
                    multiple
                  />
                  {t(`${translationPage}.Resume / CV`)}
                  <AttachFileIcon />
                  {resumePreview && (
                    <span className="applyFormImgPreviewSpan">
                      <img
                        className="applyFormImgPreview"
                        src={resumePreview}
                      />
                      {resume.length > 1 && (
                        <span className="applyFormImgPreviewLength">
                          {" "}
                          +{resume.length - 1}{" "}
                        </span>
                      )}
                    </span>
                  )}
                </label>
                <p>*</p>
              </span>
              <label style={{ cursor: "pointer" }} className="applyCol2">
                <input
                  id="photo"
                  onChange={(e) =>
                    fileOnChange(
                      e,
                      "photo",
                      resume,
                      setResume,
                      resumePreview,
                      setResumePreview,
                      photo,
                      setPhoto,
                      photoPreview,
                      setPhotoPreview,
                      "",
                      ""
                    )
                  }
                  type="file"
                />
                {t(`${translationPage}.Photo`)}
                <AttachFileIcon />
                {photo && (
                  <img className="applyFormImgPreview" src={photoPreview} />
                )}
              </label>
              <select
                onChange={(e) => {
                  handleOnChange(e.target.value, "type");
                }}
                className="applyCol1"
              >
                <option selected hidden value="">
                  {t(`${translationPage}.Type`)}
                </option>
                {hiringTypes.map((type) => (
                  <option value={type.value}>{t(type.translationKey)}</option>
                ))}
              </select>
              <select
                onChange={(e) => {
                  handleOnChange(e.target.value, "appStream");
                }}
                name="appStream"
                className="applyCol2"
              >
                <option value="">
                  {t(`${translationPage}.How Did You Hear About Us?`)}
                </option>
                {applicationsStreams.map((stream) => (
                  <option value={stream.value}>
                    {t(stream.translationKey)}
                  </option>
                ))}
              </select>
            </div>
          ) : formStep === 2 ? (
            <div className="applyFormInnerdiv">
              <span className="applyCol1 applyColRequired">
                <input
                  type={dateinputIsFocused ? "date" : "text"}
                  name="dob"
                  id="dob"
                  required
                  placeholder={t(`${translationPage}.Date of birth`)}
                  onChange={(e) => {
                    handleOnChange(e.target.value, "dob");
                  }}
                  onFocus={() => setDateinputIsFocused(true)}
                  onBlur={() => setDateinputIsFocused(false)}
                />
                <p>*</p>
              </span>
              <span className="applyCol2 applyColRequired">
                <input
                  type="text"
                  name="birthPlace"
                  placeholder={t(`${translationPage}.Place of birth`)}
                  id="birthPlace"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "birthPlace");
                  }}
                />
                <p>*</p>
              </span>
              <input
                className="applyCol1"
                type="text"
                name="nationality"
                placeholder={t(`${translationPage}.Nationality`)}
                id="nationality"
                required={false}
                onChange={(e) => {
                  handleOnChange(e.target.value, "nationality");
                }}
              />
              <span className="applyCol2 applyColRequired">
                <input
                  name="maritalStatus"
                  placeholder={t(`${translationPage}.Marital Status`)}
                  id="maritalStatus"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "maritalStatus");
                  }}
                />
                <p>*</p>
              </span>
              <span
                className="applyCol1 applySelectRequired"
                style={{ position: "relative" }}
              >
                <select
                  onChange={(e) => {
                    handleOnChange(e.target.value, "pregnant");
                  }}
                  required
                >
                  <option className="applyDefaultSelectValue" hidden value="">
                    {t(`${translationPage}.Pregnant`)}
                  </option>
                  <option value="Yes">{t(`${translationPage}.Yes`)}</option>
                  <option value="No">{t(`${translationPage}.No`)}</option>
                </select>
                <p>*</p>
              </span>
              <span className="applyCol2 applyColRequired">
                <input
                  name="noOfChildren"
                  placeholder={t(`${translationPage}.Number of Children`)}
                  id="noOfChildren"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "noOfChildren");
                  }}
                />
                <p>*</p>
              </span>
              <span className="applyCol1 applyColRequired">
                <input
                  name="transportationMeans"
                  placeholder={t(`${translationPage}.Transportation Means`)}
                  id="transportationMeans"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "transportationMeans");
                  }}
                />
                <p>*</p>
              </span>
              <span className="applyCol2 applyColRequired">
                <input
                  name="diplomas"
                  placeholder={t(`${translationPage}.Diplomas`)}
                  id="diplomas"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "diplomas");
                  }}
                />
                <p>*</p>
              </span>
              <span className="applyCol1 applyColRequired">
                <input
                  name="professionalExperience"
                  placeholder={t(`${translationPage}.Professional Experience`)}
                  id="professionalExperience"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "professionalExperience");
                  }}
                />
                <p>*</p>
              </span>
              <span className="applyCol2 applyColRequired">
                <input
                  name="currentSituation"
                  placeholder={t(`${translationPage}.Current Situation`)}
                  id="currentSituation"
                  required
                  onChange={(e) => {
                    handleOnChange(e.target.value, "currentSituation");
                  }}
                />
                <p>*</p>
              </span>
              <input
                className="applyCol1"
                name="address"
                placeholder={t(`${translationPage}.Address`)}
                id="address"
                onChange={(e) => {
                  handleOnChange(e.target.value, "address");
                }}
              />
              <input
                className="applyCol2"
                name="languages"
                placeholder={t(`${translationPage}.Languages`)}
                id="languages"
                onChange={(e) => {
                  handleOnChange(e.target.value, "languages");
                }}
              />
            </div>
          ) : (
            <>
              <textarea
                className="applyApplicationLetter"
                placeholder={t(`${translationPage}.Application Letter`)}
                name="applicationLetter"
                id="applicationLetter"
                required
                onChange={(e) => {
                  handleOnChange(e.target.value, "applicationLetter");
                }}
              />
            </>
          )}
        </form>
        {formStep < 3 ? (
          <button
            onClick={() => handleAdvance()}
            className="applyFormBtn"
            id="applyFormBtn"
          >
            {t(`${translationPage}.NEXT`)}
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            className="applyFormBtn"
            id="applyFormBtn"
          >
            {t(`${translationPage}.APPLY`)}
          </button>
        )}
        <span>
          <span>* *&nbsp;</span>
          <p>{t(`${translationPage}.uploadPhotoTxt`)}</p>
        </span>
      </div>
    </div>
  );
};

export default Apply;
