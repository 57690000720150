import "./letter.scss";
import Directors from "../../images/Directors.JPG";
import { useTranslation } from "react-i18next";
import { letter } from "../../utils/texts";

const Letter = () => {
  const { i18n } = useTranslation();

  const getTranslatedText = (element) => {
    const currentLanguage = i18n.language;
    const text = letter[element][currentLanguage];
    return <p dangerouslySetInnerHTML={{ __html: text }}></p>;
  };

  return (
    <div className="letterContainer">
      <h1>{getTranslatedText("title")}</h1>
      <img src={Directors} alt="HTIS Principals/Founders" />
      <p>{getTranslatedText("text")} </p>
      <span>{getTranslatedText("signature")} </span>
    </div>
  );
};

export default Letter;
