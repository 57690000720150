import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
//Imports for Kindergarten
import Artists from "../../images/cycles/kindergarten/Artists.JPG";
import Artists2 from "../../images/cycles/kindergarten/Artists2.JPG";
import Artists3 from "../../images/cycles/kindergarten/Artists3.JPG";
import Artists4 from "../../images/cycles/kindergarten/Artists4.JPG";
import Artists5 from "../../images/cycles/kindergarten/Artists5.JPG";
import Baking from "../../images/cycles/kindergarten/Baking.JPG";
import Cooking from "../../images/cycles/kindergarten/Cooking.JPG";
import Cooking2 from "../../images/cycles/kindergarten/Cooking2.JPG";
import Cooking3 from "../../images/cycles/kindergarten/Cooking3.JPG";
import Cooking4 from "../../images/cycles/kindergarten/Cooking4.JPG";
import Cooking5 from "../../images/cycles/kindergarten/Cooking5.JPG";
import Dancing from "../../images/cycles/kindergarten/Dancing.JPG";
import Eid from "../../images/cycles/kindergarten/Eid.JPG";
import Eid2 from "../../images/cycles/kindergarten/Eid2.JPG";
import EndOfYearParty from "../../images/cycles/kindergarten/EndOfYearParty.JPG";
import EndOfYearParty2 from "../../images/cycles/kindergarten/EndOfYearParty2.JPG";
import EndOfYearParty3 from "../../images/cycles/kindergarten/EndOfYearParty3.JPG";
import Farming from "../../images/cycles/kindergarten/Farming.JPG";
import FirstDayOfSchool from "../../images/cycles/kindergarten/FirstDayOfSchool.JPG";
import Painting from "../../images/cycles/kindergarten/Painting.JPG";
import Painting2 from "../../images/cycles/kindergarten/Painting2.JPG";
import Painting3 from "../../images/cycles/kindergarten/Painting3.JPG";
import Painting4 from "../../images/cycles/kindergarten/Painting4.JPG";
import Painting5 from "../../images/cycles/kindergarten/Painting5.JPG";
import WallClimbing from "../../images/cycles/kindergarten/WallClimbing.JPG";
//Imports for Elementary
import Agadir from "../../images/cycles/elementary/Agadir.JPG";
import Agadir2 from "../../images/cycles/elementary/Agadir2.JPG";
import Certificates from "../../images/cycles/elementary/Certificates.JPG";
import DarIkalimo from "../../images/cycles/elementary/DarIkalimo.jpg";
import DarIkalimo2 from "../../images/cycles/elementary/DarIkalimo2.jpg";
import EndOfYearPartyElementary from "../../images/cycles/elementary/EndOfYearParty.jpg";
import EndOfYearParty2Elementary from "../../images/cycles/elementary/EndOfYearParty2.jpg";
import EndOfYearParty3Elementary from "../../images/cycles/elementary/EndOfYearParty3.jpg";
import EndOfYearParty4Elementary from "../../images/cycles/elementary/EndOfYearParty4.jpg";
import EndOfYearParty5Elementary from "../../images/cycles/elementary/EndOfYearParty5.jpg";
import Henna from "../../images/cycles/elementary/Henna.JPG";
import Henna2 from "../../images/cycles/elementary/Henna2.JPG";
import IceSkating from "../../images/cycles/elementary/IceSkating.jpg";
import IndependenceDay from "../../images/cycles/elementary/IndependenceDay.JPG";
import Ourika from "../../images/cycles/elementary/Ourika.jpg";
import Sushi from "../../images/cycles/elementary/Sushi.jpg";
import Certificates2 from "../../images/cycles/elementary/Certificates2.JPG";
import Bees from "../../images/cycles/elementary/Bees.JPG";
import Bees2 from "../../images/cycles/elementary/Bees2.JPG";
import BakingElementary from "../../images/cycles/elementary/Baking.JPG";
import Gymnastics from "../../images/cycles/elementary/Gymnastics.JPG";
//Imports for Middle School
import AgadirMiddle from "../../images/cycles/middle/Agadir.JPG";
import Audience from "../../images/cycles/middle/Audience.JPG";
import Cervantes from "../../images/cycles/middle/Cervantes.JPG";
import Cervantes2 from "../../images/cycles/middle/Cervantes2.JPG";
import Cervantes3 from "../../images/cycles/middle/Cervantes3.JPG";
import Chess from "../../images/cycles/middle/Chess.JPG";
import Church from "../../images/cycles/middle/Church.JPG";
import Church2 from "../../images/cycles/middle/Church2.JPG";
import CPR from "../../images/cycles/middle/CPR.JPG";
import EndOfSchoolYearPartyMiddle from "../../images/cycles/middle/EndOfSchoolYearParty.JPG";
import Exam from "../../images/cycles/middle/Exam.JPG";
import Exam2 from "../../images/cycles/middle/Exam2.JPG";
import Museum from "../../images/cycles/middle/Museum.JPG";
import Museum2 from "../../images/cycles/middle/Museum2.JPG";
import Museum3 from "../../images/cycles/middle/Museum3.JPG";
import Paintball from "../../images/cycles/middle/Paintball.JPG";
import RedGreenFlags from "../../images/cycles/middle/RedGreenFlags.JPG";
import SchoolRadio from "../../images/cycles/middle/SchoolRadio.JPG";
import Snacks from "../../images/cycles/middle/Snacks.jpg";
import Snacks2 from "../../images/cycles/middle/Snacks2.JPG";
import Snacks3 from "../../images/cycles/middle/Snacks3.JPG";
import Snacks4 from "../../images/cycles/middle/Snacks4.JPG";
import Synagogue from "../../images/cycles/middle/Synagogue.JPG";
import Synagogue2 from "../../images/cycles/middle/Synagogue2.JPG";
import Trip from "../../images/cycles/middle/Trip.JPG";
import Global from "../../images/cycles/middle/Global.jpg";
import Debate from "../../images/cycles/middle/Debate.JPG";
//Imports for High School
import Amphi from "../../images/cycles/high/Amphi.JPG";
import Amphi2 from "../../images/cycles/high/Amphi2.jpg";
import Cambridge from "../../images/cycles/high/Cambridge.JPG";
import DarTifl from "../../images/cycles/high/DarTifl.JPG";
import Graduation from "../../images/cycles/high/Graduation.jpg";
import InClass from "../../images/cycles/high/InClass.JPG";
import InClass2 from "../../images/cycles/high/InClass2.jpg";
import MUN from "../../images/cycles/high/MUN.JPG";
import MUN2 from "../../images/cycles/high/MUN2.JPG";
import Orientation from "../../images/cycles/high/Orientation.JPG";
import Party from "../../images/cycles/high/Party.JPG";
import PE from "../../images/cycles/high/PE.jpg";
import PE2 from "../../images/cycles/high/PE2.jpg";
import Quad from "../../images/cycles/high/Quad.JPG";
import Quad2 from "../../images/cycles/high/Quad2.JPG";
import ProgressiveImage from "react-progressive-graceful-image";
import Loading from "../../images/Loading.gif";
import { useTranslation } from "react-i18next";

function CyclePhotos() {
  const cycle = useLocation().pathname.split("/")[2];
  const [slideIndex, setSlideIndex] = useState(1);
  const { t } = useTranslation();
  const translationPage = "pages.cyclePhotos";

  const kindergartenPhotos = [
    { src: Artists, index: 1, alt: "" },
    { src: Artists2, index: 2, alt: "" },
    { src: Artists3, index: 3, alt: "" },
    { src: Artists4, index: 4, alt: "" },
    { src: Artists5, index: 5, alt: "" },
    { src: Baking, index: 6, alt: "" },
    { src: Cooking, index: 7, alt: "" },
    { src: Cooking2, index: 8, alt: "" },
    { src: Cooking3, index: 9, alt: "" },
    { src: Cooking4, index: 10, alt: "" },
    { src: Cooking5, index: 11, alt: "" },
    { src: Dancing, index: 12, alt: "" },
    { src: Eid, index: 13, alt: "" },
    { src: Eid2, index: 13, alt: "" },
    { src: EndOfYearParty, index: 15, alt: "" },
    { src: EndOfYearParty2, index: 16, alt: "" },
    { src: EndOfYearParty3, index: 17, alt: "" },
    { src: Farming, index: 18, alt: "" },
    { src: FirstDayOfSchool, index: 19, alt: "" },
    { src: Painting, index: 20, alt: "" },
    { src: Painting2, index: 21, alt: "" },
    { src: Painting3, index: 22, alt: "" },
    { src: Painting4, index: 23, alt: "" },
    { src: Painting5, index: 24, alt: "" },
    { src: WallClimbing, index: 25, alt: "" },
  ];
  const elementaryPhotos = [
    { src: Agadir, index: 1, alt: "Elementary" },
    { src: Henna2, index: 2, alt: "Henna" },
    { src: Agadir2, index: 3, alt: "" },
    { src: Certificates, index: 4, alt: "" },
    { src: DarIkalimo, index: 5, alt: "" },
    { src: DarIkalimo2, index: 6, alt: "" },
    { src: EndOfYearPartyElementary, index: 7, alt: "" },
    { src: EndOfYearParty2Elementary, index: 8, alt: "" },
    { src: EndOfYearParty3Elementary, index: 9, alt: "" },
    { src: EndOfYearParty4Elementary, index: 10, alt: "" },
    { src: EndOfYearParty5Elementary, index: 11, alt: "" },
    { src: Henna, index: 12, alt: "" },
    { src: IceSkating, index: 13, alt: "" },
    { src: IndependenceDay, index: 14, alt: "" },
    { src: Ourika, index: 15, alt: "" },
    { src: Sushi, index: 16, alt: "" },
    { src: Certificates2, index: 17, alt: "" },
    { src: Bees, index: 18, alt: "" },
    { src: Bees2, index: 19, alt: "" },
    { src: Gymnastics, index: 20, alt: "" },
    { src: BakingElementary, index: 21, alt: "" },
  ];
  const middleSchoolPhotos = [
    { src: AgadirMiddle, index: 1, alt: "" },
    { src: Audience, index: 2, alt: "" },
    { src: Cervantes, index: 3, alt: "" },
    { src: Cervantes2, index: 4, alt: "" },
    { src: Cervantes3, index: 5, alt: "" },
    { src: Chess, index: 6, alt: "" },
    { src: Church, index: 7, alt: "" },
    { src: Church2, index: 8, alt: "" },
    { src: CPR, index: 9, alt: "" },
    { src: EndOfSchoolYearPartyMiddle, index: 10, alt: "" },
    { src: Exam, index: 11, alt: "" },
    { src: Exam2, index: 12, alt: "" },
    { src: Museum, index: 13, alt: "" },
    { src: Museum2, index: 14, alt: "" },
    { src: Museum3, index: 15, alt: "" },
    { src: Paintball, index: 16, alt: "" },
    { src: RedGreenFlags, index: 17, alt: "" },
    { src: SchoolRadio, index: 18, alt: "" },
    { src: Snacks, index: 19, alt: "" },
    { src: Snacks2, index: 20, alt: "" },
    { src: Snacks3, index: 21, alt: "" },
    { src: Snacks4, index: 22, alt: "" },
    { src: Synagogue, index: 23, alt: "" },
    { src: Synagogue2, index: 24, alt: "" },
    { src: Trip, index: 25, alt: "" },
    { src: Global, index: 26, alt: "" },
    { src: Debate, index: 27, alt: "" },
  ];
  const highSchoolPhotos = [
    { src: Amphi, index: 1, alt: "" },
    { src: Amphi2, index: 2, alt: "" },
    { src: Cambridge, index: 3, alt: "" },
    { src: DarTifl, index: 4, alt: "" },
    { src: Graduation, index: 5, alt: "" },
    { src: InClass, index: 6, alt: "" },
    { src: InClass2, index: 7, alt: "" },
    { src: MUN, index: 8, alt: "" },
    { src: MUN2, index: 9, alt: "" },
    { src: Orientation, index: 10, alt: "" },
    { src: Party, index: 11, alt: "" },
    { src: PE, index: 12, alt: "" },
    { src: PE2, index: 13, alt: "" },
    { src: Quad, index: 14, alt: "" },
    { src: Quad2, index: 15, alt: "" },
  ];
  //otherwise, display click on button to select cycle

  useEffect(() => {
    setSlideIndex(1);
  }, [cycle]);

  const openModal = () => {
    document.getElementById("cyclePhotosModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("cyclePhotosModal").style.display = "none";
  };

  const plusSlides = (n) => {
    const newIndex = slideIndex + n;
    if (newIndex > 4) {
      setSlideIndex(1);
    } else if (newIndex < 1) {
      setSlideIndex(4);
    } else {
      setSlideIndex(newIndex);
    }
    showSlides(newIndex);
  };

  const currentSlide = (n) => {
    showSlides(n);
  };

  function showSlides(n) {
    const slides = document.getElementsByClassName("cyclePhotosModalSlides");
    const dots = document.getElementsByClassName("cyclePhotosDemo2");
    const captionText = document.getElementById("cyclePhotosCaption");
    let i;
    let index = n;
    if (n > slides.length) index = 1;
    else if (n < 1) index = slides.length;
    for (i = 0; i < slides.length; i++) slides[i].style.display = "none";
    for (i = 0; i < dots.length; i++)
      dots[i].className = dots[i].className.replace(" active", "");
    slides[index - 1].style.display = "block";
    dots[index - 1].className += " active";
    captionText.innerHTML = dots[index - 1].alt;
    setSlideIndex(index);
  }

  return (
    <div className="cyclePhotosContainer">
      <Link className="links" to={`/${cycle}`}>
        <ReplyIcon className="cyclePhotosGoBackArrow" />
      </Link>
      {cycle === "kindergarten" ? (
        <div>
          <div
            className="cyclePhotosRow"
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                kindergartenPhotos.length / 3
              )}, 400px)`,
            }}
          >
            {kindergartenPhotos.map((photo) => (
              <div className="cyclePhotosColumn">
                <ProgressiveImage src={photo.src} placeholder={Loading}>
                  {(src, loading) => (
                    <img
                      className={loading ? "cycleImageGalleryLoading" : ""}
                      src={src}
                      onClick={() => {
                        openModal();
                        currentSlide(photo.index);
                      }}
                    />
                  )}
                </ProgressiveImage>
              </div>
            ))}
          </div>

          <div id="cyclePhotosModal" className="cyclePhotosModal">
            <span className="cyclePhotosCloseBtn" onClick={closeModal}>
              &times;
            </span>
            <div className="cyclePhotosModalContent">
              {kindergartenPhotos.map((photo) => (
                <div className="cyclePhotosModalSlides">
                  <div className="cyclePhotosNumberTxt">
                    {photo.index} / {kindergartenPhotos.length}
                  </div>
                  <img
                    src={photo.src}
                    className="cyclePhotosDemo2"
                    alt={photo.alt}
                  />
                </div>
              ))}
            </div>

            <a className="cyclePhotosPrevBtn" onClick={() => plusSlides(-1)}>
              &#10094;
            </a>
            <a className="cyclePhotosNextBtn" onClick={() => plusSlides(1)}>
              &#10095;
            </a>

            <div className="cyclePhotosCaptioncontainer">
              <p id="cyclePhotosCaption"></p>
            </div>
          </div>
        </div>
      ) : cycle === "elementary" ? (
        <>
          <div
            className="cyclePhotosRow"
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                elementaryPhotos.length / 3
              )}, 400px)`,
            }}
          >
            {elementaryPhotos.map((photo) => (
              <div className="cyclePhotosColumn">
                <ProgressiveImage src={photo.src} placeholder={Loading}>
                  {(src, loading) => (
                    <img
                      className={loading ? "cycleImageGalleryLoading" : ""}
                      src={src}
                      onClick={() => {
                        openModal();
                        currentSlide(photo.index);
                      }}
                    />
                  )}
                </ProgressiveImage>
              </div>
            ))}
          </div>

          <div id="cyclePhotosModal" className="cyclePhotosModal">
            <span className="cyclePhotosCloseBtn" onClick={closeModal}>
              &times;
            </span>
            <div className="cyclePhotosModalContent">
              {elementaryPhotos.map((photo) => (
                <div className="cyclePhotosModalSlides">
                  <div className="cyclePhotosNumberTxt">
                    {photo.index} / {elementaryPhotos.length}
                  </div>
                  <img
                    src={photo.src}
                    className="cyclePhotosDemo2"
                    alt={photo.alt}
                  />
                </div>
              ))}
            </div>

            <a className="cyclePhotosPrevBtn" onClick={() => plusSlides(-1)}>
              &#10094;
            </a>
            <a className="cyclePhotosNextBtn" onClick={() => plusSlides(1)}>
              &#10095;
            </a>

            <div className="cyclePhotosCaptioncontainer">
              <p id="cyclePhotosCaption"></p>
            </div>
          </div>
        </>
      ) : cycle === "middleSchool" ? (
        <>
          <div
            className="cyclePhotosRow"
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                middleSchoolPhotos.length / 3
              )}, 400px)`,
            }}
          >
            {middleSchoolPhotos.map((photo) => (
              <div className="cyclePhotosColumn">
                <ProgressiveImage src={photo.src} placeholder={Loading}>
                  {(src, loading) => (
                    <img
                      className={loading ? "cycleImageGalleryLoading" : ""}
                      src={src}
                      onClick={() => {
                        openModal();
                        currentSlide(photo.index);
                      }}
                    />
                  )}
                </ProgressiveImage>
              </div>
            ))}
          </div>

          <div id="cyclePhotosModal" className="cyclePhotosModal">
            <span className="cyclePhotosCloseBtn" onClick={closeModal}>
              &times;
            </span>
            <div className="cyclePhotosModalContent">
              {middleSchoolPhotos.map((photo) => (
                <div className="cyclePhotosModalSlides">
                  <div className="cyclePhotosNumberTxt">
                    {photo.index} / {middleSchoolPhotos.length}
                  </div>
                  <img
                    src={photo.src}
                    className="cyclePhotosDemo2"
                    alt={photo.alt}
                  />
                </div>
              ))}
            </div>

            <a className="cyclePhotosPrevBtn" onClick={() => plusSlides(-1)}>
              &#10094;
            </a>
            <a className="cyclePhotosNextBtn" onClick={() => plusSlides(1)}>
              &#10095;
            </a>

            <div className="cyclePhotosCaptioncontainer">
              <p id="cyclePhotosCaption"></p>
            </div>
          </div>
        </>
      ) : cycle === "highSchool" ? (
        <>
          <div
            className="cyclePhotosRow"
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                highSchoolPhotos.length / 3
              )}, 400px)`,
            }}
          >
            {highSchoolPhotos.map((photo) => (
              <div className="cyclePhotosColumn">
                <ProgressiveImage src={photo.src} placeholder={Loading}>
                  {(src, loading) => (
                    <img
                      className={loading ? "cycleImageGalleryLoading" : ""}
                      src={src}
                      onClick={() => {
                        openModal();
                        currentSlide(photo.index);
                      }}
                    />
                  )}
                </ProgressiveImage>
              </div>
            ))}
          </div>
          <div id="cyclePhotosModal" className="cyclePhotosModal">
            <span className="cyclePhotosCloseBtn" onClick={closeModal}>
              &times;
            </span>
            <div className="cyclePhotosModalContent">
              {highSchoolPhotos.map((photo) => (
                <div className="cyclePhotosModalSlides">
                  <div className="cyclePhotosNumberTxt">
                    {photo.index} / {highSchoolPhotos.length}
                  </div>
                  <img
                    src={photo.src}
                    className="cyclePhotosDemo2"
                    alt={photo.alt}
                  />
                </div>
              ))}
            </div>
            <a className="cyclePhotosPrevBtn" onClick={() => plusSlides(-1)}>
              &#10094;
            </a>
            <a className="cyclePhotosNextBtn" onClick={() => plusSlides(1)}>
              &#10095;
            </a>
            <div className="cyclePhotosCaptioncontainer">
              <p id="cyclePhotosCaption"></p>
            </div>
          </div>
        </>
      ) : (
        <div className="cyclePhotosRedirectContainer">
          <p>{t(`${translationPage}.Please select a cycle`)}</p>
          <div>
            <Link className="links" to="/photos/kindergarten">
              <span
                style={{ backgroundColor: "#ef711d" }}
                className="cyclePhotosRedirectBtn"
              >
                {t(`${translationPage}.Kindergarten`)}
              </span>
            </Link>
            <Link className="links" to="/photos/kindergarten">
              <span
                style={{ backgroundColor: "#4a154d" }}
                className="cyclePhotosRedirectBtn"
              >
                {t(`${translationPage}.Elementary`)}
              </span>
            </Link>
            <Link className="links" to="/photos/middleSchool">
              <span
                style={{ backgroundColor: "#9fdd3a" }}
                className="cyclePhotosRedirectBtn"
              >
                {t(`${translationPage}.Middle School`)}
              </span>
            </Link>
            <Link className="links" to="/photos/highSchool">
              <span
                style={{ backgroundColor: "lightgray" }}
                className="cyclePhotosRedirectBtn"
              >
                {t(`${translationPage}.High Shcool`)}
              </span>
            </Link>
          </div>
        </div>
      )}
      <div className="cyclePhotosSocialIcons">
        <p>{t(`${translationPage}.View More on Our Social Media`)}</p>
        <span>
          <a href="https://fr-fr.facebook.com/hilali.targa/" target="_blank">
            <FacebookIcon
              className="cyclePhotosSocialIcon"
              style={{ fontSize: "5vh", color: "#4267b2", cursor: "pointer" }}
            />
          </a>
          <a href="https://www.instagram.com/htis_official/" target="_blank">
            <InstagramIcon
              className="cyclePhotosSocialIcon"
              style={{ fontSize: "5vh", color: "#bc318f", cursor: "pointer" }}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCQs4zHrM9OTza-mgX99XO1Q"
            target="_blank"
          >
            <YouTubeIcon
              className="cyclePhotosSocialIcon"
              style={{ fontSize: "5vh", color: "#ff0000", cursor: "pointer" }}
            />
          </a>
        </span>
      </div>
    </div>
  );
}

export default CyclePhotos;
