import "./hiring.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Applications from "../../components/applications/Applications";
import NewApplication from "../../components/newApplication/NewApplication";
import CurrentHiringNeeds from "../../components/currentHiringNeeds/CurrentHiringNeeds";
import Meetings from "../../components/meetings/Meetings";
import Calls from "../../components/calls/Calls";
import CandidatesTests from "../../components/candidatesTests/CandidatesTests";
import AccessDenied from "../../components/accessDenied/AccessDenied";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const Hiring = () => {
  const { user } = useContext(AuthContext);
  const [value, setValue] = useState("1");
  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();
  const translationPage = "pages.hiring";

  return (
    <div className="hiringContainer">
      <Link className="links" to="/user" style={{ padding: "20px" }}>
        <ArrowBackIosIcon />
        {t(`${translationPage}.Go Back`)}
      </Link>
      {user?.role === "hr" || user?.role === "admin" ? (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext className="hiringTabContext" value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "fit-content",
                margin: "auto",
              }}
            >
              <TabList className="hiringTabList" onChange={handleTabChange}>
                <Tab
                  className="hiringTab"
                  label="View All Applications"
                  value="1"
                />
                <Tab
                  className="hiringTab"
                  label="Add New Application"
                  value="2"
                />
                <Tab className="hiringTab" label="Current Needs" value="3" />
                <Tab className="hiringTab" label="RDVs" value="4" />
                <Tab className="callsTab" label="Calls" value="5" />
                <Tab className="candidatesTestsTab" label="Tests" value="6" />
              </TabList>
            </Box>
            <TabPanel className="hiringViewAppsWrapper" value="1">
              <Applications />
            </TabPanel>
            <TabPanel className="hiringAddAppWrapper" value="2">
              <NewApplication />
            </TabPanel>
            <TabPanel className="hiringNeedsWrapper" value="3">
              <CurrentHiringNeeds />
            </TabPanel>
            <TabPanel className="rdvsWrapper" value="4">
              <Meetings />
            </TabPanel>
            <TabPanel className="callsWrapper" value="5">
              <Calls />
            </TabPanel>
            <TabPanel className="candidatesTestsWrapper" value="6">
              <CandidatesTests />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <AccessDenied fontColor="black" />
      )}
    </div>
  );
};

export default Hiring;
