import "./suppliesLists.scss";
//
//import PreKSuppliesListPhoto from "../../images/suppliesLists/kindergarten/PRE-K FOURNITURE MATERNELLE 2023 2024.jpg";
import PreKSuppliesListPhoto from "../../images/suppliesLists/kindergarten/PS.jpg";
//import JuniorKSuppliesListPhoto from "../../images/suppliesLists/kindergarten/JUNIOR-K FOURNITURE MATERNELLE 2023 2024.jpg";
import JuniorKSuppliesListPhoto from "../../images/suppliesLists/kindergarten/MS.jpg";
//import SeniorKSuppliesListPhoto from "../../images/suppliesLists/kindergarten/SENIOR K FOURNITURE MATERNELLE 2023 2024.jpg";
import SeniorKSuppliesListPhoto from "../../images/suppliesLists/kindergarten/GS.jpg";
//
//import FirstYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/Fournitures 1.A.P 2023-2024-0001.jpg";
import FirstYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/1AP-1.jpg";
//import FirstYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/Fournitures 1.A.P 2023-2024-0002.jpg";
import FirstYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/1AP-2.jpg";
//import SecondYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/Fournitures 2AP 2023 2024-0001.jpg";
import SecondYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/2AP-1.jpg";
//import SecondYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/Fournitures 2AP 2023 2024-0002.jpg";
import SecondYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/2AP-2.jpg";
//import ThirdYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/FOURNITURE 3AP 2023 2024.docx_page-0001.jpg";
import ThirdYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/3AP-1.jpg";
//import ThirdYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/FOURNITURE 3AP 2023 2024.docx_page-0002.jpg";
import ThirdYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/3AP-2.jpg";
//import FourthYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/FOURNITURE 4.A.P 2023 2024.docx_page-0001.jpg";
import FourthYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/4AP-1.jpg";
//import FourthYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/FOURNITURE 4.A.P 2023 2024.docx_page-0002.jpg";
import FourthYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/4AP-2.jpg";
//import FifthYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/fournitures 5AP 2023 2024.docx_page-0001.jpg";
import FifthYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/5AP-1.jpg";
//import FifthYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/fournitures 5AP 2023 2024.docx_page-0002.jpg";
import FifthYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/5AP-2.jpg";
//import SixthYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/FOURNITURE 6.A.P 2023 2024.docx_page-0001.jpg";
import SixthYearSuppliesListPhoto1 from "../../images/suppliesLists/elementary/6AP-1.jpg";
//import SixthYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/FOURNITURE 6.A.P 2023 2024.docx_page-0002.jpg";
import SixthYearSuppliesListPhoto2 from "../../images/suppliesLists/elementary/6AP-2.jpg";
//
//import FirstOfMiddleSuppliesListPhoto1 from "../../images/suppliesLists/middle/Fournitures 1AC.docx_page-0001.jpg";
import FirstOfMiddleSuppliesListPhoto1 from "../../images/suppliesLists/middle/1AC-1.jpg";
//import FirstOfMiddleSuppliesListPhoto2 from "../../images/suppliesLists/middle/Fournitures 1AC.docx_page-0002.jpg";
import FirstOfMiddleSuppliesListPhoto2 from "../../images/suppliesLists/middle/1AC-2.jpg";
//import SecondOfMiddleSuppliesListPhoto1 from "../../images/suppliesLists/middle/fournitures 2.A.C..doc_page-0001.jpg";
import SecondOfMiddleSuppliesListPhoto1 from "../../images/suppliesLists/middle/2AC-1.jpg";
//import SecondOfMiddleSuppliesListPhoto2 from "../../images/suppliesLists/middle/fournitures 2.A.C..doc_page-0002.jpg";
import SecondOfMiddleSuppliesListPhoto2 from "../../images/suppliesLists/middle/2AC-2.jpg";
//import ThirdOfMiddleSuppliesListPhoto1 from "../../images/suppliesLists/middle/fournitures 3.A.C. (1).docx_page-0001.jpg";
import ThirdOfMiddleSuppliesListPhoto1 from "../../images/suppliesLists/middle/3AC-1.jpg";
//import ThirdOfMiddleSuppliesListPhoto2 from "../../images/suppliesLists/middle/fournitures 3.A.C. (1).docx_page-0002.jpg";
import ThirdOfMiddleSuppliesListPhoto2 from "../../images/suppliesLists/middle/3AC-2.jpg";
//
//import CCSuppliesListPhoto1 from "../../images/suppliesLists/high/fournitures tc.docx_page-0001.jpg";
import CCSuppliesListPhoto1 from "../../images/suppliesLists/high/TC-1.jpg";
//import CCSuppliesListPhoto2 from "../../images/suppliesLists/high/fournitures tc.docx_page-0002.jpg";
import CCSuppliesListPhoto2 from "../../images/suppliesLists/high/TC-2.jpg";
//import FirstHighExSuppliesListPhoto1 from "../../images/suppliesLists/high/fournitures 1.A.B..docx_page-0001.jpg";
import FirstHighExSuppliesListPhoto1 from "../../images/suppliesLists/high/1AB-1.jpg";
//import FirstHighExSuppliesListPhoto2 from "../../images/suppliesLists/high/fournitures 1.A.B..docx_page-0002.jpg";
import FirstHighExSuppliesListPhoto2 from "../../images/suppliesLists/high/1AB-2.jpg";
//import FirstHighMathSuppliesListPhoto1 from "../../images/suppliesLists/high/1A.B. MATH SCHOOL SUPPLIES.docx_page-0001.jpg";
import FirstHighMathSuppliesListPhoto1 from "../../images/suppliesLists/high/1AB-sciences-maths.jpg";
import FirstHighMathSuppliesListPhoto2 from "../../images/suppliesLists/high/1A.B. MATH SCHOOL SUPPLIES.docx_page-0002.jpg"; //Missing for 24-25
//import SecondHighPCSuppliesListPhoto1 from "../../images/suppliesLists/high/P.C 2AB.docx_page-0001.jpg";
import SecondHighPCSuppliesListPhoto1 from "../../images/suppliesLists/high/P.C2AB-1.jpg";
//import SecondHighPCSuppliesListPhoto2 from "../../images/suppliesLists/high/P.C 2AB.docx_page-0002.jpg";
import SecondHighPCSuppliesListPhoto2 from "../../images/suppliesLists/high/P.C2AB-2.jpg";
//import SecondHighMathSuppliesListPhoto1 from "../../images/suppliesLists/high/MATH 2AB.docx_page-0001.jpg";
import SecondHighMathSuppliesListPhoto1 from "../../images/suppliesLists/high/Maths2AB-1.jpg";
//import SecondHighMathSuppliesListPhoto2 from "../../images/suppliesLists/high/MATH 2AB.docx_page-0002.jpg";
import SecondHighMathSuppliesListPhoto2 from "../../images/suppliesLists/high/Maths2AB-2.jpg";
//
import ReplyIcon from "@mui/icons-material/Reply";
import { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import { useTranslation } from "react-i18next";

const SuppliesLists = () => {
  const { t } = useTranslation();
  const translationPage = "pages.suppliesList";
  //KINDERGARTEN
  const [showPS, setShowPS] = useState(false);
  const [showMS, setShowMS] = useState(false);
  const [showGS, setShowGS] = useState(false);
  //ELEMENTARY
  const [showCP, setShowCP] = useState(false);
  const [showCE1, setShowCE1] = useState(false);
  const [showCE2, setShowCE2] = useState(false);
  const [showCM1, setShowCM1] = useState(false);
  const [showCM2, setShowCM2] = useState(false);
  const [show6AP, setShow6AP] = useState(false);
  //MIDDLE
  const [showGrade7, setShowGrade7] = useState(false);
  const [showGrade8, setShowGrade8] = useState(false);
  const [showGrade9, setShowGrade9] = useState(false);
  //HIGH
  const [showCC, setShowCC] = useState(false);
  const [show1HSMath, setShow1HSMath] = useState(false);
  const [show1HSScience, setShow1HSScience] = useState(false);
  const [show2HSMath, setShow2HSMath] = useState(false);
  const [show2HSPhysics, setShow2HSPhysics] = useState(false);

  //KG
  if (showPS)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowPS(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.listPreK`)}
        </p>
        <img
          className="suppliesListImg"
          src={PreKSuppliesListPhoto}
          alt="Supplies List Pre-k"
        />
      </div>
    );

  if (showMS)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowMS(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.listKG`)}
        </p>
        <img
          className="suppliesListImg"
          src={JuniorKSuppliesListPhoto}
          alt="Supplies List Kindergarten"
        />
      </div>
    );

  if (showGS)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowGS(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.listSeniorK`)}
        </p>
        <img
          className="suppliesListImg"
          src={SeniorKSuppliesListPhoto}
          alt="Supplies List Senior Kindergarten"
        />
      </div>
    );

  //Elementary
  if (showCP)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowCP(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.primaryFirst`)}
        </p>
        <img
          className="suppliesListImg"
          src={FirstYearSuppliesListPhoto1}
          alt="Supplies List CP"
        />
        <img
          className="suppliesListImg"
          src={FirstYearSuppliesListPhoto2}
          alt="Supplies List CP"
        />
      </div>
    );

  if (showCE1)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowCE1(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.primarySecond`)}
        </p>
        <img
          className="suppliesListImg"
          src={SecondYearSuppliesListPhoto1}
          alt="Supplies List CE1"
        />
        <img
          className="suppliesListImg"
          src={SecondYearSuppliesListPhoto2}
          alt="Supplies List CE1"
        />
      </div>
    );

  if (showCE2)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowCE2(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.primaryThird`)}
        </p>
        <img
          className="suppliesListImg"
          src={ThirdYearSuppliesListPhoto1}
          alt="Supplies List CE2"
        />
        <img
          className="suppliesListImg"
          src={ThirdYearSuppliesListPhoto2}
          alt="Supplies List CE2"
        />
      </div>
    );
  if (showCM1)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowCM1(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.primaryFourth`)}
        </p>
        <img
          className="suppliesListImg"
          src={FourthYearSuppliesListPhoto1}
          alt="Supplies List CM1"
        />
        <img
          className="suppliesListImg"
          src={FourthYearSuppliesListPhoto2}
          alt="Supplies List CM1"
        />
      </div>
    );

  if (showCM2)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowCM2(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.primaryFifth`)}
        </p>
        <img
          className="suppliesListImg"
          src={FifthYearSuppliesListPhoto1}
          alt="Supplies List CM2"
        />
        <img
          className="suppliesListImg"
          src={FifthYearSuppliesListPhoto2}
          alt="Supplies List CM2"
        />
      </div>
    );

  if (show6AP)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShow6AP(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.primarySixth`)}
        </p>
        <img
          className="suppliesListImg"
          src={SixthYearSuppliesListPhoto1}
          alt="Supplies List 6AP"
        />
        <img
          className="suppliesListImg"
          src={SixthYearSuppliesListPhoto2}
          alt="Supplies List 6AP"
        />
      </div>
    );

  //Middle
  if (showGrade7)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowGrade7(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.middleFirst`)}
        </p>
        <img
          className="suppliesListImg"
          src={FirstOfMiddleSuppliesListPhoto1}
          alt="Supplies List Grade 7"
        />
        <img
          className="suppliesListImg"
          src={FirstOfMiddleSuppliesListPhoto2}
          alt="Supplies List Grade 7"
        />
      </div>
    );

  if (showGrade8)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowGrade8(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.middleSecond`)}
        </p>
        <img
          className="suppliesListImg"
          src={SecondOfMiddleSuppliesListPhoto1}
          alt="Supplies List Grade 8"
        />
        <img
          className="suppliesListImg"
          src={SecondOfMiddleSuppliesListPhoto2}
          alt="Supplies List Grade 8"
        />
      </div>
    );

  if (showGrade9)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowGrade9(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.middleThird`)}
        </p>
        <img
          className="suppliesListImg"
          src={ThirdOfMiddleSuppliesListPhoto1}
          alt="Supplies List Grade 9"
        />
        <img
          className="suppliesListImg"
          src={ThirdOfMiddleSuppliesListPhoto2}
          alt="Supplies List Grade 9"
        />
      </div>
    );

  //High
  if (showCC)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShowCC(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.listCC`)}
        </p>
        <img
          className="suppliesListImg"
          src={CCSuppliesListPhoto1}
          alt="Supplies List CC"
        />
        <img
          className="suppliesListImg"
          src={CCSuppliesListPhoto2}
          alt="Supplies List CC"
        />
      </div>
    );

  if (show1HSMath)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShow1HSMath(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.highFirst`)}
        </p>
        <img
          className="suppliesListImg"
          src={FirstHighMathSuppliesListPhoto1}
          alt="Supplies List 1Y HS Math"
        />
        <img
          className="suppliesListImg"
          src={FirstHighMathSuppliesListPhoto2}
          alt="Supplies List 1Y HS Math"
        />
      </div>
    );

  if (show1HSScience)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShow1HSScience(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.highFirstScience`)}
        </p>
        <img
          className="suppliesListImg"
          src={FirstHighExSuppliesListPhoto1}
          alt="Supplies List 1Y HS Science"
        />
        <img
          className="suppliesListImg"
          src={FirstHighExSuppliesListPhoto2}
          alt="Supplies List 1Y HS Science"
        />
      </div>
    );

  if (show2HSMath)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShow2HSMath(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.highSecondMath`)}
        </p>
        <img
          className="suppliesListImg"
          src={SecondHighMathSuppliesListPhoto1}
          alt="Supplies List 2Y HS Math"
        />
        <img
          className="suppliesListImg"
          src={SecondHighMathSuppliesListPhoto2}
          alt="Supplies List 2Y HS Math"
        />
      </div>
    );

  if (show2HSPhysics)
    return (
      <div className="suppliesListShowSingle">
        <ReplyIcon
          onClick={() => setShow2HSPhysics(false)}
          className="cyclePhotosGoBackArrow"
        />
        <p className="suppliesListShowSingleHeader">
          {t(`${translationPage}.highSecondPhysics`)}
        </p>
        <img
          className="suppliesListImg"
          src={SecondHighPCSuppliesListPhoto1}
          alt="Supplies List 2Y HS Physics"
        />
        <img
          className="suppliesListImg"
          src={SecondHighPCSuppliesListPhoto2}
          alt="Supplies List 2Y HS Physics"
        />
      </div>
    );

  return (
    <>
      <div className="suppliesListContainer">
        <span className="suppliesListTextsWrapper">
          <h1>{t(`${translationPage}.Supplies Lists`)}</h1>
        </span>
        <span className="suppliesListsCycle">
          {t(`${translationPage}.Kindergarten`)}
        </span>
        <ul className="suppliesListsUL">
          <span onClick={() => setShowPS(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Pre-K / Nursery`)}</li>
          </span>
          <span onClick={() => setShowMS(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Kindergarten / Junior K`)}</li>
          </span>
          <span onClick={() => setShowGS(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Preparatory Year / Senior K`)}</li>
          </span>
        </ul>
        <span className="suppliesListsCycle">
          {t(`${translationPage}.Elementary`)}
        </span>
        <ul className="suppliesListsUL">
          <span onClick={() => setShowCP(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.First Year / CP`)}</li>
          </span>
          <span onClick={() => setShowCE1(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Second Year / CE1`)}</li>
          </span>
          <span onClick={() => setShowCE2(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Third Year / CE2`)}</li>
          </span>
          <span onClick={() => setShowCM1(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Fourth Year / CM1`)}</li>
          </span>
          <span onClick={() => setShowCM2(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Fifth Year / CM2`)}</li>
          </span>
          <span onClick={() => setShow6AP(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Sixth Year / 6AP`)}</li>
          </span>
        </ul>
        <span className="suppliesListsCycle">
          {t(`${translationPage}.Middle School`)}
        </span>
        <ul className="suppliesListsUL">
          <span onClick={() => setShowGrade7(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.First Year / Grade 7`)}</li>
          </span>
          <span onClick={() => setShowGrade8(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Second Year / Grade 8`)}</li>
          </span>
          <span onClick={() => setShowGrade9(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Third Year / Grade 9`)}</li>
          </span>
        </ul>
        <span className="suppliesListsCycle">
          {t(`${translationPage}.High School`)}
        </span>
        <ul className="suppliesListsUL">
          <span onClick={() => setShowCC(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.Common Core`)}</li>
          </span>
          <span onClick={() => setShow1HSScience(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.firstYearScience`)}</li>
          </span>
          <span onClick={() => setShow1HSMath(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.firstYearMath`)}</li>
          </span>
          <span onClick={() => setShow2HSPhysics(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li>{t(`${translationPage}.secondYearPhysics`)}</li>
          </span>
          <span onClick={() => setShow2HSMath(true)}>
            <CreateIcon className="suppliesListULIcon" />
            <li> {t(`${translationPage}.secondYearMath`)}</li>
          </span>
        </ul>
      </div>
    </>
  );
};

export default SuppliesLists;
