import "./home.scss";
import Popup from "../../components/popup/Popup";
import { useEffect, useState, useRef } from "react";
import Carousel from "../../components/carousel/Carousel";
import { SliderData } from "../../utils/carouselImages";
import Inquire from "../../images/Inquire2.jpg";
import Visit from "../../images/Visit.jpg";
import Register from "../../images/Register.jpg";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PublicIcon from "@mui/icons-material/Public";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Testimonials from "../../components/testimonials/Testimonials";
import DELFDALFLogo from "../../images/DELFDALFLogo.png";
import DELELogo from "../../images/DELELogo.jpeg";
import RedCrossLogo from "../../images/RedCrossLogo.png";
import CambridgeLogo from "../../images/CambridgeLogo.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";
import { PopupState } from "../../context/Popup";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import Logo from "../../images/Logo.png";
import { constants } from "../../utils/constants";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const Home = () => {
  const {
    popupActive,
    popupTitle,
    popupBody,
    popupImage,
    popupBtn,
    popupLink,
    popupOpen,
    setPopupOpen,
    popupIsCountdown,
  } = PopupState();
  const LIMIT = 3;
  const hostAndBackendPort = constants.hostAndBackendPort;
  const eventsPF = `${hostAndBackendPort}images/calendarEvents/`;
  const [eventImgHeight, setEventImgHeight] = useState(300);
  const descRef = useRef(null);
  const { t } = useTranslation();
  const translationPage = "pages.home";
  const [noOfEventsToDisplay, setNoOfEventsToDisplay] = useState(3);

  // Fetch upcoming events
  const { data: upcomingData, loading: upcomingLoading } = useFetch(
    `calendarEvents/upcoming?limit=${LIMIT}`
  );
  const upcomingEvents = upcomingData?.events;

  // Fetch past events
  const { data: pastData, loading: pastLoading } = useFetch(
    `calendarEvents/past?limit=${LIMIT}`
  );
  const pastEvents = pastData?.events;

  const latestNews = upcomingEvents?.length > 0 ? upcomingEvents : pastEvents;
  const eventsType = upcomingEvents?.length > 0 ? "future" : "past";
  const loading = upcomingLoading || pastLoading;

  useEffect(() => {
    if (descRef.current) {
      const descHeight = descRef.current.clientHeight;
      setEventImgHeight(300 - descHeight);
    }
  }, [descRef]);

  useEffect(() => {
    if (window.innerWidth < 700) setNoOfEventsToDisplay(1);
    else if (window.innerWidth < 900) setNoOfEventsToDisplay(2);
    else setNoOfEventsToDisplay(3);
  }, []);

  return (
    <div
      className={
        latestNews?.length > 0
          ? "homeContainerWithEvents homeContainer"
          : "homeContainerWithNoEvents homeContainer"
      }
    >
      <Carousel slides={SliderData} />
      <div className="homeHTISNumbers">
        <h1 className="homeHTISNumbersHeader">
          {t(`${translationPage}.HTIS By the Numbers`)}
        </h1>
        <div className="homeHTISNumbersIconsWrapper">
          <span>
            <SchoolIcon className="homeHTISNumbersIcon" />
            <p className="homeHTISNumber">{t(`${translationPage}.1 to 6`)}</p>
            <p className="homeHTISNumberInfo">
              {t(`${translationPage}.Faculty / Student Ratio`)}
            </p>
          </span>
          <span>
            <AccountBalanceIcon className="homeHTISNumbersIcon" />
            <p className="homeHTISNumber">11+</p>
            <p className="homeHTISNumberInfo">
              {t(`${translationPage}.Student Clubs & Organizations`)}
            </p>
          </span>
          <span>
            <PublicIcon className="homeHTISNumbersIcon" />
            <p className="homeHTISNumber">60%+</p>
            <p className="homeHTISNumberInfo">
              {t(`${translationPage}.English-speaking Faculty`)}
            </p>
          </span>
          <span>
            <WorkspacePremiumIcon className="homeHTISNumbersIcon" />
            <p className="homeHTISNumber">80%</p>
            <p className="homeHTISNumberInfo">
              {t(`${translationPage}.Teachers with Advanced Degrees`)}
            </p>
          </span>
        </div>
      </div>
      <div className="homeThreeCircles">
        <Link className="links" to="/contact">
          <span
            className="homeCircleInquire"
            style={{
              background: `url(${Inquire})`,
            }}
          >
            <p className="homeCircleText hover-underline-animation hover-animation-grape">
              {t(`${translationPage}.INQUIRE`)}
            </p>
          </span>
        </Link>
        <Link className="links" to="/virtualTour">
          <span
            className="homeCircle"
            style={{
              background: `url(${Visit})`,
            }}
          >
            <p className="homeCircleText hover-underline-animation hover-animation-grape">
              {t(`${translationPage}.VISIT`)}
            </p>
          </span>
        </Link>
        <a
          className="links"
          target="_blank"
          rel="noreferrer"
          href="https://www.koolskools.com/unconnected/school_client_inscription/gAAAAABj5Ux6o2m6855CBseXwLjxOT9UVWv-Z2QeyXq2qas_54MujAdd0WE4SFkkEQx8C8kWxASFLmVwM5IHGtaTB-Jow29BQw=="
        >
          <span
            className="homeCircle"
            style={{
              background: `url(${Register})`,
            }}
          >
            <p className="homeCircleText hover-underline-animation hover-animation-grape">
              {t(`${translationPage}.REGISTER`)}
            </p>
          </span>
        </a>
      </div>
      {loading ? (
        <ClipLoader
          className="homeLatestNewsLoader"
          color="#4a154d"
          size={100}
        />
      ) : (
        latestNews?.length > 0 && (
          <div className="homeLatestNews">
            <h1 className="homeLatestNewsHeader">
              {eventsType === "future"
                ? "Upcoming Events"
                : "Latest News & Events"}
            </h1>
            <div className="homeLatestNewsWrapper">
              {latestNews.length > 0 &&
                latestNews?.slice(0, noOfEventsToDisplay).map((event) => (
                  <Link
                    className="homeNewsCard links"
                    to={event.link || "/calendar"}
                  >
                    {event?.photo ? (
                      <img
                        className="homeNewsCardImg"
                        style={{ height: `${eventImgHeight}px` }}
                        src={eventsPF + event.photo}
                        alt="Event"
                      />
                    ) : (
                      <div className="homeNewsCardDefaultImg">
                        <img
                          className="homeNewsCardDefaultImgLogo"
                          src={Logo}
                          alt="HTIS logo"
                        />
                      </div>
                    )}
                    <div className="homeNewsCardTexts">
                      <div className="homeNewsCardTextsTitle">
                        {event.title}
                      </div>
                      <span className="homeNewsCardDateInline">
                        <CalendarMonthIcon style={{ color: "#4a154d" }} />
                        <p>
                          {moment(event.start).utc().format("LL")}{" "}
                          {event.link ? <AttachFileIcon /> : ""}
                        </p>
                      </span>
                      {event.desc && (
                        <div ref={descRef} className="homeNewsCardDesc">
                          {event.desc}
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
            </div>
            <Link className="links" to="/agenda">
              <button className="homeLatestNewsBtn">
                {t(`${translationPage}.View More`)}
              </button>
            </Link>
          </div>
        )
      )}
      <Testimonials />
      <div className="homeCertifications">
        <img
          className="homeCertificationsLogo"
          src={CambridgeLogo}
          alt="Cambridge Logo"
        />
        <img
          className="homeCertificationsLogo"
          src={DELFDALFLogo}
          alt="DELF DALF Logo"
        />
        <img
          className="homeCertificationsLogo"
          src={DELELogo}
          alt="DELE Logo"
        />
        <img
          className="homeCertificationsLogo"
          src={RedCrossLogo}
          alt="Red Cross Logo"
        />
      </div>
      {popupOpen && popupActive && (
        <Popup
          state={{
            popupTitle,
            popupBody,
            popupImage,
            popupBtn,
            popupLink,
            setPopupOpen,
            popupIsCountdown,
          }}
        />
      )}
    </div>
  );
};

export default Home;
