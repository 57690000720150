import "./testimonials.scss";
import { useTranslation } from "react-i18next";
import { testimonials } from "../../utils/texts";

const Testimonials = () => {
  const { t, i18n } = useTranslation();
  const translationComponent = "testimonials";

  const getTranslatedText = (testimonial) => {
    const currentLanguage = i18n.language;
    return testimonials[testimonial][currentLanguage];
  };

  return (
    <section className="testimonial text-center">
      <h1 className="testimonialHeader">
        {t(`${translationComponent}.Testimonials`)}
      </h1>
      <div
        id="testimonial4"
        className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
        data-ride="carousel"
        data-pause="hover"
        data-interval="5000"
        data-duration="2000"
      >
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item">
            <div className="testimonial4_slide">
              <p>{getTranslatedText("1")}</p>
              <h4>Lola D.</h4>
            </div>
          </div>
          <div className="carousel-item active">
            <div className="testimonial4_slide">
              <p>{getTranslatedText("2")}</p>
              <h4>Tania R.</h4>
            </div>
          </div>
          <div className="carousel-item">
            <div className="testimonial4_slide">
              <p>{getTranslatedText("3")}</p>
              <h4>Zineb L.</h4>
            </div>
          </div>
          <div className="carousel-item">
            <div className="testimonial4_slide">
              <p>{getTranslatedText("4")}</p>
              <h4>Fatima Zohra E.</h4>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#testimonial4"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a
          className="carousel-control-next"
          href="#testimonial4"
          data-slide="next"
        >
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
    </section>
  );
};

export default Testimonials;
