import "./club.scss";
import Clubs from "../../images/Clubs.jpeg";
import { useLocation } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import { clubTexts } from "../../utils/texts";

const Club = () => {
  const club = useLocation().pathname.split("/")[2];
  const clubDisplay =
    club === "tigersInteract"
      ? "Tigers Interact Club"
      : club === "tigersSocial"
      ? "Tigers Social Club"
      : club === "tigersSports"
      ? "Tigers Sports Club"
      : club === "tigersReading"
      ? "Tigers Reading Club"
      : club === "tigersVirtualExchange"
      ? "Tigers Virtual Exchange Club"
      : club === "tigersMedia"
      ? "Tigers Media Club"
      : club === "tigersCooking"
      ? "Tigers Cooking Club"
      : club === "tigersPublicSpeaking"
      ? "Tigers Public Speaking Club"
      : club === "tigersRadio"
      ? "Tigers School Radio Club"
      : club === "tigersChess"
      ? "Tigers Chess Club"
      : club === "tigersTheater"
      ? "Tigers Theater Club"
      : "";
  const { t, i18n } = useTranslation();
  const translationPage = "pages.club";

  const getTranslatedText = (clubName) => {
    const currentLanguage = i18n.language;
    return clubTexts[clubName][currentLanguage];
  };

  return (
    <div className="goBackArrowAndClubContainerWrapper">
      <Link className="links" to={`/clubs`}>
        <ReplyIcon className="cycleGoBackArrow" />
      </Link>
      <div className="clubContainer">
        <h1>{clubDisplay}</h1>
        {club === "tigersInteract" ? (
          <>
            <p>{getTranslatedText("tigersInteract")}</p>
            <span className="clubSocialMediaIcons">
              <a
                className="links clubSocialMediaIcon clubIGIcon"
                href="https://www.instagram.com/interacthilalitarga/"
                target="_blank"
              >
                <InstagramIcon style={{ fontSize: "5vh" }} />
              </a>
              <a
                className="links clubSocialMediaIcon clubTwitterIcon"
                href="https://twitter.com/InteractHTIS"
                target="_blank"
              >
                <TwitterIcon style={{ fontSize: "5vh" }} />
              </a>
              <a
                className="links clubSocialMediaIcon clubFBIcon"
                href="https://www.facebook.com/Interacthilalitarga/"
                target="_blank"
              >
                <FacebookIcon style={{ fontSize: "5vh" }} />
              </a>
              <a
                className="links clubSocialMediaIcon clubYTIcon"
                href="https://www.youtube.com/watch?v=BWWb6xySAME&list=PLtR-4Oz-WiEjzfnvaGKXuHwGxBVPjXb6r"
                target="_blank"
              >
                <YouTubeIcon style={{ fontSize: "5vh" }} />
              </a>
            </span>
          </>
        ) : club === "tigersSocial" ? (
          <>
            <p>{getTranslatedText("tigersSocial")}</p>
          </>
        ) : club === "tigersSports" ? (
          <>
            <p>{getTranslatedText("tigersSports")}</p>
            <a
              className="links clubSocialMediaIcon clubFBIcon clubSocialMediaIconSingle"
              href="https://www.facebook.com/profile.php?id=100048821537018"
              target="_blank"
            >
              <FacebookIcon style={{ fontSize: "5vh" }} />
            </a>
          </>
        ) : club === "tigersReading" ? (
          <>
            <p>{getTranslatedText("tigersReading")}</p>
          </>
        ) : club === "tigersVirtualExchange" ? (
          <>
            <p>{getTranslatedText("tigersVirtualExchange")}</p>
            <span className="clubSocialMediaIcons">
              <a
                className="links clubSocialMediaIcon"
                href="https://virtualexchange.ma/hilali-targa-international-high-school/"
                target="_blank"
              >
                <LanguageIcon style={{ fontSize: "5vh" }} />
              </a>
              <a
                className="links clubSocialMediaIcon clubYTIcon"
                href="https://www.youtube.com/watch?v=KHe-fJ4e68g&list=PLtR-4Oz-WiEiWf74CRsWu1IhUvJ01fZyk"
                target="_blank"
              >
                <YouTubeIcon style={{ fontSize: "5vh" }} />
              </a>
            </span>
          </>
        ) : club === "tigersMedia" ? (
          <>
            <p>{getTranslatedText("tigersMedia")}</p>
          </>
        ) : club === "tigersCooking" ? (
          <>
            <p>{getTranslatedText("tigersCooking")}</p>
          </>
        ) : club === "tigersPublicSpeaking" ? (
          <>
            <p>{getTranslatedText("tigersPublicSpeaking")}</p>
          </>
        ) : club === "tigersRadio" ? (
          <>
            <p>{getTranslatedText("tigersRadio")}</p>
          </>
        ) : club === "tigersChess" ? (
          <>
            <p>{getTranslatedText("tigersChess")}</p>
          </>
        ) : club === "tigersTheater" ? (
          <>
            <p>{getTranslatedText("tigersTheater")}</p>
          </>
        ) : (
          <div className="serviceRedirectContainer">
            <p>{t(`${translationPage}.Please select a club`)}</p>
            <div>
              <span>
                <Link className="links" to="/clubs/tigersInteract">
                  <span className="serviceRedirectBtn">Tigers Interact</span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersSocial">
                  <span className="serviceRedirectBtn">Tigers Social</span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersSports">
                  <span className="serviceRedirectBtn">Tigers Sports</span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersReading">
                  <span className="serviceRedirectBtn">Tigers Reading</span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersVirtualExchange">
                  <span className="serviceRedirectBtn">
                    Tigers Virtual Exchange
                  </span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersMedia">
                  <span className="serviceRedirectBtn">Tigers Media</span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersCooking">
                  <span className="serviceRedirectBtn">Tigers Cooking</span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersPublicSpeaking">
                  <span className="serviceRedirectBtn">
                    Tigers Public Speaking
                  </span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersRadio">
                  <span className="serviceRedirectBtn">
                    Tigers School Radio
                  </span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersChess">
                  <span className="serviceRedirectBtn">Tigers Chess</span>
                </Link>
              </span>
              <span>
                <Link className="links" to="/clubs/tigersTheater">
                  <span className="serviceRedirectBtn">Tigers Theater</span>
                </Link>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Club;
