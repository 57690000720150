export const clubTexts = {
  tigersInteract: {
    en: `Tigers Interact is a philanthropy club that encourages young people to
    participate in community service projects and make a positive
    difference in the world. The club offers students the opportunity
    to develop leadership skills, build relationships, and contribute
    to important causes such as fighting poverty, disease, and
    inequality.`,
    fr: `Tigers Interact est un club philanthropique qui encourage les jeunes à
    participer à des projets de service communautaire et à apporter une
    différence positive dans le monde. Le club offre aux étudiants la possibilité
    de développer des compétences en leadership, de construire des relations et de contribuer
    à des causes importantes telles que la lutte contre la pauvreté, les maladies et
    l'inégalité.`,
    ar: `Tigers Interact هو نادٍ خيري يشجع الشباب على المشاركة في مشاريع خدمة المجتمع وإحداث
    فرق إيجابي في العالم. يوفر النادي للطلاب الفرصة
    لتطوير مهارات القيادة وبناء العلاقات والمساهمة
    في قضايا هامة مثل مكافحة الفقر والأمراض و
    عدم المساواة.`,
  },
  tigersSocial: {
    en: `The Tigers Social club at HTIS is a group of students who organize and
    participate in fun and engaging activities designed to promote
    social connections and foster a sense of community. Some of the
    activities the club may be involved in include dances, game
    nights, outdoor adventures, and cultural events. The social club
    is a great way for students to meet new people, make friends, and
    have fun together.`,
    fr: `Tigers Social est un groupe d'étudiants qui organisent et
    participent à des activités amusantes et engageantes conçues pour promouvoir
    les liens sociaux et favoriser un sentiment de communauté. Certaines des
    activités auxquelles le club peut participer comprennent des danses, des soirées
    jeux, des aventures en plein air et des événements culturels. Le club social
    est un excellent moyen pour les étudiants de rencontrer de nouvelles personnes, se faire des amis et
    s'amuser ensemble.`,
    ar: `Tigers Social هو مجموعة من الطلاب الذين ينظمون و
    يشاركون في أنشطة ممتعة وشيقة مصممة لتعزيز
    الروابط الاجتماعية وتعزيز شعور المجتمع. بعض
    الأنشطة التي يمكن أن يشترك فيها النادي تشمل الرقص وليالي
    الألعاب والمغامرات في الهواء الطلق والفعاليات الثقافية. النادي الاجتماعي
    هو وسيلة رائعة للطلاب للقاء أشخاص جدد وتكوين صداقات و
    قضاء وقت ممتع معًا.`,
  },
  tigersSports: {
    en: `The Tigers Sports club is a group of students who share a passion
    for sports and physical activity. The club offers students the
    opportunity to participate in a variety of sports and physical
    activities, including basketball, volleyball, soccer, and more.
    The Tigers sports club is a great way for students to stay active,
    develop their athletic skills, and have fun.`,
    fr: `Tigers Sports est un groupe d'étudiants qui partagent une passion
    pour le sport et l'activité physique. Le club offre aux étudiants la
    possibilité de participer à une variété de sports et d'activités physiques,
    y compris le basket-ball, le volley-ball, le soccer, et plus encore.
    Le club Tigers Sports est un excellent moyen pour les étudiants de rester actifs,
    de développer leurs compétences sportives et de s'amuser.`,
    ar: `Tigers Sports هو مجموعة من الطلاب الذين يشتركون في شغف
    الرياضة والنشاط البدني. يوفر النادي للطلاب الفرصة
    للمشاركة في مجموعة متنوعة من الرياضات والأنشطة البدنية،
    بما في ذلك كرة السلة والكرة الطائرة وكرة القدم وغيرها.
    يعتبر نادي Tigers Sports وسيلة رائعة للطلاب للبقاء نشطين،
    وتطوير مهاراتهم الرياضية، والاستمتاع بالوقت.`,
  },
  tigersReading: {
    en: `The Tigers Reading club at your school is a group of students who enjoy
    reading and discussing books. The club offers students the
    opportunity to explore a variety of literary genres, discuss their
    favorite books, and participate in fun reading-related activities.
    The reading club is a great way for students to develop their love
    of reading and connect with other book lovers.`,
    fr: `Tigers Reading est un groupe d'étudiants qui aiment
    lire et discuter des livres. Le club offre aux étudiants la possibilité
    d'explorer une variété de genres littéraires, de discuter de leurs
    livres préférés et de participer à des activités amusantes liées à la lecture.
    Le club de lecture est un excellent moyen pour les étudiants de développer leur amour
    de la lecture et de se connecter avec d'autres amoureux des livres.`,
    ar: `Tigers Reading هو مجموعة من الطلاب الذين يستمتعون
    بالقراءة ومناقشة الكتب. يوفر النادي للطلاب الفرصة
    لاستكشاف مجموعة متنوعة من الأنواع الأدبية، ومناقشة
    الكتب المفضلة لديهم، والمشاركة في أنشطة ممتعة متعلقة بالقراءة.
    يعتبر نادي القراءة وسيلة رائعة للطلاب لتطوير حبهم
    للقراءة والتواصل مع عشاق الكتب الآخرين.`,
  },
  tigersVirtualExchange: {
    en: `The Tigers Virtual Exchange club is a group of students who befriend and
    communicate with students from other countries. The club offers
    students the opportunity to learn about different cultures, share
    their own culture, and practice their language skills. The virtual
    exchange club is a great way for students to expand their global
    perspective and make friends from around the world.`,
    fr: `Tigers Virtual Exchange est un groupe d'étudiants qui se lient d'amitié et
    communiquent avec des étudiants d'autres pays. Le club offre aux étudiants
    l'opportunité d'apprendre sur différentes cultures, de partager
    leur propre culture et de pratiquer leurs compétences linguistiques. Le club
    d'échange virtuel est un excellent moyen pour les étudiants d'élargir leur perspective
    mondiale et de se faire des amis du monde entier.`,
    ar: `Tigers Virtual Exchange هو مجموعة من الطلاب الذين يصادقون و
    يتواصلون مع طلاب من بلدان أخرى. يوفر النادي
    للطلاب الفرصة للتعرف على ثقافات مختلفة ومشاركة
    ثقافتهم الخاصة وممارسة مهاراتهم اللغوية. إن النادي التبادلي الافتراضي
    هو وسيلة رائعة للطلاب لتوسيع منظورهم العالمي
    وتكوين صداقات من جميع أنحاء العالم.`,
  },
  tigersMedia: {
    en: `The Tigers Media club at your school is a group of students who are
    interested in media and communication. The club offers students
    the opportunity to develop their skills in photography,
    videography, graphic design, and other media-related areas. The
    media club is a great way for students to explore their creative
    interests and develop their skills in a supportive environment.`,
    fr: `Tigers Media est un groupe d'étudiants intéressés par
    les médias et la communication. Le club offre aux étudiants
    l'opportunité de développer leurs compétences en photographie,
    en vidéographie, en design graphique et dans d'autres domaines liés aux médias. Le
    club média est un excellent moyen pour les étudiants d'explorer leurs intérêts créatifs
    et de développer leurs compétences dans un environnement favorable.`,
    ar: `Tigers Media هو مجموعة من الطلاب الذين يهتمون بوسائل الإعلام والاتصالات. يوفر النادي للطلاب الفرصة
    لتطوير مهاراتهم في التصوير الفوتوغرافي والتصوير الفيديو والتصميم الجرافيكي
    ومجالات أخرى متعلقة بوسائل الإعلام. نادي الإعلام هو وسيلة رائعة للطلاب لاستكشاف اهتماماتهم الإبداعية
    وتطوير مهاراتهم في بيئة داعمة.`,
  },
  tigersCooking: {
    en: `The Tigers Cooking club is a culinary haven for aspiring chefs and food
    enthusiasts. Students gather to learn various cooking techniques,
    experiment with flavors, and discover the joy of creating
    delicious dishes. From baking mouthwatering pastries to preparing
    international cuisines, the Cooking Club fosters creativity,
    teamwork, and a love for all things gastronomic.`,
    fr: `Tigers Cooking est un paradis culinaire pour les chefs aspirants et les amateurs de cuisine. Les étudiants se réunissent pour apprendre différentes techniques de cuisine,
    expérimenter les saveurs et découvrir la joie de créer
    de délicieux plats. De la pâtisserie alléchante à la préparation
    des cuisines internationales, le club de cuisine favorise la créativité,
    le travail d'équipe et l'amour de tout ce qui est gastronomique.`,
    ar: `Tigers Cooking هو ملاذ طهي للطهاة الطموحين وهواة الطعام. يجتمع الطلاب لتعلم مختلف تقنيات الطهي،
    وتجربة النكهات، واكتشاف فرحة إعداد
    الأطباق اللذيذة. من خبز الحلويات الشهية إلى إعداد
    المأكولات العالمية، يعزز نادي الطهي الإبداع والعمل الجماعي
    وحب كل ما يتعلق بالطهو.`,
  },
  tigersPublicSpeaking: {
    en: `The Tigers Public Speaking club empowers students to express themselves
    confidently and eloquently. Through engaging activities and
    workshops, members develop effective communication skills, sharpen
    their public speaking abilities, and overcome stage fright.
    Whether it's delivering persuasive speeches or engaging in lively
    debates, the Public Speaking Club nurtures the leaders and
    influential orators of tomorrow.`,
    fr: `Tigers Public Speaking permet aux étudiants de s'exprimer
    avec confiance et éloquence. Grâce à des activités captivantes et
    des ateliers, les membres développent des compétences de communication efficaces, affinent
    leurs capacités de prise de parole en public et surmontent la peur de la scène.
    Que ce soit en prononçant des discours persuasifs ou en participant à des débats animés,
    le club de prise de parole en public nourrit les leaders et
    les orateurs influents de demain.`,
    ar: `Tigers Public Speaking يمنح الطلاب القدرة على التعبير عن أنفسهم
    بثقة وبلاغة. من خلال الأنشطة المشوقة و
    ورش العمل، يطور الأعضاء مهارات التواصل الفعالة، يحسنون
    قدراتهم في الكلام العام، ويتغلبون على رهبة المسرح.
    سواء كان تقديم خطب مقنعة أو المشاركة في مناقشات متحمسة،
    يعزز نادي الكلام العام قادة وخطباء مؤثرين في المستقبل.`,
  },
  tigersRadio: {
    en: `The Tigers Radio club is the hub of creativity and entertainment.
    Students immerse themselves in the world of broadcasting, hosting
    radio shows, curating playlists, and conducting interviews. With
    state-of-the-art equipment and a passion for music and
    storytelling, members of the School Radio Club bring the school
    community together through their engaging broadcasts, keeping
    everyone informed and entertained.`,
    fr: `Tigers Radio est le centre de la créativité et du divertissement.
    Les étudiants se plongent dans le monde de la radiodiffusion, animent
    des émissions de radio, créent des listes de lecture et mènent des interviews. Avec
    un équipement à la pointe de la technologie et une passion pour la musique et
    la narration, les membres du club de radio scolaire rassemblent la communauté scolaire
    à travers leurs émissions captivantes, en tenant
    tout le monde informé et diverti.`,
    ar: `Tigers Radio هو مركز الإبداع والترفيه.
    يغمر الطلاب أنفسهم في عالم البث الإذاعي، واستضافة
    برامج الراديو، واختيار قوائم التشغيل، وإجراء المقابلات
    معدات حديثة وشغف بالموسيقى وروح السرد، يجمع أعضاء نادي الراديو المدرسي
    مجتمع المدرسة من خلال بثواتهم الشيقة، مع إبقاء
    الجميع مطلعًا ومسليًا.`,
  },
  tigersChess: {
    en: `The Tigers Chess club is a haven for strategic thinkers and lovers of the
    ancient game. Students gather to enhance their critical thinking,
    concentration, and problem-solving skills through friendly chess
    matches and tournaments. Whether you're a novice or an experienced
    player, the Chess Club provides a supportive environment to learn,
    practice, and embrace the intricacies of this timeless game.`,
    fr: `Tigers Chess est un havre pour les penseurs stratégiques et les amateurs du
    jeu ancien. Les étudiants se réunissent pour améliorer leur réflexion critique,
    leur concentration et leurs compétences en résolution de problèmes grâce à des parties d'échecs amicales
    et des tournois. Que vous soyez un novice ou un joueur expérimenté,
    le club d'échecs offre un environnement favorable pour apprendre,
    s'entraîner et appréhender les subtilités de ce jeu intemporel.`,
    ar: `Tigers Chess هو ملاذ للمفكرين الاستراتيجيين ومحبي
    اللعبة القديمة. يجتمع الطلاب لتعزيز التفكير النقدي لديهم،
    والتركيز، ومهارات حل المشكلات من خلال مباريات الشطرنج الودية
    والبطولات. سواء كنت مبتدئًا أو لاعبًا ذو خبرة،
    يوفر نادي الشطرنج بيئة داعمة للتعلم،
    التدريب، واستيعاب تفاصيل هذه اللعبة الخالدة.`,
  },
  tigersTheater: {
    en: `The Tigers Theater club is a vibrant stage where creativity and
    expression take center stage. Students explore the magical world
    of theater, participating in acting workshops, rehearsals, and
    captivating performances. From classic plays to original
    productions, the Theater Club fosters teamwork, self-confidence,
    and a deep appreciation for the performing arts, allowing students
    to unleash their inner actors and create unforgettable memories on
    the stage.`,
    fr: `Tigers Theater est une scène vibrante où la créativité et
    l'expression sont mises en avant. Les étudiants explorent le monde magique
    du théâtre, participent à des ateliers de jeu, à des répétitions et
    à des représentations captivantes. Des pièces classiques aux productions originales,
    le club de théâtre favorise le travail d'équipe, la confiance en soi,
    et une profonde appréciation des arts de la scène, permettant aux étudiants
    de libérer leur côté acteur et de créer des souvenirs inoubliables sur
    la scène.`,
    ar: `Tigers Theater هو مسرح نابض بالحياة حيث تأخذ الإبداع و
    التعبير المركز الأول. يستكشف الطلاب العالم السحري
    للمسرح، ويشاركون في ورش العمل التمثيلية والتمارين و
    العروض الساحرة. من المسرحيات الكلاسيكية إلى الإنتاجات الأصلية،
    يعزز نادي المسرح العمل الجماعي، والثقة بالنفس،
    وتقدير عميق للفنون المسرحية، مما يتيح للطلاب
    إظهار مواهبهم التمثيلية الداخلية وإنشاء ذكريات لا تُنسى على
    المسرح.`,
  },
};

export const cyclesTexts = {
  kindergarten: {
    en: `We understand that the early years of a child's education are crucial
    for their development and future success. That's why we are committed to
    providing a nurturing and stimulating environment for our youngest
    learners in our kindergarten/preschool program.
    <br />
    Our program is designed to give your child the best possible start in
    their educational journey. We offer a play-based, hands-on learning
    approach that encourages exploration, creativity, and problem-solving.
    Our program is led by experienced and caring teachers who are dedicated
    to ensuring that each child receives individualized attention and
    support.
    <br />
    We also believe that early childhood education is about much more than
    just academics. Our program focuses on the whole child, which includes
    social-emotional development, physical development, and cognitive
    development. We encourage our students to develop a love of learning and
    a sense of curiosity that will stay with them for years to come.
    <ul>
      Our program includes a variety of activities and experiences that are
      designed to foster your child's development. These include:
      <li>
        <b>Language and Literacy:</b> Our program focuses on developing
        strong language and literacy skills through storytelling, rhyming
        games, and reading activities.
      </li>
      <li>
        <b>Math and Science:</b> We introduce our students to basic math and
        science concepts through hands-on activities that encourage
        exploration and experimentation.
      </li>
      <li>
        <b>Creative Arts:</b> Our program includes a variety of creative
        arts activities, such as painting, drawing, and music, to help
        students express themselves and develop their creativity.
      </li>
      <li>
        <b>Physical Development:</b> We offer a range of activities to help
        develop our students' physical skills, including outdoor play, gross
        motor activities, and yoga.
      </li>
    </ul>
    At HTIS, we understand that every child is unique, and we strive to
    create a learning environment that meets the individual needs of each
    child. We work closely with parents and guardians to ensure that our
    program supports each child's development and fosters a love of learning
    that will last a lifetime.`,
    fr: `Nous comprenons que les premières années de l'éducation d'un enfant sont cruciales
    pour son développement et son succès futur. C'est pourquoi nous nous engageons à
    fournir un environnement bienveillant et stimulant pour nos plus jeunes
    apprenants dans notre programme de maternelle/préscolaire.
    <br />
    Notre programme est conçu pour offrir à votre enfant le meilleur départ possible
    dans son parcours éducatif. Nous proposons une approche d'apprentissage ludique et pratique
    qui encourage l'exploration, la créativité et la résolution de problèmes.
    Notre programme est dirigé par des enseignants expérimentés et attentionnés
    qui sont dévoués à s'assurer que chaque enfant bénéficie d'une attention individualisée et
    de soutien.
    <br />
    Nous pensons également que l'éducation de la petite enfance concerne bien plus que
    les seuls aspects académiques. Notre programme met l'accent sur l'enfant dans sa globalité,
    ce qui inclut le développement socio-émotionnel, le développement physique et le développement cognitif.
    Nous encourageons nos élèves à développer l'amour de l'apprentissage et
    un sens de la curiosité qui les accompagneront tout au long de leur vie.
    <ul>
      Notre programme comprend une variété d'activités et d'expériences qui visent
      à favoriser le développement de votre enfant. Celles-ci comprennent :
      <li>
        <b>Langage et littératie :</b> Notre programme met l'accent sur le développement
        de solides compétences en langage et en littératie grâce à des histoires, des jeux de rimes
        et des activités de lecture.
      </li>
      <li>
        <b>Mathématiques et sciences :</b> Nous initions nos élèves aux concepts mathématiques
        et scientifiques de base grâce à des activités pratiques qui encouragent
        l'exploration et l'expérimentation.
      </li>
      <li>
        <b>Arts créatifs :</b> Notre programme comprend une variété d'activités artistiques
        telles que la peinture, le dessin et la musique, pour aider les élèves
        à s'exprimer et à développer leur créativité.
      </li>
      <li>
        <b>Développement physique :</b> Nous proposons une gamme d'activités visant à aider
        au développement des compétences physiques de nos élèves, notamment le jeu en extérieur,
        les activités motrices globales et le yoga.
      </li>
    </ul>
    Chez HTIS, nous comprenons que chaque enfant est unique, et nous nous efforçons
    de créer un environnement d'apprentissage qui répond aux besoins individuels de chaque
    enfant. Nous travaillons en étroite collaboration avec les parents et les tuteurs
    pour nous assurer que notre programme soutient le développement de chaque enfant et
    favorise l'amour de l'apprentissage qui durera toute une vie.`,
    ar: `نحن ندرك أن السنوات الأولى من تعليم الطفل ذات أهمية بالغة
    لتنميته ونجاحه المستقبلي. ولهذا السبب نلتزم بتوفير بيئة محفزة ومثيرة لأطفالنا
    الصغار في برنامجنا لروضة الأطفال / الحضانة.
    <br />
    يتم تصميم برنامجنا لتقديم أفضل بداية ممكنة لطفلك في رحلته التعليمية. نقدم نهجًا تعليميًا قائمًا على اللعب والتجربة
    يشجع على الاستكشاف والإبداع وحل المشكلات. يقود برنامجنا معلمون ذوو خبرة ورعاية يكرسون جهودهم
    لضمان أن يتلقى كل طفل اهتمامًا ودعمًا فرديين.
    <br />
    نؤمن أيضًا بأن التعليم في الطفولة المبكرة يتعلق بأكثر من مجرد الأكاديميات. يركز برنامجنا على الطفل بشكل كامل،
    وهذا يشمل التنمية الاجتماعية والعاطفية والجسدية والإدراكية. نشجع طلابنا على تنمية حب التعلم و
    الفضول الذي سيكون معهم لسنوات قادمة.
    <ul>
      يتضمن برنامجنا مجموعة من الأنشطة والتجارب التي تهدف إلى تعزيز تنمية طفلك. وتشمل هذه الأنشطة:
      <li>
        <b>اللغة والقراءة والكتابة:</b> يركز برنامجنا على تنمية مهارات قوية في اللغة والقراءة والكتابة
        من خلال القصص وألعاب القوافي وأنشطة القراءة.
      </li>
      <li>
        <b>الرياضيات والعلوم:</b> نقدم لطلابنا مفاهيم الرياضيات والعلوم الأساسية من خلال أنشطة تشجع
        على الاستكشاف والتجربة.
      </li>
      <li>
        <b>الفنون الإبداعية:</b> يتضمن برنامجنا مجموعة من الأنشطة الفنية الإبداعية مثل الرسم والتلوين والموسيقى
        لمساعدة الطلاب على التعبير عن أنفسهم وتنمية إبداعهم.
      </li>
      <li>
        <b>التطور الجسدي:</b> نقدم مجموعة من الأنشطة لمساعدة في تطوير مهارات طلابنا الجسدية، بما في ذلك اللعب في الهواء الطلق
        وأنشطة الحركة الكبرى واليوغا.
      </li>
    </ul>
    في HTIS، ندرك أن كل طفل فريد، ونسعى لإنشاء بيئة تعليمية تلبي احتياجات كل طفل فردي و
    تعزز حب التعلم الذي سيستمر مدى الحياة. نعمل عن كثب مع الآباء والأوصياء لضمان أن برنامجنا يدعم تطور كل طفل
    ويعزز حب التعلم الذي سيستمر مدى الحياة.`,
  },
  elementary: {
    en: `We believe that the elementary years are a crucial time for laying the
    foundation for a student's future success. Our elementary school program
    is designed to provide a comprehensive education that fosters a love of
    learning, critical thinking, and global citizenship.
    <br />
    Our elementary school program is structured to help students build a
    strong foundation in core academic subjects such as Mathematics,
    Language Arts, Science, and Social Studies. Our experienced and
    passionate teachers use a variety of teaching strategies and resources
    to engage students and foster a love of learning.
    <br />
    We understand that every student is unique, and we work to ensure that
    our program meets the individual needs of each student. Our small class
    sizes allow our teachers to provide individualized attention and support
    to each student.
    <ul>
      In addition to academic subjects, our elementary school program
      includes a range of activities and experiences that help students
      develop important skills and qualities. These include:
      <li>
        <b>Character Development:</b> Our program includes character
        education that helps students develop essential qualities such as
        empathy, responsibility, and respect. We believe that character
        development is just as important as academic achievement.
      </li>
      <li>
        <b>Foreign Language Instruction:</b> We believe that learning a
        foreign language is an important part of preparing students for
        success in a globalized world. Our elementary school program
        includes foreign language instruction in English and Spanish.
      </li>
      <li>
        <b>Physical Education:</b> We offer a range of physical education
        activities that help students develop their gross motor skills and
        healthy habits.
      </li>
      <li>
        <b>Creative Arts:</b> Our program includes a variety of creative
        arts activities, such as music, visual arts, and drama, to help
        students express themselves and develop their creativity.
      </li>
    </ul>
    We also believe that education is a partnership between parents,
    students, and teachers. We work closely with parents to ensure that our
    program supports each student's academic and personal growth. We invite
    you to visit our school and learn more about our elementary school
    program.`,
    fr: `Nous croyons que les années de l'école élémentaire sont un moment crucial pour jeter les
    bases du succès futur de l'élève. Notre programme de l'école élémentaire
    est conçu pour offrir une éducation globale qui favorise l'amour de
    l'apprentissage, la pensée critique et la citoyenneté mondiale.
    <br />
    Notre programme de l'école élémentaire est structuré pour aider les élèves à construire
    une base solide dans les matières académiques de base telles que les mathématiques,
    le français, les sciences et les études sociales. Nos enseignants expérimentés et
    passionnés utilisent une variété de stratégies d'enseignement et de ressources
    pour impliquer les élèves et favoriser l'amour de l'apprentissage.
    <br />
    Nous comprenons que chaque élève est unique, et nous veillons à ce que
    notre programme réponde aux besoins individuels de chaque élève. Nos effectifs réduits
    permettent à nos enseignants de fournir une attention et un soutien individualisés
    à chaque élève.
    <ul>
      En plus des matières académiques, notre programme de l'école élémentaire
      comprend une gamme d'activités et d'expériences qui aident les élèves à
      développer des compétences et des qualités importantes. Celles-ci comprennent :
      <li>
        <b>Le développement du caractère :</b> Notre programme comprend
        l'éducation au caractère qui aide les élèves à développer des qualités essentielles telles que
        l'empathie, la responsabilité et le respect. Nous croyons que le développement du caractère
        est tout aussi important que la réussite académique.
      </li>
      <li>
        <b>L'enseignement des langues étrangères :</b> Nous croyons que l'apprentissage d'une
        langue étrangère est une partie importante de la préparation des élèves à
        la réussite dans un monde globalisé. Notre programme de l'école élémentaire
        comprend l'enseignement des langues étrangères en français et en anglais.
      </li>
      <li>
        <b>L'éducation physique :</b> Nous proposons une gamme d'activités d'éducation physique
        qui aident les élèves à développer leurs compétences motrices globales et
        de bonnes habitudes de vie.
      </li>
      <li>
        <b>Les arts créatifs :</b> Notre programme comprend une variété d'activités artistiques
        créatives telles que la musique, les arts visuels et le théâtre, pour aider
        les élèves à s'exprimer et à développer leur créativité.
      </li>
    </ul>
    Nous croyons également que l'éducation est un partenariat entre les parents,
    les élèves et les enseignants. Nous travaillons en étroite collaboration avec les parents pour nous assurer que notre
    programme soutient la croissance académique et personnelle de chaque élève. Nous vous invitons
    à visiter notre école et à en savoir plus sur notre programme de l'école élémentaire.`,
    ar: `نعتقد أن سنوات المدرسة الابتدائية هي وقت حاسم لبناء
    الأساس لنجاح مستقبل الطالب. برنامجنا للمدرسة الابتدائية
    مصمم لتوفير تعليم شامل يعزز حب
    التعلم والتفكير النقدي والمواطنة العالمية.
    <br />
    يتم تنظيم برنامجنا للمدرسة الابتدائية لمساعدة الطلاب على بناء
    أساس قوي في المواد الأكاديمية الأساسية مثل الرياضيات،
    اللغة الإنجليزية، العلوم والدراسات الاجتماعية. يستخدم معلمونا ذوو خبرة و
    شغف مجموعة متنوعة من استراتيجيات التدريس والموارد
    لجذب الطلاب وتعزيز حب التعلم.
    <br />
    نحن ندرك أن كل طالب فريد، ونعمل على ضمان أن
    برنامجنا يلبي الاحتياجات الفردية لكل طالب. حجم فصولنا الصغيرة
    يسمح لمعلمينا بتقديم اهتمام ودعم فردي لكل طالب.
    <ul>
      بالإضافة إلى المواد الأكاديمية، يشمل برنامجنا للمدرسة الابتدائية
      مجموعة من الأنشطة والتجارب التي تساعد الطلاب على
      تطوير مهارات وصفات مهمة. وتشمل هذه :
      <li>
        <b>تنمية الشخصية :</b> يشمل برنامجنا تعليم الشخصية
        الذي يساعد الطلاب على تطوير صفات أساسية مثل
        التعاطف والمسؤولية والاحترام. نحن نعتقد أن تنمية الشخصية
        مهمة بنفس أهمية التحصيل الأكاديمي.
      </li>
      <li>
        <b>تعليم اللغات الأجنبية :</b> نعتقد أن تعلم
        اللغة الأجنبية هو جزء مهم من إعداد الطلاب للنجاح في
        عالم متعدد الثقافات. يشمل برنامجنا للمدرسة الابتدائية
        تعليم اللغات الأجنبية باللغتين الإنجليزية والإسبانية.
      </li>
      <li>
        <b>التربية البدنية :</b> نقدم مجموعة من الأنشطة في التربية البدنية
        التي تساعد الطلاب على تطوير مهاراتهم الحركية الكبرى و
        العادات الصحية.
      </li>
      <li>
        <b>الفنون الإبداعية :</b> يشمل برنامجنا مجموعة من الأنشطة الفنية
        الإبداعية مثل الموسيقى والفنون التشكيلية والدراما، لمساعدة
        الطلاب على التعبير عن أنفسهم وتنمية إبداعهم.
      </li>
    </ul>
    نحن أيضًا نعتقد أن التعليم هو شراكة بين الآباء والأمهات،
    الطلاب والمعلمين. نعمل بشكل وثيق مع الآباء والأمهات لضمان أن
    برنامجنا يدعم النمو الأكاديمي والشخصي لكل طالب. ندعوك
    لزيارة مدرستنا ومعرفة المزيد عن برنامجنا للمدرسة الابتدائية.`,
  },
  middleSchool: {
    en: `We understand that the middle school years can be a time of significant
    growth and development for students. Our middle school program is
    designed to provide a challenging and stimulating academic environment
    that prepares students for high school and beyond.
    <br />
    Our middle school program builds upon the strong foundation that
    students develop in elementary school, and is designed to help students
    deepen their understanding of core academic subjects such as
    Mathematics, Language Arts, Science, and Social Studies. Our experienced
    and dedicated teachers use a variety of teaching strategies and
    resources to engage students and foster a love of learning.
    <br />
    We understand that at this age, students are discovering their passions
    and interests. That's why we offer a range of clubs and organizations
    that allow students to explore their interests and develop new skills.
    Some of the clubs and organizations available to our middle school
    students include the Media club, cooking club, social club, and more.
    <ul>
      We believe that education goes beyond the classroom, and our middle
      school program includes a range of experiences and activities that
      help students develop important skills and qualities. These include:
      <li>
        <b>Community Service:</b> We encourage our students to get involved
        in their communities and make a positive difference. Our community
        service program helps students develop empathy and a sense of
        responsibility.
      </li>
      <li>
        <b>Foreign Language Instruction:</b> Our middle school program
        continues to provide instruction in English and Spanish. We believe
        that learning a foreign language is an important part of preparing
        students for success in a globalized world.
      </li>
      <li>
        <b>Creative Arts:</b> Our program includes a range of creative arts
        activities, such as music, visual arts, and drama, to help students
        express themselves and develop their creativity.
      </li>
      <li>
        <b>Physical Education:</b> We offer a range of physical education
        activities that help students develop their gross motor skills and
        healthy habits.
      </li>
    </ul>
    At HTIS, we are committed to providing a safe and nurturing environment
    that supports each student's academic and personal growth. We work
    closely with parents to ensure that our program meets the individual
    needs of each student.`,
    fr: `Nous comprenons que les années de collège peuvent être une période de
    croissance et de développement significatifs pour les élèves. Notre programme
    de collège est conçu pour fournir un environnement académique stimulant
    qui prépare les élèves au lycée et au-delà.
    <br />
    Notre programme de collège s'appuie sur les bases solides que les
    élèves développent à l'école élémentaire et vise à approfondir leur
    compréhension des matières académiques essentielles telles que les
    mathématiques, la langue française, les sciences et les études sociales.
    Nos enseignants expérimentés et dévoués utilisent une variété de
    stratégies d'enseignement et de ressources pour engager les élèves et
    favoriser l'amour de l'apprentissage.
    <br />
    Nous comprenons qu'à cet âge, les élèves découvrent leurs passions
    et leurs intérêts. C'est pourquoi nous proposons une gamme de clubs et
    d'organisations qui permettent aux élèves d'explorer leurs intérêts et
    de développer de nouvelles compétences. Parmi les clubs et les
    organisations disponibles pour nos élèves de collège, on trouve le club
    média, le club de cuisine, le club social, et bien d'autres.
    <ul>
      Nous croyons que l'éducation va au-delà de la salle de classe, et notre
      programme de collège comprend une variété d'expériences et d'activités
      qui aident les élèves à développer des compétences et des qualités
      importantes. Celles-ci incluent :
      <li>
        <b>Service communautaire :</b> Nous encourageons nos élèves à s'impliquer
        dans leur communauté et à faire une différence positive. Notre programme
        de service communautaire aide les élèves à développer l'empathie et le
        sens des responsabilités.
      </li>
      <li>
        <b>Enseignement des langues étrangères :</b> Notre programme de collège
        continue à proposer l'enseignement de l'anglais et du français. Nous
        croyons que l'apprentissage d'une langue étrangère est une partie
        importante de la préparation des élèves à réussir dans un monde
        globalisé.
      </li>
      <li>
        <b>Arts créatifs :</b> Notre programme comprend une variété d'activités
        artistiques créatives, telles que la musique, les arts visuels et le
        théâtre, pour aider les élèves à s'exprimer et à développer leur
        créativité.
      </li>
      <li>
        <b>Education physique :</b> Nous proposons une gamme d'activités
        d'éducation physique qui aident les élèves à développer leurs
        compétences motrices globales et de bonnes habitudes de vie.
      </li>
    </ul>
    Chez HTIS, nous nous engageons à fournir un environnement sûr et
    stimulant qui soutient la croissance académique et personnelle de chaque
    élève. Nous travaillons en étroite collaboration avec les parents pour
    nous assurer que notre programme répond aux besoins individuels de chaque
    élève.`,
    ar: `نحن نفهم أن سنوات المدرسة المتوسطة يمكن أن تكون فترة من النمو والتطور الهام للطلاب. يهدف برنامجنا في المدرسة المتوسطة
    إلى توفير بيئة أكاديمية تحفّز وتتحدى الطلاب وتعدهم للمرحلة الثانوية وما بعدها.
    <br />
    يبني برنامجنا في المدرسة المتوسطة على الأساس القوي الذي يتطوّره الطلاب في المرحلة الابتدائية، وهو مصمم لمساعدة الطلاب
    على تعميق فهمهم للمواد الأكاديمية الأساسية مثل الرياضيات واللغة الإنجليزية والعلوم والدراسات الاجتماعية. يستخدم معلمونا
    ذوو الخبرة والشغف مجموعة متنوعة من استراتيجيات التدريس والموارد لجذب الطلاب وتعزيز حب التعلم.
    <br />
    نحن نفهم أنه في هذا العمر، يكتشف الطلاب شغفهم واهتماماتهم. لهذا السبب نقدم مجموعة من الأندية والمنظمات التي تتيح للطلاب استكشاف اهتماماتهم وتطوير مهارات جديدة.
    بعض الأندية والمنظمات المتاحة لطلاب المدرسة المتوسطة تشمل نادي الإعلام، نادي الطبخ، نادي النشاط الاجتماعي، وغيرها.
    <ul>
      نحن نؤمن أن التعليم يتجاوز الفصول الدراسية، ويشمل برنامجنا في المدرسة المتوسطة مجموعة من التجارب والأنشطة التي تساعد الطلاب على تطوير مهارات وصفات هامة. تشمل هذه:
      <li>
        <b>خدمة المجتمع:</b> نشجع طلابنا على المشاركة في المجتمعات الخاصة بهم وإحداث فرق إيجابي. يساعد برنامج خدمة المجتمع لدينا الطلاب على تطوير التعاطف والمسؤولية.
      </li>
      <li>
        <b>تعليم اللغات الأجنبية:</b> يواصل برنامجنا في المدرسة المتوسطة تقديم تعليم اللغات الأجنبية بالإنجليزية والإسبانية. نعتقد أن تعلم اللغات الأجنبية هو جزء مهم من تجهيز الطلاب للنجاح في عالم عالمي.
      </li>
      <li>
        <b>التربية البدنية:</b> نقدم مجموعة من الأنشطة في التربية البدنية التي تساعد الطلاب على تطوير مهاراتهم الحركية الكبرى وعادات صحية.
      </li>
      <li>
        <b>الفنون الإبداعية:</b> يتضمن برنامجنا مجموعة من الأنشطة الفنية الإبداعية، مثل الموسيقى والفنون التشكيلية والدراما، لمساعدة الطلاب على التعبير عن أنفسهم وتطوير إبداعهم.
      </li>
    </ul>
    في HTIS، نحن ملتزمون بتوفير بيئة آمنة ومحفزة تدعم نمو الطلاب الأكاديمي والشخصي. نعمل عن كثب مع الأهل لضمان أن برنامجنا يلبي الاحتياجات الفردية لكل طالب.`,
  },
  highSchool: {
    en: `We believe that the high school years are a critical time for preparing
    students for success in college and beyond. Our high school program is
    designed to provide a rigorous academic curriculum that challenges
    students and prepares them for the demands of higher education.
    <br />
    Our high school program builds upon the strong foundation that students
    develop in middle school, and is designed to help students deepen their
    understanding of core academic subjects such as Mathematics, Language
    Arts, Science, and Social Studies. Our experienced and passionate
    teachers use a variety of teaching strategies and resources to engage
    students and foster a love of learning.
    <br />
    We understand that at this stage, students are preparing to take on new
    responsibilities and make important decisions about their future. That's
    why we offer a range of college preparatory programs and resources that
    help students prepare for college applications and entry. Our college
    preparatory programs include guidance on standardized tests, college
    essays, and interviews. <br />
    In addition to our rigorous academic program, we offer a range of
    extracurricular activities that allow students to explore their
    interests and develop new skills. Our high school students have access
    to a range of clubs and organizations, including the Debate club, Model
    UN, Robotics club, and more.
    <br />
    <ul>
      We believe that education goes beyond the classroom, and our high
      school program includes a range of experiences and activities that
      help students develop important skills and qualities. These include:
      <li>
        <b>Community Service:</b> We encourage our students to get involved
        in their communities and make a positive difference. Our community
        service program helps students develop empathy and a sense of
        responsibility.
      </li>
      <li>
        <b>Foreign Language Instruction:</b> Our high school program
        continues to provide instruction in French and Arabic. We believe
        that learning a foreign language is an important part of preparing
        students for success in a globalized world.
      </li>
      <li>
        <b>Creative Arts:</b> Our program includes a range of creative arts
        activities, such as music, visual arts, and drama, to help students
        express themselves and develop their creativity.
      </li>
      <li>
        <b>Physical Education:</b> We offer a range of physical education
        activities that help students develop their gross motor skills and
        healthy habits.
      </li>
    </ul>
    We are committed to providing a safe and nurturing environment that
    supports each student's academic and personal growth. We work closely
    with parents to ensure that our program meets the individual needs of
    each student.`,
    fr: `Nous croyons que les années du lycée sont une période cruciale pour préparer les élèves à réussir à l'université et au-delà. Notre programme de lycée est conçu pour offrir un cursus académique rigoureux qui challenge les étudiants et les prépare aux exigences de l'enseignement supérieur.
    <br />
    Notre programme de lycée s'appuie sur les solides fondations acquises au collège et vise à approfondir la compréhension des matières essentielles telles que les mathématiques, la langue française, les sciences et les sciences sociales. Nos enseignants expérimentés et passionnés utilisent une variété de méthodes pédagogiques et de ressources pour engager les élèves et susciter leur amour de l'apprentissage.
    <br />
    Nous comprenons qu'à ce stade, les étudiants se préparent à assumer de nouvelles responsabilités et à prendre des décisions importantes concernant leur avenir. C'est pourquoi nous proposons une gamme de programmes et de ressources préparatoires à l'université qui aident les étudiants à se préparer aux candidatures et à l'admission à l'université. Nos programmes préparatoires à l'université comprennent des conseils sur les tests standardisés, les essais universitaires et les entretiens.
    <br />
    En plus de notre programme académique rigoureux, nous proposons une variété d'activités parascolaires qui permettent aux étudiants d'explorer leurs intérêts et de développer de nouvelles compétences. Nos élèves du lycée ont accès à une gamme de clubs et d'organisations, y compris le club de débat, le club des Nations Unies, le club de robotique et bien plus encore.
    <br />
    <ul>
      Nous croyons que l'éducation ne se limite pas à la salle de classe, c'est pourquoi notre programme de lycée inclut une variété d'expériences et d'activités qui aident les étudiants à développer des compétences et des qualités importantes. Celles-ci comprennent :
      <li>
        <b>Service Communautaire :</b> Nous encourageons nos étudiants à s'impliquer dans leur communauté et à faire une différence positive. Notre programme de service communautaire aide les étudiants à développer l'empathie et le sens des responsabilités.
      </li>
      <li>
        <b>Enseignement des Langues Étrangères :</b> Notre programme de lycée continue à proposer l'enseignement de la langue française et de l'arabe. Nous croyons que l'apprentissage des langues étrangères est une partie importante de la préparation des étudiants à réussir dans un monde globalisé.
      </li>
      <li>
        <b>Arts Créatifs :</b> Notre programme comprend une gamme d'activités artistiques créatives, telles que la musique, les arts visuels et le théâtre, pour aider les étudiants à s'exprimer et à développer leur créativité.
      </li>
      <li>
        <b>Éducation Physique :</b> Nous proposons une gamme d'activités d'éducation physique qui aident les étudiants à développer leurs compétences motrices globales et de bonnes habitudes de vie.
      </li>
    </ul>
    Nous nous engageons à fournir un environnement sûr et favorable qui soutient la croissance académique et personnelle de chaque étudiant. Nous travaillons en étroite collaboration avec les parents pour nous assurer que notre programme répond aux besoins individuels de chaque étudiant.`,
    ar: `نعتقد أن فترة المدرسة الثانوية هي فترة حاسمة لإعداد الطلاب للنجاح في الجامعة وما بعدها. برنامجنا في المدرسة الثانوية مصمم لتوفير منهاج أكاديمي صارم يتحدى الطلاب ويعدهم لمتطلبات التعليم العالي.
    <br />
    يستند برنامجنا في المدرسة الثانوية إلى الأسس القوية التي يطورها الطلاب في المدرسة المتوسطة، ويهدف إلى مساعدة الطلاب على تعميق فهمهم للمواد الأكاديمية الأساسية مثل الرياضيات واللغة العربية والعلوم والدراسات الاجتماعية. يستخدم معلمونا ذوو الخبرة والشغف مجموعة من استراتيجيات التدريس والموارد لجذب الطلاب وتعزيز حب العلم.
    <br />
    ندرك أنه في هذه المرحلة، يقوم الطلاب بالاستعداد لتحمل مسؤوليات جديدة واتخاذ قرارات مهمة بشأن مستقبلهم. لهذا السبب نقدم مجموعة من البرامج والموارد التحضيرية للكلية التي تساعد الطلاب على الاستعداد لتقديم طلبات الكلية والقبول. تشمل برامجنا التحضيرية للكلية التوجيه فيما يتعلق بالاختبارات المعيارية والمقالات الجامعية والمقابلات. <br />
    بالإضافة إلى برنامجنا الأكاديمي الصارم، نقدم مجموعة من الأنشطة المدرسية المدعومة التي تتيح للطلاب استكشاف اهتماماتهم وتطوير مهارات جديدة. يتمتع طلابنا في المدرسة الثانوية بفرصة الانضمام إلى مجموعة من الأندية والمنظمات، بما في ذلك نادي النقاش، نموذج الأمم المتحدة، نادي الروبوتات، وغيرها.
    <br />
    <ul>
      نحن نؤمن بأن التعليم يتجاوز الحصص الدراسية، وبرنامجنا في المدرسة الثانوية يشمل مجموعة من التجارب والأنشطة التي تساعد الطلاب على تطوير مهارات وصفات هامة. تشمل هذه:
      <li>
        <b>خدمة المجتمع:</b> نشجع طلابنا على المشاركة في مجتمعاتهم وصنع فرق إيجابي. برنامج خدمة المجتمع لدينا يساعد الطلاب على تطوير التعاطف والمسؤولية.
      </li>
      <li>
        <b>تعليم اللغات الأجنبية:</b> يستمر برنامجنا في المدرسة الثانوية في تقديم تعليم في اللغة العربية والإنجليزية. نحن نعتقد أن تعلم اللغة الأجنبية هو جزء مهم من إعداد الطلاب للنجاح في عالم متعدد الثقافات.
      </li>
      <li>
        <b>الفنون الإبداعية:</b> يشمل برنامجنا مجموعة من الأنشطة الفنية الإبداعية، مثل الموسيقى والفنون البصرية والمسرح، لمساعدة الطلاب على التعبير عن أنفسهم وتنمية إبداعهم.
      </li>
      <li>
        <b>التربية البدنية:</b> نقدم مجموعة من الأنشطة في التربية البدنية التي تساعد الطلاب على تطوير مهاراتهم الحركية الكبرى وعادات صحية.
      </li>
    </ul>
    نحن ملتزمون بتوفير بيئة آمنة ومحفزة تدعم نمو الطلاب الأكاديمي والشخصي. نعمل عن كثب مع أولياء الأمور لضمان أن برنامجنا يلبي الاحتياجات الفردية لكل طالب.`,
  },
};

export const eLearningTexts = {
  Starfall: {
    en: `Teaches early literacy and numeracy skills through interactive games, songs, and activities.`,
    fr: `Enseigne les compétences de base en lecture et en numératie à travers des jeux interactifs, des chansons et des activités.`,
    ar: `يعلم مهارات القراءة المبكرة والعد والحساب من خلال الألعاب التفاعلية والأغاني والأنشطة.`,
  },
  ABCmouse: {
    en: `Offers a comprehensive curriculum for kindergarten, covering subjects like math, reading, science, and art.`,
    fr: `Propose un programme complet pour la maternelle, couvrant des sujets tels que les mathématiques, la lecture, les sciences et l'art.`,
    ar: `يقدم منهاجاً شاملاً لرياض الأطفال، يغطي مواضيع مثل الرياضيات والقراءة والعلوم والفن.`,
  },
  PBSKids: {
    en: `Provides educational games and videos covering various topics, including early literacy, math, science, and social skills.`,
    fr: `Propose des jeux éducatifs et des vidéos couvrant divers sujets, dont la lecture précoce, les mathématiques, les sciences et les compétences sociales.`,
    ar: `يقدم ألعابًا تعليمية ومقاطع فيديو تغطي مواضيع مختلفة، بما في ذلك القراءة المبكرة والرياضيات والعلوم والمهارات الاجتماعية.`,
  },
  KhanAcademyKids: {
    en: `Offers interactive lessons, games, and activities in math, reading, social-emotional learning, and more.`,
    fr: `Propose des leçons interactives, des jeux et des activités en mathématiques, en lecture, en apprentissage socio-émotionnel, et bien plus encore.`,
    ar: `يقدم دروسًا تفاعلية وألعابًا وأنشطة في الرياضيات والقراءة والتعلم الاجتماعي والعاطفي، وأكثر من ذلك.`,
  },
  BrainPOP: {
    en: `Provides animated educational videos across subjects such as math, science, social studies, English, and arts.`,
    fr: `Propose des vidéos éducatives animées couvrant des sujets tels que les mathématiques, les sciences, les études sociales, l'anglais et les arts.`,
    ar: `يوفر مقاطع فيديو تعليمية متحركة تغطي مواضيع مثل الرياضيات والعلوم والدراسات الاجتماعية واللغة الإنجليزية والفنون.`,
  },
  NatGeoKids: {
    en: `Offers engaging articles, videos, and quizzes on science, nature, animals, geography, and history.`,
    fr: `Propose des articles captivants, des vidéos et des quiz sur les sciences, la nature, les animaux, la géographie et l'histoire.`,
    ar: `يقدم مقالات ومقاطع فيديو واختبارات شيقة حول العلوم والطبيعة والحيوانات والجغرافيا والتاريخ.`,
  },
  Quizlet: {
    en: `Offers a wide range of study materials, flashcards, and interactive quizzes for subjects such as math, science, social studies, languages, and more.`,
    fr: `Propose une large gamme de matériel d'étude, de fiches de révision et de quiz interactifs pour des matières telles que les mathématiques, les sciences, les études sociales, les langues, et bien plus encore.`,
    ar: `يقدم مجموعة واسعة من المواد الدراسية وبطاقات التعلم السريع والاختبارات التفاعلية لمواد مثل الرياضيات والعلوم والدراسات الاجتماعية واللغات، وأكثر.`,
  },
  CK12: {
    en: `Provides free digital textbooks, lessons, and interactive resources covering math, science, social studies, and more.`,
    fr: `Propose des manuels numériques gratuits, des leçons et des ressources interactives couvrant les mathématiques, les sciences, les études sociales, et bien plus encore.`,
    ar: `يوفر كتبًا رقمية مجانية ودروسًا وموارد تفاعلية تغطي مواد مثل الرياضيات والعلوم والدراسات الاجتماعية، وأكثر.`,
  },
  ReadWorks: {
    en: `Offers a collection of reading comprehension passages and lesson plans across various subjects.`,
    fr: `Propose une collection de passages de compréhension de lecture et des plans de leçons couvrant divers sujets.`,
    ar: `يقدم مجموعة من المقاطع التي تساعد على فهم المقروءات وخطط الدروس في مواضيع متنوعة.`,
  },
  Coursera: {
    en: `Offers online courses from universities and institutions covering a wide range of subjects for high school students.`,
    fr: `Propose des cours en ligne dispensés par des universités et des institutions couvrant un large éventail de sujets pour les lycéens.`,
    ar: `يقدم دورات عبر الإنترنت من الجامعات والمؤسسات تغطي مجموعة واسعة من المواضيع لطلاب المدارس الثانوية.`,
  },
  KhanAcademy: {
    en: `Provides video lessons and practice exercises in math, science, history, economics, computer science, and more.`,
    fr: `Propose des leçons vidéo et des exercices pratiques en mathématiques, en sciences, en histoire, en économie, en informatique, et bien plus encore.`,
    ar: `يقدم دروس فيديو وتمارين عملية في الرياضيات والعلوم والتاريخ والاقتصاد وعلوم الحاسوب، وأكثر.`,
  },
  MITOpenCourseWare: {
    en: `Offers free access to course materials, lectures, and resources from various disciplines taught at the Massachusetts Institute of Technology.`,
    fr: `Offre un accès gratuit aux supports de cours, aux conférences et aux ressources provenant de diverses disciplines enseignées à l'Institut de technologie du Massachusetts.`,
    ar: `يوفر الوصول المجاني إلى مواد الدورات والمحاضرات والموارد من مجموعة من التخصصات المدرسة في معهد ماساتشوستس للتكنولوجيا.`,
  },
  Codecademy: {
    en: `Offers interactive lessons and projects suitable for middle school, high school, and beyond, catering to learners of different skill levels.`,
    fr: `Propose des leçons interactives et des projets adaptés aux collégiens, lycéens et au-delà, pour les apprenants de différents niveaux de compétences.`,
    ar: `يقدم دروسًا تفاعلية ومشاريع مناسبة لطلاب المرحلة المتوسطة والثانوية وما بعدها، وتلبية احتياجات المتعلمين ذوي المستويات المهارية المختلفة.`,
  },
  Udemy: {
    en: `Provides a wide range of courses on various subjects, including academic subjects and professional development.`,
    fr: `Propose une large gamme de cours sur divers sujets, y compris les matières académiques et le développement professionnel.`,
    ar: `يقدم مجموعة واسعة من الدورات في مواضيع مختلفة، بما في ذلك المواد الأكاديمية وتطوير المهارات المهنية.`,
  },
};

export const testimonials = {
  1: {
    en: `One of a kind establishment, thanks to its system promoting on
    the one hand an exceptional relationship between parents and the
    teaching staff and on the other hand an adequate educational
    system allowing any child, even with learning difficulties, to
    flourish.`,
    fr: `Un établissement unique en son genre, grâce à son système qui favorise d'une part une relation exceptionnelle entre les parents et le personnel enseignant, et d'autre part un système éducatif adapté permettant à chaque enfant, même en difficulté d'apprentissage, de s'épanouir.`,
    ar: `مؤسسة فريدة من نوعها، بفضل نظامها الذي يعزز من جهة العلاقة الاستثنائية بين الآباء وأعضاء هيئة التدريس، ومن جهة أخرى نظام تعليمي مناسب يسمح لأي طفل، حتى إذا كان يعاني من صعوبات في التعلم، أن يتطور وينمو.`,
  },
  2: {
    en: `Beautiful philosophy and practice of education!`,
    fr: `Belle philosophie et pratique de l'éducation !`,
    ar: `فلسفة وممارسة تعليمية رائعة!`,
  },
  3: {
    en: `I recommend this school. I've been studying there for 10 years.`,
    fr: `Je recommande cette école. J'y étudie depuis 10 ans.`,
    ar: `أوصي بهذه المدرسة. لقد درست هناك لمدة 10 سنوات.`,
  },
  4: {
    en: `I highly recommend Hilali Targa International School. I have 2
    children there (in elementary and middle school) I am very
    satisfied. They have a very good level, do follow-ups with
    students all the time, have a well-kept cafeteria. varied and
    very interesting activities, a pleasant setting. management is
    present on site ..... etc. Congratulations and thank you to the
    whole team.`,
    fr: `Je recommande vivement l'école internationale Hilali Targa. J'ai 2 enfants là-bas (à l'école primaire et au collège) et je suis très satisfait. Ils ont un très bon niveau, font des suivis avec les élèves tout le temps, ont une cafétéria bien entretenue, des activités variées et très intéressantes, un cadre agréable. La direction est présente sur place... etc. Félicitations et merci à toute l'équipe.`,
    ar: `أوصي بشدة بمدرسة هيلالي تارغا العالمية. لدي اثنين من الأطفال هناك (في المدرسة الابتدائية والمدرسة المتوسطة) وأنا راضٍ جدًا. لديهم مستوى جيد جدًا، يتابعون الطلاب طوال الوقت، لديهم كافتيريا نظيفة وأنشطة متنوعة ومثيرة للاهتمام، بيئة لطيفة. الإدارة حاضرة في الموقع... إلخ. تهانينا وشكراً للفريق بأكمله.`,
  },
};

export const letter = {
  title: {
    en: `Letter from the Founding Directors`,
    fr: `Lettre des Directeurs Fondateurs`,
    ar: `رسالة من المديرين المؤسسين`,
  },
  text: {
    en: `We would like to take this opportunity to express our gratitude to all
  of you for your continued support and dedication towards our school. It
  is hard to believe that we started this journey almost 3 decades ago,
  and we are proud of the progress that we have made in providing quality
  education to our students ever since. When we established our first
  institution in 1996, our goal was to create a school that would provide
  an education that would prepare our students for the ever-changing
  global landscape. We wanted to create an environment that would foster
  creativity, critical thinking, and an appreciation for diversity. Over
  the years, we have been privileged to witness the growth of our students
  as they have graduated and gone on to make their mark in the world. We
  have been fortunate to have a team of dedicated educators who have
  worked tirelessly to ensure that our students receive the best education
  possible. We are particularly proud of the various achievements that our
  school has accomplished over the years. Our students have consistently
  performed well academically, with many of them being accepted into some
  of the world's top universities. We have also developed different robust
  programs that have seen our students excel in various disciplines. As we
  look to the future, we are excited about the possibilities that lie
  ahead. We remain committed to providing our students with a high-quality
  education that prepares them for the challenges of the future. We will
  continue to invest in our teachers and infrastructure to ensure that we
  are providing the best possible education to our students. Finally, we
  would like to thank our parents, students, and staff for their continued
  support over the years. Your trust in our school is greatly appreciated,
  and we look forward to working together to ensure that our students
  continue to achieve great success.`,
    fr: `Nous souhaitons profiter de cette occasion pour exprimer notre gratitude à tous
  pour votre soutien continu et votre dévouement envers notre école. Il
  est difficile de croire que nous avons commencé ce voyage il y a près de 3 décennies,
  et nous sommes fiers des progrès que nous avons réalisés en offrant une éducation de qualité
  à nos élèves depuis lors. Lorsque nous avons créé notre première
  institution en 1996, notre objectif était de créer une école qui offrirait
  une éducation préparant nos élèves au paysage mondial en constante évolution. Nous voulions créer un environnement qui favoriserait
  la créativité, la pensée critique et l'appréciation de la diversité. Au fil des ans, nous avons eu le privilège de voir nos élèves grandir
  alors qu'ils ont obtenu leur diplôme et ont laissé leur empreinte dans le monde. Nous
  avons eu la chance d'avoir une équipe d'enseignants dévoués qui ont
  travaillé sans relâche pour garantir que nos élèves reçoivent la meilleure éducation
  possible. Nous sommes particulièrement fiers des différents succès que notre
  école a réalisés au fil des ans. Nos élèves ont toujours obtenu
  de bons résultats académiques, beaucoup d'entre eux étant admis dans certaines
  des meilleures universités du monde. Nous avons également développé différents
  programmes solides qui ont permis à nos élèves de se démarquer dans diverses disciplines. En envisageant l'avenir, nous sommes enthousiasmés par les possibilités qui se présentent
  devant nous. Nous restons engagés à fournir à nos élèves une éducation de haute qualité
  qui les prépare aux défis de l'avenir. Nous continuerons d'investir dans nos enseignants et notre infrastructure pour garantir que nous
  offrons la meilleure éducation possible à nos élèves. Enfin, nous
  tenons à remercier nos parents, nos élèves et notre personnel pour leur soutien continu au fil des ans. Votre confiance en notre école est grandement appréciée,
  et nous sommes impatients de travailler ensemble pour garantir que nos élèves
  continuent d'obtenir un grand succès.`,
    ar: `نود أن نستغل هذه الفرصة للتعبير عن امتناننا لكم جميعًا
  على دعمكم المستمر وتفانيكم نحو مدرستنا. من الصعب تصديق أننا بدأنا هذه الرحلة منذ حوالي 3 عقود،
  ونحن فخورون بالتقدم الذي حققناه في توفير التعليم ذو الجودة
  لطلابنا منذ ذلك الحين. عندما أسست أول مؤسسة لدينا في عام 1996، كان هدفنا إنشاء مدرسة تقدم
  تعليماً يعد طلابنا للمشهد العالمي المتغير باستمرار. أردنا إنشاء بيئة تعزز
  الإبداع والتفكير النقدي وتقدير التنوع. على مر السنين، كان لنا شرف مشاهدة نمو طلابنا
  بينما تخرجوا وذهبوا لترك بصمتهم في العالم. لقد كان لنا الحظ بأن لدينا فريق من المعلمين المتفانيين الذين
  عملوا بلا كلل لضمان حصول طلابنا على أفضل تعليم ممكن.
  نحن فخورون بشكل خاص بالإنجازات المختلفة التي حققتها مدرستنا على مر السنين. طلابنا
  حققوا تحصيلًا أكاديميًا ممتازًا باستمرار، حيث تم قبول العديد منهم في بعض
  من أفضل الجامعات في العالم. لقد قمنا أيضًا بتطوير برامج قوية متنوعة ساهمت في تفوق طلابنا في مجالات مختلفة. ونحن
  ننظر إلى المستقبل بشغف بالإمكانيات التي تنتظرنا. نحن ملتزمون بتوفير تعليم عالي الجودة
  لطلابنا يجهزهم لمواجهة تحديات المستقبل. سنواصل الاستثمار في معلمينا وبنيتنا التحتية لضمان أننا
  نقدم أفضل تعليم ممكن لطلابنا. أخيرًا، نود أن نشكر أولياء الأمور والطلاب والموظفين على دعمهم المستمر على مر السنين. نقدر كثيرا ثقتكم في مدرستنا،
  ونتطلع إلى العمل معًا لضمان أن طلابنا يستمرون في تحقيق النجاح الكبير.`,
  },
  signature: {
    en: `Sincerely, <br /> Mr. and Mrs. Hilali <br /> Founders and Directors of HTIS`,
    fr: `Cordialement, <br /> M. et Mme Hilali <br /> Fondateurs et Directeurs de HTIS`,
    ar: `مع خالص التحية <br /> السيد والسيدة الهلالي`,
  },
};

export const MUNIDA = {
  title: {
    en: "Young Ambassadors to the UN",
    fr: "Jeunes Ambassadeurs auprès de l'ONU",
    ar: "سفراء الشباب للأمم المتحدة",
  },
  text: {
    en: `At HTIS, we believe in empowering our students to become global citizens
    who can make a positive impact on the world. Through our partnership
    with the Italian Diplomatic Academy (IDA), we offer our students the
    opportunity to become Young Ambassadors to the United Nations.
    <br /> This program is designed for students who are passionate about
    diplomacy, international relations, and social justice. As Young
    Ambassadors, students will have the chance to participate in
    international conferences and workshops, attend lectures and meetings
    with diplomats, and engage with other young leaders from around the
    world. <br />
    Our Young Ambassadors program is open to students from all backgrounds
    and academic disciplines. Whether you aspire to work in government,
    international organizations, or the non-profit sector, this program will
    provide you with the skills, knowledge, and network you need to succeed.`,
    fr: `À HTIS, nous croyons en l'autonomisation de nos étudiants pour devenir des citoyens du monde
    qui peuvent avoir un impact positif sur le monde. Grâce à notre partenariat
    avec l'Académie diplomatique italienne (IDA), nous offrons à nos étudiants la
    possibilité de devenir de jeunes ambassadeurs auprès des Nations Unies.
    <br /> Ce programme est conçu pour les étudiants passionnés par
    la diplomatie, les relations internationales et la justice sociale. En tant que jeunes
    ambassadeurs, les étudiants auront la possibilité de participer à
    des conférences et des ateliers internationaux, d'assister à des conférences et des réunions
    avec des diplomates, et d'échanger avec d'autres jeunes leaders du monde entier. <br />
    Notre programme de jeunes ambassadeurs est ouvert aux étudiants de tous horizons
    et de toutes disciplines académiques. Que vous aspiriez à travailler dans le gouvernement,
    les organisations internationales ou le secteur à but non lucratif, ce programme vous
    fournira les compétences, les connaissances et le réseau dont vous avez besoin pour réussir.`,
    ar: `نحن في HTIS نؤمن بتمكين طلابنا ليصبحوا مواطنين عالميين
    قادرين على إحداث تأثير إيجابي على العالم. من خلال شراكتنا
    مع الأكاديمية الدبلوماسية الإيطالية (IDA) ، نقدم لطلابنا
    فرصة أن يصبحوا سفراء شباب للأمم المتحدة.
    <br /> هذا البرنامج مصمم للطلاب الذين يشتهون
    الدبلوماسية والعلاقات الدولية والعدالة الاجتماعية. كسفراء
    شباب ، سيتاح للطلاب فرصة المشاركة في
    المؤتمرات وورش العمل الدولية ، وحضور المحاضرات والاجتماعات
    مع الدبلوماسيين ، والتفاعل مع قادة شباب آخرين من جميع
    أنحاء العالم. <br />
    برنامج سفراء الشباب لدينا مفتوح لطلاب جميع الخلفيات
    والتخصصات الأكاديمية. سواء كنت تطمح للعمل في الحكومة
    أو المنظمات الدولية أو القطاع غير الربحي ، فإن هذا البرنامج سوف
    يزودك بالمهارات والمعرفة والشبكة التي تحتاجها لتحقيق النجاح.`,
  },
};

export const orientation = {
  title: {
    en: "Orientation",
    fr: "Orientation",
    ar: "التوجيه",
  },
  text: {
    en: `At HTIS, we believe that one of the most important decisions a student
    can make is choosing their academic path for the future. That's why we
    offer a comprehensive orientation program to help our students make
    informed decisions about their future studies and career goals.
    <br />
    Our orientation program is designed to provide students with all the
    information they need to make the right choices. We offer guidance and
    counseling services to help students identify their strengths,
    interests, and aspirations. Through our program, students can explore
    their options and make informed decisions about their academic and
    professional future.
    <br />
    One of the main options available to our students is the choice between
    two academic tracks: Physical Sciences or Mathematics Sciences. Both
    tracks are rigorous and demanding, but they offer students a unique and
    enriching experience that prepares them for the future.
    <br />
    Our orientation program also includes workshops, seminars, and
    presentations from professionals in various fields. This allows our
    students to learn about the different career options available to them
    and the requirements needed to pursue those careers.
    <br />
    At HTIS, we believe that our orientation program is an essential part of
    our students' education. It equips them with the knowledge and skills
    they need to succeed in their academic and professional pursuits. We are
    proud of the success our students have achieved, and we are committed to
    supporting them every step of the way.`,
    fr: `À HTIS, nous croyons que l'une des décisions les plus importantes qu'un étudiant
    peut prendre est de choisir sa voie académique pour l'avenir. C'est pourquoi nous
    proposons un programme d'orientation complet pour aider nos étudiants à prendre
    des décisions éclairées concernant leurs études futures et leurs objectifs de carrière.
    <br />
    Notre programme d'orientation est conçu pour fournir aux étudiants toutes les
    informations dont ils ont besoin pour faire les bons choix. Nous proposons des services
    d'orientation et de conseil pour aider les étudiants à identifier leurs forces,
    leurs intérêts et leurs aspirations. Grâce à notre programme, les étudiants peuvent explorer
    leurs options et prendre des décisions éclairées concernant leur avenir académique et
    professionnel.
    <br />
    L'un des principaux choix offerts à nos étudiants est le choix entre
    deux filières académiques : Sciences Physiques ou Sciences Mathématiques. Les deux
    filières sont rigoureuses et exigeantes, mais elles offrent aux étudiants une expérience unique et
    enrichissante qui les prépare pour l'avenir.
    <br />
    Notre programme d'orientation comprend également des ateliers, des séminaires et
    des présentations de professionnels de différents domaines. Cela permet à nos
    étudiants de découvrir les différentes options de carrière qui s'offrent à eux
    et les exigences nécessaires pour poursuivre ces carrières.
    <br />
    À HTIS, nous considérons que notre programme d'orientation est une partie essentielle de
    l'éducation de nos étudiants. Il leur fournit les connaissances et les compétences
    dont ils ont besoin pour réussir dans leurs études et leurs projets professionnels. Nous sommes
    fiers de la réussite de nos étudiants et nous nous engageons à les soutenir à chaque étape du chemin.`,
    ar: `في HTIS ، نعتقد أن أحد أهم القرارات التي يمكن أن يتخذها الطالب هو اختيار مساره الأكاديمي للمستقبل. لهذا السبب نقدم برنامج توجيه شامل لمساعدة طلابنا على اتخاذ قرارات مدروسة بشأن دراساتهم المستقبلية وأهدافهم المهنية.
    <br />
    تم تصميم برنامج التوجيه الخاص بنا لتزويد الطلاب بجميع المعلومات التي يحتاجونها لاتخاذ القرارات الصائبة. نحن نقدم خدمات التوجيه والاستشارة لمساعدة الطلاب في تحديد نقاط قوتهم واهتماماتهم وتطلعاتهم. من خلال برنامجنا ، يمكن للطلاب استكشاف خياراتهم واتخاذ قرارات مدروسة بشأن مستقبلهم الأكاديمي والمهني.
    <br />
    إحدى الخيارات الرئيسية المتاحة لطلابنا هي الاختيار بين مسارين أكاديميين: العلوم الفيزيائية أو العلوم الرياضية. كلا المسارين صعبين ومتطلبين ، ولكنهما يوفران للطلاب تجربة فريدة ومثمرة تمهد لهم الطريق للمستقبل.
    <br />
    يشمل برنامج التوجيه الخاص بنا أيضًا ورش عمل وندوات وعروض من متخصصين في مجالات مختلفة. يتيح هذا لطلابنا التعرف على خيارات الوظائف المختلفة المتاحة لهم والمتطلبات اللازمة لمتابعة تلك الوظائف.
    <br />
    في HTIS ، نعتقد أن برنامج التوجيه لدينا هو جزء أساسي من تعليم طلابنا. إنه يزودهم بالمعرفة والمهارات التي يحتاجونها للنجاح في دراستهم ومسيرتهم المهنية. نحن فخورون بالنجاحات التي حققها طلابنا ، ونلتزم بدعمهم في كل خطوة يخطونها.`,
  },
};

export const project = {
  title: {
    en: `Our School Project`,
    fr: `Notre Projet Scolaire`,
    ar: `مشروعنا المدرسي`,
  },
  slogan1: {
    en: `An Establishment With An `,
    fr: `Un Établissement Avec Une `,
    ar: `مؤسسة ذات `,
  },
  slogan2: {
    en: `International Dimension`,
    fr: `Dimension Internationale`,
    ar: `بعد دولي`,
  },
  slogan3: {
    en: `and <br /> An Academic Career of`,
    fr: `et <br /> Un Parcours Académique d'`,
    ar: `و <br /> مسار أكاديمي من `,
  },
  slogan4: {
    en: `Excellence`,
    fr: `Excellence`,
    ar: `التفوق`,
  },
  text: {
    en: `At Hilali Targa International School, our mission is to provide a
    high-quality education that prepares our students for the challenges of
    the future. Our goal is to create a school culture that fosters
    creativity, critical thinking, and an appreciation for diversity.
    <br />
    We believe that every student has the potential to achieve great things,
    and we are committed to helping our students realize their full
    potential. Our curriculum is designed to provide our students with a
    well-rounded education that prepares them for success in all aspects of
    life.
    <br />
    Our school project is centered around four key pillars:
    <ol>
      <li>
        <b>Academic Excellence:</b> Our curriculum is designed to challenge
        our students and provide them with a solid foundation in core
        academic subjects such as Mathematics, Science, Language Arts, and
        Social Studies. Our teachers are highly qualified and are committed
        to providing a stimulating learning environment that encourages
        students to take risks and explore new ideas.
      </li>
      <li>
        <b>Character Development:</b> We believe that character development
        is just as important as academic achievement. Our school project
        includes programs that promote character development, such as
        community service, leadership development, and ethical
        decision-making.
      </li>
      <li>
        <b>Global Citizenship:</b> In today's interconnected world, it is
        important for students to develop an appreciation for diversity and
        an understanding of other cultures. Our school project includes
        programs that promote global citizenship, such as cultural exchange
        programs, foreign language instruction, and international travel
        opportunities.
      </li>
      <li>
        <b>Innovation and Creativity:</b> We believe that innovation and
        creativity are essential skills for success in the 21st century. Our
        school project includes programs that promote innovation and
        creativity, such as robotics, computer programming, and the arts.
      </li>
    </ol>
    We are committed to providing our students with a safe and nurturing
    environment that encourages learning and growth. We believe that
    education is a partnership between students, parents, and teachers, and
    we are committed to working together to ensure the success of our
    students.`,
    fr: `À Hilali Targa International School, notre mission est de fournir une éducation de qualité qui prépare nos étudiants aux défis de l'avenir. Notre objectif est de créer une culture scolaire qui favorise la créativité, la pensée critique et l'appréciation de la diversité.
    <br />
    Nous croyons que chaque étudiant a le potentiel de réaliser de grandes choses, et nous nous engageons à aider nos étudiants à réaliser leur plein potentiel. Notre programme d'études est conçu pour fournir à nos étudiants une éducation globale qui les prépare au succès dans tous les aspects de la vie.
    <br />
    Notre projet scolaire repose sur quatre piliers fondamentaux :
    <ol>
      <li>
        <b>Excellence Académique :</b> Notre programme d'études est conçu pour challenger nos étudiants et leur fournir une base solide dans les matières académiques essentielles telles que les mathématiques, les sciences, la langue et la littérature, et les sciences sociales. Nos enseignants sont hautement qualifiés et s'engagent à fournir un environnement d'apprentissage stimulant qui encourage les étudiants à prendre des risques et à explorer de nouvelles idées.
      </li>
      <li>
        <b>Développement du Caractère :</b> Nous croyons que le développement du caractère est aussi important que la réussite académique. Notre projet scolaire inclut des programmes qui favorisent le développement du caractère, tels que le service communautaire, le développement du leadership et la prise de décisions éthiques.
      </li>
      <li>
        <b>Citoyenneté Mondiale :</b> Dans le monde interconnecté d'aujourd'hui, il est important pour les étudiants de développer une appréciation de la diversité et une compréhension des autres cultures. Notre projet scolaire comprend des programmes qui favorisent la citoyenneté mondiale, tels que les programmes d'échange culturel, l'enseignement des langues étrangères et les opportunités de voyages internationaux.
      </li>
      <li>
        <b>Innovation et Créativité :</b> Nous croyons que l'innovation et la créativité sont des compétences essentielles pour réussir au XXIe siècle. Notre projet scolaire comprend des programmes qui favorisent l'innovation et la créativité, tels que la robotique, la programmation informatique et les arts.
      </li>
    </ol>
    Nous nous engageons à offrir à nos étudiants un environnement sûr et propice à l'apprentissage et à la croissance. Nous croyons que l'éducation est un partenariat entre les étudiants, les parents et les enseignants, et nous nous engageons à travailler ensemble pour assurer la réussite de nos étudiants.`,
    ar: `في Hilali Targa International School، نؤمن بتمكين طلابنا ليصبحوا مواطنين عالميين يمكنهم تحقيق تأثير إيجابي على العالم. من خلال شراكتنا مع الأكاديمية الدبلوماسية الإيطالية (IDA)، نقدم لطلابنا فرصة أن يصبحوا سفراءً شبابًا للأمم المتحدة.
    <br /> هذا البرنامج مصمم للطلاب الذين يهتمون بالدبلوماسية والعلاقات الدولية والعدالة الاجتماعية. بصفتهم سفراء شباب، سيتاح للطلاب الفرصة للمشاركة في المؤتمرات وورش العمل الدولية وحضور المحاضرات والاجتماعات مع الدبلوماسيين والتفاعل مع القادة الشباب من جميع أنحاء العالم.
    <br />
    برنامجنا للسفراء الشباب متاح للطلاب من جميع الخلفيات والتخصصات الأكاديمية. سواء كنت تطمح للعمل في الحكومة أو المنظمات الدولية أو القطاع غير الربحي، سيوفر لك هذا البرنامج المهارات والمعرفة والشبكة التي تحتاجها لتحقيق النجاح.`,
  },
};

export const publicSpeaking = {
  title: {
    en: `Public Speaking`,
    fr: `Expression Publique`,
    ar: `الخطابة العامة`,
  },
  text: {
    en: `At HTIS, we recognize the importance of effective communication skills,
  particularly in the area of public speaking. Our school provides a safe
  and supportive environment for students to develop their public speaking
  skills, and we encourage all students to participate in various speaking
  opportunities. <br />
  Throughout the school year, we organize public speaking events and
  competitions, ranging from class presentations to school-wide speeches.
  These opportunities allow our students to practice and refine their
  skills, gain confidence, and develop their own unique style.
  <br />
  Our school also offers public speaking workshops and coaching sessions
  to help students improve their skills and prepare for upcoming events.
  Our experienced teachers and trained coaches provide guidance on
  speechwriting, delivery techniques, and overcoming nervousness and
  anxiety. <br />
  At HTIS, we believe that public speaking is an essential skill for
  success, and we are committed to helping our students become effective
  communicators and confident speakers.`,
    fr: `Chez HTIS, nous reconnaissons l'importance des compétences en communication efficace,
  notamment dans le domaine de l'expression publique. Notre école offre un
  environnement sûr et favorable pour permettre aux élèves de développer
  leurs compétences en expression publique, et nous encourageons tous les
  élèves à participer à diverses occasions de prise de parole. <br />
  Tout au long de l'année scolaire, nous organisons des événements et des
  concours d'expression publique, allant des présentations en classe aux
  discours à l'échelle de l'école. Ces occasions permettent à nos élèves de
  pratiquer et de perfectionner leurs compétences, de gagner en confiance et
  de développer leur propre style unique.
  <br />
  Notre école propose également des ateliers et des séances de coaching en
  expression publique pour aider les élèves à améliorer leurs compétences et
  à se préparer aux événements à venir. Nos enseignants expérimentés et nos
  coachs qualifiés offrent des conseils sur la rédaction de discours, les
  techniques de présentation et la gestion du trac et de l'anxiété. <br />
  Chez HTIS, nous considérons que l'expression publique est une compétence
  essentielle pour réussir, et nous nous engageons à aider nos élèves à
  devenir des communicateurs efficaces et des orateurs confiants.`,
    ar: `في HTIS ، ندرك أهمية مهارات الاتصال الفعالة ،
  خاصة في مجال الخطابة العامة. توفر مدرستنا بيئة آمنة
  وداعمة لتطوير مهارات الخطابة العامة لدى الطلاب ، ونشجع جميع الطلاب
  على المشاركة في فرص الكلام المختلفة. <br />
  طوال العام الدراسي ، ننظم فعاليات ومسابقات للخطابة العامة ،
  تتراوح بين تقديمات الفصل وخطب على مستوى المدرسة.
  تتيح هذه الفرص لطلابنا ممارسة وتنمية مهاراتهم ، واكتساب الثقة ،
  وتطوير أسلوبهم الفريد الخاص بهم.
  <br />
  تقدم مدرستنا أيضًا ورش عمل وجلسات تدريب في الخطابة العامة
  لمساعدة الطلاب على تحسين مهاراتهم والاستعداد للفعاليات القادمة.
  يقدم معلمونا ذوو الخبرة والمدربون المتدربون إرشادات حول
  كتابة الخطاب ، وتقنيات التقديم ، والتغلب على القلق والقلق. <br />
  في HTIS ، نعتقد أن الخطابة العامة هي مهارة أساسية للنجاح ،
  ونحن ملتزمون بمساعدة طلابنا على أن يصبحوا متحدثين فعّالين
  وأشخاص يتحدثون بثقة.`,
  },
};

export const radio = {
  title: {
    en: `School Radio`,
    fr: `Radio Scolaire`,
    ar: `إذاعة المدرسة`,
  },
  text: {
    en: `Welcome to HTIS Radio, where the students take the mic and make their
  voices heard! Our radio station is run entirely by our students, who
  bring you the latest news, music, and entertainment straight from the
  heart of our school. <br />
  Our hosts and organizers work hard to create content that appeals to all
  of our listeners, from interviews with teachers and fellow students, to
  discussions on current events, to music shows featuring a variety of
  genres.
  <br /> We believe that the school radio is not only a great way to
  showcase the talent and creativity of our students, but also an
  important platform to share ideas, perspectives, and stories that matter
  to our community. <br />
  So, tune in to HTIS Radio and join the conversation!`,
    fr: `Bienvenue à la Radio HTIS, où les étudiants prennent le micro et se
  font entendre ! Notre station de radio est entièrement gérée par nos
  étudiants, qui vous apportent les dernières nouvelles, de la musique et
  du divertissement tout droit de notre école. <br />
  Nos animateurs et organisateurs travaillent dur pour créer du contenu
  qui plaise à tous nos auditeurs, des interviews avec les enseignants et
  les autres étudiants, des discussions sur l'actualité, des émissions de
  musique couvrant une variété de genres.
  <br /> Nous pensons que la radio scolaire est non seulement un excellent
  moyen de mettre en valeur le talent et la créativité de nos étudiants,
  mais aussi une plateforme importante pour partager des idées, des points
  de vue et des histoires qui sont importantes pour notre communauté. <br />
  Alors, branchez-vous sur la Radio HTIS et participez à la conversation!`,
    ar: `مرحبًا بك في راديو HTIS، حيث يأخذ الطلاب الميكروفون ويجعلون أصواتهم
  مسموعة! إذاعتنا تعمل بالكامل من قبل طلابنا، الذين يقدمون لكم آخر الأخبار
  والموسيقى والترفيه مباشرةً من قلب مدرستنا. <br />
  يعمل مقدمو البرامج والمنظمون بجد لإنشاء محتوى يلائم جميع
  المستمعين لدينا، من المقابلات مع المعلمين وزملاء الطلاب، إلى
  المناقشات حول الأحداث الجارية، إلى برامج الموسيقى التي تقدم مجموعة
  متنوعة من الأنواع الموسيقية.
  <br /> نعتقد أن الراديو المدرسي ليس فقط وسيلة رائعة لعرض موهبة وإبداع
  طلابنا، ولكنه أيضًا منصة مهمة لمشاركة الأفكار والآراء والقصص التي تهم
  مجتمعنا. <br />
  لذا، انضم إلى راديو HTIS وانضم إلى المحادثة!`,
  },
};

export const rules = {
  title: {
    en: `School Rules`,
    fr: `Règles de l'école`,
    ar: `قوانين المدرسة`,
  },
  text: {
    en: `We believe that a safe and respectful environment is essential for effective learning and personal growth. To ensure the well-being and success of all our students, we have established a set of school rules that everyone is expected to follow. These rules are designed to create a positive and inclusive atmosphere where everyone can thrive.<br/>
    <ol><li><b>Respect for Others:</b> We promote kindness, respect, and empathy towards all members of our school community. Treat others as you would like to be treated, and embrace the diversity of backgrounds, perspectives, and cultures within our school.</li>
    <li><b>Academic Integrity:</b> We value honesty and integrity in all academic endeavors. Plagiarism, cheating, or any form of academic dishonesty is strictly prohibited. Uphold the principles of intellectual honesty and give credit where it is due.</li>
    <li><b>Responsible Behavior:</b> Take responsibility for your actions and choices. Be accountable for your behavior both inside and outside the school premises. Avoid engaging in any form of bullying, harassment, or disruptive behavior that may hinder the learning environment.</li>
    <li><b>Attendance and Punctuality:</b> Regular attendance and punctuality are important for academic progress. Attend all classes, arrive on time, and notify the school in case of absence or tardiness. Consistent attendance helps maintain a conducive learning environment.</li>
    <li><b>Dress Code:</b> Dress appropriately and adhere to the school's dress code policy. Maintain a neat and modest appearance that reflects professionalism and respect for oneself and others.</li>
    <li><b>Technology Usage:</b> Use technology responsibly and in accordance with the school's guidelines. Respect the privacy and intellectual property rights of others. Avoid accessing inappropriate content or engaging in cyberbullying or online harassment.</li>
    </ol> `,
    fr: `Nous croyons qu'un environnement sûr et respectueux est essentiel pour un apprentissage efficace et une croissance personnelle. Afin d'assurer le bien-être et la réussite de tous nos élèves, nous avons établi un ensemble de règles scolaires que tout le monde est tenu de suivre. Ces règles sont conçues pour créer une atmosphère positive et inclusive où chacun peut s'épanouir.<br/>
    <ol><li><b>Respect des autres :</b> Nous encourageons la gentillesse, le respect et l'empathie envers tous les membres de notre communauté scolaire. Traitez les autres comme vous aimeriez être traité, et appréciez la diversité des origines, des perspectives et des cultures au sein de notre école.</li>
    <li><b>Intégrité académique :</b> Nous valorisons l'honnêteté et l'intégrité dans toutes les entreprises académiques. Le plagiat, la tricherie ou toute forme de malhonnêteté académique sont strictement interdits. Respectez les principes de l'honnêteté intellectuelle et donnez du crédit là où il est dû.</li>
    <li><b>Comportement responsable :</b> Assumez la responsabilité de vos actions et de vos choix. Soyez responsable de votre comportement à la fois à l'intérieur et à l'extérieur de l'établissement scolaire. Évitez de vous livrer à toute forme de harcèlement, d'intimidation ou de comportement perturbateur pouvant entraver l'environnement d'apprentissage.</li>
    <li><b>Assiduité et ponctualité :</b> Une assiduité régulière et la ponctualité sont importantes pour les progrès scolaires. Assistez à tous les cours, arrivez à l'heure et informez l'école en cas d'absence ou de retard. Une assiduité constante contribue à maintenir un environnement d'apprentissage propice.</li>
    <li><b>Code vestimentaire :</b> Habillez-vous de manière appropriée et respectez la politique vestimentaire de l'école. Maintenez une apparence soignée et modeste qui reflète le professionnalisme et le respect de soi-même et des autres.</li>
    <li><b>Utilisation de la technologie :</b> Utilisez la technologie de manière responsable et conformément aux directives de l'école. Respectez la vie privée et les droits de propriété intellectuelle des autres. Évitez d'accéder à du contenu inapproprié ou de vous livrer à du cyberharcèlement ou du harcèlement en ligne.</li>
    </ol> `,
    ar: `نعتقد أن البيئة الآمنة والمحترمة أمر ضروري للتعلم الفعال والنمو الشخصي. من أجل ضمان رفاهية ونجاح جميع طلابنا، قمنا بوضع مجموعة من القوانين المدرسية التي من المتوقع أن يلتزم بها الجميع. تم تصميم هذه القوانين لخلق جو إيجابي وشامل يتيح للجميع الازدهار.<br/>
    <ol><li><b>احترام الآخرين:</b> نشجع اللطف والاحترام والتعاطف تجاه جميع أعضاء مجتمعنا المدرسي. عامل الآخرين كما تحب أن يعاملوك، وتقبل تنوع الخلفيات والآراء والثقافات داخل مدرستنا.</li>
    <li><b>النزاهة الأكاديمية:</b> نقدر النزاهة والنزاهة في جميع الجهود الأكاديمية. يُحظر بشدة الانتحال، الغش أو أي شكل من أشكال الغش الأكاديمي. حافظ على مبادئ النزاهة الفكرية وامنح الاعتراف حيث يستحق.</li>
    <li><b>السلوك المسؤول:</b> تحمل مسؤولية أفعالك واختياراتك. كن مسؤولاً عن سلوكك داخل المدرسة وخارجها. تجنب المشاركة في أي شكل من أشكال التنمر أو التحرش أو السلوك المزعج الذي قد يعيق بيئة التعلم.</li>
    <li><b>الحضور والانتظام:</b> الحضور الدائم والانتظام في المواعيد المحددة مهمان للتقدم الأكاديمي. حضور جميع الحصص، والوصول في الموعد المحدد، وإبلاغ المدرسة في حالة الغياب أو التأخر. الحضور المنتظم يساعد في الحفاظ على بيئة تعلم ملائمة.</li>
    <li><b>شروط اللباس:</b> ارتد الزي الملائم والالتزام بسياسة زي المدرسة. حافظ على مظهر نظيف ومحتشم يعكس الاحترافية والاحترام للذات وللآخرين.</li>
    <li><b>استخدام التكنولوجيا:</b> استخدم التكنولوجيا بشكل مسؤول ووفقًا لإرشادات المدرسة. احترم خصوصية الآخرين وحقوق الملكية الفكرية. تجنب الوصول إلى محتوى غير مناسب أو المشاركة في التنمر الإلكتروني أو التحرش عبر الإنترنت.</li>
    </ol> `,
  },
};

export const scholarships = {
  title: {
    en: `Scholarships`,
    fr: `Bourses d'études`,
    ar: `المنح الدراسية`,
  },
  text: {
    en: `At HTIS, we are committed to supporting our students' pursuit of higher education and providing them with valuable opportunities for personal and academic growth. One of the ways we do this is by helping students find scholarships for their educational journey. We understand that financial assistance can play a crucial role in making dreams come true and opening doors to a brighter future.<br/>
    Through our dedicated scholarship program, HTIS connects students with various scholarship opportunities both within the country and internationally. We collaborate with esteemed organizations, universities, and guest speakers who specialize in scholarship programs to provide valuable insights and guidance.<br/>
    HTIS hosts informative sessions and workshops where experts introduce students to a wide range of scholarships available. These sessions cover different types of scholarships, eligibility criteria, application processes, and tips for crafting compelling scholarship applications. Our goal is to empower students with the knowledge and resources they need to successfully navigate the scholarship landscape.<br/>
    We also facilitate connections with scholarship providers and organizations that offer financial aid and support to students with exceptional talents, academic achievements, or specific areas of interest. Whether it's academic scholarships, sports scholarships, artistic scholarships, or scholarships for underrepresented groups, HTIS strives to broaden the horizons of our students and help them access opportunities that align with their aspirations.<br/>
    Furthermore, we encourage students at HTIS to actively explore scholarship databases, online platforms, and governmental programs to find additional scholarship options. Our dedicated team is always available to assist students in their search, provide guidance, and offer personalized advice based on their unique goals and aspirations.<br/>
    At HTIS, we firmly believe that every student deserves an equal opportunity to pursue their dreams and achieve their full potential. We are dedicated to providing comprehensive support and resources to help students at HTIS unlock scholarship opportunities that can make a significant difference in their educational journey.`,
    fr: `À HTIS, nous nous engageons à soutenir la poursuite des études supérieures de nos élèves et à leur offrir de précieuses opportunités de croissance personnelle et académique. L'un des moyens que nous utilisons pour cela est d'aider les élèves à trouver des bourses pour leur parcours éducatif. Nous comprenons que l'aide financière peut jouer un rôle crucial pour réaliser des rêves et ouvrir les portes d'un avenir meilleur.<br/>
    Grâce à notre programme de bourses dédié, HTIS met en relation les élèves avec diverses opportunités de bourses, tant au niveau national qu'international. Nous collaborons avec des organisations prestigieuses, des universités et des conférenciers invités spécialisés dans les programmes de bourses pour fournir des informations précieuses et des conseils.<br/>
    HTIS organise des sessions informatives et des ateliers où des experts présentent aux élèves une large gamme de bourses disponibles. Ces sessions couvrent différents types de bourses, les critères d'éligibilité, les processus de candidature et des conseils pour rédiger des demandes de bourses convaincantes. Notre objectif est de donner aux élèves les connaissances et les ressources nécessaires pour naviguer avec succès dans le paysage des bourses.<br/>
    Nous facilitons également les connexions avec les fournisseurs de bourses et les organisations qui offrent une aide financière et un soutien aux élèves dotés de talents exceptionnels, de réalisations académiques ou d'intérêts spécifiques. Qu'il s'agisse de bourses académiques, de bourses sportives, de bourses artistiques ou de bourses pour les groupes sous-représentés, HTIS s'efforce d'élargir les horizons de nos élèves et de les aider à accéder à des opportunités qui correspondent à leurs aspirations.<br/>
    De plus, nous encourageons les élèves d'HTIS à explorer activement les bases de données de bourses, les plateformes en ligne et les programmes gouvernementaux pour trouver d'autres options de bourses. Notre équipe dévouée est toujours disponible pour aider les élèves dans leur recherche, fournir des conseils et offrir des conseils personnalisés en fonction de leurs objectifs et aspirations uniques.<br/>
    À HTIS, nous croyons fermement que chaque élève mérite une chance égale de poursuivre ses rêves et d'atteindre son plein potentiel. Nous nous engageons à fournir un soutien complet et des ressources pour aider les élèves d'HTIS à débloquer des opportunités de bourses qui peuvent faire une différence significative dans leur parcours éducatif.`,
    ar: `في HTIS ، نلتزم بدعم طلابنا في سعيهم للتعليم العالي وتوفير فرص قيّمة للنمو الشخصي والأكاديمي. أحد الطرق التي نستخدمها لتحقيق ذلك هو مساعدة الطلاب في العثور على المنح الدراسية لرحلتهم التعليمية. نحن ندرك أن المساعدة المالية يمكن أن تلعب دورًا حاسمًا في تحقيق الأحلام وفتح أبواب مستقبل أفضل.<br/>
    من خلال برنامج المنح الدراسية المكرس لدينا ، تتيح HTIS للطلاب فرصًا متنوعة للحصول على المنح الدراسية سواء داخل البلاد أو في المستوى الدولي. نحن نتعاون مع منظمات مرموقة وجامعات ومتحدثين ضيوف متخصصين في برامج المنح الدراسية لتوفير رؤى قيمة وتوجيه.<br/>
    تنظم HTIS جلسات إعلامية وورش عمل حيث يقدم الخبراء للطلاب مجموعة متنوعة من المنح الدراسية المتاحة. تغطي هذه الجلسات مختلف أنواع المنح الدراسية ومعايير الأهلية وعمليات التقديم ونصائح لكتابة طلبات المنح الدراسية المقنعة. هدفنا هو تمكين الطلاب من المعرفة والموارد التي يحتاجونها للتنقل بنجاح في مجال المنح الدراسية.<br/>
    نحن أيضًا نسهل الاتصالات مع مقدمي المنح الدراسية والمنظمات التي تقدم المساعدة المالية والدعم للطلاب ذوي المواهب الاستثنائية أو التحصيل الأكاديمي أو المجالات المهتمين بها. سواء كانت المنح الأكاديمية أو المنح الرياضية أو المنح الفنية أو المنح للفئات التي تعاني من التمييز ، تسعى HTIS لتوسيع آفاق طلابنا ومساعدتهم في الوصول إلى الفرص التي تتوافق مع تطلعاتهم.<br/>
    علاوة على ذلك ، نشجع طلاب HTIS على استكشاف قواعد البيانات للمنح الدراسية والمنصات الإلكترونية وبرامج الحكومة للعثور على خيارات إضافية للمنح الدراسية. فريقنا المخصص متاح دائمًا لمساعدة الطلاب في بحثهم ، وتقديم الإرشادات ، وتقديم المشورة الشخصية بناءً على أهدافهم وتطلعاتهم الفريدة.<br/>
    في HTIS ، نعتقد بقوة أن كل طالب يستحق فرصة متساوية لتحقيق أحلامه وتحقيق إمكاناته الكاملة. نحن ملتزمون بتوفير الدعم الشامل والموارد لمساعدة طلاب HTIS في استكشاف فرص المنح الدراسية التي يمكن أن تحدث فرقًا كبيرًا في رحلتهم التعليمية.`,
  },
};

export const serviceToTranslate = {
  bus: {
    en: `Our school bus service provides safe and reliable transportation for
    students to and from school. Our buses are equipped with seat belts
    and are regularly inspected to ensure the safety of our students. We
    have designated bus routes and stops that cover most areas of the
    city.`,
    fr: `Notre service de bus scolaire assure un transport sûr et fiable des
    élèves aller-retour de l'école. Nos bus sont équipés de ceintures de
    sécurité et font l'objet d'inspections régulières pour garantir la
    sécurité de nos élèves. Nous disposons d'itinéraires et d'arrêts de
    bus désignés couvrant la plupart des quartiers de la ville.`,
    ar: `خدمة الحافلات المدرسية لدينا توفر وسيلة نقل آمنة وموثوقة للطلاب من وإلى
    المدرسة. تحتوي حافلاتنا على أحزمة أمان ويتم فحصها بشكل منتظم لضمان سلامة
    طلابنا. لدينا مسارات ومحطات حافلات محددة تغطي معظم مناطق المدينة.`,
  },
  cafeteria: {
    en: `Our cafeteria provides healthy and nutritious meals for our
    students. We offer a range of food options, including vegetarian and
    non-vegetarian dishes, and work with our nutritionist to ensure that
    our menu meets the dietary needs of our students. Our cafeteria
    staff takes pride in providing delicious meals that fuel our
    students for their day.`,
    fr: `Notre cafétéria propose des repas sains et nutritifs pour nos
    élèves. Nous proposons une gamme d'options alimentaires, y compris
    des plats végétariens et non végétariens, et travaillons avec notre
    nutritionniste pour garantir que notre menu réponde aux besoins
    alimentaires de nos élèves. Notre personnel de la cafétéria est fier
    de fournir des repas délicieux qui donnent de l'énergie à nos
    élèves tout au long de la journée.`,
    ar: `توفر مقصفنا وجبات صحية ومغذية لطلابنا. نحن نقدم مجموعة من الخيارات
    الغذائية، بما في ذلك الأطباق النباتية والغير نباتية، ونعمل مع أخصائي
    التغذية لضمان أن قائمة طعامنا تلبي الاحتياجات الغذائية لطلابنا.
    يفخر فريق المقصف لدينا بتقديم وجبات لذيذة تمد طلابنا بالطاقة طوال اليوم.`,
  },
  infirmary: {
    en: `Our infirmary provides a safe and caring environment for students
    who need medical attention during the school day. Our nurse is
    available on-site to provide basic first-aid care and attend to any
    medical needs of our students. We also have protocols in place to
    ensure the safety of our students with medical conditions.`,
    fr: `Notre infirmerie offre un environnement sûr et attentionné aux élèves
    qui ont besoin de soins médicaux pendant la journée scolaire. Notre
    infirmière est disponible sur place pour prodiguer des soins de
    premiers secours de base et répondre aux besoins médicaux de nos
    élèves. Nous avons également mis en place des protocoles pour
    garantir la sécurité de nos élèves atteints de problèmes de santé.`,
    ar: `توفر عيادتنا مكانًا آمنًا ومهتمًا للطلاب الذين يحتاجون إلى رعاية طبية
    خلال فترة الدراسة. ممرضتنا متواجدة في الموقع لتقديم الرعاية الأولية
    الأساسية والاهتمام بأي احتياجات طبية لطلابنا. لدينا أيضًا بروتوكولات
    محددة لضمان سلامة طلابنا الذين يعانون من حالات صحية.`,
  },
  tutoring: {
    en: `Our tutoring program provides academic support to students who need
    additional help with their studies. Our experienced tutors work
    one-on-one with students to develop their understanding of key
    concepts and improve their grades. We offer tutoring services in a
    range of subjects and work closely with parents to ensure that our
    tutoring program meets the individual needs of each student.`,
    fr: `Notre programme de tutorat offre un soutien académique aux élèves qui
    ont besoin d'une aide supplémentaire dans leurs études. Nos tuteurs
    expérimentés travaillent en tête-à-tête avec les élèves pour
    développer leur compréhension des concepts clés et améliorer leurs
    notes. Nous proposons des services de tutorat dans une variété de
    matières et travaillons en étroite collaboration avec les parents
    pour nous assurer que notre programme de tutorat répond aux besoins
    individuels de chaque élève.`,
    ar: `يوفر برنامج التدريس الخاص بنا الدعم الأكاديمي للطلاب الذين يحتاجون
    إلى مساعدة إضافية في دراستهم. يعمل أفراد فريقنا من المعلمين
    ذوي الخبرة مع الطلاب على أساس فردي لتطوير فهمهم للمفاهيم الرئيسية
    وتحسين درجاتهم. نحن نقدم خدمات التدريس الخاص في مجموعة من المواد
    ونعمل عن كثب مع أولياء الأمور لضمان أن برنامجنا للتدريس الخاص يلبي
    الاحتياجات الفردية لكل طالب.`,
  },
  library: {
    en: `Our library provides a rich and diverse collection of books and
    resources for our students. We encourage our students to develop a
    love of reading and provide a range of reading materials that cater
    to their interests and abilities. Our library staff is always
    available to help students find the right book and develop their
    research skills. We also offer computer access for research and
    projects.`,
    fr: `Notre bibliothèque met à disposition de nos élèves une collection
    riche et diversifiée de livres et de ressources. Nous encourageons
    nos élèves à développer l'amour de la lecture et proposons une gamme
    de documents adaptés à leurs intérêts et à leurs capacités. Notre
    personnel de bibliothèque est toujours disponible pour aider les
    élèves à trouver le bon livre et développer leurs compétences en
    recherche. Nous proposons également un accès aux ordinateurs pour la
    recherche et les projets.`,
    ar: `توفر مكتبتنا مجموعة غنية ومتنوعة من الكتب والموارد لطلابنا. نحن
    نشجع طلابنا على تنمية حب القراءة ونوفر مجموعة من المواد القرائية
    التي تلبي اهتماماتهم وقدراتهم. فريق مكتبتنا دائمًا متاح لمساعدة
    الطلاب في إيجاد الكتاب المناسب وتطوير مهارات البحث لديهم. نحن نوفر
    أيضًا وصولًا إلى الكمبيوتر للبحث والمشاريع.`,
  },
  ASC: {
    en: `Our after-school care program provides a safe and structured
    environment for students who need care after school hours. Our
    program includes a range of activities that help students develop
    their social skills, complete their homework, and have fun. Our
    after-school care staff is trained and experienced in working with
    children of all ages.`,
    fr: `Notre programme de garde après l'école offre un environnement sûr et
    structuré aux élèves qui ont besoin d'une prise en charge après les
    heures de classe. Notre programme comprend une gamme d'activités qui
    aident les élèves à développer leurs compétences sociales, à
    terminer leurs devoirs et à s'amuser. Notre personnel de garde
    après l'école est formé et expérimenté dans le travail avec les
    enfants de tous âges.`,
    ar: `يوفر برنامج رعاية الأطفال بعد المدرسة بيئة آمنة ومنظمة للطلاب الذين
    يحتاجون إلى الرعاية بعد ساعات المدرسة. يشمل برنامجنا مجموعة من
    الأنشطة التي تساعد الطلاب على تطوير مهاراتهم الاجتماعية وإكمال
    واجباتهم المنزلية والاستمتاع. يتم تدريب موظفي رعاية الأطفال بعد
    المدرسة ولديهم خبرة في العمل مع الأطفال من جميع الأعمار.`,
  },
};

export const sports = {
  title: {
    en: `Sports`,
    fr: `Sports`,
    ar: `الرياضة`,
  },
  text: {
    en: `At HTIS, we believe that sports play an important role in the
    development of our students, not only physically but also mentally and
    socially. We offer a range of sports programs to cater to the interests
    and abilities of all our students, from recreational to competitive
    levels. <br />
    Our Tigers Sports Club is a great way for students to get involved and
    stay active. We have teams in various sports such as basketball,
    volleyball, soccer, and track and field. Our coaches are experienced and
    dedicated to helping our students improve their skills and reach their
    potential. <br />
    Aside from the physical benefits of sports, our program also promotes
    teamwork, sportsmanship, and leadership. We encourage our athletes to
    display good sportsmanship and respect for their opponents, coaches, and
    officials at all times.
    <br />
    Through our sports program, we hope to instill in our students the
    values of discipline, dedication, and perseverance that they can carry
    with them beyond their time at HTIS. We are proud of our students'
    achievements in sports and look forward to continuing to support and
    develop their athletic abilities.`,
    fr: `À HTIS, nous croyons que le sport joue un rôle important dans le
    développement de nos étudiants, non seulement sur le plan physique, mais aussi
    sur les plans mental et social. Nous proposons une gamme de programmes
    sportifs pour répondre aux intérêts et aux capacités de tous nos étudiants,
    du récréatif au compétitif. <br />
    Notre club sportif Tigers est un excellent moyen pour les étudiants de s'impliquer et
    de rester actifs. Nous avons des équipes dans différents sports tels que le
    basketball, le volleyball, le football et l'athlétisme. Nos entraîneurs sont
    expérimentés et dévoués pour aider nos étudiants à améliorer leurs compétences et
    atteindre leur potentiel. <br />
    Outre les avantages physiques du sport, notre programme favorise également
    le travail d'équipe, l'esprit sportif et le leadership. Nous encourageons nos
    athlètes à faire preuve de bon esprit sportif et de respect envers leurs
    adversaires, leurs entraîneurs et les officiels en tout temps.
    <br />
    Grâce à notre programme sportif, nous espérons inculquer à nos étudiants les
    valeurs de discipline, de dévouement et de persévérance qu'ils pourront
    emporter avec eux au-delà de leur passage à l'HTIS. Nous sommes fiers des
    réalisations de nos étudiants dans le domaine sportif et avons hâte de
    continuer à les soutenir et à développer leurs compétences athlétiques.`,
    ar: `في HTIS، نؤمن بأهمية الرياضة في تنمية طلابنا، ليس فقط بدنياً ولكن أيضاً عقلياً واجتماعياً.
    نقدم مجموعة من البرامج الرياضية التي تلبي اهتمامات وقدرات جميع طلابنا، من المستوى الترفيهي إلى المستوى التنافسي. <br />
    نادينا الرياضي "Tigers" هو وسيلة رائعة للطلاب للمشاركة والبقاء نشطين. لدينا فرق في مختلف الرياضات مثل كرة السلة،
    الكرة الطائرة، كرة القدم وألعاب القوى. مدربونا ذوو خبرة وملتزمون بمساعدة طلابنا على تحسين مهاراتهم وتحقيق إمكاناتهم. <br />
    بالإضافة إلى الفوائد البدنية للرياضة، يعزز برنامجنا التعاون وروح المنافسة الرياضية والقيادة. نحن نشجع رياضيينا على
    الظهور بروح رياضية حسنة واحترام خصومهم ومدربيهم والحكام في جميع الأوقات.
    <br />
    من خلال برنامجنا الرياضي، نأمل أن نغرس في طلابنا قيم الانضباط والتفاني والصبر التي يمكنهم حملها معهم بعد مغادرتهم HTIS.
    نحن فخورون بإنجازات طلابنا في الرياضة ونتطلع إلى مواصلة دعمهم وتطوير قدراتهم الرياضية.`,
  },
};

export const tigersNights = {
  text: {
    en: `Are you ready for the ultimate night of fun, excitement and
    unforgettable memories? Look no further than Tigers Nights, the latest
    addition to our school traditions!
    <br />
    Organized by the Interact Club, Tigers Nights is the ultimate school
    event that you do not want to miss. From the moment you all step in, you'll
    be surrounded by a vibrant and lively atmosphere, filled with laughter
    and joy. Whether you're all looking to dance the night away, watch a movie
    with your friends, or test your skills at game stations, Tigers Nights
    has something for everyone. <br />
    But Tigers Nights is more than just fun and games. It's a chance for all of you
    to connect with your fellow students and build friendships that will
    last a lifetime. The games and activities are designed to promote
    teamwork, communication and problem-solving skills, allowing all of you to
    challenge yourselves and grow alongside your peers.
    <br />
    And let's not forget the delicious food that will be served. From savory
    to sweet, there's something to satisfy every craving.`,
    fr: `Êtes-vous prêts pour la nuit ultime d'amusement, d'excitation et de souvenirs inoubliables ? Ne cherchez pas plus loin que Tigers Nights, le dernier ajout à nos traditions scolaires !
    <br />
    Organisé par le Club Interact, Tigers Nights est l'événement scolaire ultime à ne pas manquer. Dès que vous entrez tous, vous serez entourés d'une atmosphère vibrante et animée, remplie de rires et de joie. Que vous souhaitiez danser toute la nuit, regarder un film avec vos amis ou tester vos compétences aux stations de jeux, Tigers Nights a quelque chose pour tout le monde. <br />
    Mais Tigers Nights, c'est plus que des jeux. C'est l'occasion pour vous tous de vous connecter avec vos camarades de classe et de construire des amitiés qui dureront toute une vie. Les jeux et les activités sont conçus pour promouvoir le travail d'équipe, la communication et les compétences en résolution de problèmes, vous permettant à tous de vous challenger et de grandir aux côtés de vos pairs.
    <br />
    Et n'oublions pas la délicieuse nourriture qui sera servie. Des plats salés aux sucreries, il y en a pour satisfaire toutes les envies.`,
    ar: `هل أنتم مستعدون لأروع ليلة مليئة بالمرح والإثارة والذكريات التي لا تُنسى؟ لا تبحثوا بعيدًا، فقط عن Tigers Nights، أحدث إضافة إلى تقاليدنا المدرسية!
    <br />
    منظمة من قبل نادي التفاعل، Tigers Nights هي الحدث المدرسي الأقصى الذي لا يمكنكم أن تفوتوه. من اللحظة التي تدخلون فيها، ستكونون محاطين بأجواء نابضة بالحياة والنشاط، مليئة بالضحك والفرح. سواء كنتم جميعًا تبحثون عن الرقص طوال الليل، أو مشاهدة فيلم مع أصدقائكم، أو اختبار مهاراتكم في محطات الألعاب، فإن Tigers Nights لديها شيء للجميع. <br />
    ولكن Tigers Nights هي أكثر من مجرد المرح والألعاب. إنها فرصة لكم جميعًا للتواصل مع زملائكم الطلاب وبناء صداقات ستدوم مدى الحياة. تم تصميم الألعاب والأنشطة لتعزيز العمل الجماعي والتواصل ومهارات حل المشكلات، مما يتيح لكم جميعًا تحدي أنفسكم والنمو بجانب أقرانكم.
    <br />
    ودعونا لا ننسى الطعام اللذيذ الذي سيتم تقديمه. من المأكولات اللذيذة إلى الحلويات، هناك شيء لإرضاء كل رغبة.`,
  },
  photoGallery: {
    en: `Photo Gallery`,
    fr: `Galerie de photos`,
    ar: `معرض الصور`,
  },
};

export const traditions = {
  title: {
    en: `Our Traditions`,
    fr: `Nos Traditions`,
    ar: `تقاليدنا`,
  },
  text1: {
    en: `Welcome to Hilali Targa International School (HTIS), a school steeped in
    rich traditions since its establishment in 1996. Our school has a proud
    history of nurturing young minds, fostering a sense of community, and
    promoting excellence in academics, sports, and extracurricular
    activities. Our traditions are the cornerstone of our school's culture
    and are cherished by our students, staff, and alumni alike.`,
    fr: `Bienvenue à Hilali Targa International School (HTIS), une école imprégnée de
    riches traditions depuis sa création en 1996. Notre école a une fière
    histoire de formation des jeunes esprits, de favorisation d'un sentiment de communauté et
    de promotion de l'excellence dans les domaines académiques, sportifs et extracurriculaires.
    Nos traditions sont la pierre angulaire de la culture de notre école
    et sont chéries par nos étudiants, notre personnel et nos anciens élèves.`,
    ar: `مرحبًا بكم في مدرسة الهلالي تارجة الدولية (HTIS)، وهي مدرسة عريقة في
    التقاليد الغنية منذ تأسيسها في عام 1996. تتميز مدرستنا بتاريخ فخور
    في تنمية العقول الشابة، وتعزيز الانتماء إلى المجتمع، و
    تعزيز التفوق في المجالات الأكاديمية والرياضية والنشاطات اللاصفية.
    تعتبر تقاليدنا أساس ثقافة مدرستنا
    وتحظى بتقدير من طلابنا وموظفينا وخريجينا على حد سواء.`,
  },
  text2: {
    en: `One of our longstanding traditions at HTIS is our annual Sports Day,
      which brings together students from all grades for a day of friendly
      competition and camaraderie. Students participate in a variety of
      track and field events, showcasing their athleticism, teamwork, and
      school spirit. The day is filled with cheers, colorful team banners,
      and a festive atmosphere that fosters a sense of belonging and pride
      in our school.`,
    fr: `L'une de nos traditions de longue date à HTIS est notre Journée sportive annuelle,
      qui réunit les étudiants de tous les niveaux pour une journée de compétition amicale
      et de camaraderie. Les étudiants participent à une variété d'épreuves
      d'athlétisme, mettant en valeur leur athlétisme, leur esprit d'équipe et
      l'esprit scolaire. La journée est remplie d'acclamations, de bannières colorées des équipes,
      et d'une atmosphère festive qui favorise un sentiment d'appartenance et de fierté
      envers notre école.`,
    ar: `واحدة من تقاليدنا القديمة في HTIS هي يومنا الرياضي السنوي،
      الذي يجمع طلاب جميع الصفوف ليوم من المنافسة الودية
      وروح الأخوة. يشارك الطلاب في مجموعة متنوعة من
      الفعاليات الرياضية والألعاب، حيث يبرزون روح الفريق والقوة البدنية و
      الروح المدرسية. اليوم مليء بالتصفيق والأعلام الملونة للفرق،
      وجو بهيج يعزز الانتماء والفخر
      بمدرستنا.`,
  },
  text3: {
    en: `Another cherished tradition at HTIS is our annual Cultural Festival,
      where students and staff come together to celebrate the diversity of
      our school community. Our students hail from various countries and
      cultures, and this festival provides a platform for them to showcase
      their unique traditions, music, dance, and cuisine. It promotes
      cultural exchange and understanding among our students and fosters a
      sense of global citizenship.`,
    fr: `Une autre tradition chère à HTIS est notre Festival culturel annuel,
      où les étudiants et le personnel se réunissent pour célébrer la diversité de
      notre communauté scolaire. Nos étudiants viennent de différents pays et
      cultures, et ce festival leur offre une plateforme pour présenter
      leurs traditions uniques, leur musique, leur danse et leur cuisine. Il favorise
      les échanges culturels et la compréhension entre nos étudiants et favorise un
      sentiment de citoyenneté mondiale.`,
    ar: `تقليد آخر يعتز به HTIS هو مهرجاننا الثقافي السنوي،
      حيث يجتمع الطلاب والموظفون للاحتفال بتنوع
      مجتمع مدرستنا. يأتي طلابنا من مختلف البلدان و
      الثقافات، ويوفر هذا المهرجان منصة لهم لعرض
      تقاليدهم الفريدة وموسيقاهم ورقصهم ومأكولاتهم. يعزز
      تبادل الثقافات والتفاهم بين طلابنا ويعزز
      شعورًا بالمواطنة العالمية.`,
  },
  text4: {
    en: `Community service is also deeply ingrained in our school traditions.
      HTIS encourages students to give back to the community through various
      service initiatives, such as volunteering at local charities,
      organizing fundraisers for social causes, and participating in
      environmental conservation projects. These activities instill in our
      students a sense of social responsibility and empathy towards others,
      helping them develop into compassionate and socially conscious
      individuals.`,
    fr: `Le service communautaire est également profondément ancré dans nos traditions scolaires.
      HTIS encourage les étudiants à redonner à la communauté grâce à diverses
      initiatives de service, telles que le bénévolat dans des œuvres de bienfaisance locales,
      l'organisation de collectes de fonds pour des causes sociales et la participation à
      des projets de conservation de l'environnement. Ces activités inculquent à nos
      étudiants un sens des responsabilités sociales et de l'empathie envers les autres,
      les aidant à se développer en tant qu'individus compatissants et socialement conscients.`,
    ar: `الخدمة المجتمعية جزء لا يتجزأ أيضًا من تقاليدنا المدرسية.
      يشجع HTIS الطلاب على إعادة العطاء للمجتمع من خلال مبادرات خدمية مختلفة،
      مثل التطوع في الجمعيات الخيرية المحلية،
      تنظيم حملات تمويل للقضايا الاجتماعية، والمشاركة في
      مشاريع الحفاظ على البيئة. تنمي هذه الأنشطة
      في طلابنا الشعور بالمسؤولية الاجتماعية والتعاطف تجاه الآخرين،
      مما يساعدهم على التطور والنمو كأفراد متعاطفين وواعين اجتماعيًا.`,
  },
  text5: {
    en: `Academic excellence is a core value at HTIS, and our school traditions
      reflect this commitment to high-quality education. We recognize and
      celebrate outstanding academic achievements through our annual
      academic awards ceremony, where students who have excelled in their
      studies are honored and applauded. This tradition motivates our
      students to strive for excellence in their academic pursuits and
      fosters a culture of continuous learning.`,
    fr: `L'excellence académique est une valeur fondamentale à HTIS, et nos traditions scolaires
      reflètent cet engagement envers une éducation de haute qualité. Nous reconnaissons et
      célébrons les réalisations académiques exceptionnelles lors de notre
      cérémonie annuelle de remise des prix académiques, où les étudiants qui se sont distingués
      dans leurs études sont honorés et applaudis. Cette tradition motive nos
      étudiants à s'efforcer d'exceller dans leurs études et
      favorise une culture d'apprentissage continu.`,
    ar: `التفوق الأكاديمي هو قيمة أساسية في HTIS، وتعكس تقاليدنا المدرسية
      هذا الالتزام بتقديم تعليم عالي الجودة. نعترف ونحتفل بالإنجازات الأكاديمية المتميزة
      من خلال حفل توزيع الجوائز الأكاديمية السنوي الذي نقيمه، حيث يتم تكريم وتشجيع الطلاب الذين تفوقوا
      في دراستهم. تلك التقليد يحفز طلابنا على السعي للتفوق في مساراتهم الأكاديمية و
      يعزز ثقافة التعلم المستمر.`,
  },
  text6: {
    en: `And now, we proudly introduce our newest school tradition:`,
    fr: `Et maintenant, nous sommes fiers de vous présenter notre toute nouvelle tradition scolaire :`,
    ar: `والآن، نحن بكل فخر نقدم لكم تقليدنا المدرسي الجديد:`,
  },
  text7: {
    en: `Tigers Nights is an exciting addition to our HTIS traditions,
    celebrating our students. It is an evening filled with games,
    delicious food, movie screenings, dancing, and much more. Tigers
    Nights provides a fun and relaxed atmosphere for our students to
    unwind, socialize, and create lasting memories with their classmates.
    It is a time for them to let loose, enjoy various activities, and
    strengthen their bonds with one another. Tigers Nights adds a vibrant
    and energetic element to our school traditions, allowing our students
    to express their unique personalities and enjoy a well-deserved break
    from their academic pursuits.`,
    fr: `Tigers Nights est une nouvelle tradition passionnante de notre école, qui célèbre nos étudiants. C'est une soirée remplie de jeux, de délicieux repas, de projections de films, de danse et bien plus encore. Tigers Nights offre une atmosphère amusante et détendue à nos étudiants pour se détendre, socialiser et créer des souvenirs durables avec leurs camarades de classe. C'est un moment pour eux de se lâcher, de profiter de diverses activités et de renforcer leurs liens les uns avec les autres. Tigers Nights apporte un élément vibrant et énergique à nos traditions scolaires, permettant à nos étudiants d'exprimer leur personnalité unique et de profiter d'une pause bien méritée dans leurs études.`,
    ar: `تايغرز نايتس هو إضافة مثيرة لتقاليدنا في HTIS، يحتفل بها طلابنا. إنها أمسية مليئة بالألعاب والطعام اللذيذ وعروض الأفلام والرقص وأكثر من ذلك بكثير. تايغرز نايتس يوفر جوًا ممتعًا ومريحًا لطلابنا للاسترخاء والتواصل وخلق ذكريات دائمة مع زملائهم في الصف. إنه وقت لهم للترفيه عن أنفسهم، والاستمتاع بأنشطة متنوعة، وتعزيز روابطهم مع بعضهم البعض. يضيف تايغرز نايتس عنصرًا حيويًا ومفعمًا بالحيوية لتقاليدنا المدرسية، مما يسمح لطلابنا بالتعبير عن شخصياتهم الفريدة والاستمتاع بفترة راحة مستحقة من مساراتهم الأكاديمية.`,
  },
  text8: {
    en: `In conclusion, the traditions at Hilali Targa International School are
    an integral part of our school's heritage and identity. They promote a
    positive school culture, foster a sense of belonging and pride, and
    instill important values such as community service, academic
    excellence, and inclusivity. As we continue to uphold these
    traditions, we are committed to providing our students with a holistic
    education that prepares them for success in an ever-changing world
    while cherishing the rich legacy of our school.`,
    fr: `En conclusion, les traditions de l'école internationale Hilali Targa font partie intégrante de l'héritage et de l'identité de notre école. Elles favorisent une culture scolaire positive, encouragent un sentiment d'appartenance et de fierté, et inculquent des valeurs importantes telles que le service à la communauté, l'excellence académique et l'inclusion. En continuant de respecter ces traditions, nous nous engageons à offrir à nos étudiants une éducation globale qui les prépare à réussir dans un monde en constante évolution tout en chérissant le riche héritage de notre école.`,
    ar: `في الختام، تعتبر التقاليد في مدرسة الهلالي تارجة الدولية جزءًا لا يتجزأ من تراث وهوية مدرستنا. إنها تعزز ثقافة مدرسية إيجابية وتعزز الانتماء والفخر، وتزرع القيم الهامة مثل خدمة المجتمع والتفوق الأكاديمي والشمولية. وبينما نستمر في الالتزام بتلك التقاليد، فإننا ملتزمون بتقديم تعليم شامل يعد طلابنا للنجاح في عالم متغير باستمرار مع التقدير لتراث مدرستنا الغني.`,
  },
};

export const trips = {
  title: {
    en: `School Trips & Excursions`,
    fr: `Sorties scolaires et excursions`,
    ar: `رحلات المدرسة والنزهات`,
  },
  text: {
    en: `<p>
    At HTIS, we believe that learning should not be limited to the
    classroom. That's why we organize school trips for students of all ages,
    from kindergarten to high school. Our trips are not only educational but
    also provide opportunities for students to have fun and create lasting
    memories.
    <br />
    We offer a variety of trips, from local museums and historical sites to
    amusement parks and recreational areas. Sometimes, we organize trips
    outside of the country to give students a chance to explore new cultures
    and expand their worldview. <br />
    These trips are not just about having fun; they are also designed to
    reinforce classroom learning and allow students to apply what they have
    learned in real-world contexts. Our teachers and staff accompany
    students on these trips to provide guidance and ensure their safety.
    <br />
    At HTIS, we believe that school trips are an integral part of the
    educational experience, providing opportunities for students to learn,
    grow, and have fun outside the classroom.
  </p>`,
    fr: `<p>
    À HTIS, nous croyons que l'apprentissage ne devrait pas se limiter à la salle de classe. C'est pourquoi nous organisons des sorties scolaires pour les élèves de tous les âges, de la maternelle au lycée. Nos sorties ne sont pas seulement éducatives, mais offrent également des opportunités aux élèves de s'amuser et de créer des souvenirs durables.
    <br />
    Nous proposons une variété de sorties, des musées locaux et sites historiques aux parcs d'attractions et aux espaces de loisirs. Parfois, nous organisons des voyages à l'étranger pour donner aux élèves l'occasion de découvrir de nouvelles cultures et d'élargir leur vision du monde. <br />
    Ces sorties ne sont pas seulement pour s'amuser ; elles sont également conçues pour renforcer l'apprentissage en classe et permettre aux élèves d'appliquer ce qu'ils ont appris dans des contextes réels. Nos enseignants et le personnel accompagnent les élèves lors de ces sorties pour les guider et assurer leur sécurité.
    <br />
    À HTIS, nous considérons que les sorties scolaires sont une partie intégrante de l'expérience éducative, offrant aux élèves des opportunités d'apprendre, de grandir et de s'amuser en dehors de la salle de classe.
  </p>`,
    ar: `<p>
    في HTIS، نؤمن بأن عملية التعلم لا ينبغي أن تقتصر على الفصل الدراسي. لهذا السبب نقوم بتنظيم رحلات مدرسية لطلاب جميع الأعمار، بدءًا من رياض الأطفال وحتى المدارس الثانوية. لا تكون رحلاتنا مجرد تعليمية فحسب، بل توفر أيضًا فرصًا للطلاب للاستمتاع وخلق ذكريات دائمة.
    <br />
    نقدم مجموعة متنوعة من الرحلات، بدءًا من المتاحف المحلية والمواقع التاريخية إلى الحدائق الترفيهية والمناطق الترفيهية. في بعض الأحيان، ننظم رحلات خارج البلاد لإتاحة فرصة للطلاب لاستكشاف ثقافات جديدة وتوسيع وجهات نظرهم. <br />
    لا تتعدى هذه الرحلات مجرد التسلية، بل هي مصممة أيضًا لتعزيز التعلم في الفصل الدراسي والسماح للطلاب بتطبيق ما تعلموه في سياقات العالم الحقيقي. يرافق معلمونا والموظفون الطلاب في هذه الرحلات لتقديم الإرشاد وضمان سلامتهم.
    <br />
    في HTIS، نعتقد أن الرحلات المدرسية هي جزء لا يتجزأ من التجربة التعليمية، وتوفر فرصًا للطلاب للتعلم والنمو والاستمتاع خارج الفصل الدراسي.
  </p>`,
  },
};

export const virtualExchange = {
  title: {
    en: `Virtual Exchange`,
    fr: `Échange virtuel`,
    ar: `التبادل الافتراضي`,
  },
  text: {
    en: `Virtual exchange is a unique opportunity for students to engage in
    cross-cultural learning and build relationships with peers from around
    the world, all while never leaving the comfort of their own classrooms.
    At HTIS, we believe in the value of virtual exchange and have created a
    club dedicated to fostering these connections. Through our virtual
    exchange program, students can participate in online discussions,
    collaborate on projects, and even engage in virtual field trips with
    students from other countries. By connecting with their peers from
    different cultures, students develop important skills in communication,
    empathy, and global awareness. Through virtual exchange, we aim to
    create a more interconnected and compassionate world.`,
    fr: `L'échange virtuel est une opportunité unique pour les étudiants de s'engager
    dans un apprentissage interculturel et de nouer des relations avec des camarades
    du monde entier, tout en restant dans le confort de leur propre salle de classe.
    À HTIS, nous croyons en la valeur de l'échange virtuel et avons créé un club
    dédié à favoriser ces connexions. Grâce à notre programme d'échange virtuel, les
    étudiants peuvent participer à des discussions en ligne, collaborer sur des projets
    et même participer à des voyages virtuels avec des étudiants d'autres pays. En
    se connectant avec leurs pairs de différentes cultures, les étudiants développent
    des compétences importantes en communication, en empathie et en conscience mondiale.
    Grâce à l'échange virtuel, nous visons à créer un monde plus interconnecté et
    compatissant.`,
    ar: `التبادل الافتراضي هو فرصة فريدة للطلاب للانخراط في التعلم الثقافي العابر للحدود
    وبناء علاقات مع زملائهم من جميع أنحاء العالم، دون أن يغادروا راحة فصولهم الدراسية الخاصة
    بهم. في HTIS، نؤمن بقيمة التبادل الافتراضي وقد أنشأنا نادٍ مخصص لتعزيز هذه الروابط.
    من خلال برنامج التبادل الافتراضي الخاص بنا، يمكن للطلاب المشاركة في مناقشات عبر الإنترنت،
    والتعاون في المشاريع، وحتى المشاركة في رحلات ميدانية افتراضية مع طلاب من دول أخرى. من خلال
    الاتصال بأقرانهم من ثقافات مختلفة، يطور الطلاب مهارات هامة في التواصل والتعاطف والوعي العالمي.
    من خلال التبادل الافتراضي، نهدف إلى خلق عالم أكثر ارتباطًا وتعاطفًا.`,
  },
};

export const webinars = {
  title: {
    en: `Interviews & Webinars`,
    fr: `Entretiens et Webinaires`,
    ar: `المقابلات والندوات الإلكترونية`,
  },
  text: {
    en: `At Hilali Targa International School (HTIS), we are committed to providing our students with a diverse range of learning experiences. That's why we organize interviews and webinars featuring people from all walks of life. We believe that these experiences can help broaden students' horizons and provide them with valuable insights into various fields.
    <br /><br />
    Some of our interviews and webinars are even conducted by students, for students. We believe that student-led initiatives play a crucial role in helping young people develop their leadership and communication skills. We encourage our students to take on this responsibility and create opportunities for themselves and their peers.
    <br /><br />
    We also invite world-renowned experts and professionals to share their experiences with our students. These guests provide valuable insights into their respective fields, allowing students to gain a deeper understanding of different industries and professions. This exposure to the real world can help students make informed decisions about their future careers.
    <br /><br />
    Whether it's a student-led interview or a talk by a world-renowned expert, we believe that our interview and webinar series offer our students valuable opportunities for learning and personal growth.`,
    fr: `À l'Hilali Targa International School (HTIS), nous nous engageons à offrir à nos étudiants une gamme diversifiée d'expériences d'apprentissage. C'est pourquoi nous organisons des entretiens et des webinaires mettant en vedette des personnes de tous les horizons. Nous croyons que ces expériences peuvent aider à élargir les horizons des étudiants et leur fournir des perspectives précieuses dans différents domaines.
    <br /><br />
    Certains de nos entretiens et webinaires sont même menés par des étudiants, pour des étudiants. Nous croyons que les initiatives dirigées par les étudiants jouent un rôle crucial dans le développement des compétences en leadership et en communication des jeunes. Nous encourageons nos étudiants à assumer cette responsabilité et à créer des opportunités pour eux-mêmes et leurs pairs.
    <br /><br />
    Nous invitons également des experts et des professionnels de renommée mondiale à partager leurs expériences avec nos étudiants. Ces invités apportent des perspectives précieuses sur leurs domaines respectifs, permettant aux étudiants d'approfondir leur compréhension des différentes industries et professions. Cette exposition au monde réel peut aider les étudiants à prendre des décisions éclairées sur leurs futures carrières.
    <br /><br />
    Qu'il s'agisse d'un entretien dirigé par des étudiants ou d'une conférence donnée par un expert de renommée mondiale, nous pensons que notre série d'entretiens et de webinaires offre à nos étudiants des opportunités précieuses d'apprentissage et de développement personnel.`,
    ar: `في مدرسة الهلالي تارجة الدولية (HTIS)، نحن ملتزمون بتوفير مجموعة متنوعة من تجارب التعلم لطلابنا. ولهذا السبب نقوم بتنظيم مقابلات وندوات إلكترونية تستضيف أشخاصًا من جميع مناحي الحياة. نحن نعتقد أن هذه التجارب يمكن أن تساعد في توسيع آفاق الطلاب وتزويدهم برؤى قيمة في مجالات مختلفة.
    <br /><br />
    بعض المقابلات والندوات الإلكترونية لدينا يتم إجراؤها بواسطة الطلاب أنفسهم، من أجل الطلاب. نحن نعتقد أن المبادرات التي يقودها الطلاب تلعب دورًا حاسمًا في مساعدة الشباب على تطوير مهارات القيادة والاتصال. نشجع طلابنا على تحمل هذه المسؤولية وخلق فرص لأنفسهم ولأقرانهم.
    <br /><br />
    ندعو أيضًا خبراء ومحترفين عالميين من الصف الأول إلى الصف الثاني عشر لمشاركة تجاربهم مع طلابنا. يقدم هؤلاء الضيوف رؤى قيمة في مجالاتهم المختلفة، مما يتيح للطلاب فهمًا أعمق للصناعات والمهن المختلفة. يمكن لهذا التعرض للعالم الحقيقي أن يساعد الطلاب على اتخاذ قرارات مستنيرة بشأن مستقبلهم المهني.
    <br /><br />
    سواء كانت المقابلة التي يقودها الطلاب أو محاضرة من قبل خبير عالمي، نحن نعتقد أن سلسلة المقابلات والندوات الخاصة بنا تقدم لطلابنا فرصًا قيمة للتعلم والنمو الشخصي.`,
  },
};
