const translationMeetingTypes = "constants.meetingTypes";
const translationMeetingOutcomes = "constants.meetingOutcomes";
const translationPositions = "constants.positions";
const translationRoles = "constants.roles";
const translationApplicationStatus = "constants.applicationStatus";
const translationCycles = "constants.cycles";
const translationHiringTypes = "constants.hiringTypes";
const translationSubjects = "constants.subjects";
const translationTestsDifficulty = "constants.testsDifficulty";
const translationApplicationsStreams = "constants.applicationStreams";
const translationStudentTestsPeriods = "constants.studentTestsPeriods";
const translationSomethingWentWrong = "constants.somethingWentWrong";

export const constants = {
  hostAndBackendPort: "http://www.hilalitarga.com/api/",
};

export const languages = {
  en: { nativeName: "English", flag: "🇺🇸 " },
  fr: { nativeName: "Francais", flag: "🇫🇷 " },
  ar: { nativeName: "Arabic", flag: "🇲🇦 " },
  //ber: { nativeName: "Berber", flag: "ⵣ  " },
  //es: { nativeName: "Español", flag: "🇪🇸 " },
};

export const meetingTypes = [
  {
    value: "Face-to-face interview",
    name: "Face-to-face interview",
    translationKey: `${translationMeetingTypes}.face2face`,
  },
  {
    value: "Written test",
    name: "Written test",
    translationKey: `${translationMeetingTypes}.writtenTest`,
  },
  {
    value: "In-class observation",
    name: "In-class observation",
    translationKey: `${translationMeetingTypes}.inClassObservation`,
  },
  {
    value: "Demo lesson",
    name: "Demo lesson",
    translationKey: `${translationMeetingTypes}.demoLesson`,
  },
  {
    value: "Interview with inspector",
    name: "Interview with inspector",
    translationKey: `${translationMeetingTypes}.inspectorInterview`,
  },
  {
    value: "Paperwork (Accounting Dpt)",
    name: "Paperwork (Accounting Dpt)",
    translationKey: `${translationMeetingTypes}.paperwork`,
  },
  {
    value: "Other",
    name: "Other",
    translationKey: `${translationMeetingTypes}.other`,
  },
];

export const meetingTimes = [
  { value: "08:00" },
  { value: "08:15" },
  { value: "08:30" },
  { value: "08:45" },
  { value: "09:00" },
  { value: "09:15" },
  { value: "09:30" },
  { value: "09:45" },
  { value: "10:00" },
  { value: "10:15" },
  { value: "10:30" },
  { value: "10:45" },
  { value: "11:00" },
  { value: "11:15" },
  { value: "11:30" },
  { value: "11:45" },
  { value: "12:00" },
  { value: "12:15" },
  { value: "12:30" },
  { value: "12:45" },
  { value: "13:00" },
  { value: "13:15" },
  { value: "13:30" },
  { value: "13:45" },
  { value: "14:00" },
  { value: "14:15" },
  { value: "14:30" },
  { value: "14:45" },
  { value: "15:00" },
  { value: "15:15" },
  { value: "15:30" },
  { value: "15:45" },
  { value: "16:00" },
  { value: "16:15" },
  { value: "16:30" },
  { value: "16:45" },
  { value: "17:00" },
  { value: "17:15" },
  { value: "17:30" },
  { value: "17:45" },
  { value: "18:00" },
];

export const meetingOutcomes = [
  {
    value: "Rejected",
    name: "Rejected",
    translationKey: `${translationMeetingOutcomes}.rejected`,
  },
  {
    value: "Offer Not Accepted",
    name: "Offer Not Accepted",
    translationKey: `${translationMeetingOutcomes}.offerNotAccepted`,
  },
  {
    value: "Candidate Unavailable",
    name: "Candidate Unavailable",
    translationKey: `${translationMeetingOutcomes}.candidateUnavailable`,
  },
  {
    value: "Job Unavailable",
    name: "Job Unavailable",
    translationKey: `${translationMeetingOutcomes}.jobUnavailable`,
  },
  {
    value: "null",
    name: "None",
    translationKey: `${translationMeetingOutcomes}.none`,
  },
  {
    value: "Other",
    name: "Other",
    translationKey: `${translationMeetingOutcomes}.other`,
  },
];

export const positions = [
  {
    value: "Accounting",
    label: "Accounting",
    translationKey: `${translationPositions}.Accounting`,
  },
  {
    value: "Administration",
    label: "Administration",
    translationKey: `${translationPositions}.Administration`,
  },
  {
    value: "Arabic",
    label: "Arabic",
    translationKey: `${translationPositions}.Arabic`,
  },
  {
    value: "Custody",
    label: "Custody",
    translationKey: `${translationPositions}.Custody`,
  },
  {
    value: "Driver",
    label: "Driver",
    translationKey: `${translationPositions}.Driver`,
  },
  {
    value: "English",
    label: "English",
    translationKey: `${translationPositions}.English`,
  },
  {
    value: "French",
    label: "French",
    translationKey: `${translationPositions}.French`,
  },
  {
    value: "History",
    label: "History",
    translationKey: `${translationPositions}.History`,
  },
  {
    value: "Islamic Education",
    label: "Islamic Education",
    translationKey: `${translationPositions}.Islamic Education`,
  },
  {
    value: "IT",
    label: "IT",
    translationKey: `${translationPositions}.IT`,
  },
  {
    value: "Math",
    label: "Math",
    translationKey: `${translationPositions}.Math`,
  },
  {
    value: "Music",
    label: "Music",
    translationKey: `${translationPositions}.Music`,
  },
  {
    value: "Nursery",
    label: "Nursery",
    translationKey: `${translationPositions}.Nursery`,
  },
  { value: "PE", label: "PE", translationKey: `${translationPositions}.PE` },
  {
    value: "Philosophy",
    label: "Philosophy",
    translationKey: `${translationPositions}.Philosophy`,
  },
  {
    value: "Physics",
    label: "Physics",
    translationKey: `${translationPositions}.Physics`,
  },
  {
    value: "Bus",
    label: "Bus",
    translationKey: `${translationPositions}.Bus`,
  },
  {
    value: "SVT",
    label: "SVT",
    translationKey: `${translationPositions}.SVT`,
  },
  {
    value: "Teaching Assistant",
    label: "Teaching Assistant",
    translationKey: `${translationPositions}.Teaching Assistant`,
  },
  {
    value: "Engineering Sciences",
    label: "Engineering Sciences",
    translationKey: `${translationPositions}.Engineering Sciences`,
  },
  {
    value: "Other",
    label: "Other",
    translationKey: `${translationPositions}.Other`,
  },
];

export const ratings = [
  { value: 1, name: 1 },
  { value: 2, name: 2 },
  { value: 3, name: 3 },
];

export const roles = [
  {
    value: "Admin",
    name: "Admin",
    translationKey: `${translationRoles}.Admin`,
  },
  { value: "HR", name: "HR", translationKey: `${translationRoles}.HR` },
  { value: "IT", name: "IT", translationKey: `${translationRoles}.IT` },
  {
    value: "Academics",
    name: "Academics",
    translationKey: `${translationRoles}.Academics`,
  },
];

export const applicationStatus = [
  {
    value: "Applied / Not seen",
    name: "Applied / Not seen",
    translationKey: `${translationApplicationStatus}.notSeen`,
  },
  {
    value: "Ongoing",
    name: "Ongoing",
    translationKey: `${translationApplicationStatus}.ongoing`,
  },
  {
    value: "Test needed (no appointment)",
    name: "Test needed (no appointment)",
    translationKey: `${translationApplicationStatus}.noAptTestNeeded`,
  },
  {
    value: "Test needed (appointment set)",
    name: "Test needed (appointment set)",
    translationKey: `${translationApplicationStatus}.aptTestNeeded`,
  },
  {
    value: "Test to be corrected",
    name: "Test to be corrected",
    translationKey: `${translationApplicationStatus}.testToBeCorrected`,
  },
  {
    value: "Test Passed",
    name: "Test Passed",
    translationKey: `${translationApplicationStatus}.testPassed`,
  },
  {
    value: "Average",
    name: "Average",
    translationKey: `${translationApplicationStatus}.average`,
  },
  {
    value: "Failed test",
    name: "Failed test",
    translationKey: `${translationApplicationStatus}.testFailed`,
  },
  {
    value: "To be interviewed (no appointment)",
    name: "To be interviewed (no appointment)",
    translationKey: `${translationApplicationStatus}.noAptToBeInterviewed`,
  },
  {
    value: "To be interviewed (appointment set)",
    name: "To be interviewed (appointment set)",
    translationKey: `${translationApplicationStatus}.aptToBeInterviewed`,
  },
  {
    value: "For future reference / À garder",
    name: "For future reference / À garder",
    translationKey: `${translationApplicationStatus}.forFuture`,
  },
  {
    value: "Offer Extended",
    name: "Offer Extended",
    translationKey: `${translationApplicationStatus}.offerExtended`,
  },
  {
    value: "Accepted Offer",
    name: "Accepted Offer",
    translationKey: `${translationApplicationStatus}.acceptedOffer`,
  },
  {
    value: "Rejected Offer",
    name: "Rejected Offer",
    translationKey: `${translationApplicationStatus}.rejectedOffer`,
  },
  {
    value: "Unfavorable",
    name: "Unfavorable",
    translationKey: `${translationApplicationStatus}.unfavorable`,
  },
];

export const cycles = [
  {
    value: "Kindergarten",
    label: "Kindergarten",
    translationKey: `${translationCycles}.Kindergarten`,
  },
  {
    value: "Elementary",
    label: "Elementary",
    translationKey: `${translationCycles}.Elementary`,
  },
  {
    value: "Middle",
    label: "Middle",
    translationKey: `${translationCycles}.Middle`,
  },
  { value: "High", label: "High", translationKey: `${translationCycles}.High` },
];

export const hiringTypes = [
  {
    value: "Full-time job",
    name: "Full-time job",
    translationKey: `${translationHiringTypes}.fullTimeJob`,
  },
  {
    value: "Contractor / Vacataire",
    name: "Contractor / Vacataire",
    translationKey: `${translationHiringTypes}.contractor`,
  },
  {
    value: "Retired Contractor / Vacataire",
    name: "Retired Contractor / Vacataire",
    translationKey: `${translationHiringTypes}.retiredContractor`,
  },
  {
    value: "Internship",
    name: "Internship",
    translationKey: `${translationHiringTypes}.internship`,
  },
  {
    value: "Unpaid Internship",
    name: "Unpaid Internship",
    translationKey: `${translationHiringTypes}.unpaidInternship`,
  },
];

export const subjects = [
  {
    value: "Maths",
    name: "Maths",
    translationKey: `${translationSubjects}.Maths`,
  },
  {
    value: "French",
    name: "French",
    translationKey: `${translationSubjects}.French`,
  },
  {
    value: "Arabic",
    name: "Arabic",
    translationKey: `${translationSubjects}.Arabic`,
  },
  { value: "SVT", name: "SVT", translationKey: `${translationSubjects}.SVT` },
  { value: "H.G", name: "H.G", translationKey: `${translationSubjects}.HG` },
  { value: "P.C", name: "P.C", translationKey: `${translationSubjects}.PC` },
  {
    value: "English",
    name: "English",
    translationKey: `${translationSubjects}.English`,
  },
  {
    value: "Islamic Education",
    name: "Islamic Education",
    translationKey: `${translationSubjects}.Islamic Education`,
  },
  {
    value: "Computer / IT",
    name: "Computer / IT",
    translationKey: `${translationSubjects}.IT`,
  },
];

export const testsDifficulty = [
  {
    value: "Easy",
    name: "Easy",
    translationKey: `${translationTestsDifficulty}.Easy`,
  },
  {
    value: "Normal",
    name: "Normal",
    translationKey: `${translationTestsDifficulty}.Normal`,
  },
  {
    value: "Hard",
    name: "Hard",
    translationKey: `${translationTestsDifficulty}.Hard`,
  },
];

export const gradeLevels = [
  {
    label: "kindergarten",
    translationKey: `${translationCycles}.Kindergarten`,
    grades: [
      { value: "P.S", name: "P.S", translationKey: `P.S` },
      { value: "M.S", name: "M.S", translationKey: `M.S` },
      { value: "G.S", name: "G.S", translationKey: `G.S` },
    ],
  },
  {
    label: "elementary",
    translationKey: `${translationCycles}.Elementary`,
    grades: [
      { value: "1.A.P", name: "1.A.P", translationKey: `1.A.P` },
      { value: "2.A.P", name: "2.A.P", translationKey: `2.A.P` },
      { value: "3.A.P", name: "3.A.P", translationKey: `3.A.P` },
      { value: "4.A.P", name: "4.A.P", translationKey: `4.A.P` },
      { value: "5.A.P", name: "5.A.P", translationKey: `5.A.P` },
      { value: "6.A.P", name: "6.A.P", translationKey: `6.A.P` },
    ],
  },
  {
    label: "middle",
    translationKey: `${translationCycles}.Middle`,
    grades: [
      { value: "1.A.C", name: "1.A.C", translationKey: `1.A.C` },
      { value: "2.A.C", name: "2.A.C", translationKey: `2.A.C` },
      { value: "3.A.C", name: "3.A.C", translationKey: `3.A.C` },
    ],
  },
  {
    label: "high",
    translationKey: `${translationCycles}.High`,
    grades: [
      { value: "TC", name: "T.C", translationKey: `T.C` },
      { value: "1.A.B", name: "1.A.B", translationKey: `1.A.B` },
      {
        value: "2.A.B / Terminale",
        name: "2.A.B / Terminale",
        translationKey: `2.A.B / Terminale`,
      },
    ],
  },
  {
    label: "cycle",
    translationKey: `${translationCycles}.Cycle`,
    grades: [
      {
        value: "Kindergarten",
        name: "Kindergarten",
        translationKey: `${translationCycles}.Kindergarten`,
      },
      {
        value: "Elementary",
        name: "Elementary",
        translationKey: `${translationCycles}.Elementary`,
      },
      {
        value: "Middle",
        name: "Middle",
        translationKey: `${translationCycles}.Middle`,
      },
      {
        value: "High",
        name: "High",
        translationKey: `${translationCycles}.High`,
      },
    ],
  },
];

export const applicationsStreams = [
  {
    value: "Organic (Word of mouth)",
    name: "Organic (Word of mouth)",
    translationKey: `${translationApplicationsStreams}.Organic (Word of mouth)`,
  },
  {
    value: "Maroc Annonces",
    name: "Maroc Annonces",
    translationKey: `Maroc Annonces`,
  },
  {
    value: "Anapec",
    name: "Anapec",
    translationKey: `Anapec`,
  },
  {
    value: "Indeed",
    name: "Indeed",
    translationKey: `Indeed`,
  },
  {
    value: "Faculty / University / Flyer",
    name: "Faculty / University / Flyer",
    translationKey: `${translationApplicationsStreams}.Faculty / University / Flyer`,
  },
  {
    value: "Current HTIS Employee or Intern",
    name: "Current HTIS Employee or Intern",
    translationKey: `${translationApplicationsStreams}.Current HTIS Employee or Intern`,
  },
  {
    value: "Social Media (Facebook, Instagram, Youtube...)",
    name: "Social Media (Facebook, Instagram, Youtube...)",
    translationKey: `Social Media (Facebook, Instagram, Youtube...)`,
  },
  {
    value: "Search Engine (google...)",
    name: "Search Engine (google...)",
    translationKey: `${translationApplicationsStreams}.Search Engine (google...)`,
  },
  {
    value: "Other",
    name: "Ohter",
    translationKey: `${translationApplicationsStreams}.Other`,
  },
];

export const studentTestsPeriods = [
  {
    value: "1st Trimester",
    name: "1st Trimester",
    translationKey: `${translationStudentTestsPeriods}.1st Trimester`,
  },
  {
    value: "2nd Trimester",
    name: "2nd Trimester",
    translationKey: `${translationStudentTestsPeriods}.2nd Trimester`,
  },
  {
    value: "3rd Trimester",
    name: "3rd Trimester",
    translationKey: `${translationStudentTestsPeriods}.3rd Trimester`,
  },
];

export function somethingWentWrong(t) {
  return t(`${translationSomethingWentWrong}`);
}
