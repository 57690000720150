import "./calendar.scss";
import { useContext, useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Box } from "@mui/material";
import {
  meetingTimes,
  cycles,
  somethingWentWrong,
  constants,
} from "../../utils/constants";
import axios from "axios";
import { message } from "antd";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { AuthContext } from "../../context/AuthContext";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { fileOnChangeCalendarEvent } from "../../hooks/fileOnChange";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Select from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const Calendar = () => {
  const { user } = useContext(AuthContext);
  const [showMinistryEvents, setShowMinistryEvents] = useState(false);
  const [showSchoolEvents, setShowSchoolEvents] = useState(false);
  const [showAllEvents, setShowAllEvents] = useState(true);
  const [displayPrompt, setDisplayPrompt] = useState(false);
  const [initialEvents, setInitialEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [calendarApi, setCalendarApi] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const hostAndBackendPort = constants.hostAndBackendPort;
  const PF = `${hostAndBackendPort}calendarEvents/images/`;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const translationPage = "pages.calendar";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [shouldHideHeaderToolbar, setShouldHideHeaderToolbar] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    if (windowWidth <= 600) setShouldHideHeaderToolbar(true);
  }, [window.innerWidth]);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      let events;
      if (showMinistryEvents)
        events = await axios.get("calendarEvents/ministry");
      else if (showSchoolEvents)
        events = await axios.get("calendarEvents/school");
      else events = await axios.get("calendarEvents");
      if (events.data.status === "success")
        setInitialEvents(events.data.events);
    } catch (err) {
      message.error(somethingWentWrong);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEvents();
  }, [showMinistryEvents, showSchoolEvents, showAllEvents]);

  const handleNoUserEventClick = (selected) => {
    const title = selected.event._def.title;
    const start = selected.event.start?.toString().slice(0, 21);
    const end = selected.event.end?.toString().slice(0, 21);
    const cycle = selected.event._def.extendedProps.cycle;

    if (end)
      alert(
        "Event: " +
          title +
          "\n" +
          "From " +
          start +
          " to " +
          end +
          "\n" +
          "Cycle: " +
          cycle
      );
    else
      alert(
        "Event: " + title + "\n" + "From " + start + "\n" + "Cycle: " + cycle
      );
  };

  const saveEvent = async (evnt) => {
    try {
      const { data } = await axios.post("calendarEvents", evnt);
      if (data.status === "success") {
        calendarApi.unselect();
        calendarApi.addEvent(data.savedEvent);
        setTimeout(() => {
          setDisplayPrompt(false);
        }, 1000);
      } else if (data.message) message.error(data.message);
      else message.error(somethingWentWrong);
    } catch (err) {
      message.error(somethingWentWrong);
    } finally {
      setLoadingSave(false);
    }
  };

  const Prompt = () => {
    const title = useRef();
    const start = selectedDate;
    const end = useRef();
    const type = useRef();
    const [cycleList, setCycleList] = useState([]);
    const [cycle, setCycle] = useState([]);
    const startTime = useRef();
    const endTime = useRef();
    const link = useRef();
    const desc = useRef();
    const [photo, setPhoto] = useState([]);
    const [photoSize, setPhotoSize] = useState(0);
    const [photoPreview, setPhotoPreview] = useState();

    const handleSaveClick = async (selected) => {
      try {
        setLoadingSave(true);
        if (!title.current.value || !type.current.value) {
          message.error(
            t(`${translationPage}.Please fill out all required fields`)
          );
          return;
        }
        const startHrs = startTime.current.value.slice(0, 2);
        const startMns = startTime.current.value.slice(3);
        const endHrs = endTime.current.value.slice(0, 2);
        const endMns = endTime.current.value.slice(3);
        const d1 = new Date(start);
        const d2 = new Date(end.current?.value || start);
        d1.setHours(startHrs);
        d1.setMinutes(startMns);
        if (!isNaN(d2.getTime())) {
          d2.setHours(endHrs);
          d2.setMinutes(endMns);
        } else {
          message.error("Invalid end date");
          return;
        }
        const newEvent = {
          title: title.current.value,
          start: d1,
          end: d2 || "",
          type: type.current.value,
          cycle: cycle,
          link: link.current.value,
          desc: desc.current.value,
          photo: [],
        };
        if (photo.length > 0) {
          const photoData = new FormData();
          for (let i = 0; i < photo.length; i++) {
            const filename =
              Date.now() + newEvent.title + "calendarPhoto" + photo[i].name;
            photoData.append("name", filename);
            const img = photo[i];
            photoData.append("file", img, filename);
            newEvent.photo.push(filename);
          }
          try {
            const imageUploadRes = await axios.post(
              "calendarEvents/multiUpload",
              photoData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (imageUploadRes.data.status !== "201")
              message.error(somethingWentWrong);
          } catch (err) {
            message.error(somethingWentWrong);
          }
        }
        saveEvent(newEvent);
      } catch (err) {}
    };

    return (
      <div className="schoolCalendarPromptContainer">
        <div className="schoolCalendarPromptWrapper">
          <p>{t(`${translationPage}.Add New Event`)}</p>
          <form>
            <span>
              <span className="schoolCalendarPromptSpan">
                <input
                  required
                  ref={title}
                  type="text"
                  placeholder={t(`${translationPage}.Title`)}
                />
                <p>&nbsp; *</p>
              </span>
              <span className="schoolCalendarPromptSpan">
                <select required ref={type}>
                  <option value="" selected hidden>
                    {t(`${translationPage}.Type`)}
                  </option>
                  <option value="school">
                    {t(`${translationPage}.School`)}
                  </option>
                  <option value="ministry">
                    {t(`${translationPage}.Ministry`)}
                  </option>
                </select>
                <p>&nbsp; *</p>
              </span>
              <span
                className="calendarMultiSelect"
                style={{ position: "relative" }}
              >
                <Select
                  placeholder="Cycle(s)"
                  required
                  options={cycles.map((cycle) => ({
                    value: cycle.value,
                    label: t(cycle.translationKey),
                  }))}
                  isMulti
                  value={cycleList}
                  onChange={(selected, triggeredAction) => {
                    if (triggeredAction.action === "clear") {
                      setCycleList([]);
                      setCycle([]);
                    } else if (triggeredAction.action === "remove-value") {
                      const removedValue = triggeredAction.removedValue.value;
                      setCycleList(
                        cycleList.filter(
                          (option) => option.value !== removedValue
                        )
                      );
                      setCycle(cycle.filter((val) => val !== removedValue));
                    } else if (triggeredAction.action === "select-option") {
                      setCycleList(selected);
                      const values = selected?.map((option) => option?.value);
                      setCycle(values);
                    }
                  }}
                  closeMenuOnSelect={false}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      outline: state.isFocused ? "none" : "none",
                      border: state.isFocused ? "none" : "none",
                      boxShadow: state.isFocused ? "none" : "none",
                      height: state.menuIsOpen ? "100%" : "40px",
                      overflow: state.isFocused ? "visible" : "hidden",
                      overflow: state.menuIsOpen ? "visible" : "hidden",
                    }),
                    indicatorsContainer: (base, state) => ({
                      ...base,
                      position: state.isMulti ? "absolute" : "inherit",
                      top: state.isMulti ? "0" : "inherit",
                      right: state.isMulti ? "0" : "inherit",
                      zIndex: "999",
                      backgroundColor: "white",
                      display: "flex",
                    }),
                  }}
                />
              </span>
              <label>
                <input
                  type="file"
                  onChange={(e) =>
                    fileOnChangeCalendarEvent(
                      e,
                      "add",
                      photo,
                      setPhoto,
                      photoPreview,
                      setPhotoPreview,
                      photoSize,
                      setPhotoSize
                    )
                  }
                  accept="image/jpg ,image/gif, image/jpeg, image/webp, image/png"
                />
                {t(`${translationPage}.Photo`)}
                <AttachFileIcon />
                {photoPreview && (
                  <span className="calendarPhotoPreviewSpan">
                    <img
                      className="calendarFormImgPreview"
                      src={photoPreview}
                    />
                    {photo.length > 1 && (
                      <span className="calendarFormImgPreviewLength">
                        {" "}
                        +{photo.length - 1}{" "}
                      </span>
                    )}
                  </span>
                )}
              </label>
              <span>{photoSize}/16mb</span>
              <input
                required
                ref={desc}
                type="text"
                placeholder={t(`${translationPage}.Description`)}
              />
            </span>
            <span>
              <input disabled placeholder={`Start Date : ${start}`} />
              <input
                ref={end}
                type="text"
                onFocus={(e) => {
                  e.target.type = "date";
                  e.target.min = start;
                }}
                onBlur={(e) => (e.target.type = "text")}
                placeholder={t(`${translationPage}.End date`)}
              />
              <select ref={startTime}>
                <option value="" selected hidden>
                  {t(`${translationPage}.Start Time`)}
                </option>
                {meetingTimes.map((time) => (
                  <option value={time.value}>{time.value}</option>
                ))}
              </select>
              <select ref={endTime}>
                <option value="" selected hidden>
                  {t(`${translationPage}.End Time`)}
                </option>
                {meetingTimes.map((time) => (
                  <option value={time.value}>{time.value}</option>
                ))}
              </select>
              <input
                required
                ref={link}
                type="text"
                placeholder={t(`${translationPage}.Link`)}
              />
            </span>
          </form>
          <span className="schoolCalendarPromptBtns">
            <button onClick={handleSaveClick}>
              {loadingSave ? (
                <ClipLoader color="#4a154d" size={25} />
              ) : (
                t(`${translationPage}.Save`)
              )}
            </button>
            <button onClick={() => setDisplayPrompt(false)}>
              {t(`${translationPage}.Cancel`)}
            </button>
          </span>
        </div>
      </div>
    );
  };

  const eventTimeFormat = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const EditDeleteEvent = () => {
    const title = useRef();
    const link = useRef();
    const desc = useRef();
    const type = useRef();
    const start = useRef();
    const end = useRef();
    const startTime = useRef();
    const endTime = useRef();
    const [newPhoto, setNewPhoto] = useState([]);
    const [newPhotoPreview, setNewPhotoPreview] = useState("");
    const [handleShowEdit, setHandleShowEdit] = useState(false);
    const [cycleListEdit, setCycleListEdit] = useState([]);
    const [cycleEdit, setCycleEdit] = useState([]);
    //const [photo, setPhoto] = useState("");

    const handleEventClick = async (selected) => {
      if (window.confirm(t(`${translationPage}.confirmDeleteEvent`))) {
        try {
          const { data } = await axios.delete(
            `calendarEvents/${selectedEvent._def.extendedProps._id}`
          );
          if (data.status === "success") {
            message.success(data.message);
            selectedEvent.remove();
            setTimeout(() => {
              setShowEditPopup(false);
              fetchEvents();
            }, 1000);
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    };

    const handleUpdate = async () => {
      setLoadingUpdate(true);
      let d1 = "",
        d2 = "";
      //changing the start time
      if (startTime.current.value) {
        //are we also changing the start date?
        if (start.current.value) {
          d1 = new Date(start.current.value);
        } else {
          d1 = new Date(selectedEvent.start);
        }
        d1.setHours(startTime.current.value.slice(0, 2));
        d1.setMinutes(startTime.current.value.slice(3));
        //not changing the start time but changing the start date
      } else if (start.current.value) {
        const oldDate = new Date(selectedEvent.start);
        d1 = new Date(start.current.value);
        d1.setHours(oldDate.getHours()); //
        d1.setMinutes(oldDate.getMinutes()); //
      }
      //changing the end time
      if (endTime.current.value) {
        //are we also changing the end date?
        if (end.current.value) {
          d2 = new Date(end.current.value);
        } else {
          d2 = new Date(selectedEvent.end);
        }
        if (!isNaN(d2.getTime())) {
          d2.setHours(endTime.current.value.slice(0, 2));
          d2.setMinutes(endTime.current.value.slice(3));
        } else {
          message.error("Invalid end date");
          return;
        }
        //not changing the end time but changing the end date
      } else if (end.current.value) {
        d2 = new Date(end.current.value);
        const oldDate = new Date(selectedEvent.end);
        if (!isNaN(d2.getTime())) {
          d2.setHours(endTime.current.value.slice(0, 2));
          d2.setMinutes(endTime.current.value.slice(3));
        } else {
          message.error("Invalid end date");
          return;
        }
      }
      const updatedEvent = {
        title: title.current.value || selectedEvent._def.title,
        link: link.current.value || selectedEvent._def.extendedProps.link,
        desc: desc.current.value || selectedEvent._def.extendedProps.desc,
        start: d1 || selectedEvent.start,
        end: d2 || selectedEvent.end,
        type: type.current.value || selectedEvent._def.extendedProps.type,
        cycle: cycleEdit.length
          ? cycleEdit
          : selectedEvent._def.extendedProps.cycle,
        photo: selectedEvent._def.extendedProps.photo || [],
      };
      if (
        updatedEvent.start &&
        updatedEvent.end &&
        updatedEvent.start > updatedEvent.end
      )
        message.error(
          t(`${translationPage}.The start date can't be less than the end date`)
        );
      //TODO: if the event has a photo already, delete the old one first
      if (newPhoto.length > 0) {
        const data = new FormData();
        for (let i = 0; i < newPhoto.length; i++) {
          const filename =
            Date.now() +
            updatedEvent.title +
            "calendarPhoto" +
            newPhoto[i].name;
          data.append("name", filename);
          const img = newPhoto[i];
          data.append("file", img, filename);
          updatedEvent.photo.push(filename);
        }
        try {
          const photoUploadRes = await axios.post(
            "calendarEvents/multiUpload",
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
      try {
        const { data } = await axios.put(
          `calendarEvents/${selectedEvent?._def.extendedProps._id}`,
          updatedEvent
        );
        if (data.status === "success") {
          message.success(data.message);
          setTimeout(() => {
            setShowEditPopup(false);
            setHandleShowEdit(false);
            fetchEvents();
          }, 1000);
        } else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      } finally {
        setLoadingUpdate(false);
      }
    };

    const handleResetField = async (element) => {
      if (window.confirm(t(`${translationPage}.confirmDelete`) + element)) {
        try {
          const { data } = await axios.put(
            `calendarEvents/remove/${selectedEvent?._def.extendedProps._id}/${element}`
          );
          if (data.status === "success") {
            message.success(data.message);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else message.error(data.message);
        } catch (err) {
          message.error(somethingWentWrong);
        }
      }
    };

    return (
      <div className="schoolCalendarPromptContainer">
        {handleShowEdit ? (
          <div className="schoolCalendarEditPopupWrapper">
            <p>{t(`${translationPage}.Edit Event`)}</p>
            <form>
              <span>
                <input
                  required
                  ref={title}
                  type="text"
                  placeholder={selectedEvent?._def.title}
                />
                <select ref={type}>
                  <option value="" selected hidden>
                    {t(`${translationPage}.Type: `)}
                    {selectedEvent?._def.extendedProps.type || " "}
                  </option>
                  <option value="school">
                    {t(`${translationPage}.School`)}
                  </option>
                  <option value="ministry">
                    {t(`${translationPage}.Ministry`)}
                  </option>
                </select>
                <span
                  className="calendarMultiSelect"
                  style={{ position: "relative" }}
                >
                  <Select
                    required
                    options={cycles.map((cycle) => ({
                      value: cycle.value,
                      label: t(cycle.translationKey),
                    }))}
                    isMulti
                    value={cycleListEdit}
                    onChange={(selected, triggeredAction) => {
                      if (triggeredAction.action === "clear") {
                        setCycleListEdit([]);
                        setCycleEdit([]);
                      } else if (triggeredAction.action === "remove-value") {
                        const removedValue = triggeredAction.removedValue.value;
                        setCycleListEdit(
                          cycleListEdit.filter(
                            (option) => option.value !== removedValue
                          )
                        );
                        setCycleEdit(
                          cycleEdit.filter((val) => val !== removedValue)
                        );
                      } else if (triggeredAction.action === "select-option") {
                        setCycleListEdit(selected);
                        const values = selected?.map((option) => option?.value);
                        setCycleEdit(values);
                      }
                    }}
                    closeMenuOnSelect={false}
                    placeholder={
                      selectedEvent._def.extendedProps.cycle.length > 0
                        ? selectedEvent._def.extendedProps.cycle.join(", ")
                        : t(`${translationPage}.Cycle(s) :`)
                    }
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        outline: state.isFocused ? "none" : "none",
                        border: state.isFocused ? "none" : "none",
                        boxShadow: state.isFocused ? "none" : "none",
                        height: state.menuIsOpen ? "100%" : "40px",
                        overflow: state.isFocused ? "visible" : "hidden",
                        overflow: state.menuIsOpen ? "visible" : "hidden",
                      }),
                      indicatorsContainer: (base, state) => ({
                        ...base,
                        position: state.isMulti ? "absolute" : "inherit",
                        top: state.isMulti ? "0" : "inherit",
                        right: state.isMulti ? "0" : "inherit",
                        zIndex: "999",
                        backgroundColor: "white",
                        display: "flex",
                      }),
                    }}
                  />
                </span>
                <span className="schoolCalendarEditPopupWrapperLabelInline">
                  <label>
                    <input
                      type="file"
                      onChange={(e) =>
                        fileOnChangeCalendarEvent(
                          e,
                          "edit",
                          newPhoto,
                          setNewPhoto,
                          newPhotoPreview,
                          setNewPhotoPreview
                        )
                      }
                    />
                    {t(`${translationPage}.Photo`)}
                    <AttachFileIcon />
                    {(newPhotoPreview ||
                      selectedEvent?._def.extendedProps.photo.length > 0) && (
                      <span className="calendarPhotoPreviewSpan">
                        <img
                          className="calendarFormImgPreview"
                          src={
                            selectedEvent?._def.extendedProps.photo.length > 0
                              ? PF + selectedEvent?._def.extendedProps.photo[0]
                              : newPhotoPreview
                          }
                        />
                        {(selectedEvent?._def.extendedProps.photo.length > 1 ||
                          newPhoto.length > 1 ||
                          selectedEvent?._def.extendedProps.photo.length +
                            newPhoto.length >
                            1) && (
                          <span className="newApplicationFormImgPreviewLength">
                            {" "}
                            +
                            {selectedEvent?._def.extendedProps.photo.length +
                              newPhoto.length -
                              1}
                          </span>
                        )}
                      </span>
                    )}
                  </label>
                  {selectedEvent?._def.extendedProps.photo.length > 0 && (
                    <ClearIcon
                      onClick={() => handleResetField("photo")}
                      className="clearIconDeleteField"
                    />
                  )}
                </span>
                <span className="schoolCalendarEditPopupWrapperLabelInline">
                  <input
                    required
                    ref={desc}
                    type="text"
                    placeholder={
                      selectedEvent._def.extendedProps.desc || "Description"
                    }
                  />
                  {selectedEvent._def.extendedProps.desc && (
                    <ClearIcon
                      onClick={() => handleResetField("desc")}
                      className="clearIconDeleteField"
                    />
                  )}
                </span>
              </span>
              <span>
                <input
                  ref={start}
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                  }}
                  onBlur={(e) => (e.target.type = "text")}
                  placeholder={`${
                    t(`${translationPage}.Start Date : `) +
                      selectedEvent.start?.toString()?.slice(0, 15) || ""
                  }`}
                />
                <input
                  ref={end}
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.min = start;
                  }}
                  onBlur={(e) => (e.target.type = "text")}
                  placeholder={`${
                    t(`${translationPage}.End Date : `) +
                      selectedEvent.end?.toString().slice(0, 15) || ""
                  }`}
                />
                <select ref={startTime}>
                  <option value="" selected hidden>
                    {t(`${translationPage}.Start Time`)}{" "}
                    {selectedEvent.start?.toString().slice(16, 21) || ""}
                  </option>
                  {meetingTimes.map((time) => (
                    <option value={time.value}>{time.value}</option>
                  ))}
                </select>
                <select ref={endTime}>
                  <option value="" selected hidden>
                    {t(`${translationPage}.End Time:`)}{" "}
                    {selectedEvent.end?.toString().slice(16, 21) || ""}
                  </option>
                  {meetingTimes.map((time) => (
                    <option value={time.value}>{time.value}</option>
                  ))}
                </select>
                <span className="schoolCalendarEditPopupWrapperLabelInline">
                  <input
                    required
                    ref={link}
                    type="text"
                    placeholder={
                      selectedEvent?._def.extendedProps.link || "Link"
                    }
                  />
                  {selectedEvent?._def.extendedProps.link && (
                    <ClearIcon
                      onClick={() => handleResetField("link")}
                      className="clearIconDeleteField"
                    />
                  )}
                </span>
              </span>
            </form>
            <span className="schoolCalendarPromptBtns">
              {loadingUpdate ? (
                <ClipLoader color="#4a154d" size={25} />
              ) : (
                <button onClick={handleUpdate}>
                  {t(`${translationPage}.Update`)}
                </button>
              )}
              <button
                onClick={() => {
                  setHandleShowEdit(false); /*setDisplayPrompt(false)*/
                }}
              >
                {t(`${translationPage}.Cancel`)}
              </button>
            </span>
          </div>
        ) : (
          <div className="schoolCalendarPromptPopupWrapper">
            <button onClick={() => setHandleShowEdit(true)}>
              {t(`${translationPage}.Edit Event`)}
            </button>
            <button onClick={() => handleEventClick()}>
              {t(`${translationPage}.Delete Event`)}
            </button>
            <button onClick={() => setShowEditPopup(false)}>
              {t(`${translationPage}.Cancel`)}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {user && (
        <Link className="links" style={{ padding: "20px" }} to="/user">
          <ArrowBackIosIcon /> {t(`${translationPage}.Go Back`)}
        </Link>
      )}
      {showEditPopup && <EditDeleteEvent />}
      {displayPrompt && <Prompt />}
      {loading ? (
        <span className="agendaLoaderSpan">
          <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
        </span>
      ) : (
        <>
          <div className="schoolCalendarNotes">
            <div>
              <span>
                <span style={{ backgroundColor: "#DE7134" }}></span>{" "}
                {t(`${translationPage}.Kindergarten`)}
              </span>
              <span>
                <span style={{ backgroundColor: "#4A164D" }}></span>
                {t(`${translationPage}.Elementary`)}
              </span>
              <span>
                <span style={{ backgroundColor: "#8DC341" }}></span>
                {t(`${translationPage}.Middle School`)}
              </span>
            </div>
            <div>
              <span>
                <span style={{ backgroundColor: "gray" }}></span>
                {t(`${translationPage}.High School`)}
              </span>
              <span>
                <span style={{ backgroundColor: "#1A252F" }}></span>
                {t(`${translationPage}.Many / All Cycles`)}
              </span>
            </div>
          </div>
          <FormGroup>
            <div className="schoolCalendarSwitches">
              <FormControlLabel
                control={
                  <Switch
                    color={showMinistryEvents ? "secondary" : "warning"}
                    checked={showMinistryEvents}
                    defaultChecked={false}
                    onChange={() => {
                      setShowSchoolEvents(false);
                      setShowAllEvents(false);
                      setShowMinistryEvents(true);
                    }}
                  />
                }
                className="schoolCalendarSwitch"
                label={t(`${translationPage}.Only Show Official Events`)}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showSchoolEvents}
                    color={showSchoolEvents ? "secondary" : "warning"}
                    defaultChecked={false}
                    onChange={() => {
                      setShowMinistryEvents(false);
                      setShowSchoolEvents(true);
                      setShowAllEvents(false);
                    }}
                  />
                }
                className="schoolCalendarSwitch"
                label={t(`${translationPage}.Only Show School Events`)}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showAllEvents}
                    color={showAllEvents ? "secondary" : "warning"}
                    defaultChecked={true}
                    onChange={() => {
                      setShowMinistryEvents(false);
                      setShowAllEvents(true);
                      setShowSchoolEvents(false);
                    }}
                  />
                }
                className="schoolCalendarSwitch"
                label={t(`${translationPage}.Show All Events`)}
              />
            </div>
          </FormGroup>
          <div className="schoolCalendarContainer">
            <Box m="20px">
              <Box display="flex" justifyContent="space-between">
                <Box flex="1 1 100%" ml="15px">
                  <FullCalendar
                    height="75vh"
                    plugins={[
                      multiMonthPlugin,
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      listPlugin,
                    ]}
                    headerToolbar={
                      shouldHideHeaderToolbar
                        ? null
                        : {
                            left: "prev next today",
                            center: "title",
                            right:
                              "multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                          }
                    }
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    select={(e) => {
                      user && setSelectedDate(e["startStr"]);
                      user && setDisplayPrompt(true);
                      user && setCalendarApi(e.view.calendar);
                    }}
                    eventTimeFormat={eventTimeFormat}
                    eventClick={
                      user && (user.role === "admin" || user.role === "it")
                        ? (e) => {
                            setShowEditPopup(true);
                            setSelectedEvent(e.event);
                          }
                        : handleNoUserEventClick
                    }
                    weekends={true}
                    events={initialEvents}
                    eventContent={(eventInfo) => {
                      return (
                        <div
                          className={`schoolCalendarEventTitle ${
                            eventInfo.event._def.extendedProps.cycle.length > 1
                              ? "many"
                              : eventInfo.event._def.extendedProps.cycle[0]
                          }`}
                        >
                          {eventInfo.event.title}
                        </div>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default Calendar;
