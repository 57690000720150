import "./currentHiringNeeds.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext, useEffect, useState } from "react";
import {
  positions,
  hiringTypes,
  cycles,
  somethingWentWrong,
} from "../../utils/constants";
import axios from "axios";
import { message } from "antd";
import { AuthContext } from "../../context/AuthContext";
import DeletePopup from "../../components/deletePopup/DeletePopup";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

const defaultNeedData = {
  position: "",
  type: "",
  cycle: "",
  start: "",
  urgent: false,
};

const CurrentHiringNeeds = () => {
  const { user } = useContext(AuthContext);
  const userRole = user?.role;
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [needs, setNeeds] = useState([]);
  const [loadingNeeds, setLoadingNeeds] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const element = "need";
  const [selectedNeed, setSelectedNeed] = useState();
  const [refresh, setRefresh] = useState();
  const { t } = useTranslation();
  const translationComponent = "hiringNeeds";

  useEffect(() => {
    setLoadingNeeds(true);
    const fetchNeeds = async () => {
      try {
        const { data } = await axios.get(`hiringNeeds`);
        if (data.status === "success") setNeeds(data.needs);
        else message.error(data.message);
      } catch (err) {
        message.error(somethingWentWrong);
      }
      setLoadingNeeds(false);
    };
    fetchNeeds();
  }, [refresh]);

  const displayDeletePopup = () => {
    return (
      <div className="deletePopupContainer">
        <DeletePopup
          element={element}
          closeDeletePopup={() => {
            setOpenDeletePopup(false);
          }}
          need={selectedNeed}
          userRole={userRole}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    );
  };

  const CreateNewNeed = () => {
    const [needData, setNeedData] = useState(defaultNeedData);
    const handleNeedData = (newValue, fieldName) => {
      needData[fieldName] = newValue;
      setNeedData(needData);
    };
    const [cycleList, setCycleList] = useState([]);
    const [cycle, setCycle] = useState([]);

    const handleCreateNewNeed = async (e) => {
      e.preventDefault();
      const { position, type, cycle, start, urgent } = needData;
      if (needData.position.trim() === "")
        message.error(t(`${translationComponent}.positionCantBeEmpty`));
      if (needData.type.trim() === "")
        message.error(t(`${translationComponent}.typeCantBeEmpty`));

      const newNeed = {
        position,
        type,
        cycle,
        start,
        urgent,
      };
      try {
        const { data } = await axios.post("hiringNeeds", newNeed);
        if (data.status === "success") {
          message.success(data.message);
          setNeedData(defaultNeedData);
          setRefresh(!refresh);
          setShowCreatePopup(false);
        }
      } catch (err) {
        message.error(somethingWentWrong);
      }
    };

    return (
      <div className="createNewNeedContainer">
        <div className="createNewNeedWrapper">
          <p>{t(`${translationComponent}.Create new HR Need`)}</p>
          <form className="createNewNeedForm">
            <span>
              <label>
                {t(`${translationComponent}.Position`)} &nbsp;{" "}
                <span style={{ color: "#ef711d" }}>*</span>
              </label>
              <select
                required
                name="position"
                onChange={(e) => handleNeedData(e.target.value, "position")}
              >
                <option value="" selected hidden></option>
                {positions.map((position) => (
                  <option key={position.value} value={position.value}>
                    {t(position.translationKey)}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label>
                {t(`${translationComponent}.Type`)} &nbsp;{" "}
                <span style={{ color: "#ef711d" }}>*</span>
              </label>
              <select
                required
                name="type"
                onChange={(e) => handleNeedData(e.target.value, "type")}
              >
                <option value="" selected hidden></option>
                {hiringTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {t(type.translationKey)}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label>{t(`${translationComponent}.Cycle`)}</label>
              <span className="currentHiringNeedsMultiSelectSpan">
                <Select
                  className="currentHiringNeedsMultiSelect"
                  required
                  options={cycles.map((cycle) => ({
                    value: cycle.value,
                    label: t(cycle.translationKey),
                  }))}
                  isMulti
                  value={cycleList}
                  onChange={(selected, triggeredAction) => {
                    if (triggeredAction.action === "clear") {
                      setCycleList([]);
                      setCycle([]);
                    } else if (triggeredAction.action === "remove-value") {
                      const removedValue = triggeredAction.removedValue.value;
                      setCycleList(
                        cycleList.filter(
                          (option) => option.value !== removedValue
                        )
                      );
                      setCycle(cycle.filter((val) => val !== removedValue));
                    } else if (triggeredAction.action === "select-option") {
                      setCycleList(selected);
                      const values = selected?.map((option) => option?.value);
                      setCycle(values);
                    }
                  }}
                  closeMenuOnSelect={false}
                  placeholder="Cycle(s)"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      outline: state.isFocused ? "none" : "none",
                      border: state.isFocused ? "none" : "none",
                      boxShadow: state.isFocused ? "none" : "none",
                      height: state.menuIsOpen ? "100%" : "40px",
                      overflow: state.isFocused ? "visible" : "hidden",
                      overflow: state.menuIsOpen ? "visible" : "hidden",
                      width: "100%",
                    }),
                    indicatorsContainer: (base, state) => ({
                      ...base,
                      zIndex: "999",
                      backgroundColor: "white",
                    }),
                  }}
                />
              </span>
            </span>
            <span>
              <label>
                {t(
                  `${translationComponent}.Start (e.g: Immediately, September...)`
                )}
              </label>
              <input
                onChange={(e) => handleNeedData(e.target.value, "start")}
                type="text"
              />
            </span>
            <span>
              <label>{t(`${translationComponent}.Urgent`)}</label>
              <select
                name="urgent"
                onChange={(e) => handleNeedData(e.target.value, "urgent")}
              >
                <option value="" selected hidden></option>
                <option key="true" value="true">
                  {t(`${translationComponent}.Yes`)}
                </option>
                <option key="false" value="false">
                  {t(`${translationComponent}.No`)}
                </option>
              </select>
            </span>
          </form>
          <span className="createNewNeedBtns">
            <button onClick={handleCreateNewNeed}>
              {t(`${translationComponent}.Create`)}
            </button>
            <button
              onClick={() => {
                setNeedData(defaultNeedData);
                setShowCreatePopup(false);
              }}
            >
              {t(`${translationComponent}.Cancel`)}
            </button>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="currentHiringNeedsContainer">
        <button
          className="currentHiringNeedsAddBtn"
          onClick={() => setShowCreatePopup(true)}
        >
          {t(`${translationComponent}.Add New`)}
        </button>
        {loadingNeeds ? (
          <span className="agendaLoaderSpan">
            <ClipLoader className="agendaLoader" color="#4a154d" size={100} />
          </span>
        ) : (
          <div className="currentHiringNeedsWrapper">
            {needs?.map((need) => (
              <span
                className={
                  need.urgent
                    ? "currentHiringNeed currentHiringNeedUrgt"
                    : "currentHiringNeed"
                }
              >
                <DeleteIcon
                  onClick={() => {
                    setSelectedNeed(need);
                    setOpenDeletePopup(true);
                  }}
                  className="currentHiringNeedDeleteIcon"
                />
                <p>{need.position}</p>
                <p>{need.type}</p>
                <p>{need.cycle}</p>
                <p>{need.start}</p>
              </span>
            ))}
          </div>
        )}
      </div>
      <span className="currentHiringNeedsColorCode">
        <span></span>
        <p>{t(`${translationComponent}.Urgent`)}</p>
      </span>
      {openDeletePopup && displayDeletePopup()}

      {showCreatePopup && <CreateNewNeed />}
    </>
  );
};

export default CurrentHiringNeeds;
